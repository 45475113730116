import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
// import Accordion from '../../components/general/accordion';
// import { qurbanFaqs } from './faqs';
import { selectProducts } from '../../store/products';
import sheepBundleImg from '../../images/landings/dhul-hijjah/goat-bundle.png';
// import cowBundleImg from '../../images/landings/dhul-hijjah/cow-bundle.png';
import buildMasjidImg from '../../images/landings/dhul-hijjah/build-masjid.jpg';
import waterWellImg from '../../images/landings/dhul-hijjah/water-well.jpg';
import pumpWaterWellImg from '../../images/landings/dhul-hijjah/pump-water-well.jpg';
import powerWaterWellImg from '../../images/landings/dhul-hijjah/power-water-well.jpg';
import dhulHijjah1Img from '../../images/landings/dhul-hijjah/dhul-hijjah-1.jpg';
import dhulHijjah2Img from '../../images/landings/dhul-hijjah/dhul-hijjah-2.jpg';
import dhulHijjahVectorImg from '../../images/landings/dhul-hijjah/dhul-hijjah-vector.png';
import banner from '../../images/landings/dhul-hijjah/Dhul-Hijjah-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/landings/dhul-hijjah/Dhul-Hijjah-Hero-Banner-Mobile.jpg';
import { QuoteWrapper } from '../../components/general/quoteWrapper';
// import DhulHijjahTabs from './components/dhulHijjahTabs';

const QurbanDhulHijjahLandingPage = ({ isCampaign }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const seo = SeoData[selectedcountryCode];
  const [sheepGoat, setSheepGoat] = useState({
    title: 'qurban sheep/goat',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      type: 'goat',
      title: 'Ultimate Qurban'
    }
  });
  const [cowShare, setCowShare] = useState({
    title: 'qurban cow share',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      title: 'Ultimate Qurban',
      // type: 'cow',
      type: 'goat',
    }
  });
  const [cow, setCow] = useState({
    title: 'cow',
    description: '',
    checkboxProducts: [],
    products: [],
  });
  const [foodAid, setFoodAid] = useState({
    title: 'Food Aid',
    description: '',
    checkboxProducts: [],
    products: [],
  });
  const [zakat, setZakat] = useState({
    title: 'Zakat',
    description: '',
    products: [],
  });
  // const [buildAMasjidTab, setBuildAMasjidTab] = useState([0]);
  const [buildAMasjid] = useState({
    title: 'Build A Masjid',
    description: 'Your generosity can aid in the construction of a Main Masjid block and a small mosque, providing a serene and devout space for the small community to connect with Allah through prayer.',
    img: buildMasjidImg,
    alt: '',
    products: [],
  });
  // const [buildAWellTab, setBuildAWellTab] = useState([0, 0, 0]);
  const [waterWell] = useState({
    title: 'Water Well',
    description: `This donation will go towards building wells where they are most needed and will also help with maintaining existing wells.`,
    img: waterWellImg,
    products: [],
  });
  const [pumpWaterWell] = useState({
    title: 'hand pump Water Well',
    description: `Give a community clean water with a hand pump well, reliable for over a decade and backed by a 3-year warranty.`,
    img: pumpWaterWellImg,
    products: [],
  });
  const [powerWaterWell] = useState({
    title: 'powered Water Well',
    description: `Provide clean, running water to communities in need with L1/L2 water wells, powered by solar/electricity for easy access, and make a lasting impact over 40+ years.`,
    img: powerWaterWellImg,
    products: [],
  });
  // const [palestineEmergencyTab, setPalestineEmergencyTab] = useState([0, 0, 0]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSheepGoatProducts = [];
    let tempSheepGoatBundleProduct = {};
    let tempCowShareProducts = [];
    // let tempCowShareBundleProduct = {};
    let tempCowProducts = [];
    let tempFoodAidProducts = [];
    let tempZakatProducts = [];
    let tempCheckBoxProducts = [];
    let tempBuildAMasjidTab = [];
    let tempBuildAMasjidProducts = [];
    let tempBuildAWellTab = [];
    let tempWaterWellProducts = [];
    let tempPumpWaterWellProducts = [];
    let tempPowerWaterWellProducts = [];
    let tempPalestineEmergencyProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Checkbox products
          if (inner_item_product.creator === 'TK-QB-2024-FA-00') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine 2kg Meat',
            });
            tempCheckBoxProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-FA-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine 10kg of Rice',
            });
            tempCheckBoxProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-EG28') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Qurban Eid Gift',
            });
            tempCheckBoxProducts[2] = inner_item_product;
          }

          // SheepGoat
          if (inner_item_product.creator === 'QB-2024-S17') {

            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine',
            });
            tempSheepGoatProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S18') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestinian Refugees',
              nameDescription: `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempSheepGoatProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S19') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Yemen',
              showHeartIcon: true,
            });
            tempSheepGoatProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S20') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
              showHeartIcon: true,
            });
            tempSheepGoatProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S24') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Syrian Refugees',
              nameDescription: `(based in Türkiye)`,
            });
            tempSheepGoatProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S23') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Afghanistan',
            });
            tempSheepGoatProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S25') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Jordan',
            });
            tempSheepGoatProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S21') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
            });
            tempSheepGoatProducts[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S27') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempSheepGoatProducts[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S22') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempSheepGoatProducts[9] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-UQ-01') {
            tempSheepGoatBundleProduct = inner_item_product;
          }

          // Cow share
          // if (inner_item_product.creator === 'QB-2024-S9') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Palestine',
          //   });
          //   tempCowShareProducts[0] = inner_item_product;
          // }
          if (inner_item_product.creator === 'QB-2024-S11') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'India',
              showHeartIcon: true,
            });
            tempCowShareProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S10') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Egypt',
              name: 'Palestinian Refugees',
              nameDescription: `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempCowShareProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S12') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
              showHeartIcon: true,
            });
            tempCowShareProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S16') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempCowShareProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S14') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
              isSoldOut: true,
            });
            tempCowShareProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S15') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Turkey',
              isSoldOut: true,
            });
            tempCowShareProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S13') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempCowShareProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S9') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: `Palestine`,
              isSoldOut: true,
            });
            tempCowShareProducts[7] = inner_item_product;
          }
          // if (inner_item_product.creator === 'QB-2024-UQ-02') {
          //   tempCowShareBundleProduct = inner_item_product;
          // }

          // Cow
          // if (inner_item_product.creator === 'QB-2024-S1') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Palestine',
          //   });
          //   tempCowProducts[0] = inner_item_product;
          // }
          if (inner_item_product.creator === 'QB-2024-S3') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'India',
              showHeartIcon: true,
            });
            tempCowProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S2') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestinian Refugees',
              nameDescription: `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempCowProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S4') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
              showHeartIcon: true,
            });
            tempCowProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S8') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempCowProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S6') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
              isSoldOut: true,
            });
            tempCowProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S7') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Turkey',
              isSoldOut: true,
            });
            tempCowProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S5') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempCowProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S1') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: `Palestine`,
              isSoldOut: true,
            });
            tempCowProducts[7] = inner_item_product;
          }

          // Food Aid
          if (inner_item_product.creator === 'QB-2024-FA-03') {
            tempFoodAidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') {
            tempFoodAidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Feed 10 Orphans'
            });
            tempFoodAidProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Feed 20 Orphans'
            });
            tempFoodAidProducts[3] = inner_item_product;
          }

          // Zakat
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Give Zakat Al Mal'
            });
            tempZakatProducts[1] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              staticLink: '/zakat-calculator',
              name: 'Calculate your Zakat',
              creator: 'RM24-ZM-013-'
            });
            tempZakatProducts[0] = inner_item_product;
          }

          // Build Masjid
          if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') tempBuildAMasjidProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'MSJ-WA-LRG-2023-02') tempBuildAMasjidProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'GD-MP015') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempBuildAMasjidProducts[2] = inner_item_product
          };

          // Build Water well
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempWaterWellProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') tempPumpWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempPumpWaterWellProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') tempPumpWaterWellProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-IBW-2023-01-031') tempPumpWaterWellProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempPowerWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-L2W-2023-01-027') tempPowerWaterWellProducts[1] = inner_item_product;

          // palestineEmergencyProducts
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            // inner_item_product = Object.assign({}, inner_item_product, {
            //   name: 'PALESTINE APPEAL',
            // });
            tempPalestineEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') tempPalestineEmergencyProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') tempPalestineEmergencyProducts[2] = inner_item_product;

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSheepGoatProducts = tempSheepGoatProducts.filter(n => n)
    tempCowShareProducts = tempCowShareProducts.filter(n => n)
    tempCowProducts = tempCowProducts.filter(n => n)
    tempFoodAidProducts = tempFoodAidProducts.filter(n => n)
    tempZakatProducts = tempZakatProducts.filter(n => n)
    tempCheckBoxProducts = tempCheckBoxProducts.filter(n => n)
    tempBuildAMasjidProducts = tempBuildAMasjidProducts.filter(n => n)
    tempWaterWellProducts = tempWaterWellProducts.filter(n => n)
    tempPumpWaterWellProducts = tempPumpWaterWellProducts.filter(n => n)
    tempPowerWaterWellProducts = tempPowerWaterWellProducts.filter(n => n)

    tempBuildAMasjidTab[0] = buildAMasjid;
    tempBuildAMasjidTab[0].products = tempBuildAMasjidProducts;

    tempBuildAWellTab[0] = waterWell;
    tempBuildAWellTab[0].products = tempWaterWellProducts;
    tempBuildAWellTab[1] = pumpWaterWell;
    tempBuildAWellTab[1].products = tempPumpWaterWellProducts;
    tempBuildAWellTab[2] = powerWaterWell;
    tempBuildAWellTab[2].products = tempPowerWaterWellProducts;

    // setBuildAMasjidTab(tempBuildAMasjidTab);
    // setBuildAWellTab(tempBuildAWellTab);
    // setPalestineEmergencyTab(tempPalestineEmergencyProducts);


    setSheepGoat({
      ...sheepGoat,
      products: tempSheepGoatProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...sheepGoat.bundleProduct, product: tempSheepGoatBundleProduct }
    });
    setCowShare({
      ...cowShare,
      products: tempCowShareProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...cowShare.bundleProduct, product: tempSheepGoatBundleProduct }
      // bundleProduct: { ...cowShare.bundleProduct, product: tempCowShareBundleProduct }
    })
    setCow({
      ...cow,
      checkboxProducts: tempCheckBoxProducts,
      products: tempCowProducts
    })
    setFoodAid({
      ...foodAid,
      checkboxProducts: tempCheckBoxProducts,
      products: tempFoodAidProducts
    });
    setZakat({
      ...zakat,
      products: tempZakatProducts
    })
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white" showRegularImg={false}>
      {seo && (
        <Helmet>
          <title>{`${seo['qurban']['title']}`}</title>
          <meta name="title" content={`${seo['qurban']['title']}`} />
          <meta name="description" content={`${seo['qurban']['description']}`} />
        </Helmet>
      )}
      {/* /* -------------------------------------------------------------------------- */}
      <section className="flex justify-center" >
        <img src={banner} alt="banner" className='max-[500px]:hidden w-full' />
        <img src={bannerMobile} alt="banner" className='min-[501px]:hidden w-full' />
      </section>
      <section className="flex justify-center flex-col" >
        <div className="md:container md:mx-auto px-4 order-2 md:order-1">
          <div className="flex flex-col py-8 md:py-16">
            <h1 className="text-[#253B7E] md:text-center leading-[30px] md:leading-[50px] text-[25px] md:text-[40px] mb-4 md:mb-6 font-brandingBold">
              The first <span className='text-[#F60362] mx-1'>10 days of Dhul-Hijjah</span> are the days most beloved by Allah SWT. These days are revered as the best days of the year, surpassing even the esteemed days of Ramadan. Prophet Muhammad ﷺ emphasized their importance, stating,
            </h1>
            <div className="my-4">
              <QuoteWrapper
                quote={true}
                quoteText={'- (Bukhari)'}
                className="bg-[#E1EFF2]"
                text={`There are no days on which righteous deeds are more beloved to Allah than these ten days.`}
              />
            </div>
            <p className="md:text-lg text-[#78716C] font-brandingMedium- font-['Montserrat'] text-center-">
              It is within these days that we are encouraged to perform deeds that are most beloved by Allah SWT - the Deeds of Dhul Hijjah -  from <span className="text-[#253B7E] font-bold mx-1">extra Salat, reading Quran, dua, fasting</span> and <span className="text-[#253B7E] font-bold ml-1">giving Sadaqah</span>.
            </p>
          </div>
        </div>
        {/* ------------------------------- Products Section ---------------------------- */}
        {/* <div className="flex- w-full order-1 md:order-2">
          <DhulHijjahTabs
            headerText="This Dhul Hijjah, perform the Deed that Feeds with your Qurban!"
            footerText="Don’t wait. Donate your Qurban Today."
            sheepGoatProducts={sheepGoat}
            cowShareProducts={cowShare}
            cowProducts={cow}
            foodAidProducts={foodAid}
            zakatProducts={zakat}
            buildAMasjidTab={buildAMasjidTab}
            buildAWellTab={buildAWellTab}
            palestineEmergencyTab={palestineEmergencyTab}
          />
        </div> */}
        {/* /* -------------------------------------------------------------------------- */}
      </section>
      <section className='md:container md:mx-auto px-4 md:px-0 py-8 md:py-12'>
        <div className="flex flex-wrap">
          <div className="basis-full md:basis-1/2 lg:basis-5/12 flex flex-col">
            <img src={dhulHijjah1Img} alt="dhul-hijjah" className='w-full mb-2' />
            <img src={dhulHijjah2Img} alt="dhul-hijjah" className='w-full mb-2' />
          </div>
          <div className="basis-full md:basis-1/2 lg:basis-6/12 flex flex-col md:pl-8 lg:pl-12">
            <h1 className="text-2xl md:text-[40px] md:leading-[44px] text-[#253b7e] font-brandingBold mb-2 md:mb-2">
              Maximize your blessings and rewards: <span className="text-[#f60362]">Perform the Deeds of Dhul Hijjah</span>
            </h1>
            <p className="text-[#78716C] md:text-lg font-['Montserrat']">
              Harness the spiritual potential of Dhul Hijjah and increase your rewards by engaging in righteous deeds loved by Allah:
            </p>
            <div className="flex items-start pt-4 md:pt-8">
              <img src={dhulHijjahVectorImg} alt="dhulhijjah" className='' />
              <div className="pl-2 md:pl-4">
                <h3 className="text-lg text-[#253b7e] font-brandingBold mb-2">Fasting</h3>
                <p className="text-[#78716C] md:text-lg- font-['Montserrat']">
                  Follow the sunnah of fasting during the first nine days of Dhul Hijjah including the Day of Arafat.
                </p>
              </div>
            </div>
            <div className="flex items-start pt-4 md:pt-8">
              <img src={dhulHijjahVectorImg} alt="dhulhijjah" className='' />
              <div className="pl-2 md:pl-4">
                <h3 className="text-lg text-[#253b7e] font-brandingBold mb-2">The Virtues of Giving</h3>
                <p className="text-[#78716C] md:text-lg- font-['Montserrat']">
                  Perform the Deed that Feeds by fulfilling your Qurban/Qurbani, helping us reach impoverished communities worldwide. MATW delivers Qurban/Qurbani in the most difficult to reach areas including Yemen and Palestine, ensuring families in need can celebrate Eid with dignity.
                </p>
              </div>
            </div>
            <div className="flex items-start pt-4 md:pt-8">
              <img src={dhulHijjahVectorImg} alt="dhulhijjah" className='' />
              <div className="pl-2 md:pl-4">
                <h3 className="text-lg text-[#253b7e] font-brandingBold mb-2">Empower Change Through Charity</h3>
                <p className="text-[#78716C] md:text-lg- font-['Montserrat']">
                  Embrace the spirit of giving by donating Zakat and Sadaqah during this blessed month. Your contributions alleviate hunger, provide stability, and spread joy among vulnerable populations, making Eid al-Adha a time of celebration for all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /* -------------------------------- helped us ------------------------------- */}

      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Images --------------------------------- */}
      <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7] p-4 md:py-16 md:px-0`} >
        <div className="w-full md:container md:mx-auto bg-white rounded-md p-8 lg:p-16">
          <h2 class="cursor-pointer w-full text-center text-[30px] lg:text-[50px] font-['Gotcha'] mb-4 md:mb-6 text-[#253B7E]">
            Experience the Sacredness of <span className="text-[#F60362]">Dhul Hijjah</span>
          </h2>
          <p className="text-[#78716C] md:text-lg font-['Montserrat'] text-center w-full md:w-10/12 md:mx-auto">
            Unlock the blessings of the ten best days by supporting MATW’s efforts to uplift communities in need. Your generosity not only fulfills religious obligations but also embodies the spirit of compassion and solidarity cherished by Allah (SWT). Join us in making a difference this Dhul Hijjah.
          </p>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h2 className="text-[#253B7E] text-left  sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-brandingBold">
          Dhul Hijjah <span className="text-[#F60362]"> FAQ’s</span>
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">What deeds should Muslims do in the 10 Days of Dhul Hijjah?</p>
            <div className="text-base font-brandingMedium mt-3 text-[#78716C]">
              <p className="">
                The first 10 days of Dhul Hijjah are the most loved by Allah. So believers should strive in them with specific acts of worship. They should:
              </p>
              <ul className="list-decimal pl-2 mt-2">
                <li>Perform their Udhiyah (Qurban/Qurbani) sacrifice.</li>
                <li>Fast/Siyam. In addition to performing Udhiyah, Prophet Muhammad PBUH encouraged fasting especially on the 9th day of Dhul Hijjah (Arafat).</li>
                <li>Dhikr and rememberance of Allah SWT. This is also the practice of Prophet Muhammad PBUH. Especially takbir, tahmid, tahlil, tasbih.</li>
                <li>Sadaqah. All Muslims are encouraged to spend in charity, not just in the days of Dhul Hijjah but all times of the year. It is in these days, however, where rewards are increased.</li>
                <li>Repentance. All Muslims are encouraged to sincerely seek forgiveness.</li>
              </ul>
            </div>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">What date is 1st Dhul Hijjah 2024?</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">Dhul Hijjah 2024 begins on 7th June 2024 and runs for a month until July 7, 2024. The 9th of Dhul Hijjah (Day of Arafah) will be on June 16 and 10th Dhul Hijjah (Eid al Adha) will be on June 17.</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">Do I have to fast all days of the first 10 days of Dhul Hijjah?</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">It is encouraged that we as Muslims fast during the first 9 days of Dhul Hijjah, as it is a sunnah that is rewarded and expiates sind. It's highly recommended to fast on the 9th day of Dhul Hijjah, which is the Day of Arafat. The 10th day of Dhul Hijjah is Eid al-Adha. Fasting on this day is prohibited.</p>
          </div>
        </div>

      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default QurbanDhulHijjahLandingPage;