import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react';
import VideosSection from '../../components/general/videosSection';
import sheepBundleImg from '../../images/landings/dhul-hijjah/goat-bundle.png';
import { selectProducts } from '../../store/products';
import WidgetHome from "../../components/widgets/widgetHome"
// import banner from '../../images/landings/palestine/Palestine-mercy-wide-hero-banner.jpg';
// import bannerMobile from '../../images/landings/palestine/Palestine-mercy-mobile-hero-banner.jpg';
// import bannerMobile from '../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Mobile.jpg';
import bannerFr from '../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Desktop-fr.jpg';
import bannerMobileFr from '../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Mobile-fr.jpg';
import matwImpactPhoto from '../../images/landings/palestine/matw-impact.png';

import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import sponsorFamilyImg from '../../images/landings/palestine/crisis-and-emergencies-palestine-palestinian-refugee-egypt-settlement.jpg';
import palestineRefugeeImg from '../../images/landings/palestine/crisis-and-emergencies-palestine-palestinian-refugee-settlement-support.jpg';
import shelterOrphanImg from '../../images/landings/palestine/shelter-for-orphan.jpg';
import feedOrphanImg from '../../images/landings/palestine/feed-a-palestine-orphan.jpg';
import supportFamilyImg from '../../images/landings/palestine/support-a-family-with-orphans.jpg';
import babyMilkImg from '../../images/landings/palestine/food-and-water-aid-food-aid-baby-milk-for-52-weeks.jpg';
import donateMealImg from '../../images/landings/palestine/donate-a-meal.jpg';
import donateCleanWaterImg from '../../images/landings/palestine/donate-clean-water.jpg';
import { OurPromiseSection } from '../../components/general';
import PalestineTabs from './components/palestineTabs';
import { impactInfo, impactSliderData, palestineVideos as videos } from './fixtures';
import DonateSection from './components/common-sections/donate';
import MuslimsAroundSection from './components/common-sections/muslimsAround';
import ImagesSection from './components/common-sections/images';
import FAQSection from './components/common-sections/faq';
import limbsImg from '../../images/landings/palestine/big-Limbs_of_Hope_Campaign_Website_Image.jpg';

const PalestineLandingPage = ({ lang = 'en' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);
  const [saveOrphansProducts, setSaveOrphansProducts] = useState([0, 0, 0, 0]);
  const [palestineRefugeeSettlementProducts, setPalestineRefugeeSettlementProducts] = useState([0, 0]);
  const [palestineHungerProducts, setPalestineHungerProducts] = useState([0, 0]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [sheepGoat, setSheepGoat] = useState({
    // title: 'qurban sheep/goat',
    title: lang === 'fr' ? 'MOUTONS/CHÈVRES QURBANI' : lang === 'ar' ? 'الأضاحي الأغنام / الماعز' : 'qurban sheep/goat',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      type: 'goat',
      // title: 'Ultimate Qurban'
      title: lang === 'fr' ? 'Qurbane ultime' : lang === 'ar' ? 'حزمة أضاحي' : 'Ultimate Qurban',
    },
  });
  const [cowShare, setCowShare] = useState({
    // title: 'qurban cow share',
    title: lang === 'fr' ? 'Partage de vaches' : lang === 'ar' ? 'حصة البقر' : 'cow share',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      title: 'Ultimate Qurban',
      type: 'goat',
    },
  });
  const [cow, setCow] = useState({
    title: 'cow',
    description: '',
    checkboxProducts: [],
    products: [],
  });
  const [sponsorFamily] = useState({
    title: lang === 'fr' ? 'Parrainer une famille' : lang === 'ar' ? 'اكفل عائلة فلسطينية' : 'Sponsor a Family',
    description:
      lang === 'fr'
        ? 'Aidez à fournir un soutien continu aux familles de réfugiés palestiniens résidant en Égypte. Offrez des allocations mensuelles pour couvrir leurs besoins essentiels, notamment la nourriture, les frais médicaux, le loyer et les frais d’éducation.'
        : lang === 'ar'
        ? `المساعدة في تقديم الدعم المستمر لعائلات اللاجئين الفلسطينيين المقيمين في مصر. سيقدم تبرعك مصروفا شهريا لمرة واحدة لتغطية الاحتياجات الأساسية المطلوبة بعد إعادة التوطين في بلد جديد ومنزل جديد. وهذا يشمل الغذاء والنفقات الطبية والإيجار وتكاليف التعليم.`
        : 'Help provide ongoing support to Palestinian refugee families residing in Egypt. Offer monthly allowances to cover their essential needs, including food, medical expenses, rent and education costs.',
    img: sponsorFamilyImg,
    products: [],
  });
  const [palestineRefugee] = useState({
    title:
      lang === 'fr' ? 'Installation des réfugiés palestiniens' : lang === 'ar' ? 'دعم توطين اللاجئين الفلسطينيين' : 'Palestine Refugee Settlement',
    description:
      lang === 'fr'
        ? 'Aidez à fournir tous les éléments essentiels nécessaires pour fonder une famille de réfugiés palestiniens lors de leur réinstallation en Égypte, dans leur nouveau foyer.'
        : lang === 'ar'
        ? `المساعدة في توفير الضروريات المطلوبة للاجئين من فلسطين لبدء حياة جديدة في مصر. سيساعد تبرعك في توفير الموارد اللازمة لهم لإعادة التشغيل وإعادة التوطين في مصر ، وتحقيق مستويات الراحة الأساسية في منازلهم الجديدة.`
        : 'Help provide all the essentials required to set up a Palestinian refugee family as they resettle in Egypt, in their new home.',
    img: palestineRefugeeImg,
    products: [],
  });
  const [feedAPalestineOrphan] = useState({
    title: lang === 'fr' ? 'Nourrir un orphelin palestinien' : lang === 'ar' ? 'أطعم يتيم فلسطيني' : 'Feed a Palestinian Orphan',
    description:
      lang === 'fr'
        ? 'Livrez un repas chaud aux enfants orphelins de Gaza et évitez-les de dormir le ventre vide pendant une famine imminente.'
        : lang === 'ar'
        ? `ساعد في إطعام الأيتام في فلسطين! سيقدم تبرعك وجبات مغذية ساخنة للأيتام الذين يعانون من آثار المجاعة التي تلوح في الأفق ، مما يضمن عدم نومهم جائعين.`
        : 'Deliver a hot meal to orphaned children in Gaza and protect them from sleeping hungry during a looming famine.',
    img: feedOrphanImg,
    products: [],
  });
  const [supportFamilyOrphan] = useState({
    title: lang === 'fr' ? 'Soutenir une famille avec des orphelins' : lang === 'ar' ? 'ادعم عائلة لديها أيتام' : 'Support A Family With Orphans',
    description:
      lang === 'fr'
        ? 'Votre don unique peut soutenir une veuve et des familles avec des enfants orphelins, qui ont perdu leur soutien de famille et leur capacité à gagner leur vie pendant un mois entier ! Ce don contribuera à fournir des repas quotidiens et à donner accès à de l’eau potable, les aidant ainsi à survivre à la famine et à alléger les souffrances provoquées par le conflit.'
        : lang === 'ar'
        ? `لمساعدة في تقديم الدعم العاجل للأرامل وأطفالهم الأيتام في فلسطين. يمكن أن يوفر تبرعك لمرة واحدة الإغاثة التي تشمل احتياجاتهم الأساسية مثل الطعام والوجبات اليومية والحصول على المياه النظيفة والملابس والدعم النفسي والرعاية الصحية والمساعدة التعليمية.`
        : 'Your one-off donation can sustain a widow and families with orphaned children, who have lost their breadwinner and ability to earn a living for an entire month!  This donation will help deliver daily meals and provide access to clean water, helping them survive the famine and alleviate the suffering brought on by conflict.',
    img: supportFamilyImg,
    products: [],
  });
  const [shelterOrphan] = useState({
    title: lang === 'fr' ? 'Refuge pour orphelins' : lang === 'ar' ? 'مأوى الأيتام' : 'Shelter for Orphans',
    description:
      lang === 'fr'
        ? `Protégez les orphelins en les abritant avec votre Sadaqah ! Ce fonds sera utilisé pour aider à protéger et à fournir un abri aux enfants orphelins palestiniens. Grâce à ce fonds, nous sommes en mesure de fournir des produits de première nécessité tels que des tentes, des kits d'hygiène, des couvertures, des oreillers, des matelas et d'autres produits de première nécessité nécessaires à une installation temporaire.`
        : lang === 'ar'
        ? `احم الأيتام من خلال إيوائهم بصدقتك! سيساعد تبرعك في توفير حزمة مأوى تتضمن خيمة ومجموعة أدوات النظافة والبطانيات والوسائد والمراتب والمزيد من الضروريات اللازمة للاستقرار المؤقت. تبرع الآن.`
        : `Protect orphans by sheltering them with your Sadaqah! This fund will be used to help protect and provide shelter to Palestinian orphaned children. Through this fund, we're able to deliver essentials such as tents, hygiene kits, blankets, pillows, mattresses and more basic necessities required for temporary settlement.`,
    img: shelterOrphanImg,
    products: [],
  });
  const [donateMeal] = useState({
    title: lang === 'fr' ? `Faites don d'un repas` : lang === 'ar' ? 'تبرع بوجبة' : 'Donate a Meal',
    description:
      lang === 'fr'
        ? `Soutenez les familles palestiniennes confrontées à la faim. Faites un don d'un repas aujourd'hui. Votre don fournit des nutriments essentiels à une famille dans le besoin.`
        : lang === 'ar'
        ? `قف مع العائلات في فلسطين التي تواجه الجوع. تبرع بوجبة اليوم. يوفر تبرعك العناصر الغذائية الأساسية لعائلة محتاجة.`
        : `Stand with Families in Palestine Facing Hunger. Donate a Meal Today. Your donation provides essential nutrients for a family in need.`,
    img: donateMealImg,
    products: [],
  });
  const [donateCleanWater] = useState({
    title: lang === 'fr' ? `Faites un don d'eau propre` : lang === 'ar' ? 'تبرع بالمياه النظيفة' : 'Donate Clean Water',
    description:
      lang === 'fr'
        ? `Faites un don d’eau potable aujourd’hui : chaque dollar fournit une hydratation essentielle à une famille dans le besoin.`
        : lang === 'ar'
        ? `تبرع بالمياه النظيفة اليوم: كل دولار يوفر الماء الأساسي لعائلة محتاجة.`
        : `Donate Clean Water Today: Every dollar provides essential hydration for a family in need.`,
    img: donateCleanWaterImg,
    products: [],
  });
  const [babyMilk] = useState({
    title: lang === 'fr' ? `Lait pour bébé` : lang === 'ar' ? 'حليب الأطفال' : 'Baby milk',
    description:
      lang === 'fr'
        ? `Fournir une livraison urgente de lait pour bébé aux familles qui n’ont pas les moyens de fournir cet aliment de base à leurs nourrissons.`
        : lang === 'ar'
        ? `توفير إمدادات عاجلة من حليب الأطفال للأسر التي لا تستطيع توفير هذا الغذاء الأساسي لأطفالها الرضع.`
        : `Provide a delivery of an urgent baby milk supply to families who cannot afford to provide this staple to their infant children.`,
    img: babyMilkImg,
    products: [],
  });

  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempPalestineEmergencyProducts = [];
    let tempSaveOrphansProducts = [];
    let tempPalestineRefugeeSettlementProducts = [];
    let tempPalestineHungerProducts = [];
    let tempFeedOrphanProducts = [];
    let tempSupportFamilyProducts = [];
    let tempShelterOrphanProducts = [];
    // let tempPalestineRefugeeProducts = [];
    let tempSponsorFamilyProducts = [];
    let tempSupportRefugeeSettlementProducts = [];
    let tempDonateMealProducts = [];
    let tempDonateWaterProducts = [];
    let tempBabyMilkProducts = [];
    let tempSheepGoatProducts = [];
    let tempSheepGoatBundleProduct = {};
    let tempCowShareProducts = [];
    let tempCheckBoxProducts = [];
    let tempCowProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // palestineEmergencyProducts
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Sauvez des vies en Palestine` : lang === 'ar' ? `أنقذوا الأرواح في فلسطين` : inner_item_product.name,
              description:
                lang === 'fr'
                  ? `Fournir une aide essentielle, notamment de la nourriture, de l'eau, des soins médicaux et un abri, là où cela est le plus nécessaire. Faites une différence dans la vie des personnes touchées par l’urgence en Palestine.`
                  : lang === 'ar'
                  ? `توفير الإغاثة الأساسية، بما في ذلك الغذاء والماء والرعاية الطبية والمأوى، حيث تشتد الحاجة إليها. إحداث فرق في حياة المتضررين من حالة الطوارئ في فلسطين.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempPalestineEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-MASU-003') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `GAZA : Les branches de l'espoir` : lang === 'ar' ? `غزة: أطراف الأمل` : 'GAZA: Limbs of Hope',
              image_link: inner_item_product.image_link || limbsImg,
              description:
                lang === 'fr'
                  ? `Votre générosité peut transformer une vie. En faisant un don à notre collecte de fonds pour les membres prothétiques, vous contribuez à fournir un membre prothétique à une personne dans le besoin.`
                  : lang === 'ar'
                  ? `يمكن لكرمك أن يغير حياة إنسان. من خلال التبرع لحملة جمع التبرعات للأطراف الاصطناعية، فإنك تساعد في توفير طرف اصطناعي لشخص محتاج.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempPalestineEmergencyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `SECOURS MÉDICAL EN PALESTINE` : lang === 'ar' ? `الإغاثة الطبية الفلسطينية` : inner_item_product.name,
              description:
                lang === 'fr'
                  ? `Fournir une assistance médicale d’urgence aux personnes blessées dans l’urgence palestinienne. Cela contribuera à fournir des articles de secours médicaux d’urgence et un soutien hospitalier.`
                  : lang === 'ar'
                  ? `تقديم المساعدة الطبية العاجلة للجرحى والمصابين في حالة الطوارئ الفلسطينية. سيساعد هذا في توفير مواد الإغاثة الطبية العاجلة ودعم المستشفى.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempPalestineEmergencyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `PACK ALIMENTATION FAMILIALE ESSENTIELLE DE LA PALESTINE`
                  : lang === 'ar'
                  ? `حزمة الغذاء العائلي الأساسية في فلسطين`
                  : inner_item_product.name,
              description:
                lang === 'fr'
                  ? `Prévoir les fournitures essentielles pour les repas, dont 2 sacs de riz, 2 sacs de pâtes, 6 légumes en conserve, 1 fruit en conserve, 4 viandes et poissons en conserve, 1 huile de cuisson, 1 paquet de lentilles, 1 sac de farine, 2 sachets de sucre, 2 sachets de sel, 6 du lait ou du lait en poudre, 2 sachets de thé et de café et 4 ingrédients pour le pain et la panification`
                  : lang === 'ar'
                  ? `توفير المستلزمات الأساسية للوجبات ، بما في ذلك 2 كيس أرز ، 2 كيس معكرونة ، 6 خضروات معلبة ، 1 فاكهة معلبة ، 4 لحوم وأسماك معلبة ، 1 زيت طبخ ، 1 عبوة عدس ، 1 كيس دقيق ، 2 عبوة سكر ، 2 كيس ملح ، 6 حليب أو حليب مجفف ، 2 عبوة شاي وقهوة ، و 4 مكونات لصنع الخبز والخبز.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempPalestineEmergencyProducts[3] = inner_item_product;
          }

          // Feed orphans
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-315') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir un orphelin` : lang === 'ar' ? `إطعام يتيم في فلسطين` : `Feed An Orphan`,
            });
            tempFeedOrphanProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir 10 orphelins` : lang === 'ar' ? `إطعام 10 أيتام في فلسطين` : `Feed 10 Orphans`,
            });
            tempFeedOrphanProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir 20 orphelins` : lang === 'ar' ? `إطعام 20 يتيما في فلسطين` : `Feed 20 Orphans`,
            });
            tempFeedOrphanProducts[2] = inner_item_product;
          }

          // Support family
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-311') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Famille avec orphelins depuis 1 mois`
                  : lang === 'ar'
                  ? `دعم عائلة مع الأيتام لمدة 1 شهر`
                  : `Family With Orphans For 1 Month`,
            });
            tempSupportFamilyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-312') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `5 familles avec orphelins pendant 1 mois`
                  : lang === 'ar'
                  ? `دعم 5 أسر مع الأيتام لمدة 1 شهر`
                  : `5 Families With Orphans for 1 month`,
            });
            tempSupportFamilyProducts[1] = inner_item_product;
          }

          // Shelter orphans
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-313') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempShelterOrphanProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-314') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Pack abri pour les orphelins palestiniens`
                  : lang === 'ar'
                  ? `حزمة المأوى للأيتام الفلسطينيين`
                  : inner_item_product.name,
            });
            tempShelterOrphanProducts[0] = inner_item_product;
          }

          // sponsorFamilyProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-12-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName:
                lang === 'fr'
                  ? `Famille de 12 personnes pour 1 mois`
                  : lang === 'ar'
                  ? `اكفل أسرة فلسطينية مكونة من 12 فردا لمدة شهر`
                  : 'Family of 12 for 1 Month',
            });
            tempSponsorFamilyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-8-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName:
                lang === 'fr'
                  ? `Famille de 8 personnes pendant 1 mois`
                  : lang === 'ar'
                  ? `اكفل أسرة فلسطينية مكونة من 8 أفراد لمدة 1 شهر`
                  : 'Family of 8 for 1 Month',
            });
            tempSponsorFamilyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-4-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName:
                lang === 'fr'
                  ? `Famille de 4 personnes pendant 1 mois`
                  : lang === 'ar'
                  ? `اكفل عائلة فلسطينية مكونة من 4 أفراد لمدة 1 شهر`
                  : 'Family of 4 for 1 Month',
            });
            tempSponsorFamilyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempSponsorFamilyProducts[3] = inner_item_product;
          }

          // SupportRefugeeSettlementProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName:
                lang === 'fr'
                  ? `Règlement égyptien pour les réfugiés palestiniens`
                  : lang === 'ar'
                  ? `مخيم للاجئين الفلسطينيين في مصر`
                  : 'Palestinian Refugee Egypt Settlement',
            });
            tempSupportRefugeeSettlementProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempSupportRefugeeSettlementProducts[1] = inner_item_product;
          }

          // Donate meal
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-401') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `Faire don d'un repas - 20 personnes` : lang === 'ar' ? `تبرع بوجبة - 20 شخص` : 'Donate a meal - 20 people',
            });
            tempDonateMealProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-402') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `Faire don d'un repas - 50 personnes` : lang === 'ar' ? `تبرع بوجبة - 50 شخص` : 'Donate a meal - 50 people',
            });
            tempDonateMealProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-400') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempDonateMealProducts[2] = inner_item_product;
          }

          // Donate water
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-404') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Faites un don d'eau potable - 500 personnes`
                  : lang === 'ar'
                  ? `تبرع بمياه نظيفة - 500 شخص`
                  : 'Donate Clean Water - 500 people',
            });
            tempDonateWaterProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-405') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Faites un don d'eau potable - 1500 personnes`
                  : lang === 'ar'
                  ? `تبرع بمياه نظيفة - 1500 شخص`
                  : 'Donate Clean Water - 1500 people',
            });
            tempDonateWaterProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-406') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Faites un don d'eau potable - 2500 personnes`
                  : lang === 'ar'
                  ? `تبرع بمياه نظيفة - 2500 شخص`
                  : 'Donate Clean Water - 2500 people',
            });
            tempDonateWaterProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-403') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempDonateWaterProducts[3] = inner_item_product;
          }

          // Baby milk
          if (inner_item_product.creator === 'EMR-PAL-BMP-2024-001') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Du lait pour bébé pendant 4 semaines en Palestine`
                  : lang === 'ar'
                  ? `حليب الأطفال لمدة 4 أسابيع في فلسطين`
                  : 'Baby Milk for 4 Weeks in Palestine',
            });
            tempBabyMilkProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-BMP-2024-002') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Du lait pour bébé pendant 4 semaines en Palestine`
                  : lang === 'ar'
                  ? `حليب الأطفال لمدة 8 أسابيع في فلسطين`
                  : 'Baby Milk for 8 Weeks in Palestine',
            });
            tempBabyMilkProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-BMP-2024-003') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Du lait pour bébé pendant 12 semaines en Palestine`
                  : lang === 'ar'
                  ? `حليب الأطفال لمدة 12 أسبوعًا في فلسطين`
                  : 'Baby Milk for 12 Weeks in Palestine',
            });
            tempBabyMilkProducts[2] = inner_item_product;
          }
          // SheepGoat
          if (inner_item_product.creator === 'QB-2024-S17') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine',
            });
            tempSheepGoatProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S18') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestinian Refugees',
              nameDescription: `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempSheepGoatProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S19') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Yemen',
              showHeartIcon: true,
            });
            tempSheepGoatProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S20') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
              showHeartIcon: true,
            });
            tempSheepGoatProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S24') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Syrian Refugees',
              nameDescription: `(based in Türkiye)`,
            });
            tempSheepGoatProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S23') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Afghanistan',
            });
            tempSheepGoatProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S25') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Jordan',
            });
            tempSheepGoatProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S21') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
            });
            tempSheepGoatProducts[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S27') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempSheepGoatProducts[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S22') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempSheepGoatProducts[9] = inner_item_product;
          }

          // SheepGoat Bundle
          if (inner_item_product.creator === 'QB-2024-UQ-01') {
            tempSheepGoatBundleProduct = inner_item_product;
          }

          // Cow share
          if (inner_item_product.creator === 'QB-2024-S11') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'India',
              showHeartIcon: true,
            });
            tempCowShareProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S10') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Egypt',
              name: 'Palestinian Refugees',
              nameDescription: `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempCowShareProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S12') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
              showHeartIcon: true,
            });
            tempCowShareProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S16') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempCowShareProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S14') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
              isSoldOut: true,
            });
            tempCowShareProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S15') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Turkey',
              isSoldOut: true,
            });
            tempCowShareProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S13') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempCowShareProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S9') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: `Palestine`,
              isSoldOut: true,
            });
            tempCowShareProducts[7] = inner_item_product;
          }

          // Checkbox Products
          if (inner_item_product.creator === 'TK-QB-2024-FA-00') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine 2kg Meat',
            });
            tempCheckBoxProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-FA-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine 10kg of Rice',
            });
            tempCheckBoxProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-EG28') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Qurban Eid Gift',
            });
            tempCheckBoxProducts[2] = inner_item_product;
          }

          // Cow
          if (inner_item_product.creator === 'QB-2024-S3') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'India',
              showHeartIcon: true,
            });
            tempCowProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S2') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestinian Refugees',
              nameDescription: `(based in Egypt)`,
              showHeartIcon: true,
            });
            tempCowProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S4') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
              showHeartIcon: true,
            });
            tempCowProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S8') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempCowProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S6') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
              isSoldOut: true,
            });
            tempCowProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S7') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Turkey',
              isSoldOut: true,
            });
            tempCowProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S5') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempCowProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S1') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: `Palestine`,
              isSoldOut: true,
            });
            tempCowProducts[7] = inner_item_product;
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSaveOrphansProducts[0] = feedAPalestineOrphan;
    tempSaveOrphansProducts[0].products = tempFeedOrphanProducts;
    tempSaveOrphansProducts[1] = supportFamilyOrphan;
    tempSaveOrphansProducts[1].products = tempSupportFamilyProducts;
    tempSaveOrphansProducts[2] = shelterOrphan;
    tempSaveOrphansProducts[2].products = tempShelterOrphanProducts;

    tempPalestineRefugeeSettlementProducts[0] = sponsorFamily;
    tempPalestineRefugeeSettlementProducts[0].products = tempSponsorFamilyProducts;
    tempPalestineRefugeeSettlementProducts[1] = palestineRefugee;
    tempPalestineRefugeeSettlementProducts[1].products = tempSupportRefugeeSettlementProducts;

    tempPalestineHungerProducts[0] = donateMeal;
    tempPalestineHungerProducts[0].products = tempDonateMealProducts;
    tempPalestineHungerProducts[1] = donateCleanWater;
    tempPalestineHungerProducts[1].products = tempDonateWaterProducts;
    tempPalestineHungerProducts[2] = babyMilk;
    tempPalestineHungerProducts[2].products = tempBabyMilkProducts;
    console.log('humger=', tempDonateMealProducts);
    console.log('humger=', tempDonateWaterProducts);

    tempFeedOrphanProducts = tempFeedOrphanProducts.filter(n => n);
    tempSupportFamilyProducts = tempSupportFamilyProducts.filter(n => n);
    tempShelterOrphanProducts = tempShelterOrphanProducts.filter(n => n);
    tempSponsorFamilyProducts = tempSponsorFamilyProducts.filter(n => n);
    tempSupportRefugeeSettlementProducts = tempSupportRefugeeSettlementProducts.filter(n => n);
    tempDonateMealProducts = tempDonateMealProducts.filter(n => n);
    tempDonateWaterProducts = tempDonateWaterProducts.filter(n => n);
    tempBabyMilkProducts = tempBabyMilkProducts.filter(n => n);

    tempPalestineEmergencyProducts = tempPalestineEmergencyProducts.filter(n => n);
    tempSaveOrphansProducts = tempSaveOrphansProducts.filter(n => n);
    tempPalestineRefugeeSettlementProducts = tempPalestineRefugeeSettlementProducts.filter(n => n);
    tempPalestineHungerProducts = tempPalestineHungerProducts.filter(n => n);
    setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
    setSaveOrphansProducts(tempSaveOrphansProducts);
    setPalestineRefugeeSettlementProducts(tempPalestineRefugeeSettlementProducts);
    setPalestineHungerProducts(tempPalestineHungerProducts);
    // setRefugeeSettlementProducts(tempSupportRefugeeSettlementProducts);
    setSheepGoat({
      ...sheepGoat,
      products: tempSheepGoatProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...sheepGoat.bundleProduct, product: tempSheepGoatBundleProduct },
    });
    setCowShare({
      ...cowShare,
      products: tempCowShareProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...cowShare.bundleProduct, product: tempSheepGoatBundleProduct },
    });
    setCow({
      ...cow,
      checkboxProducts: tempCheckBoxProducts,
      products: tempCowProducts,
    });
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  // }, [selectedCurrencyStore]);
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
          <meta name="title" content={`${seo['crisisAndEmergencies']['palestine']['title']}`} />
          <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
          {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
        </Helmet>
      )}

      <section className="xs:block md:hidden sticky top-16 z-30">
        <WidgetHome
          selectedCurrency={e => setSelectedCurrency(e.value)}
          defaultProduct={{ creator: 'EMR-DIS-PSE-2023-01', label: 'Save Lives In Palestine', value: 255 }}
        />
      </section>

      <section className="flex justify-center">
        <img
          src={lang === 'fr' ? bannerFr : 'https://cdn.matwproject.org/images/banners/MATW_PalestineEmergency_Refresh.jpg'}
          alt="Donate to Palestine"
          className="max-[500px]:hidden w-full"
        />
        <img
          src={lang === 'fr' ? bannerMobileFr : 'https://cdn.matwproject.org/images/banners/MATW_PalestineEmergency_Refresh.jpg'}
          alt="Donations to Palestine"
          className="min-[501px]:hidden w-full"
        />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="xs:hidden md:block sticky top-16 lg:top-24 z-30">
        <WidgetHome
          selectedCurrency={e => setSelectedCurrency(e.value)}
          defaultProduct={{ creator: 'EMR-DIS-PSE-2023-01', label: 'Save Lives In Palestine', value: 255 }}
        />
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <PalestineTabs
        cowProducts={cow}
        cowShareProducts={cowShare}
        sheepGoatProducts={sheepGoat}
        saveOrphansProducts={saveOrphansProducts}
        palestineHungerProducts={palestineHungerProducts}
        palestineEmergencyProducts={palestineEmergencyProducts}
        palestineRefugeeSettlementProducts={palestineRefugeeSettlementProducts}
        lang={lang}
      />
      {/* ----------------- videos section ---------------- */}
      <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
      {/* ----------------- videos section --END-------------- */}

      {/* ------------------------ Total Impact Section */}
      <section className="bg-[#E1EFF2] py-6 md:py-8 flex flex-col">
        <div className="flex items-center justify-center mb-4 md:mb-5">
          <img src={matwImpactPhoto} alt="Donate to Palestine to make your MATW impact" />
        </div>
        <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
          <h2
            className={`text-[18px] md:text-4xl ${
              lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
            } text-[#253B7E] text-center font-black`}
          >
            <span className="font-normal text-[18px] md:text-4xl text-[#78716C] uppercase mr-1">
              {lang === 'fr' ? 'IMPACT TOTAL:' : lang === 'ar' ? 'التأثير الكلي:' : 'Total Impact:'}
            </span>
            &nbsp; {impactInfo.total}
          </h2>
        </div>
        <div className="hidden md:container md:mx-auto sm:grid sm:grid-cols-4 xl:grid-cols-4 gap-16 justify-between items-center">
          {impactSliderData.map(({ img, value, title, frenchTitle, arabicTitle }, index) => (
            <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
              <img src={img.src} alt={img.alt} />
              <div className="flex flex-col items-center text-center">
                <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>{value}</h4>
                <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>
                  {lang === 'fr' ? frenchTitle : lang === 'ar' ? arabicTitle : title}
                </small>
              </div>
            </div>
          ))}
        </div>
        <div className={`sm:hidden px-6 md:px-0 relative`}>
          <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
            {impactSliderData.map(({ img, value, title, frenchTitle, arabicTitle }, index) => (
              <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                <img src={img.src} alt={img.alt} />
                <div className="flex flex-col items-center text-center">
                  <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>{value}</h4>
                  <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>
                    {lang === 'fr' ? frenchTitle : lang === 'ar' ? arabicTitle : title}
                  </small>
                </div>
              </div>
            ))}
          </div>
          {loadedImpact && instanceRefImpactSlider.current && (
            <>
              <Arrow
                left
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                className="bg-transparent"
                disabled={currentImpactSlide === 0}
                icon={impactSliderLeftIcon}
              />

              <Arrow
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                className="bg-transparent"
                icon={impactSliderRightIcon}
                rightMargin
              />
            </>
          )}
        </div>
      </section>
      {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <DonateSection lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Muslim Arounds ------------------------- */}
      <MuslimsAroundSection lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection />
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* --------------------------------- FAQ’s --------------------------------- */}
      <FAQSection lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default PalestineLandingPage;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${
        props.bg ? props.bg : 'bg-[#F60362]'
      } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${
        props.className ? props.className : ''
      } ${props.left ? 'arrow--left left-[8px]' : 'arrow--right left-auto right-[8px]'}`}
      onClick={props.onClick}
    >
      {props.icon ? (
        <img alt="arrow-img" src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-1' : '-ml-1'} `} />
      ) : (
        <svg
          className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
          {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
      )}
    </div>
  );
}