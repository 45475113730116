import React from 'react'

const DonateSection = ({ arabic, lang = 'en' }) => {
  return (
    <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
        {arabic || lang === 'ar'
          ? <>تبرع <span className="text-[#f60362]">لفلسطين</span></>
          : lang === 'fr'
            ? <>Faites un don à la <span className="text-[#f60362]">Palestine</span></>
            : <>Donate to <span className="text-[#f60362]">Palestine</span></>
        }
      </h1>
      <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'}`}>
        <p>
          {arabic || lang === 'ar'
            ? `منذ بداية النزاع في 7 أكتوبر/تشرين الأول، فقد أكثر من 30,000 فلسطيني حياتهم، بمن فيهم أكثر من 12,000 طفل. وأصيب أكثر من 63,000 فلسطيني، وأصبح أكثر من 1.9 مليون شخص نازحين داخليا. وقد فرت العائلات جنوبا، حيث الظروف بالكاد صالحة للعيش. هناك حاجة إلى الغذاء والماء والإمدادات الطبية العاجلة لضمان بقائهم على قيد الحياة.`
            : lang === 'fr'
              ? `Depuis le début du conflit le 7 octobre, plus de 30 000 Palestiniens ont perdu la vie, dont plus de 12 000 enfants. Plus de 63 000 Palestiniens ont été blessés et plus de 1,9 million de personnes ont été déplacées à l’intérieur du pays. Des familles ont fui vers le sud, où les conditions de vie sont à peine vivables. De la nourriture, de l’eau et des fournitures médicales urgentes sont nécessaires pour assurer leur survie.`
              : `Since the start of the conflict on October 7, more than 30,000 Palestinians have lost their lives, including more than 12,000 children.
                Over 63,000 Palestinians have been injured, and more than 1.9 million people have become internally displaced. Families have fled south,
                where conditions are barely liveable. Urgent food, water, and medical supplies are required to ensure their survival.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? `تعمل وزارة العمل الفلسطينية على الأرض لتقديم المساعدة المنقذة للحياة للأسر النازحة في فلسطين ، ومؤخرا للأسر الفلسطينية التي تبحث عن ملجأ في مصر. نحن بحاجة ماسة إلى دعمكم لمساعدة شعب فلسطين على البقاء ومساعدة عائلات اللاجئين على البدء من جديد.`
            : lang === 'fr'
              ? `MATW est sur le terrain pour fournir une aide vitale aux familles déplacées en Palestine et, plus récemment, aux familles palestiniennes qui cherchent refuge en Égypte. Nous avons besoin de toute urgence de votre soutien pour aider le peuple de Palestine à survivre et à aider les familles de réfugiés à repartir à zéro.`
              : `MATW is on the ground providing life-saving assistance for displaced families in Palestine and, more recently, for Palestinian families
            seeking refuge in Egypt. We urgently need your support to help the people of Palestine survive and to help refugee families start over.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? `يمكنك البدء باستخدام صدقاتك من أجل فلسطين ، والتي ستساعدنا في توفير الدعم الطبي العاجل والغذاء والمياه النظيفة والمأوى والمساعدة في نقل الأسرة اليوم. يمكنك أيضا التبرع بزكاتك إلى فلسطين ، حيث أن برنامج الاستجابة للطوارئ في فلسطين يطبق على الزكاة بنسبة 100٪.`
            : lang === 'fr'
              ? `Vous pouvez commencer par utiliser votre Sadaqah pour la Palestine, qui nous aidera à fournir un soutien médical d’urgence, de la nourriture, de l’eau potable, un abri et une aide à la réinstallation des familles dès aujourd’hui. Vous pouvez également faire don de votre Zakat à la Palestine, car notre programme d’intervention d’urgence en Palestine est 100% applicable à la Zakat.`
              : `You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and
            family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100%
            Zakat-applicable.`
          }
        </p>
        {/* <p>
          {arabic
            ? `في رمضان هذا العام، دعونا نؤدي هذه الأعمال الصالحة ونشعر ببركات الله ورحمته معا".`
            : `This Ramadan let’s perform these good deeds and feel Allah’s Blessings and Mercy together.`
          }
        </p> */}
      </div>
    </div>
  )
}

export default DonateSection