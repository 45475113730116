import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { selectSelectedCurrency } from '../../../store/user';
import ShowCurrencies from '../../../utils/showCurrencies';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
// import ReactSelect from 'react-select';
import SelectCurrency from '../../../components/general/selectCurrency';
// import creditImg from "../../images/payment/credit-cards.png";
import ConvertAUD from '../../../utils/convertAUD';
import CheckBox from '../../../components/general/checkbox';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from "react-icons/fi";
import moment from 'moment';
import { toast } from 'react-toastify';
import CustomButton from '../../../components/general/button';
// import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}
const TabContent = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const { product, onModal, addLaylatulQadrProduct } = props;
    const [currentCurrency, setCurrentCurrency] = useState();
    const [productAmount, setProductAmount] = useState('');
    const refLazyImg = useRef(null);
    const suggestedRecurringModes = [
        { value: 'month', label: 'Monthly' },
        // { value: 'week', label: 'Weekly' },
    ]
    console.log("onModal=", onModal)
    const [selectedRecurring] = useState(suggestedRecurringModes[0]);
    const suggestedPrices = [
        {
            aud: 60,
            usd: 40,
            cad: 50,
            sgd: 50,
            gbp: 30,
            eur: 20,
            myr: 50,
            aed: 140,
            idr: 29000,
        },
        {
            aud: 80,
            usd: 50,
            cad: 70,
            sgd: 80,
            gbp: 40,
            eur: 35,
            myr: 100,
            aed: 180,
            idr: 39000,
        },
        {
            aud: 95,
            usd: 60,
            cad: 85,
            sgd: 100,
            gbp: 50,
            eur: 60,
            myr: 150,
            aed: 230,
            idr: 69000,
        },
    ];
    const [selectedPrice, setSelectedPrice] = useState({
        value: suggestedPrices[0][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
        index: 0
    });
    const [isOpen, setIsOpen] = useState(false);
    const datePickerRef = useRef(null);
    const [dateRange, setDateRange] = useState(['03/30/2024', '04/09/2024']);
    const [startDate, endDate] = dateRange;
    const [continueDonation, setContinueDonation] = useState(false);


    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleAddNewProduct = () => {
        let tempProduct = product;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;

        if (!startDate) {
            toast.error("Please select start date")
            return;
        }
        if (!continueDonation && !endDate) {
            toast.error("Please select end date")
            return;
        }
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: 'day' || selectedRecurring.value,
            start_date: moment(startDate).format('DD-MM-YYYY'),
            end_date: moment(endDate).format('DD-MM-YYYY'),
            continue_donation: continueDonation ? 1 : 0
        })
        addLaylatulQadrProduct(tempProduct, currentCurrency)
    }

    const handleSelectedItem = (currency, index) => {
        let tempSelectedPrice = {};
        tempSelectedPrice.index = index;
        tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
        setSelectedPrice(tempSelectedPrice)
    }
    const handleConvert = (currency, value) => {
        return Math.round(ConvertAUD(currency, value, false))
    }
    const handleProductAmount = (currency, value) => {
        setProductAmount(handleConvert(currency, value))
    }


    const handleOpenDatePicker = () => {
        setIsOpen(true);
    };

    const handleCloseDatePicker = () => {
        setIsOpen(false);
    };
    const getDays = (s, e) => {
        const startDate = moment(s); // Assuming the start date is December 31, 2023
        const endDate = moment(e); // Assuming the end date is January 10, 2024

        // Calculate the difference in days
        const daysDifference = endDate.diff(startDate, 'days');
        return `${daysDifference} days`
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */

    useEffect(() => {
        console.log("product on tab content=", product)
    }, [product]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                handleCloseDatePicker();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore);
            selectedPrice
                ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index)
                : handleProductAmount(selectedCurrencyStore, productAmount)
        } else {
            setCurrentCurrency('AUD')
            handleSelectedItem('AUD', selectedPrice.index)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])

    /* -------------------------------------------------------------------------- */

    return (
        <div className={`bg-white w-full rounded-md ${onModal ? 'p-2 lg:py-4 lg:px-3' : 'py-4 px-2 sm:px-4 lg:py-8 lg:px-6'}`}>
            <div className="w-full flex flex-col- md:flex-row flex-wrap bg-white overflow-hidden-">
                {!onModal && <div className="pl-0 lg:pl-24 order-2 basis-full lg:basis-1/2">
                    <div className="overflow-hidden rounded-lg flex items-center basis-full h-auto lg:h-[280px] xl:h-[500px] px-2 md:px-0">
                        {product ? product.image_link
                            ? <LazyLoadImage
                                ref={refLazyImg}
                                alt={product.name}
                                effect="blur"
                                width={`100%`}
                                delayTime={500}
                                className='rounded-lg'
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/general/logo-replace.png";
                                }}
                                src={product.image_link} // use normal <img> attributes as props
                            />
                            : <img className='w-full h-full' src="/images/general/logo-replace.png" alt="" />
                            : <Skeleton height={344} />
                        }
                    </div>
                </div>}
                <div className={`flex justify-center flex-col basis-full order-1 md:order-1 ${onModal ? 'basis-full p-2' : 'lg:basis-1/2 py-2 px-2 md:py-4 md:px-8'}`}>
                    <h3 className={`text-[#093484] ${onModal ? 'text-[25px] text-center' : 'text-2xl md:text-[48px] text-center md:text-left'} capitalize bold font-medium mb-4 leading-6 md:leading-none`}>
                        {product ? product.name : <Skeleton height={48} />}
                    </h3>
                    <div className="relative">
                        {product ? <>
                            <div className={`text-center md:text-left sm:my-2 relative overflow-hidden h-auto ${(product.short_description || (product.description && product.description)).split(' ').length > 28 ? 'mb-4' : 'mb-4'}`}>
                                <p className={`text-[#78716C] ${onModal ? 'text-base' : 'text-[#78716C] text-sm leading-[20px] sm:leading-[30px]'} font-brandingMedium overflow-hidden transition-all duration-200 min-h-[79px]-`}>
                                    {product.short_description || product.description}
                                </p>
                            </div>
                        </> : <Skeleton count={4} />}
                    </div>
                </div>
                <div className={`basis-full order-3 flex-col flex justify-center ${onModal ? '' : 'mt-2 pt-2 px-2 md:px-0 md:pt-4'}`}>
                    <div className={`col-span-12 grid grid-cols-12 gap-2 ${onModal ? '' : 'bg-[#E2EFF3] md:bg-transparent rounded-lg md:rounded-none'}`}>
                        {!onModal && <div className="col-span-12 md:hidden grid grid-cols-4 mb-1">
                            {suggestedRecurringModes.map((mode, index) => {
                                return <div key={`modes${index}`} className="col-span-4" style={styles.shadow}>
                                    <div
                                        className={`h-12 text-lg bold md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] rounded-tl-lg rounded-tr-lg ${selectedRecurring.value === mode.value ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                    // onClick={() => {
                                    //     setSelectedRecurring(mode);
                                    //     setProductAmount('');
                                    // }}
                                    >
                                        {/* {mode.label} */}
                                        Daily/Last 10 Nights
                                    </div>
                                </div>
                            })}
                        </div>}
                        <div className="col-span-12 grid grid-cols-12 gap-2 px-2 pb-2 md:p-0">
                            <div onClick={() => handleOpenDatePicker()} className={`relative flex md:flex col-span-12 h-12 rounded-md ${onModal ? 'col-span-12' : 'sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-3'} lg:mb-2 items-center justify-center`} style={styles.shadow}>
                                {!startDate && <div className={`absolute z-[10] flex items-center justify-between w-full px-4`}>
                                    <div className='  text-[#1E3271] text-[20px]'>Start/End Date</div>
                                    <div><FiCalendar size={20} /></div>
                                </div>
                                }
                                <div ref={datePickerRef} className='w-full h-full'>
                                    <ReactDatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                        className="red-border w-full px-2 h-full rounded-md focus-visible:outline- "
                                        open={isOpen}
                                        onCalendarClose={() => setIsOpen(false)}
                                        minDate={moment().toDate()}
                                    />
                                </div>
                            </div>
                            {suggestedPrices.map((price, index) => {
                                return <div key={`prices${index}`} className={`${onModal ? 'col-span-4' : 'col-span-4 md:col-span-2 xl:col-span-1'} h-12`} style={styles.shadow}>
                                    <div
                                        className={`h-12 rounded-md text-base md:text-[20px] transition-all duration-200 cursor-pointer flex justify-center items-center px-1 md:px-4 min-w-[80px] ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud'] ? 'bg-[#F60362] text-white' : 'bg-white text-[#1E3271] hover:bg-[#F60362] hover:text-white'}`}
                                        onClick={() => {
                                            handleSelectedItem(currentCurrency, index);
                                            setProductAmount('');
                                        }}
                                    >
                                        {ShowCurrencies(currentCurrency, price[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                                    </div>
                                </div>
                            })}
                            <div className={`${onModal ? 'col-span-12' : 'col-span-12 lg:col-span-6 xl:col-span-4'} lg:mb-2 flex items-center justify-center`} style={styles.shadow}>
                                <div className="flex w-full md:w-1/2">
                                    <SelectCurrency onChangeCurrency={(e) => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                                </div>
                                <input
                                    value={productAmount}
                                    className="w-full -ml-1 md:ml-0 md:w-1/2 h-12 p-2 sm:rounded-md text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                                    type="number"
                                    min={"0"}
                                    onChange={(e) => {
                                        const regex = /^(?:[1-9]\d*)?$/;
                                        if (regex.test(e.target.value)) {
                                            setProductAmount(e.target.value);
                                            if (e.target.value === '') {
                                                setSelectedPrice(100);
                                            } else {
                                                setSelectedPrice(null);
                                            }
                                        }

                                    }}
                                    placeholder="Enter amount" />
                            </div>
                            <div className='my-3 col-span-12 hidden'>
                                <div className='w-full flex items-center'>
                                    <CheckBox
                                        name="continue"
                                        checked={continueDonation}
                                        className="mt-1 lg:text-[16px] text-[#1E3271]"
                                        setter={(val) => {

                                            setContinueDonation(!continueDonation)
                                        }}
                                        label={`Yes , continue to automate my donation after the end date`}
                                    />
                                </div>
                                <div className='w-full flex items-center'>
                                    <CheckBox
                                        name="cancel"
                                        checked={!continueDonation}
                                        className="mt-1 lg:text-[16px] text-[#1E3271]"
                                        setter={(val) => {

                                            setContinueDonation(!continueDonation)
                                        }}
                                        label={`No , DO NOT continue my donation after the end date`}
                                    />
                                </div>
                            </div>
                            <div className={`relative ${onModal ? 'col-span-12' : 'col-span-12 lg:col-span-6 xl:col-span-2'} lg:mb-2 flex flex-col justify-center`}>
                                {/* <button
                                    className={`text-white w-full text-lg md:text-lg uppercase transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${product ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                                    onClick={handleAddNewProduct}
                                >Donate now</button> */}
                                <CustomButton onClick={handleAddNewProduct} title="Donate now" className="" />
                                <div className={`text-[16px] leading-5 text-[#78716C] ${onModal ? 'text-center w-full flex flex-col items-center mt-2' : 'relative sm:absolute my-6 sm:my-0 sm:top-16'}`}>
                                    <div className='text-center sm:text-left'>Donate &nbsp;
                                        {ShowCurrencies(currentCurrency, Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100, true)} per day</div>
                                    <div className='text-center sm:text-left'>From: {startDate && moment(startDate).format('MM/DD/YYYY')}</div>
                                    <div className='text-center sm:text-left'>Cancel On: {endDate && moment(endDate).format('MM/DD/YYYY')}</div>
                                    <div className='text-center sm:text-left'>For: {startDate && endDate && getDays(startDate, endDate)} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-6 md:block hidden opacity-0 pointer-events-none'>
                <div className='w-full flex items-center'>
                    <CheckBox

                        name="continue"
                        checked={continueDonation}

                        className="mt-1 lg:text-[16px] text-[#1E3271]"
                        setter={(val) => {

                            setContinueDonation(!continueDonation)
                        }}
                        label={`Yes , continue to automate my donation after the end date`}

                    />
                </div>
                <div className='w-full flex items-center'>
                    <CheckBox
                        name="cancel"
                        checked={!continueDonation}

                        className="mt-1 lg:text-[16px] text-[#1E3271]"
                        setter={(val) => {

                            setContinueDonation(!continueDonation)
                        }}
                        label={`No , DO NOT continue my donation after the end date`}
                    />
                </div>
            </div>


        </div>
    );
}

export default TabContent;