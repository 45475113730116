import React from 'react';
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const AccordionLayout = ({ title, children, index, activeIndex, level, setActiveIndex }) => {
    const handleSetIndex = (index) => {
        (activeIndex !== index) ? setActiveIndex(index) : setActiveIndex(-1);
    }
    return (
        <>
            <div onClick={() => handleSetIndex(index)} className={`flex w-full justify-between rounded ${level === 1 ? 'bg-white p-2 mt-2' : 'px-4 py-2'} cursor-pointer border-b border-stone-200`}>
                <div className='flex'>
                    <div className={`${level === 1 ? activeIndex === index ? 'text-[#00a3da] font-bold' : 'text-[#777] font-bold' : activeIndex === index ? 'text-[#00a3da] font-normal' : 'text-[#888] font-normal'}`}>{title}</div>
                </div>
                <div className="flex items-center justify-center">
                    <ChevronDownIcon className={`w-4 h-4 text-stone-400 transition-all duration-300 ${activeIndex === index ? ' transform rotate-180' : ''}`} />
                </div>
            </div>
            <div className={`shadow-3xl w-full rounded-md shadow-cyan-500/50 bg-stone-50 transition-all duration-200 ease-in-out ${level === 1 ? '' : 'px-3 sm:px-6'} ${activeIndex === index ? ' h-auto py-4 mb-6' : 'h-0 overflow-hidden'}`}>
                {children}
            </div>
        </>
    );
};
export default AccordionLayout;