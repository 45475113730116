import React, { useState } from "react";
import HandleMenu from "./handleMenu";
import MenuAccordionLayout from "./menuAccordion";
import CustomButton from "../../../../components/general/button";

const MainMenu = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const {allNavbarMenus, allFooterMenus, type, onUpdateMenu, onAddMenu, onDeleteMenu} = props;
    const [currentParentId] = useState(0);
    const [currentSection] = useState(type);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeIndexL2, setActiveIndexL2] = useState(-1);
    const [activeIndexL3, setActiveIndexL3] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState();
    const allMenus = type === 'main' ? allNavbarMenus : allFooterMenus
    /* -------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------- */
    return (
        <>
            <div className="flex flex-col w-full pt-6">
                <div className='basis-full flex justify-between border-b border-stone-300 pb-2'>
                    <h2 className='text-lg md:text-2xl text-stone-700'>{type === 'main' ? 'Navbar Items' : 'Footer Items'}</h2>
                    <CustomButton
                        type='button'
                        onClick={() => onAddMenu(0)}
                        title={'Add Menu'}
                        className='!h-[42px] !bg-[#00A3DA]'
                    />
                </div>
                {allMenus && allMenus.map((l1Item, l1Index) => {
                    return <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md border border-stone-200" key={`l1${l1Index}`}>
                        <MenuAccordionLayout
                            title={l1Item.title}
                            index={l1Index}
                            order={l1Item.order}
                            level={1}
                            noBorder={true}
                            hasSubMenu={l1Item.children.length}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            className="!text-lg !font-bold"
                            handleAdd={() => onAddMenu(l1Item.id)}
                            handleEdit={() => onUpdateMenu(l1Item)}
                            handleDelete={() => onDeleteMenu(l1Item.id)}
                        >
                            {l1Item.children.length
                                ? l1Item.children.map((l2Item, l2Index) => {
                                    return <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md border border-stone-200" key={`l2${l2Index}`}>
                                        <MenuAccordionLayout
                                            title={l2Item.title}
                                            index={l2Index}
                                            order={l2Item.order}
                                            level={2}
                                            noBorder={true}
                                            hasSubMenu={l2Item.children.length}
                                            activeIndex={activeIndexL2}
                                            setActiveIndex={setActiveIndexL2}
                                            className="!text-base !font-bold"
                                            handleAdd={() => onAddMenu(l2Item.id)}
                                            handleEdit={() => onUpdateMenu(l2Item)}
                                            handleDelete={() => onDeleteMenu(l2Item.id)}
                                        >
                                            {l2Item.children.length
                                                ? l2Item.children.map((l3Item, l3Index) => {
                                                    return <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md border border-stone-200" key={`l2${l3Index}`}>
                                                        <MenuAccordionLayout
                                                            title={l3Item.title}
                                                            index={l3Index}
                                                            order={l3Item.order}
                                                            level={3}
                                                            noBorder={true}
                                                            hasSubMenu={l3Item.children.length}
                                                            activeIndex={activeIndexL3}
                                                            setActiveIndex={setActiveIndexL3}
                                                            className="!text-base !font-bold"
                                                            handleAdd={() => onAddMenu(l3Item.id)}
                                                            handleEdit={() => onUpdateMenu(l3Item)}
                                                            handleDelete={() => onDeleteMenu(l3Item.id)}
                                                        >
                                                            {l3Item.children.length
                                                                ? l3Item.children.map((l4Item, l4Index) => {
                                                                    return <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md border border-stone-200" key={`l2${l4Index}`}>
                                                                        <MenuAccordionLayout
                                                                            title={l4Item.title}
                                                                            index={l4Index}
                                                                            order={l4Item.order}
                                                                            level={3}
                                                                            noBorder={true}
                                                                            hasSubMenu={l4Item.children.length}
                                                                            activeIndex={activeIndexL3}
                                                                            setActiveIndex={setActiveIndexL3}
                                                                            className="!text-base !font-bold"
                                                                            handleAdd={() => onAddMenu(l4Item.id)}
                                                                            handleEdit={() => onUpdateMenu(l4Item)}
                                                                            handleDelete={() => onDeleteMenu(l4Item.id)}
                                                                        >
                                                                            {l4Item.children.length
                                                                                ? ''
                                                                                : <div className="flex w-full justify-center items-center py-4 text-stone-400">No SubMenu!</div>
                                                                            }

                                                                        </MenuAccordionLayout>
                                                                    </div>
                                                                })
                                                                : <div className="flex w-full justify-center items-center py-4 text-stone-400">No SubMenu!</div>
                                                            }
                                                        </MenuAccordionLayout>
                                                    </div>
                                                })
                                                : <div className="flex w-full justify-center items-center py-4 text-stone-400">No SubMenu!</div>
                                            }
                                        </MenuAccordionLayout>
                                    </div>
                                })
                                : <div className="flex w-full justify-center items-center py-4 text-stone-400">No SubMenu!</div>
                            }
                        </MenuAccordionLayout>
                    </div>
                })}
            </div>
            {showModal && <HandleMenu
                show={showModal}
                selectedMenuItem={selectedMenuItem}
                currentSection={currentSection}
                currentParentId={currentParentId}
                onClose={() => { setShowModal(false); setSelectedMenuItem(null) }}
                onSaved={() => {
                    onUpdateMenu();
                }}
            >
            </HandleMenu>}
        </>
    );
};
export default MainMenu;