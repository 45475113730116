import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import ShowCurrencies from "../../utils/showCurrencies";
import SelectCurrency from "../../components/general/selectCurrency";
import ReactSelect from "react-select";
import ConvertUSD from "../../utils/convertUSD";
// import services from "../../services";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { selectProducts } from "../../store/products";

const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const SadaqahWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { className, defaultProduct } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const products = useSelector((state) => selectProducts(state));
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
    const [addProduct] = useHandleAddProduct();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempMakeDonationProducts = [];
        let tempProductsList = [];
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === defaultProduct.creator) {
                        setSelectedProduct(inner_item_product)
                    }
                    if (inner_item.name === 'Build A Water Well') {
                        if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') { // Water Well
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                    };
                    if (inner_item.name === 'Orphans') {
                        if (inner_item_product.creator === 'MKD-SDQ-OAW-2023-02-119') { // Support an Orphan Centre
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                    };
                    if (inner_item.name === 'Caravan') {
                        if (inner_item_product.creator === 'EM-SDQ-EQ1-02-2023') { // Emergency Caravan Long Term Shelter
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                    };

                    if (inner_item.name === 'Make A Donation') {
                        tempMakeDonationProducts.push(inner_item_product);
                        tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                    }
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
        setProductsList(tempProductsList);
        setAllProducts(tempMakeDonationProducts)
    };
    const handleConvert = (currency, value) => {
        return Math.round(ConvertUSD(currency, value, false))
    }
    const handleCurrency = (currency) => {
        setCurrentCurrency(currency.value)
    }
    const handleSelectedProduct = (product) => {
        // console.log("handle-SelectedProduct",product)
        allProducts.map(item => item.id === product.value && setSelectedProduct(item))
        // setSelectedProduct(product);
    }
    const handleSelectedItem = (currency, value, index) => {
        let tempSelectedPrice = {};
        tempSelectedPrice.index = index;
        tempSelectedPrice.value = handleConvert(currency, value);
        setSelectedPrice(tempSelectedPrice)
    }
    const handleProductAmount = (currency, value) => {
        setProductAmount(handleConvert(currency, value))
    }
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurring.value,
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }

    const [selectedPrice, setSelectedPrice] = useState({
        value: handleConvert(currentCurrency, 50),
        index: 1
    });
    const [selectedRecurring, setSelectedRecurring] = useState({
        value: 'one-off',
        label: 'Single'
    })
    const suggestedPrices = [
        { value: handleConvert(currentCurrency, 50), label: ShowCurrencies(currentCurrency, handleConvert(currentCurrency, 50), false) },
        { value: handleConvert(currentCurrency, 100), label: ShowCurrencies(currentCurrency, handleConvert(currentCurrency, 100), false) },
        { value: handleConvert(currentCurrency, 150), label: ShowCurrencies(currentCurrency, handleConvert(currentCurrency, 150), false) },
    ];
    const suggestedRecurringModes = [
        { value: 'one-off', label: 'Single' },
        { value: 'week', label: 'Weekly' },
        { value: 'month', label: 'Monthly' }
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore);
            selectedPrice
            ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index * 50, selectedPrice.index)
            : handleProductAmount(selectedCurrencyStore, productAmount)
        } else {
            setCurrentCurrency('AUD')
            handleSelectedItem('AUD', selectedPrice.index * 50, selectedPrice.index)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}>
            <div className="basis-full grid grid-cols-12 gap-2">
                <div className="col-span-12 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <ReactSelect
                        options={productsList}
                        className='max-h-[48px] w-full'
                        defaultValue={selectedProduct}
                        isSearchable={false}
                        styles={styles}
                        placeholder='Select Product'
                        onChange={(e) => handleSelectedProduct(e)}
                    />
                </div>
                <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <ReactSelect
                        options={suggestedRecurringModes}
                        className='[&>*]:!cursor-pointer w-full'
                        value={selectedRecurring}
                        isSearchable={false}
                        styles={styles}
                        onChange={(e) => {
                            setSelectedRecurring(e);
                        }}
                    />
                </div>
                <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <SelectCurrency onChangeCurrency={(e) => handleCurrency(e)} classNamePrefix="orphan_select" />
                </div>
                <div className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 lg:mb-8">
                    {suggestedPrices.map((item, index) => {
                        return <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                            <div
                                className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] border border-[#F60362] ${selectedPrice && selectedPrice.value === handleConvert(currentCurrency, (index + 1) * 50) ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    let tempVal = (index + 1) * 50;
                                    handleSelectedItem(currentCurrency, tempVal, ++index);
                                    setProductAmount('');
                                }}
                            >
                                {ShowCurrencies(currentCurrency, item.value, true)}
                            </div>
                        </div>
                    })}
                </div>
                <div className="col-span-12 min-[400px]:col-span-6 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0" style={styles.shadow}>
                    <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
                    <input
                        value={productAmount}
                        className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                        type="number"
                        onChange={(e) => {
                            setProductAmount(e.target.value);
                            setSelectedPrice(null);
                        }}
                        placeholder="Enter amount" />
                </div>
                <div className="col-span-12 lg:col-span-4 flex justify-center items-center-">
                    <button
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={(e) => { handleAddNewProduct() }}
                    >Quick Donate</button>
                </div>
            </div>
        </div>
    );
}

export default SadaqahWidget;
