import { Link } from 'react-router-dom';

const SentCryptoPage = () => {
  return (
    <div className=" md:container relative mx-auto overflow-hidden">
      <div className="w-full flex justify-center  absolute top-8 left-0 z-10">
        <div className="p-2 bg-white rounded-full w-24 shadow-md ">
          <a href='/'><img className='w-20 md:w-[80px] rounded-full' src="/images/general/logo-.png" alt="matw" /></a>
        </div>
      </div>
      <div className="flex flex-wrap justify-center py-8 px-3 md:px-0">
        <div className="bg-white flex pt-20 flex-col basis-full lg:basis-9/12 xl:basis-7/12 rounded-md px-6 py-12 shadow-md mt-10 items-center">
          <div className="rounded-full bg-green-50 p-6 flex justify-center items-center border border-green-500">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 761.12 760.87" fill="currentColor" className="text-green-700 w-12 h-12">
              <path d="M756.19,340c-8.32-35.28-26-65.37-53.06-89.67a6.42,6.42,0,0,1-2.49-6c1-10.17.1-20.27-1.14-30.38-5-41.07-22.54-76.12-52.21-104.64C611.18,74.53,567.47,58.61,517.44,60.1a9.14,9.14,0,0,1-7.67-3.19C464.11,9.41,408.68-8.66,344,3.84c-36.32,7-67.1,25.07-92.19,52.39a10.68,10.68,0,0,1-9.42,3.88,150.22,150.22,0,0,0-25.43.8c-27.78,3.1-53.81,11.58-76.87,27.28-54.91,37.37-81.56,89.62-79.91,156.13a7,7,0,0,1-2.52,6C25.75,279.76,6.42,315.87,1.36,359.15c-6.43,55,10,102.62,47.63,142.79,7.19,7.68,14.84,13.95,10.74,23.63,1.29,45.19,15.31,83.48,44,115.66,36.07,40.46,81.75,60,135.9,59.48,6-.06,9.88,1.45,14.11,5.79,41.26,42.23,91.66,60,150,52.8,41-5.09,76.06-23.35,104.31-53.84,3.36-3.62,6.62-5.11,11.55-4.81a153.69,153.69,0,0,0,47.5-4.92c48-12.29,84.85-39.65,110-82.4a167.76,167.76,0,0,0,23.43-92.59c-.19-5.47,1.13-9.18,5.22-12.91a159.83,159.83,0,0,0,32.4-40.95C760.29,426.85,766.69,384.48,756.19,340ZM519.85,311.88Q453.9,404,388,496.26c-4.93,6.89-9.35,14.19-15.12,20.45-11.27,12.23-28.53,13.29-41.17,2.43-4.53-3.9-8.61-8.33-12.85-12.57Q281.6,469.3,244.36,432c-11.72-11.76-12.78-28.12-2.73-40.07,11.6-13.78,30.74-14.44,44-1.29q29.08,28.85,57.89,58c3.05,3.09,4.3,3.45,7.07-.46Q411.16,362.95,472,277.94c6-8.4,13.2-14.45,24-15,16.92-.89,30.69,11.79,31.17,29.42C527.21,299.28,524.23,305.76,519.85,311.88Z" />
            </svg>
          </div>
          <h1 className="text-green-700 text-2xl font-medium">Donation is Processing</h1>
          <div className="grid grid-cols-5 p-2 sm:p-4 w-full mt-2 gap-2 text-center">
            <p className="col-span-5 text-stone-500 mb-4">
              We're waiting for your payment. Often, blockchain takes a couple of minutes to process it. As soon as we receive it, we will send you the invoice. Thank you.
            </p>
            <div className="col-span-5 flex justify-center">
              <Link
                to={'/'}
                className={`mb-3 w-full md:w-1/3 flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#b31641] focus:shadow-[0_2px_5px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] active:bg-[#b31641] hover:bg-[#b31641] hover:shadow-[0_4px_4px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}>
                Donate More
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="ml-2 w-6 h-6">
                  <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className="p-2 sm:p-4 w-full mt-6 gap-2 flex flex-col col-span-12">
          <p className="w-full text-stone-400 text-center text-md mb-3">Follow us on Social Media</p>
          <div className="flex justify-center items-center gap-6">
            <Link
              to="https://www.facebook.com/matwproject/"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/facebook.png" alt="facebook" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://www.instagram.com/matw_project/"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/instagram.png" alt="instagram" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://twitter.com/matw_project"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/twitter.png" alt="twitter" className="w-auto h-[50%]" />
            </Link>
            <Link
              to="https://www.youtube.com/@MATWProject"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/youtube.png" alt="youtube" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://www.tiktok.com/@matw_project"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/tiktok.png" alt="tiktok" className="w-auto h-[60%]" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentCryptoPage;
