import React, { useState, useEffect } from "react";
import Card from '../../components/card';
import PagesTable from "./list";
import services from "../../../../services";
import { toast } from 'react-toastify';
import HandlePage from "./handlePage";

const PageBuilder = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [allPages, setAllPages] = useState([]);
    // const [selectedPages, setSelectedPages] = useState();
    // const [productCategories, setProductCategories] = useState();
    // const [productSubCategories, setProductSubCategories] = useState();
    const [searchedItems, setSearchedItems] = useState();
    // const [currentSearchedItems, setCurrentSearchedItems] = useState();
    const [isLoading, setIsLoading] = useState(true);
    // const [searchItems, setSearchItems] = useState({
    //     category: '',
    //     subCategory: '',
    //     name: '',
    // })
    const columnsDataProducts = [
        // {
        //     Header: "Image",
        //     accessor: "image_link",
        // },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "URL",
            accessor: "page_url",
        },
        {
            Header: "Contents",
            accessor: "contents",
        },
        {
            Header: "Created Date",
            accessor: "created_at",
        },
        {
            Header: "Updated Date",
            accessor: "updated_at",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Actions",
            accessor: 'id',
        },
    ];
    const [showList, setShowList] = useState(true);
    const [selectedPage, setSelectedPage] = useState();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getPages = async () => {
        setIsLoading(true);
        try {
            const response = await services.getAllPages();
            console.log('🚀~ get-pages ~ response:', response);
            setAllPages(response.data.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    // const handleSearchPages = (searches) => {
    //     // console.log("on search name=",allPages)
    //     let searchResult = [];
    //     allPages.map(item => {
    //         item.sub_categories.map(inner_item => {
    //             inner_item.products.map(inner_item_product => {
    //                 if (inner_item_product.name.toLowerCase().includes(searches.name.toLowerCase())) {
    //                     searchResult.push(inner_item_product);
    //                 }
    //                 return inner_item_product;
    //             });
    //             return inner_item;
    //         })
    //         return item;
    //     });
    //     // console.log("searchResult", searchResult)
    //     // setSelectedPages(searchResult)
    // }
    const handleUpdatePage = (productId) => {
        allPages.map(item => item.id === productId && setSelectedPage(item));
        // allProducts.map(item => item.id === productId && setSelectedProduct(item));
        setShowList(false);
    }
    const handleDeletePage = async (id) => {
        // let tempPageUrl = pageName.substring(0,1) === '/' ? pageName.substring(1) : pageName;
        try {
            await services.deletePage(id);
            getPages();
            toast.success('The page has been deleted successfully');
        } catch (error) {
            toast.error('Something went wrong!');
            console.error('error========', error);
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // searchedItems && handleSearchedCategory(searchedItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchedItems]);
    useEffect(() => {
        getPages();
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <Card
            title={showList ? 'Pages List' : selectedPage ? 'Edit' : 'Add New Page'}
            className={"w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6"}
            btn={<div className='flex'>
                {showList ? <button className='bg-[#0f9621] hover:bg-[#0a7418] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200' onClick={() => setShowList(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
                    Add
                </button>
                    : <button className='text-blue-600 px-8 h-10 flex items-center justify-center rounded transition-all duration-200' onClick={() => { setShowList(true); setSelectedPage(null) }}>
                        Back to List
                    </button>}
            </div>}
        >
            {console.log("allPages=", allPages, isLoading)}
            {showList
                ? (allPages.length && !isLoading)
                    ? <PagesTable
                        columnsData={columnsDataProducts}
                        // tableData={selectedPages}
                        tableData={allPages || []}
                        pageSize={allPages.length}
                        isLoading={isLoading}
                        // currentSearches={currentSearchedItems}
                        // searchItems={searchItems}
                        onSearchItems={(e) => {
                            setSearchedItems(e)
                        }}
                        onUpdatePage={(e) => handleUpdatePage(e)}
                        onDeletePage={(e) => handleDeletePage(e)}
                    />
                    : <></>
                : <HandlePage
                    show={showList}
                    selectedPage={selectedPage}
                    onClose={() => { setShowList(true); setSelectedPage(null) }}
                    onSaved={() => { getPages() }}
                >
                </HandlePage>
            }
        </Card>
    );
};
export default PageBuilder;