/* eslint-disable */
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashIcon from './DashIcon';
import services from '../../../../services';

// chakra imports

export function SidebarLinks(props) {
  /* ----------------------------- Start variables ---------------------------- */
  let location = useLocation();
  const navigate = useNavigate();
  const { routes, isMinimize } = props;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    if (location.pathname === '/' && routeName === 'dashboard') {
      return true;
    } else {
      return location.pathname.includes(routeName);
    }
    // return location.pathname.includes(routeName);
  };

  const handleLogOut = async () => {
    await services.logout();
    localStorage.removeItem('token');
    navigate('/donor/login');
  };

  const createLinks = routes => {
    return routes.map((route, index) => {
      // console.log("createLinks",route)
      return (
        // <Link key={index} to={route.layout + "/" + route.path}>
        // <Link key={index} to={`${route.path === 'dashboard' ? '/dashboard' : "/dashboard/" + route.path}`}>
        <Link
          key={index}
          to={route.path}
          onClick={e => {
            if (route.name === 'Log Out') {
              e.preventDefault();
              handleLogOut();
            }
          }}
        >
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li className="my-[3px] flex cursor-pointer items-center px-8" key={index}>
              <span className={`${activeRoute(route.path) === true ? 'font-bold text-brand-500' : 'font-medium text-gray-600'}`}>
                {route.icon ? route.icon : <DashIcon />}{' '}
              </span>
              {!isMinimize && (
                <p className={`leading-1 ml-4 flex ${activeRoute(route.path) === true ? 'font-bold text-navy-700' : 'font-medium text-gray-600'}`}>
                  {route.name}
                </p>
              )}
            </li>
            {activeRoute(route.path) ? <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500" /> : null}
          </div>
        </Link>
      );
    });
  };
  /* -------------------------------------------------------------------------- */
  return createLinks(routes);
}

export default SidebarLinks;
