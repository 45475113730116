import { useEffect, useState } from 'react';
// import services from '../../services';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { palestineFaqs } from './components/faqs';
import Accordion from '../../components/general/accordion';
import { useKeenSlider } from 'keen-slider/react'
import CrisisWidget from './components/crisisWidget';
import logos from '../../images/landings/palestine/logos(1).png';

import FoodPackImg from '../../images/landings/winter/foodPack.png';
import MedicalKitImg from '../../images/landings/winter/medical-kits.png';
import FuelImg from '../../images/landings/winter/fuel.png';
import MattressesImg from '../../images/landings/winter/mattresses.png';
import MedicalReliefImg from '../../images/landings/winter/medical-relief.png';
import HotMealsImg from '../../images/landings/winter/hot-meals.png';
import BlanketsImg from '../../images/landings/winter/blankets.png';
import MedicalBedsImg from '../../images/landings/winter/medical-beds.png';
import OurPromises from '../../images/landings/palestine/our-promise.png';
import Policy from '../../images/landings/palestine/policy100.png';
import VideosSection from '../../components/general/videosSection';
import OurPromisesMobile from '../../images/landings/palestine/OurPromisesMobile.png';
import { getProducts } from '../../store/products';


export const impactInfo = {
    blankets: '38,500',
    foodPacks: '10,577,400',
    fuel: '33,500 L',
    hotMeals: '557,700',
    mattresses: '3,450',
    medicalKits: '12,500',
    medicalAid: '28,183',
    total: '11,389,333',
    lastUpdate: '24th January 2024',
    hygieneKits: '138,100'
}
const impactNumbers = [
    {
        total: '11,389,333',
        lastUpdate: '24th January 2024'
    },
    {
        title: 'Medical Kits',
        amount: '12,500',
        image: MedicalKitImg
    },
    {
        title: 'Fuel',
        amount: '33,500 L',
        image: FuelImg
    },
    {
        title: 'Mattresses',
        amount: '3,450',
        image: MattressesImg
    },
    {
        title: 'Food Packs',
        amount: '10,577,400',
        image: FoodPackImg
    },
    {
        title: 'Medical Relief',
        amount: '28,183',
        image: MedicalReliefImg
    },
    {
        title: 'Hot Meals',
        amount: '557,700',
        image: HotMealsImg
    },
    {
        title: 'Blankets',
        amount: '38,500',
        image: BlanketsImg
    },
    {
        title: 'Medical Beds & Loading Stretchers',
        amount: '121,600',
        image: MedicalBedsImg
    }

];
const images = [
    'img1',
    'img2',
    'img3',
    'img4',
    'img5'
]
export const videos = [
    {
        link: 'https://www.youtube.com/embed/0dhqNmL1uS4',
        title: '',

    },
    {
        link: 'https://www.youtube.com/embed/j0v11vDhzV8',
        title: '',

    },
    {
        link: 'https://www.youtube.com/embed/FRp5i7MhV9w',
        title: '',

    },
];

const DonateToPalestine = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [palestineProducts, setPalestineProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
    const products = useSelector((state) => getProducts(state, 'Crisis and Emergencies', 'Palestine'));
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
    const seo = SeoData[selectedcountryCode];
    const [currentSlideImpact, setCurrentSlideImpact] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const [sliderRefImpact, instanceRefImpact] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImpact(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    /* -------------------------------------------------------------------------- */


    /* ------------------------------ Start methods ----------------------------- */

    const getAllProducts = () => {
        setIsLoaded(true);
        let tempCritical = [];
        let p = products?.subCategory?.products;
        p?.forEach(item => {
            if (item.creator === 'EMR-DIS-BFPL-2023-01-200') {
                item = Object.assign({}, item, {
                    alt:"Donate to Palestine: Sponsor A Family In Palestine For A Week",
                })
                tempCritical[0] = item; // Sponsor A Family In Palestine For A Week
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-201') {
                item = Object.assign({}, item, {
                    alt:"Donate to Palestine: Sponsor A Patient",
                })
                tempCritical[1] = item; // Sponsor A Patient
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-202') {
                item = Object.assign({}, item, {
                    alt:"Donate to Palestine: Provide Clean Water In Palestine",
                })
                tempCritical[2] = item; // Provide Clean Water In Palestine
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-203') {
                item = Object.assign({}, item, {
                    alt:"Donate to Palestine: Provide Shelter For A Family In Palestine",
                })
                tempCritical[3] = item; // Provide Shelter For A Family In Palestine
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-204') {
                item = Object.assign({}, item, {
                    alt:"Palestine Charity: Emergency Orphan Support",
                })
                tempCritical[4] = item; // Emergency Orphan Support
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-205') {
                item = Object.assign({}, item, {
                    alt:"Palestine Charity: Emergency Education Support",
                })
                tempCritical[5] = item; // Emergency Education Support
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-206') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[6] = item; // Widow Support And Shelter
            }
            if (item.creator === 'EMR-DIS-PSE-2023-01') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[7] = item; // Palestine Appeal
            }
            if (item.creator === 'EMR-DIS-MDPL-2023-01-102') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[8] = item; // Palestine Emergency Medical Kit
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-102') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[9] = item; // Palestine Essential Family Food Pack
            }
            if (item.creator === 'EMR-DIS-PSE-2023-01-01') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[10] = item; // Palestine Medical Relief
            }

        });
        tempCritical = tempCritical.filter(n => n)
        setPalestineProducts(tempCritical);
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // getProductsByName('palestine');
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='!bg-white' hideFooter={true}>
            {seo &&
                <Helmet>
                    <title>{`${seo['crisisAndEmergencies']['donateToPalestine']['title']}`}</title>
                    <meta name="title" content={`${seo['crisisAndEmergencies']['donateToPalestine']['title']}`} />
                    <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
                    {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
                </Helmet>
            }
            {/* <div className='hidden sm:flex justify-center md:mx-auto'><img src={Banner} alt="palestine-banner" /></div> */}
            <section className="h-[400px] bg-center bg-cover bg-no-repeat bg-[url('../src/images/landings/palestine/mobile-palestine-banner.jpg')] sm:bg-[url('../src/images/landings/palestine/palestine-hero-banner.jpg')]" />

            {/* /* ------------------------------- Widget ----------------------------------- */}
            {/* <div className='w-full lg:container lg:mx-auto px-4 xl:px-2 py-4 md:py-6 flex justify-center items-center'>
                <CrisisWidget
                    defaultProduct={{
                        creator: "EMR-DIS-PSE-2023-01",
                        label: "Palestine Appeal",
                        value: 255
                    }}
                />
            </div> */}

            <section className='bg-[#f60362]'>
                <section className='max-w-[1440px] md:mx-auto sm:px-0 px-4 '>
                    <div className='text-[#fff]   pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Donation</div>
                    <div className='w-full   lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <CrisisWidget className={' px-6 h-auto pt-10 bg-[#DFEEF2]'}
                            defaultProduct={{
                                creator: "EMR-DIS-PSE-2023-01",
                                label: "Palestine Appeal",
                                value: 255
                            }}
                        />
                    </div>
                    {/* <div className='w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center'>
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                            creator: "MKD-MN-001",
                            label: "Where Most Needed",
                            value: 255
                        }} />
                    </div> */}
                </section>
            </section>
            <section className="max-w-[1440px] md:mx-auto pt-4 sm:pt-8   flex justify-center bg-[url('../public/images/landings/crisis/palestine/pattern.png')]">
                <div className='flex justify-center'>
                    <div className='flex justify-center items-end sm:items-center basis-full sm:basis-10/12'>
                        <div className='basis-1/2 sm:basis-1/4'>
                            <img src={OurPromises} className="hidden sm:block" alt="our-promise" />
                            <img src={OurPromisesMobile} className="sm:hidden" alt="our-promise" />
                        </div>

                        <div className='basis-1/2 sm:basis-2/3 flex flex-wrap sm:flex-nowrap items-center justify-center sm:justify-start'>

                            <div className='order-2 sm:order-1 sm:basis-2/3 text-[#253B7E] text-[10px] sm:text-[14px] pr-3'>
                                <div className='border-[#14A2DC] text-center sm:text-left border-b-2 mb-3 pb-1'>
                                    <b className=''>Our promise to you</b>
                                </div>
                                <div className='text-[8px] sm:text-[14px] leading-[10px] sm:leading-[16px] pb-5 sm:pb-0'>
                                    Founded by Ali Banat in 2015, Muslims Around The World Project (MATW) is a
                                    global Islamic  Charity working across 24 countries around the world.
                                    Our 100% donation policy ensures that every single donation goes the
                                    extra mile in reaching those most in need.
                                </div>
                            </div>

                            <div className='order-1 sm:order-2 sm:mb-[40px]'>
                                <img src={Policy} className='w-[80px] sm:w-[130px]' alt="policy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /* -------------------------------------------------------------------------- */}

            <div className="md:container md:mx-auto flex flex-wrap mt-5">
                <div className="basis-full text-center text-6xl text-[#253b7e] flex justify-center items-center font-['Gotcha'] h-[125px]">
                    Palestine Emergency
                </div>
                {/* <div className="basis-full text-center text-6xl text-[#253b7e] flex justify-center items-center font-['Gotcha'] h-[125px]">
                    {!isLoading ? allProducts.name : <Skeleton height={80} />}
                </div> */}
                <p className='w-full text-center mb-4 md:mb-8 sm:px-4 px-1 text-lg md:text-xl text-[#253B7E]'>
                    <span className='text-[#f60362]'>Donate</span> today and <span className='text-[#f60362]'>join us</span> in making a difference to those severely affected in the current conflict.
                    <br /> <br />
                    Our MATW team is on the ground working day and night to send life-saving supplies to orphaned
                    children and families in need.
                    <br />


                </p>

                <h1 className="basis-full text-center text-2xl font-bold sm:text-5xl text-[#253b7e] flex justify-center items-center py-4">
                    Donate to Palestine: Make your donations to palestine</h1>
                <p className='w-full text-center mb-4 md:mb-8 sm:px-4 px-1 text-lg md:text-xl text-[#253B7E]'>
                    We are providing essential food, water, shelter, hygiene kits and all urgent support. Our recent
                    partnership with UNWRA and the Egyptian Youth Council ensures your donations are reaching
                    those most in need in Palestine.
                </p>
                <div className="basis-full">
                    <div className="flex flex-row justify-center flex-wrap">
                        {palestineProducts.map((item, index) => {
                            return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                <ProductBox product={item} alt={item.alt} currency={selectedCurrency} />
                            </div>
                        })
                        }
                    </div>

                    {/* /* --------------------------------- The people of Palestine need your help --------------------------------- */}
                    <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
                        <h1 className="text-3xl md:text-5xl w-full text-[#253B7E] text-center font-medium mb-5 ">
                            The people of Palestine need your help
                        </h1>
                        <h2 className="text-xl md:text-4xl md:w-2/3 px-6 text-[#253B7E] text-center font-medium ">
                            It’s been more than three months of ongoing conflict. The lives of children and their
                            families continue to be uprooted, leaving many orphaned.
                        </h2>


                        <p className="text-[#253B7E] text-[18px] md:text-[20px]  leading-5 mt-3 md:mt-12 font-brandingMedium">

                            The current situation in Palestine is catastrophic. More than 22,000 have lost their
                            lives and more than 57,000 have been injured. Many
                            children are being left orphaned, with no one to care for them and protect them.
                            <br />
                            <br />
                            More than 1.9 million people have become internally displaced, around half of them
                            are children. Families have fled South, leaving everything behind, seeking refuge
                            in shelters that can no longer accommodate the numbers. Now, during winter, everyone
                            is exposed to harsher conditions that are especially dangerous for children. Not
                            only are families trying to protect themselves from violence, they now have to
                            worry about how to protect themselves from the biting winter cold.
                            <br />
                            <br />
                            Our MATW team has been responding to the crisis. We are providing life-saving assistance
                            to those suffering from the effects of the worst outbreak of violence in the region in
                            years. Use your Zakat and Sadaqah to help us provide urgent medical support, food, clean
                            water and shelter.
                            <br />
                            <br />

                            <b> Your support is needed now more than ever before.</b>

                        </p>
                        <div className='basis-full flex justify-center py-8'>
                            <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                                DONATE NOW
                            </button>
                        </div>
                    </div>




                    {/* <div className='mt-6 lg:min-h-[250px]  lg:p-12'>
                        <h1 className="text-3xl md:text-5xl w-full text-[#253B7E] text-center font-medium mb-8 md:mb-16 lg:mb-18">
                            MATW’s IMPACT
                        </h1>
                        <div className=" flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
                            <h2 className="text-3xl md:text-[45px] w-full text-[#F60362] text-center font-black ">
                                <span className="font-normal text-2xl md:text-4xl text-[#282828] mr-1">
                                    Total Impact:
                                </span>
                                &nbsp; {impactInfo.total}
                            </h2>
                            <h4 className='text-[#282828] text-center my-4'>Last Update: {impactInfo.lastUpdate}</h4>
                        </div>
                    </div> */}
                    {/* <div className='flex flex-wrap justify-center px-2 md:px-4'>
                        <div className='basis-full md:basis-1/2 lg:min-h-[388px] px-2 md:px-4 mb-4 md:mb-8'>
                            <div className='flex flex-wrap sm:flex-nowrap sm:justify-end lg:justify-start rounded-3xl overflow-hidden'>
                                <div className='basis-full sm:basis-1/2 h-[300px] sm:min-h-[250px] lg:h-[388px] bg-cover bg-no-repeat bg-[url("../public/images/landings/crisis/palestine/medicalkit-mobile.png")] sm:bg-[url("../public/images/landings/crisis/palestine/medicalkit.png")]' />
                                <div className='px-2 pt-4 sm:pt-0 sm:px-4 basis-full sm:basis-1/2 bg-[#F60362]  flex flex-col justify-center sm:items-start items-center'>
                                    <div className='text-2xl sm:text-3xl md:text-4xl text-white font-black text-center sm:text-left mb-2 md:mb-4'>{impactInfo.medicalKits}</div>
                                    <div className='text-white font-bold lg:text-[30px] '>Medical Kits</div>
                                    <p className='sm:w-full sm:text-[14px] lg:text-[22px] text-white text-center sm:text-left px-2 pb-10 sm:px-0'>Medical Kits were distributed to victims.</p>
                                </div>
                            </div>
                        </div>
                        <div className='basis-full md:basis-1/2 lg:min-h-[388px] px-2 md:px-4 mb-4 md:mb-8'>
                            <div className='flex flex-wrap sm:flex-nowrap sm:justify-end lg:justify-start rounded-3xl overflow-hidden'>
                                <div className='basis-full sm:basis-1/2 h-[300px] sm:min-h-[250px] lg:h-[388px] bg-cover bg-no-repeat bg-[url("../public/images/landings/crisis/palestine/fuel-mobile.png")] sm:bg-[url("../public/images/landings/crisis/palestine/fuel.png")]' />
                                <div className='px-2 pt-4 sm:pt-0 sm:px-4 basis-full sm:basis-1/2 bg-[#253B7E]  flex flex-col justify-center sm:items-start items-center'>
                                    <div className='text-2xl sm:text-3xl md:text-4xl text-white font-black text-center sm:text-left mb-2 md:mb-4'>{impactInfo.fuel}</div>
                                    <div className='text-white font-bold lg:text-[30px] '>Fuel</div>
                                    <p className='sm:w-full sm:text-[14px] lg:text-[22px] text-white text-center sm:text-left px-2 pb-10 sm:px-0'>Fuel for Al-Shifa hospital to operate medical equipment and lighting.</p>
                                </div>
                            </div>
                        </div>
                        <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                            <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                                <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/mattress.png")]' />
                                <div className='mt-6'>
                                    <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.mattresses}</div>
                                    <div className='text-[#F60362] my-2 text-xl font-bold text-center'>Mattresses</div>
                                </div>
                            </div>
                        </div>
                        <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                            <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                                <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/foodpkg.png")]' />
                                <div className='mt-6'>
                                    <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.foodPacks}</div>
                                    <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Food Packs</div>
                                </div>
                            </div>
                        </div>
                        <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                            <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                                <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/blanket.png")]' />
                                <div className='mt-6'>
                                    <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.blankets}</div>
                                    <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Blankets</div>
                                </div>
                            </div>
                        </div>
                        <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                            <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                                <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/meal.png")]' />
                                <div className='mt-6'>
                                    <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.hotMeals}</div>
                                    <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Hot Meals</div>
                                </div>
                            </div>
                        </div>
                        <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                            <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                                <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/medical-aid.jpg")]' />
                                <div className='mt-6'>
                                    <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.medicalAid}</div>
                                    <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Medical Assistance</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                            <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                                <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px]' />
                                <div className='mt-6'>
                                    <div className='text-center text-[#253B7E] text-4xl font-bold'>10,000</div>
                                    <div className='text-[#F60362] my-2 text-xl sm:text-base lg:text-xl font-bold text-center'>Medical Beds & Loading Stretchers</div>
                                </div>
                            </div>
                        </div>
                        <div className='basis-full sm:basis-1/2 md:basis-1/3 px-2 md:px-4 mb-2 md:mb-4'>
                            <div className='rounded-[20px] border border-[#AAA] border-1 shadow-[0px_10px_25px_0px_rgba(37, 42, 52, 0.08)]'>
                                <div className='h-[208px] md:h-[250px] bg-cover rounded-t-[20px] bg-[url("../public/images/landings/crisis/palestine/hygieneKits.jpeg")]' />
                                <div className='mt-6'>
                                    <div className='text-center text-[#253B7E] text-4xl font-bold'>{impactInfo.hygieneKits}</div>
                                    <div className='text-[#F60362] my-2  text-xl font-bold text-center'>Hygiene Kits</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* /* -------------------------------------------------------------------------- */}
            <section className='bg-[#f1f1f1] sm:pt-5'>



                {/* ----------------- videos section ---------------- */}

                <VideosSection youtube videos={videos} />

                {/* ----------------- videos section --END-------------- */}


                {/* /* --------------------------------- Images --------------------------------- */}
                <div className="md:container md:mx-auto flex justify-center flex-wrap  sm:mt-12">
                    <div className="basis-full hidden sm:flex flex-wrap">
                        <div className="basis-full md:basis-1/3 md:pr-2 mb-4">
                            <div className="bg-[url('../src/images/landings/palestine/img1.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[465px] 2xl:h-[500px]" />
                        </div>
                        <div className="basis-full md:basis-1/3 md:px-2 mb-4">
                            <div className="bg-[url('../src/images/landings/palestine/img2.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px] mb-2" />
                            <div className="bg-[url('../src/images/landings/palestine/img3.jpg')] bg-top bg-cover sm:h-[300px] md:h-[114px] lg:h-[124px] xl:h-[150px] 2xl:h-[150px] mb-2" />
                            <div className="bg-[url('../src/images/landings/palestine/img4.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px]" />
                        </div>
                        <div className="basis-full md:basis-1/3 md:pl-2 mb-4">
                            <div className="bg-[url('../src/images/landings/palestine/img5.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[465px] 2xl:h-[500px]" />
                        </div>

                    </div>
                    <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                        <div ref={sliderRefImages} className="keen-slider">
                            {images.map((item, index) => {
                                return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full px-9 flex justify-center`}>
                                    <div className={`bg-[url('../src/images/landings/palestine/img${index + 1}.jpg')] bg-center bg-cover h-[300px] w-full`} />
                                </div>
                            })}
                        </div>
                        {loaded && instanceRefImages.current && (
                            <>
                                <Arrow
                                    left
                                    bg={'bg-[#253A7E]'}
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRefImages.current?.prev()
                                    }
                                    disabled={currentSlideImages === 0}
                                />

                                <Arrow
                                    bg={'bg-[#253A7E]'}
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRefImages.current?.next()
                                    }
                                    disabled={
                                        currentSlideImages ===
                                        instanceRefImages.current.track.details.slides.length - 1
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="w-full sm:hidden flex justify-center">
                        {loaded && instanceRefImages.current && (
                            <div className="dots flex pt-3 pb-6">
                                {[
                                    ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                ].map((idx) => {
                                    return (
                                        <button
                                            key={idx}
                                            onClick={() => {
                                                instanceRefImages.current?.moveToIdx(idx)
                                            }}
                                            className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                        ></button>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {/* /* -------------------------------------------------------------------------- */}



                {/* /* --------------------------------- Muslim Arounds ------------------------- */}
                <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
                    <h1 className="text-3xl md:text-5xl w-full text-[#253B7E] text-center font-medium mb-8 md:mb-16 lg:mb-18">
                        Muslims Around the World (MATW) on the ground in Palestine:
                    </h1>
                    <h2 className='text-[#282828] text-center  basis-full'>In Partnership with</h2>
                    <div className="basis-full md:basis-1/2 lg:basis-1/2 flex flex-wrap">
                        <div className="flex justify-center items-center">
                            <img src={logos} alt="sh logo" className='max-h-[100px]' />
                        </div>
                    </div>
                    <p className="text-[#253B7E] text-[18px] md:text-[20px] font-brandingMedium leading-5  mt-8 md:mt-12">
                        With a long standing presence in Palestine and supplies on standby for an
                        immediate response, our MATW team has been quick to deliver urgent essentials to
                        those directly affected by the conflict.

                        <br />
                        <br />
                        Our ground team is delivering emergency medical kits, urgent food
                        packs, fuel, hot meals and more to displaced families. Our primary
                        focus is reaching those most vulnerable and in need.

                        <br />
                        <br />
                        MATW’s was delivering medical donations that proved to be a critical
                        lifeline for the people of Palestine. We delivered life-saving
                        essentials such as stretchers, and beds, mattresses and blankets,
                        medical kits and supplies, assistance with staff support, fuel to
                        ensure medical make-shift camps and operational hospitals continue to run.

                        <br />
                        <br />
                        Our team has now partnered with the Egyptian Youth Council, Egyptian
                        Red Crescent and The Jordanian Hashemite Charity Organisation (JHCO)
                        to ensure aid is reaching those in need in Palestine, and are hard at
                        work on the ground putting your donations to action.
                        <br />
                        We are determined as ever to never give up. Every life is valuable and
                        your support allows us to continue doing the work that we do.
                        <br />
                        <br />

                        <b> With full transparency to our donors, every single donation to our Palestine Emergency Appeal
                            will go directly to the field, with a 100% donation policy.
                        </b>
                        <br />
                        <br />
                        <span className='text-[#f60362]'>Donations to our Palestine Emergency Appeal are also 100%
                            Zakat applicable.</span>

                    </p>
                    <div className='basis-full flex justify-center py-4 md:py-8'>
                        <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                            DONATE NOW
                        </button>
                    </div>
                </div>


            </section>
            {/* <div className="w-full mt-5 bg-[#00a3da]">
                <div className="md:container md:mx-auto mt-5 flex flex-wrap text-white pb-8 md:pb-16">
                    <div className='basis-full mt-6 p-6 lg:p-12 text-center'>
                        <p className="text-center text-xl md:text-5xl">
                            The people of
                            <span className="font-['Gotcha'] font-medium text-[#f60362] md:text-5xl mx-2 md:mx-4">
                                Palestine
                            </span>
                            need your help
                        </p>
                        <p className="text-center text-base md:text-2xl mt-2">
                            UNREST IS UNFOLDING IN PALESTINE
                        </p>
                    </div>
                    <div className='basis-full md:basis-1/2 px-4 md:px-6 mt-3'>
                        <p>
                            Devastating violence and unrest is unfolding in Palestine creating upheaval, leaving families in a state of uncertainty and fear.
                            Our MATW team is ready to respond to the crisis and are preparing to provide live saving assistance to what could be the biggest conflict in years.
                            Use your Zakat and Sadaqah to help us provide urgent medical support, food, clean water and shelter.
                            <br />
                            <br />
                            Described as the worlds largest open air prison, decades of violence has crippled Palestine’s infrastructure.
                            Families are in a constant state of rebuilding and recovery.Our MATW team on the ground reports an escalating situation of danger.
                        </p>
                    </div>
                    <div className='basis-full md:basis-1/2 px-4 md:px-6 mt-3'>
                        <p>
                            Families are preparing to flee their homes to protect themselves from the violence to come.MATW has been working on the ground in
                            Palestine delivering urgent support during emergencies, providing care for orphans through our care program and more recently,
                            rebuilding homes through our project build initiative.
                            <br />
                            <br />
                            We are dedicated to delivering urgent assistance in the most difficult to reach areas to those most in need.Every second counts.
                            Your donation could be the difference between life and death.Stand for the people of Palestine in their hour of greatest need.
                        </p>
                    </div>
                    <div className='basis-full flex justify-center py-4 md:py-8'>
                        <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-full' onClick={() => window.scroll(0, 0)}>
                            DONATE NOW
                        </button>
                    </div>
                </div>
            </div> */}

            {/* <div className="md:container md:mx-auto mt-5 flex flex-wrap">
                <div className="basis-full rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                    <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                        <iframe className='video'
                            width={'100%'}
                            height={'100%'}
                            title={'youtube'}
                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                            src="https://youtube.com/embed/9kvOCK3HKMc?autoplay=0"
                        />
                    </div>
                </div>
            </div> */}

            {/* /* --------------------------Impact Numbers------------------------------------------------ */}

            <div className="md:container md:mx-auto  mt-12 px-4 md:px-0">
                <h2 className="basis-full mb-3 md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-6xl">
                    MATW's <span className='text-[#F60362]'>IMPACT</span>
                </h2>
                <div className=' flex flex-col  items-center text-center text-white font-brandingMedium'>
                    <div className='bg-[#F60362] w-[348px] text-[26px] sm:w-[675px] my-6  h-[80] rounded-full sm:text-[50px]'>
                        TOTAL IMPACT: <span className='font-bold'>{impactNumbers[0].total}</span>
                    </div>
                    {/* <div className='text-[#78716C] hidden sm:block text-[25px]'>{impactNumbers[0].lastUpdate}</div> */}
                </div>

                <div className='hidden sm:flex gap-4 mt-6 mb-16 justify-center flex-wrap'>
                    {impactNumbers.map((item, index) => (

                        index > 0 && <div key={index} className='relative flex justify-center  p-3'>
                            <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                {item.amount}
                            </div>
                            <div className='flex flex-col justify-around items-center min-h-[365px] w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                <div><img src={item.image} alt={item.title} className='w-[130px]' /></div>
                                <div className='text-[#253B7E] text-[24px] font-bold mt-24 px-3 leading-5 text-center'> {item.title}</div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* -------------Impact numbers slider for mobile----------- */}


                <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                    <div ref={sliderRefImpact} className="keen-slider">
                        {impactNumbers.map((item, index) => {
                            return index > 0 && <div key={`carousel_impact${index}`} className={`keen-slider__slide basis-full px-4 py-2 flex justify-center`}>
                                <div className='relative flex justify-center  p-3'>
                                    <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                        {item.amount}
                                    </div>
                                    <div className='flex flex-col justify-around items-center h-[365px] min-w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                        <div><img src={item.image} alt={item.title} className='w-[130px]' /></div>
                                        <div className='text-[#253B7E] text-[24px] font-bold mt-20 leading-5 text-center'> {item.title}</div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {loaded && instanceRefImpact.current && (
                        <>
                            <Arrow
                                left
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.prev()
                                }
                                disabled={currentSlideImpact === 0}
                            />

                            <Arrow
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.next()
                                }
                                disabled={
                                    currentSlideImpact ===
                                    instanceRefImpact.current.track.details.slides.length - 1
                                }
                            />
                        </>
                    )}
                </div>
                <div className="w-full sm:hidden flex justify-center">
                    {loaded && instanceRefImpact.current && (
                        <div className="dots flex pt-3 pb-6">
                            {[
                                ...Array(instanceRefImpact.current.track.details.slides.length).keys(),
                            ].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRefImpact.current?.moveToIdx(idx)
                                        }}
                                        className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImpact === idx ? " active bg-[#F60362]" : " bg-gray-300")}
                                    ></button>
                                )
                            })}
                        </div>
                    )}
                </div>

            </div>





            {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
            {/* <div className='w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3]- py-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center  mb-4 text-[#253B7E]">Palestine donations <strong className='text-[#f60362]'>FAQs</strong></h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-2 md:px-0 mt-6">
                    {palestineFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordion>
                            </div>
                        );
                    })}
                </div>
            </div> */}
            <section className='max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10'>
                <h1 className='text-[#253B7E] text-left  sm:text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                    Palestine donations <span className='text-[#F60362]'> FAQ’s</span>
                </h1>
                <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[0].question}</h1>
                        <p className='text-base font-brandingMedium leading-4 mt-3'>
                            {palestineFaqs[0].answer}
                        </p>
                    </div>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[1].question}</h1>
                        <p className='text-base font-brandingMedium leading-4 mt-3'>
                            {palestineFaqs[1].answer}
                        </p>
                    </div>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[2].question}</h1>
                        <p className='text-base font-brandingMedium leading-4 mt-3'>
                            {palestineFaqs[2].answer}
                        </p>
                    </div>
                </div>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                    {palestineFaqs.map((item, index) => {
                        return index > 2 && (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordion>
                            </div>
                        );
                    })}
                </div>
            </section>
            {/* /* -------------------------------------------------------------------------- */}

        </Layout>
    );
}
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <div
            className={`${props.bg ? props.bg : 'bg-[#F60362]'} w-[30px] h-[60px] absolute top-[calc(50%-60px)] translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.left ? "arrow--left left-[0px]" : "arrow--right left-auto right-[0px]"}`}
            onClick={props.onClick}
        >
            <svg
                className={`w-[24px] h-[24px] fill-white cursor-pointer arrow ${props.left ? "arrow--left" : "arrow--right"
                    } ${disabeld}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                {props.left && (
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                )}
                {!props.left && (
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                )}
            </svg>
        </div>
    )
}
export default DonateToPalestine;