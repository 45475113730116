function createScn(styles) {
  return function (classNames) {
    var normalizedClassNames = classNames.trim().split(/\s+/);
    return normalizedClassNames
      .map(function (className) {
        return styles[className];
      })
      .join(' ');
  };
}

module.exports = createScn;