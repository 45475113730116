import React, { useState } from 'react'
import matwImpactPhoto from '../../../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../../../images/landings/palestine/slider-icon-left.png';
import { impactInfo, impactSliderData } from '../../fixtures';
import { useKeenSlider } from 'keen-slider/react';

const TotalImpactSection = ({ lang = 'en' }) => {
  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  return (
    <section className="bg-[#E1EFF2] py-6 md:py-8 flex flex-col">
      <div className="flex items-center justify-center mb-4 md:mb-5">
        <img src={matwImpactPhoto} alt="matw-impact" />
      </div>
      <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
        <h2 className={`text-[18px] md:text-4xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E] text-center font-black`}>
          <span className="font-normal text-[18px] md:text-4xl text-[#78716C] uppercase mr-1">
            {lang === 'fr'
              ? 'IMPACT TOTAL:'
              : lang === 'ar'
                ? 'التأثير الكلي:'
                : 'Total Impact:'
            }
          </span>
          &nbsp; {impactInfo.total}
        </h2>
      </div>
      <div className="hidden md:container md:mx-auto sm:grid sm:grid-cols-4 xl:grid-cols-4 gap-16 justify-between items-center">
        {impactSliderData.map(({ img, value, title, frenchTitle, arabicTitle }, index) => (
          <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
            <img src={img.src} alt={img.alt} />
            <div className="flex flex-col items-center text-center">
              <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>{value}</h4>
              <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>{lang === 'fr' ? frenchTitle : lang === 'ar' ? arabicTitle : title}</small>
            </div>
          </div>
        ))}
      </div>
      <div className={`sm:hidden px-6 md:px-0 relative`}>
        <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
          {impactSliderData.map(({ img, value, title, frenchTitle, arabicTitle }, index) => (
            <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
              <img src={img.src} alt={img.alt} />
              <div className="flex flex-col items-center text-center">
                <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>{value}</h4>
                <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>{lang === 'fr' ? frenchTitle : lang === 'ar' ? arabicTitle : title}</small>
              </div>
            </div>
          ))}
        </div>
        {loadedImpact && instanceRefImpactSlider.current && (
          <>
            <Arrow
              left
              onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
              className="bg-transparent"
              disabled={currentImpactSlide === 0}
              icon={impactSliderLeftIcon}
            />

            <Arrow
              onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
              disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
              className="bg-transparent"
              icon={impactSliderRightIcon}
              rightMargin
            />
          </>
        )}
      </div>
    </section>
  )
}
function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${props.bg ? props.bg : 'bg-[#F60362]'
        } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.className ? props.className : ''
        } ${props.left ? 'arrow--left left-[8px]' : 'arrow--right left-auto right-[8px]'}`}
      onClick={props.onClick}
    >
      {props.icon ? (
        <img alt='arrow-img' src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-1' : '-ml-1'} `} />
      ) : (
        <svg
          className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
          {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
      )}
    </div>
  );
}
export default TotalImpactSection;