import { useEffect, useState } from 'react';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import eventBanner from "../../images/events/event-banner-muftimenk.jpg";
import { selectProducts } from '../../store/products';

const YasminmogahedAustraliaPage = ({ speaker }) => {
    /* ----------------------------- Start variables ---------------------------- */
    const [muftimentProducts, setMuftimentProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const products = useSelector((state) => selectProducts(state));
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        if (speaker !== 'qasim') {
            products.map(item => {
                item.sub_categories.map(inner_item => {
                    inner_item.products.map(inner_item_product => {

                        if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-200') {

                            tempProductsList[0] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-201') {

                            tempProductsList[1] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204') {

                            tempProductsList[2] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {

                            tempProductsList[3] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') {

                            tempProductsList[4] = inner_item_product;

                        }
                        // if(inner_item_product.creator ==='MKD-GEN-MED-2023-01-122'){

                        //     tempProductsList[5]=inner_item_product;

                        // }
                        if (inner_item_product.creator === 'ORP-CON-ORH-2023-01-043') {

                            tempProductsList[5] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'RM24-ZM-013') {

                            tempProductsList[7] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') {

                            tempProductsList[10] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') {

                            tempProductsList[11] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') {

                            tempProductsList[12] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') {

                            tempProductsList[13] = inner_item_product;

                        }
                        return inner_item_product;
                    });
                    return inner_item;
                })
                return item;
            });
        }
        else {
            products.map(item => {
                item.sub_categories.map(inner_item => {
                    inner_item.products.map(inner_item_product => {

                        if (inner_item_product.creator === 'SSB-ORP-GEN-001') {

                            tempProductsList[0] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'EDU-GEN-BTS-20230-002') {

                            tempProductsList[1] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204') {

                            tempProductsList[2] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'FST-DEL-F50-2023-01-009') {

                            tempProductsList[3] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {

                            tempProductsList[4] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'RM24-FA-011') {

                            tempProductsList[5] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'RM24-FA-010') {

                            tempProductsList[6] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'RM24-ZM-013') {

                            tempProductsList[7] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') {

                            tempProductsList[8] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') {

                            tempProductsList[9] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') {

                            tempProductsList[10] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') {

                            tempProductsList[11] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') {

                            tempProductsList[12] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') {

                            tempProductsList[13] = inner_item_product;

                        }
                        if (inner_item_product.creator === 'MKD-MN-001') {

                            tempProductsList[14] = inner_item_product;

                        }
                        return inner_item_product;
                    });
                    return inner_item;
                })
                return item;
            });
        }
        tempProductsList = tempProductsList.filter(n => n)
        setMuftimentProducts(tempProductsList);
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout>
            <div className="flex md:w-full md:h-[120px] lg:w-full lg:h-[160px] xl:w-full xl:h-[200px] 2xl:w-full 2xl:h-[250px]">
                {<img src={eventBanner} alt={'yasminmogahedmalaysia'} className='w-full' onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/general/logo-replace.png";
                    currentTarget.className = 'max-h-[150px] mx-auto'
                }} />}
            </div>
            <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
            <div className="md:container md:mx-auto mt-5">
                <div className="grid grid-cols-12 px-3- md:px-4-">
                    <div className="col-span-12 text-center text-5xl md:text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
                        Plant Your Sadaqah
                    </div>
                    <div className="col-start-1 col-span-12 rounded-md">
                        <div className="flex flex-row justify-center flex-wrap">
                            {muftimentProducts.map((item, index) => {
                                return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                </div>
                            })
                            }
                        </div>
                        <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[200px] bg-white p-6 lg:p-12 text-center shadow-lg`}>
                            <h1 className='text-[#00a3da] text-4xl mb-4'>“Charity does not decrease wealth.” - The Prophet Mohammed ﷺ [Muslim]</h1>
                            <p className="text-[#777] text-xl mb-4">
                                Never underestimate the power of your Sadaqah or Zakat. It can change lives, uplifting the most vulnerable people in the world. Donate now.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default YasminmogahedAustraliaPage;
