import { useEffect, useState } from 'react';
import services from '../../../../services';
import Footer from '../../../../components/general/footer';
import MainFooter from './mainFooter';
import CustomNavbar from './navbar';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { setCountryCode } from '../../../../store/user';
import { setProducts } from '../../../../store/products';
import PropTypes from 'prop-types';
import Highlights from '../../../../components/general/highlights';

const Layout = props => {
  const dispatch = useDispatch();
  const { children, propertyName, className, hideFooter, childClasses, highlights, navigation, isRamadanPage } = props;
  const [isUk, setIsUk] = useState(false);
  const [isAus, setIsAus] = useState(false);
  const [allProducts, setAllProducts] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);
  const getAllProducts = async () => {
    try {
      const response = await services.allProducts();
      setAllProducts(response.data);
      dispatch(setProducts(response.data));
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getConvertedCurrency = async amount => {
    let tempRatioes = {
      EUR: 1,
      USD: 1,
      CAD: 1,
      GBP: 1,
      SGD: 1,
      MYR: 1,
      IDR: 1,
      AUD: 1,
      AED: 1,
    };
    try {
      const response = await services.convertCurrency(amount);
      // console.log("response convert aud",response)
      response.data.map(item => {
        tempRatioes[item.currency_name] = item.ratio;
        return item;
      });
      localStorage.setItem('Ratio', JSON.stringify(tempRatioes));
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const checkUKWebsite = referrer => {
    const href = referrer;
    const findTerm = term => {
      if (href.includes(term)) {
        return href;
      }
    };
    switch (referrer) {
      case findTerm('matwproject.org.uk'): // UK Website
        setIsUk(true);
        break;
      case findTerm('matwproject.org'): // AUS Website
        setIsAus(true);
        break;
      default:
        setIsUk(false);
    }
  };

  useEffect(() => {
    getConvertedCurrency(1);
    getAllProducts();
    checkUKWebsite(window.location.href);

    if (window.location.hostname) {
      switch (window.location.hostname) {
        case 'matwproject.org':
          dispatch(setCountryCode('AU'));
          break;
        case 'matwproject.org.uk':
          dispatch(setCountryCode('UK'));
          break;
        case 'matwprojectusa.org':
          dispatch(setCountryCode('US'));
          break;
        case 'matwprojectca.org':
          dispatch(setCountryCode('CA'));
          break;
        case 'matwprojectfr.org':
          dispatch(setCountryCode('FR'));
          break;
        case 'matwprojectme.org':
          dispatch(setCountryCode('ME'));
          break;
        case 'matwprojectmys.org':
          dispatch(setCountryCode('MY'));
          break;
        case 'matwprojectsgp.org':
          dispatch(setCountryCode('SG'));
          break;
        case 'matwprojectid.org':
          dispatch(setCountryCode('ID'));
          break;
        default:
          dispatch(setCountryCode('AU'));
          break;
      }
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`w-full bg-[#e2eff3] min-h-screen ${className ? className : ''}`}>
      <Helmet>
        <link rel="canonical" href={`${window.location.protocol + '//' + window.location.host}${window.location.pathname}`} />
      </Helmet>

      <CustomNavbar
        navigation={navigation}
        products={allProducts}
        scrollPosition={scrollPosition}
        isAus={isAus}
        isUk={isUk}
        title={'MATW Project Australia'}
        isRamadanPage={isRamadanPage}
      />
      {highlights === undefined ? <Highlights /> : ''}

      <div className={`${childClasses ? childClasses : ''} ${propertyName ? 'bg-white' : ''}`}>{children}</div>
      {scrollPosition > 300 && (
        <div
          className="rounded-full opacity-50 hover:opacity-100 bg-[#093484] transition-all duration-200 hover:shadow-md w-12 h-12 flex justify-center items-center cursor-pointer fixed right-8 bottom-8 text-white"
          onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
          </svg>
        </div>
      )}
      {hideFooter ? null : <Footer />}
      <MainFooter />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  propertyName: PropTypes.string,
  className: PropTypes.string,
  hideFooter: PropTypes.bool,
  childClasses: PropTypes.string,
  highlights: PropTypes.any,
  navigation: PropTypes.any,
  isRamadanPage: PropTypes.bool,
  title: PropTypes.string,
};

export default Layout;
