import header_web from '../../../images/eidFitr2024/header_web.jpg';
import header_responsive from '../../../images/eidFitr2024/header_responsive.jpg';

export const eidAlFitrBannerImages = [
  {
    src: header_web,
    alt: 'Eid al Fitr 2024: Donating on Eid',
    classNames: 'max-[500px]:hidden w-full',
  },
  {
    src: header_responsive,
    alt: 'Eid ul fitr 2024: Marking the end of ramadan',
    classNames: 'min-[501px]:hidden w-full',
  },
];

export const eidMubarakBannerImages = [
  {
    src: header_web,
    alt: 'Eid Mubarak 2024 - happy eid al fitr',
    classNames: 'max-[500px]:hidden w-full',
  },
  {
    src: header_responsive,
    alt: 'Eid Mubarak wishes 2024 - eid mubarak fitr',
    classNames: 'min-[501px]:hidden w-full',
  },
];

const HeaderImage = ({ bannerImages }) => {
  return (
    <section className="flex justify-center">
      {bannerImages && bannerImages.map(image => <img src={image.src} alt={image.alt} className={image.classNames} />)}
    </section>
  );
};

export default HeaderImage;
