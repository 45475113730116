import { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import MaxWidthContainer from '../maxWidthContainer';

export const CarouselImages = ({ carouselImages }) => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);

  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });

  return (
    <>
      {/* /* --------------------------------- Images --------------------------------- */}
      <MaxWidthContainer>
        <div className="flex justify-center flex-wrap">
          <div className="w-full flex-wrap justify-center items-center relative">
            <div ref={sliderRefImages} className="keen-slider">
              {carouselImages &&
                carouselImages.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="w-full  flex justify-center">
            {loaded && instanceRefImages.current && (
              <div className="dots flex pt-3 ">
                {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRefImages.current?.moveToIdx(idx);
                      }}
                      className={
                        'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                        (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </MaxWidthContainer>
    </>
  );
};
