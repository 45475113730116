import React, { useEffect, useState } from 'react';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import CrisisWidget from './crisisWidget';
import Layout from '../../components/general/layout';
import { Footer } from '../../components/general';
import CustomModal from '../../components/general/modal';
import mosqueImage from '../../images/landings/gift-giving/gift-giving-mosque.png';
import { selectCreatedUser } from '../../store/user';
import { useSelector } from 'react-redux';

const GiftGiving = () => {
  const [showModal, setShowModal] = useState(false);
  const userState = useSelector(selectCreatedUser);
  const [user, setUser] = useState({
    username: '',
    email: '',
    message: '',
    productName: '',
    donorName: userState?.name || '',
  });

  useEffect(() => {
    setUser(prevUser => ({
      ...prevUser,
      donorName: userState?.name || '', // Update donorName here
    }));
  }, [userState]);

  const handleUserChange = e => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleProductName = product =>
    setUser(prevUser => ({
      ...prevUser,
      productName: product,
    }));

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <Layout hideFooter className="bg-white" childClasses={'pb-0'}>
      {/* {seo && (
        <Helmet>
          <title>{`${seo['ramadanDua']['title']}`}</title>
          <meta name="title" content={`${seo['ramadanDua']['title']}`} />
          <meta name="description" content={`${seo['ramadanDua']['description']}`} />
        </Helmet>
      )} */}
      <MaxWidthContainer>
        <div className="py-8">
          <h1 className='text-[#23408F] text-[32px] md:text-[96px] font-["Gotcha"] text-center'>Gift of Giving This Ramadan 2024</h1>
        </div>
      </MaxWidthContainer>
      <div className="bg-[url('../src/images/landings/gift-giving/bg-gift-giving-desktop.jpg')] bg-center bg-cover h-[518px] aspect-auto"></div>
      <MaxWidthContainer className="mb-20 -mt-72">
        {/* Title */}
        <div className="max-w-[800px] mx-auto -mb-1">
          <img src={mosqueImage} alt="mosque" />
        </div>
        <div className="bg-[#DFEEF2] max-w-[800px] mx-auto">
          <div
            className="w-full bg-[#F60362] p-5 pb-10"
            style={{
              clipPath: 'polygon(100% 0, 100% 19%, 100% 35%, 100% 84%, 75% 88%, 47% 96%, 22% 90%, 0 88%, 0% 35%, 0 0)',
            }}
          >
            <h2 className="text-sm md:text-3xl text-white text-center p-5">
              Donate in the name of a loved one to secure blessings and barakah for your hereafter and your loved one. Make their Ramadan memorable by
              donating on their behalf, and your loved one will receive a heartfelt personalised gift certificate as a token of gratitude.
            </h2>
          </div>
          <CrisisWidget
            className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'MKD-GEN-MWA-2023-01-120',
              label: 'Select an appeals',
              value: 105,
            }}
            onShowModal={handleShowModal}
            handleUserChange={handleUserChange}
            giftingUser={user}
            handleProductName={handleProductName}
          />
        </div>
      </MaxWidthContainer>
      <Footer />
      {showModal && (
        <CustomModal
          show={showModal}
          onClose={() => setShowModal(false)}
          className="w-full md:w-full max-w-[1200px] !mx-8"
          bodyClassName="!pb-0 !px-0 !md:px-0 !md:pb-0"
          headerStyle="!justify-center !bg-transparent !absolute !top-4 !right-4 !w-8 !h-8 !border !border-white !rounded-full !p-2 !z-10"
        >
          {/* Title */}
          <div className="mx-auto">
            {/* <div className=" -mb-1">
              <img src={mosqueImageModal} alt="mosque" />
            </div> */}
            <div className="p-12">
              <div className="flex flex-col items-center gap-3 mb-16">
                <h1 className="brandingBold md:text-5xl text-center">{user.donorName ? user.donorName : 'Donor'}</h1>
                <p className="brandingBold md:text-5xl text-center">has generously donated on your behalf.</p>
                <div className="brandingBold md:text-5xl text-center">To: {user.productName ? user.productName : 'Project'}</div>
                <p className="brandingBold md:text-5xl text-center">Here’s a special message from them:</p>
              </div>
              <div className="flex flex-col items-center gap-5">
                <h6 className='font-["Gotcha"] text-[20px] md:text-6xl text-center text-[#F60362]'>
                  Salam {user.username ? user.username : 'Name'},
                </h6>
                <p className='font-["Gotcha"] text-sm md:text-5xl text-center text-[#F60362]'>{user.message ? user.message : 'message'}</p>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </Layout>
  );
};

export default GiftGiving;
