import React from 'react';

const Arrow = (props) => {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <div
      className={`${props.bg ? props.bg : 'bg-[#F60362]'} w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.left ? "arrow--left left-[0px]" : "arrow--right left-auto right-[0px]"}`}
      onClick={props.onClick}>
      <svg
        className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? "arrow--left" : "arrow--right"} ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    </div>
  )
}
export default Arrow;