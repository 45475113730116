import React from 'react'
import Widget from './widget'
import Layout from '../../components/general/layout'

const TestWidget = () => {
  return (
    <Layout>
    <div className='bg-white flex h-[90vh] justify-center items-center '>


      <div>
        <Widget hasSuggestPrice defaultProduct={{
          creator: "MKD-GEN-MFA-2023-01-118",
          label: "Food Aid",
          value: 103
        }} />
      </div>

    </div>
    </Layout>
  )
}

export default TestWidget