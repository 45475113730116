import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import TabContent from '../../home/components/tab-content';
import { useRef } from 'react';

const PalestineFamineTabs = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const { whereMostNeededProducts, feedPalestineOrphanProducts, lang = 'en' } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    {
      title: lang === 'fr' ? 'Besoins criants' : lang === 'ar' ? 'في أمس الحاجة' : 'Where most needed',
      description: '',
      products: whereMostNeededProducts,
    },
    {
      title: lang === 'fr' ? 'Nourrir un orphelin palestinien' : lang === 'ar' ? 'تكفل بإطعام يتيم فلسطيني' : 'Feed a Palestinian orphan',
      description: '',
      products: feedPalestineOrphanProducts
    }
  ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => {
                window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 })
                setActiveTabProducts(index);
              }}
              className={`${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} text-[14px] rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'}`}
              >
              {title}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {tabButtonsProducts.map((item, index) =>
              index === activeTabProducts && <React.Fragment key={`description_${index}`}>
                <div className="flex w-full justify-center mb-6">
                  <p className='text-center text-white text-[18px] md:text-3xl md:w-2/3'>
                    {item.description}
                  </p>
                </div>
                <div ref={contentRef}>
                  <TabContent
                    products={item.products}
                    lang={lang}
                    currency={selectedCurrency}
                    productsOnMobile={item.productsOnMobile ? item.productsOnMobile : null}
                    showDots={item.productsOnMobile ? false : true}
                    // showArrows={item.productsOnMobile ? false : true}
                    showArrows={false}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default PalestineFamineTabs;