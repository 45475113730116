import React from 'react';
import { Link } from 'react-router-dom';

const OtherBox = (props) => {
    return <Link to={props.link} className="flex flex-col justify-center items-center p-2 md:p-4 relative rounded-lg border-2 border-[#14A2DC] col-span-12 sm:col-span-6 hover:opacity-80 duration-100">
        <h3 className=" text-[#14A2DC] text-lg md:text-xl text-center font-medium h-16 flex items-center">
            {props.title}
        </h3>
        <div className="flex justify-center items-center h-24 text-[#14A2DC]">
            {props.svg}
        </div>
        <p className=" text-[#78716C] mt-4 md:mt-8 text-center">
            {props.description}
        </p>
    </Link>
}

export default OtherBox;