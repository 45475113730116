// import Layout from "../components/general/layout";
import Layout from "../../components/general/layout";

const AppPage = () => {
  return (
    <Layout propertyName={"bg-white"}>
      <div className="w-full">
      <div className="w-full flex justify-center bg-white banner-content lg:pt-4 md:pt-4 xs:pt-0 xs:mx-0">
          <div class="md:container md:mx-auto sm:px-4 xs:px-2 xs:mx-1">
            <div class="box">
              <div class="flex flex-wrap  items-center">
                <div class="lg:w-2/3 pr-4 pl-4 md:w-3/5">
                  <div class="section-heading lg:text-left md:text-left xs:text-center">
                    <h1>The MATW App:<span>Streamlined </span> Muslim Aid Donations & Tracking</h1>
                    <p>
                      MATW app also allows you to monitor and track your zakat donations from the start of your
                      donation to the completion of the project. It provides you with detailed information on how
                      your donation is calculated, who it is helping, and the impact it is making.
                    </p>
                    <p>
                      Download the app to advance your spiritual journey and give back to the community.
                    </p>
                    <div class="btn-sec flex">
                      <a href="https://play.google.com/store/apps/details?id=com.bga.matw">
                        <img src="/images/app/google-play.png" alt="" />
                      </a>
                      <a href="https://apps.apple.com/us/app/matw/id6446399296"className="ml-3">
                        <img src="/images/app/app-store.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="lg:w-1/3 pr-4 pl-4 md:w-2/5">
                  <div class="img-sec">
                    <img src="/images/app/1mockup.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center bg-white banner-content static-one pt-14">
          <div class="container mx-auto sm:px-4 xs:px-1">
            <div class="flex flex-wrap ">
              <div class="w-full text-center">
                <div class="section-heading">
                  <h1>MATW is committed to helping out in the most vulnerable Muslim communities
                  </h1>
                  <p>
                    By providing them with adequate resources for their basic needs such as food, shelter, clothing,
                    and medical supplies. It also works to provide educational opportunities, provide access to
                    clean water, and create job opportunities for those in need. Furthermore, MATW helps to develop
                    and implement long-term solutions that can help to prevent future humanitarian crises from
                    occurring in these communities.
                  </p>
                  <p>
                    MATW is committed to providing a meaningful, sustainable, and lasting solution that will help to
                    ensure the well-being of these communities.
                  </p>
                  <div class="btn-sec">
                    <a href="#download" class="inline-block align-middle text-center select-none border font-extrabold  whitespace-no-wrap rounded py-4 px-3 text-lg leading-normal no-underline btn-350 btn-main">Get The App</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center bg-white banner-content featured-program pt-14 pb-16 sm:pt-6 sm:pb-8 xs:pt-2 xs:pb-2" id="focus">
          <div class="container mx-auto sm:px-4">
            <div class="flex flex-wrap ">
              <div class="w-full">
                <div class="section-heading lg:text-left md:text-left xs:text-center">
                  <h1>Why choose MATW?</h1>
                  <p>
                    Our focus is on the basic rights of humans including housing, water, healthcare, education, and
                    guidance.
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap lg:text-left md:text-left sm:text-left  xs:text-center ">
              <div class="md:w-1/4 pr-4 pl-4">
                <div class="feature-box">
                  <img src="/images/app/shelter.png" alt="" />
                  <h4>Shelter</h4>
                  <p>
                    We do sustainable development of housing and shelter in the needful areas. MATW is nothing but
                    requesting your help for the lacking societies.
                  </p>
                </div>
              </div>
              <div class="md:w-1/4 pr-4 pl-4">
                <div class="feature-box">
                  <img src="/images/app/water.png" alt="" />
                  <h4>Water</h4>
                  <p>
                    Safe water, nutrition, and sanitation program always remain in our core priorities because MATW
                    understands the vulnerability of healthcare due to unsafe water.
                  </p>
                </div>
              </div>
              <div class="md:w-1/4 pr-4 pl-4">
                <div class="feature-box">
                  <img src="/images/app/education.png" alt="" />
                  <h4>Education</h4>
                  <p>
                    We have started building educational institutions for uplifting the moral and cultural values of
                    children. Moreover, every citizen of affected areas should be compatible with academic
                    education.
                  </p>
                </div>
              </div>
              <div class="md:w-1/4 pr-4 pl-4">
                <div class="feature-box">
                  <img src="/images/app/guidance.png" alt="" />
                  <h4>Guidance</h4>
                  <p>
                    We are working for constructing community guidance among the people. Our team pays a lot of
                    attention to religious affairs. Plans and guidance are needed in such areas where war and
                    disasters have already imprisoned the atmosphere.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:flex md:flex justify-center bg-white banner-content get-spread relative lg:pt-16 md:pt-16 xs:pt-4" id="download">
          <div class="w-full container mx-auto sm:px-4">
            <div class="grid md:grid-cols-3 xs:grid-cols-1">
            <div class="..."></div>
              <div class="col-span-2">
                <div class="section-heading">
                  <h1>Download the MATW Center App</h1>
                  <p>
                    Stay up to date about prayer timings, tafseer, and optional non-prayer activities.
                    Become a part of the community.
                  </p>
                </div>
                <div class="app-download">
                  <a href="https://play.google.com/store/apps/details?id=com.bga.matw">
                    <img src="/images/app/google-play.png" alt="" />
                  </a>
                  <a href="https://apps.apple.com/us/app/matw/id6446399296" >
                    <img src="/images/app/app-store.png" alt=""  className="ml-3"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="img-sec">
            <img src="/images/app/new2.png" alt="" />
          </div>
        </div>
        </div>
    </Layout>
  );
}

export default AppPage;
