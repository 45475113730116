import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import ShowCurrencies from "../../utils/showCurrencies";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { selectProducts } from "../../store/products";
import SelectCurrency from "../../components/general/selectCurrency";
//import Arrow from '../../images/general/arrow.png';
import Arrow from '../../images/landings/usa-general-donation/Greater-Impact.png';
const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const USAGeneralWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { defaultProduct, hasSuggestPrice, className } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => selectProducts(state));
    // const [productsList, setProductsList] = useState([]);
    // const [allProducts, setAllProducts] = useState([]);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedRecurringMode, setSelectedRecurringMode] = useState('one-off');
    const [selectedPrice, setSelectedPrice] = useState(100);
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
    const [addProduct] = useHandleAddProduct();
    const suggestedPrices = [
        30, 50, 100
    ]
    const suggestedRecurringModes = [
        { lable: 'Single', value: 'one-off' },
        { lable: 'Weekly', value: 'week' },
        { lable: 'Monthly', value: 'month' }
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item.name === 'Make A Donation') {
                        if (inner_item_product.creator === 'MKD-MN-001') { // Where Most Needed
                            setSelectedProduct(inner_item_product);
                        }
                    }
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
    };
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurringMode,
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD')
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`max-w-[380px]- bg-[#E2EFF3] rounded-lg sm:p-3 md:p-5 flex items-center ${className}`}>
            <div className="w-full grid grid-cols-12 gap-4">
                <div className="col-span-12 grid grid-cols-6 gap-2 h-12 relative">
                    {/* <div className="absolute -rotate-12 md:right-[6%] lg:right-[8%] xl:right-[8%] 2xl:right-[10%] -top-8">
                        <span className="font-gotcha text-[18px] text-[#253B7E] rotate-180">Greater Impact</span>
                    </div> */}
                    <div className="absolute right-[0%] sm:right-[10%] md:right-[11%] lg:right-[7%] xl:right-[15%] -top-8">
                        <span><img src={Arrow} alt="arrow" /></span>
                    </div>
                    {suggestedRecurringModes.map((mode, index) => {
                        return <div key={`modes${index}`} className="col-span-2" style={styles.shadow}>
                            <div
                                className={`h-12 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedRecurringMode === mode.value ? 'bg-[#14A2DC] text-white' : 'bg-white text-[#14A2DC] hover:bg-[#14A2DC] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedRecurringMode(mode.value);
                                    setProductAmount('');
                                }}
                            >
                                {mode.lable}
                            </div>
                        </div>
                    })}
                </div>
                {hasSuggestPrice && <div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                    {suggestedPrices.map((price, index) => {
                        return <div key={`prices${index}`} className="col-span-2" style={styles.shadow}>
                            <div
                                className={`h-12 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedPrice === price ? 'bg-[#14A2DC] text-white' : 'bg-white text-[#14A2DC] hover:bg-[#14A2DC] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedPrice(price);
                                    setProductAmount('');
                                }}
                            >
                                {ShowCurrencies(currentCurrency, price, false)}<span className={`uppercase ml-1 text-base`}>{currentCurrency}</span>
                            </div>
                        </div>
                    })}
                </div>}
                <div className="col-span-12 grid grid-cols-3 gap-2">
                    <div className="flex justify-center items-center bg-white text-[#14A2DC] rounded-md col-span-3 md:col-span-1 h-12" style={styles.shadow}>
                        <SelectCurrency onChangeCurrency={(e) => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                    </div>
                    <div className="flex justify-center items-center bg-white text-[#14A2DC] rounded-md col-span-3 md:col-span-2 h-12 px-2" style={styles.shadow}>
                        <input
                            value={productAmount}
                            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent h-12"
                            type="number"
                            onChange={(e) => {
                                setProductAmount(e.target.value);
                                setSelectedPrice(0);
                            }}
                            placeholder="Enter Amount" />
                    </div>
                </div>
                <div className="col-span-12">
                    <button
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#14A2DC]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={(e) => { handleAddNewProduct() }}
                    >Donate Now</button>
                </div>
                <div className="col-span-12 text-[#6C6B6A] text-center">
                    We accept all major cards and PayPal
                </div>
            </div>
        </div>
    );
}

export default USAGeneralWidget;
