import { createSlice } from '@reduxjs/toolkit';

const visibilitySlice = createSlice({
  name: 'visibility',
  initialState: {
    isPopupVisible: true
  },
  reducers: {
    setPopupVisibility(state, action) {
      state.isPopupVisible = action.payload;
    }
  }
});

export const { setPopupVisibility } = visibilitySlice.actions;

export const selectPopupVisibility = (state) => state.visibility.isPopupVisible;

export default visibilitySlice.reducer;