import { useRef } from 'react';

const useScrollTo = (offset = 200) => {
  const scrollToRef = useRef(null);

  const scrollTo = () => {
    if (scrollToRef.current) {
      const rect = scrollToRef.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const top = rect.top + scrollTop - offset; 
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    } else {
      console.warn('The scroll target does not exist.');
    }
  };

  return [scrollTo, scrollToRef];
};

export default useScrollTo;
