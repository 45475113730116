import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout';
import Founder from '../../images/general/founder.jpg';
import AboutUS from '../../images/general/about-us.jpg';
import { useNavigate } from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { getProducts } from '../../store/products';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';

const HundredDonationsPolicy = () => {
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [product, setProduct] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => getProducts(state, 'Donate Now', 'Make A Donation'));

    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'MKD-MN-001') {
                setProduct(item);
            }
        });

    };

    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])


    const handleAddNewProduct = () => {
        addProduct({product: product, currency: selectedCurrencyStore})
    }
    return (
        <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['hundredDonationsPolicy']['title']}`}</title>
                    <meta name="title" content={`${seo['hundredDonationsPolicy']['title']}`} />
                    <meta name="description" content={`${seo['hundredDonationsPolicy']['description']}`} />
                </Helmet>
            }
            <section className="h-[350px] text-white text-[30px] font-bold sm:h-[315px] flex flex-col items-center bg-center bg-cover  bg-no-repeat bg-[url('../src/images/general/our-promise-banner-mobile.jpg')] md:bg-[url('../src/images/general/our-promise-banner-head.jpg')]" >
                <div className='leading-[36px] md:hidden'>We have a <span className='font-gotcha text-[50px] ml-2'>100%</span>
                </div>
                <div className='leading-[36px] md:hidden'>donation policy</div>
                <div className='text-[18px] md:hidden'>Which means we give <span className='font-gotcha text-[26px] mx-2'>Everything</span>you donate</div>
            </section>
            <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7]`} >
                <div className="md:container md:mx-auto  ">
                    <div className="grid grid-cols-12 px-3 md:px-4 lg:px-6 py-8 sm:py-20">

                        <div className=" col-span-12 rounded-2xl bg-white p-6 py-10 sm:py-20">
                            <h1 className='font-gotcha text-[30px] sm:text-[50px] leading-[70px] text-center text-[#253B7E]'>
                                Our Promise To You
                            </h1>
                            <h1 className=' text-[30px] sm:text-[50px] leading-[60px] font-bold text-center text-[#253B7E]'>
                                MATW's 100% Donation Policy
                            </h1>
                            <p className='sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-brandingMedium'>
                                In the world of global philanthropy, where each contribution—be it in pounds, dollars, or dinars—is invaluable, the transparency of a charity's donation policy is paramount.
                                Amongst the plethora of charitable organisations, a distinctive model particularly stands out within Islamic charities—the 100% donation policy. This model, epitomised by Muslims Around The World (MATW),
                                assures that every single donation made by benefactors is entirely allocated to the projects they are intended to support, without any deductions for administrative or operational costs.
                                This steadfast commitment to transparency and accountability is not only praiseworthy but also serves as a beacon of trust in the charitable sector.
                            </p>

                            <div className='text-[#253B7E] text-[24px] sm:text-[40px] mt-10 font-bold'>
                                Upholding the 100% Model
                            </div>
                            <p className='mt-5 sm:mt-10 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                The operational backbone of MATW's 100% donation policy is underpinned by what might be termed 'angel investors'—a committed group of generous donors who specifically cover overhead costs such as staff wages,
                                office rent, and field trips. This innovative model ensures that the sanctity of the public's donations is preserved exclusively for charitable projects. Year upon year, MATW's adherence to this model not only
                                enhances greater transparency but also attracts increasing support from donors and volunteers worldwide, further solidifying the trust and efficacy of the Islamic charity sector.
                            </p>

                            <div className='text-[#253B7E] text-[24px] sm:text-[40px] mt-10 font-bold'>
                                A Paradigm of Islamic Charity
                            </div>
                            <p className='mt-5 sm:mt-10 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                The 100% donation policy resonates profoundly with the tenets of Islamic charity, particularly Zakat (obligatory almsgiving) and Sadaqah (voluntary charity). It ensures that the act of giving is fully honoured,
                                with every donation making a direct impact. This model aligns seamlessly with the Islamic ethos of maximising benefit to the needy whilst maintaining the utmost integrity in managing the donations entrusted by the community.
                            </p>

                            <div className='text-[#253B7E] text-[24px] sm:text-[40px] mt-10 font-bold'>
                                Broad-Spectrum Impact
                            </div>
                            <p className='mt-5 sm:mt-10 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                            The reach and impact of the 100% donation policy are extensive, spanning various initiatives from sponsoring orphans to providing urgent relief in crisis-stricken regions.
                               The guarantee that every penny donated under
                                this policy goes directly to the cause—be it through Zakat, Sadaqah, or specific campaigns like 100% Qurbani donation—motivates more individuals to contribute, knowing their entire donation will be utilised for the intended purpose without any deductions.
                            </p>

                            <div className='text-[#253B7E] text-[24px] sm:text-[40px] mt-10 font-bold'>
                                A Ripple Effect in the Community
                            </div>
                            <p className='mt-5 sm:mt-10 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                The adoption of the 100% donation policy by MATW and akin Islamic charities has established a benchmark in the philanthropic world, especially within Muslim communities. It not only bolsters the credibility of the charitable sector but also inspires a culture of generosity and compassion.
                                Donors are more inclined to support causes when they are confident that their contributions will have the maximum impact, thereby fostering a virtuous cycle of giving and assistance.
                            </p>

                            <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] mt-10 font-bold'>
                                MATW: The Islamic Charity with a 100% Donation Policy
                            </h2>
                            <p className='mt-5 sm:mt-10 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                As MATW continues to grow and extend its reach, the 100% donation policy remains a foundational pillar of its operations. It exemplifies the power of a community united by faith and compassion, striving to make a tangible difference in the lives of those in need.
                                This model not only ensures that the spirit of giving is preserved but also amplifies the impact of each donation, bringing hope and relief to countless lives across the globe
                            </p>
                            <p className='mt-5 sm:mt-10 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                The 100% donation policy, as championed by MATW, represents the gold standard in charitable giving within the Islamic community and beyond. It exemplifies how faith-based principles can be seamlessly integrated with contemporary philanthropic practices to create a transparent,
                                efficient, and impactful charity model. As this approach continues to inspire and garner support from all corners of the world, it holds the promise of a brighter, more compassionate future for all.
                            </p>
                            <div className='flex justify-center mt-10'>
                                <button onClick={() => handleAddNewProduct()} className='bg-[#F60362] text-white w-[300px] rounded-full text-[24px] font-bold h-[60px]'>
                                    Donate Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white my-20' >
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2'>Our Founder</div>
                        <div className='text-[#777777] text-[16px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            After decades of indulging in all that the dunya had to offer, and becoming a
                            successful businessman and entrepreneur - everything changed for Ali Banat after a
                            deadly diagnosis. MATW started as an act of kindness and is now a global movement.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate('/ali-banat')} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={Founder} className='' alt="policy" />
                    </div>
                </div>
                <div className="md:container mt-10 md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap  items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2' >About Us</div>
                        <div className='text-[#777777] text-[16 px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            Muslims Around The World was born of an act of kindness. Founded by Ali Banat
                            after receiving a deadly diagnosis, this organisation has grown from serving communities in one
                            country, to serving more than 96 million people in more than 20 countries around the world.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate('/purpose')} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={AboutUS} className='' alt="policy" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default HundredDonationsPolicy