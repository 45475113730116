export const sadaqahJariyaText = `Give a Sadaqah Jariyah, the gift that keeps giving, on Laylatul Qadr to multiply your reward. Like Ali Banat, who left a legacy of Sadaqah Jariyah through MATW, you too can do the same, contributing to a mosque or water well in your name or for a loved one. This enduring act ensures positive change in the lives of those in need, planting the seeds of everlasting rewards.`;

export const tabButtonsSadaqah = [
  {
    title: 'MASJIDS',
  },
  {
    title: 'WATER WELLS',
  },
  {
    title: 'ORPHANS',
  },
  {
    title: 'GIFT OF SOUND',
  },
  {
    title: 'PROJECT NOOR',
  },
];

// FAQ's
export const faq = [
  {
    id: 0,
    question: 'What is the significance of Laylatul Qadr?',
    answer: `Laylatul Qadr, known as the Night of Decree, is believed to be the holiest night in Ramadan. It's when the Quran was first revealed to Prophet Muhammad (PBUH). Acts of worship during this night are said to be more rewarding than a thousand months of worship.`,
  },
  {
    id: 1,
    question: 'When is Laylatul Qadr?',
    answer:
      'Laylatul Qadr falls within the last ten nights of Ramadan, usually on the 27th night. Scholars encourage us to seek it throughout the last ten nights as its exact date is unknown and can fall on any odd night.',
  },
  {
    id: 2,
    question: 'How can I donate during Laylatul Qadr?',
    answer: `Donating during Laylatul Qadr is simple with MATW Project - in fact, donating throughout the entire month is easy! Just choose your preferred project, select the date you’d like to start donating from, set up your payment plan, and automate your donations. This will Insha’Allah ensure you don't miss out on giving during these sacred nights!`,
  },
  {
    id: 3,
    question: 'Laylatul Qadr & why is it significant?',
    answer: `Laylatul Qadr, known as the Night of Decree, is believed to be the holiest night in Ramadan. It's when the Quran was first revealed to Prophet Muhammad (PBUH). Acts of worship during this night are said to be more rewarding than a thousand months of worship.`,
  },
  {
    id: 4,
    question: 'When is Laylatul Qadr?',
    answer:
      'Laylatul Qadr falls within the last ten nights of Ramadan, usually on the 27th night. Scholars encourage us to seek it throughout the last ten nights as its exact date is unknown and can fall on any odd night. ',
  },
  {
    id: 4,
    question: 'How can I donate during Laylatul Qadr?',
    answer: `Donating during Laylatul Qadr is simple with MATW Project - in fact, donating throughout the entire month is easy! Just choose your preferred project, select the date you’d like to start donating from, set up your payment plan, and automate your donations. This will Insha’Allah ensure you don't miss out on giving during these sacred nights!`,
  },
];