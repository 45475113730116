import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';

const Carousel = ({ withArrow, data, renderItem, keyExtractor, autoSwitch, _timeout = 5000 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      loop: true,
      created() {
        setLoaded(true);
      },
    },
    autoSwitch
      ? [
          slider => {
            let timeout;
            let mouseOver = false;
            function clearNextTimeout() {
              clearTimeout(timeout);
            }
            function nextTimeout() {
              clearTimeout(timeout);
              if (mouseOver) return;
              timeout = setTimeout(() => {
                slider.next();
              }, _timeout);
            }
            slider.on('created', () => {
              slider.container.addEventListener('mouseover', () => {
                mouseOver = true;
                clearNextTimeout();
              });
              slider.container.addEventListener('mouseout', () => {
                mouseOver = false;
                nextTimeout();
              });
              nextTimeout();
            });
            slider.on('dragStarted', clearNextTimeout);
            slider.on('animationEnded', nextTimeout);
            slider.on('updated', nextTimeout);
          },
        ]
      : undefined,
  );

  return (
    <>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {data.map((item, index) => (
            <div key={keyExtractor(item)} className="keen-slider__slide">
              {renderItem({
                item,
                index,
                currentSlide,
              })}
            </div>
          ))}
        </div>
        {loaded && instanceRef.current && withArrow && (
          <>
            <Arrow left onClick={e => e.stopPropagation() || instanceRef.current?.prev()} disabled={currentSlide === 0} />

            <Arrow
              onClick={e => e.stopPropagation() || instanceRef.current?.next()}
              disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
            />
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <div className="hidden md:flex md:pt-3 pt-2 justify-center items-center ">
          {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
            return (
              <button
                key={`q${idx}`}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={
                  'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                  (currentSlide === idx ? ' bg-[#00a3da] hover:opacity-60' : 'bg-[#777] hover:bg-[#253b7e')
                }
              ></button>
            );
          })}
        </div>
      )}
    </>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
      {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
    </svg>
  );
}
export default Carousel;
