import { useEffect } from 'react';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
// import MainPageForm from '../components/general/mainPageForm';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
const PrivacyPolicyPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const items = [
        {
            title: 'SAFEGUARDING YOUR PRIVACY IS IMPORTANT TO US', subtitle: 'Introduction', description: `
        MATW International exercises its best efforts to respect the privacy of every individual who visits our website. As such, we have developed a stringent privacy policy that reflects that respect. Your privacy is important to us, we provide you with notice and choices about the collection and use of your information. The information contained in this notice explains our online information practices.
        On our website, we do not collect personally identifiable information from you unless you provide it to us voluntarily and knowingly. This means we do not require you to register or provide information to us in order to view our site. MATW International only gathers personally identifiable data—such as names, postal and email addresses, contact information, credit card information or other information—when you voluntarily submit it. This information is used solely by MATW International for internal purposes. MATW International data lists are never sold to third parties. We will only share personally identifiable information with third parties when:
        The person submitting the information authorises us to share it;
        Sharing the information is a necessary operation of the site, for instance, to process a credit card transaction/donation; or
        We are required to by law.
        This notice applies to all information collected from or submitted to the MATW International  website located at matwproject.org and any related sub-domains.
        By visiting MATW International’s website, you accept the practices described in this privacy policy.`
        },
        {
            title: 'YOUR INFORMATION', subtitle: 'The Information We Collect', description: `
            MATW International does not automatically collect your personally identifiable information when you visit our website. We do recognise the internet service provider of visitors, as well as the domain name or IP address of the server from where you are visiting. This information is used to analyse and predict site traffic. The data we collect are totals of those visiting from a domain, not information on individual users.
            We cannot recognise the personally identifiable data—such as names, postal addresses, e-mail addresses, contact information, credit card information or other information—about our visitors that would allow us to identify the particular visitors to our website.
            You do not have to provide any personal information on our website unless you choose to do so. MATW International only gathers personal information—such as your name, postal and e-mail address, contact information, credit card information or other information— when you voluntarily submit it in an online form. If you make a donation online, personal information will be collected from you in order to process that donation.`
        },
        {
            title: '', subtitle: 'How We Use Information', description: `
            MATW International is the sole owner of any information collected on this site. MATW International does not sell personal information about its website users.
            MATW International states unambiguously that (a) it will not sell, trade and share a donor’s personal information with anyone else, nor send donor mailings on behalf of other organisations; or (b) it will only share personal information once the donor has given it permission to do so.
            The personal information you provide us when using our website—such as your name, postal and e-mail address, contact information, credit card information or other information—will be kept strictly confidential and used to support your customer relationship with MATW International, as well as to notify you of updated information.
            If you allow us to, we will use personal information that you submit in an online form to place you on our mailing list for regular updates. If you do not wish to receive such mailings from MATW International, you will have the opportunity at any time to request removal from future mailings by contacting us or using the unsubscribe function in any of our marketing emails.
            MATW International strongly adheres to anti-spam practices in its e-mail outreach. All e-mails sent on our list have an easy unsubscribe option at the bottom of each e-mail, and lists are never shared with any third parties.`
        },
        {
            title: '', subtitle: 'Personal Information and Credit Card Security', description: `MATW International respects and supports the safety and security of your transaction, and we take every precaution to protect your information.
            Donations made on our website are secure to the fullest extent possible. We use high-grade, 128-bit encryption and the “https” security protocol through Secure Socket Layer (SSL) technology to communicate with your browser software. SSL protects information as it is transferred through the Internet. This method is the industry standard security protocol, which makes it extremely difficult for anyone else to intercept the credit card information you send to us. Companies we work with to process credit card transactions also use high grade encryption and security protocols.
            You can recognise secure website pages by checking for the secure symbol at the bottom of your browser screen. For most browsers, this symbol is either a lock or a key. When the lock symbol appears to be locked, the page is secure. When it appears to be unlocked, the page is insecure.
            Additionally, all of the transactional data we collect from users is protected against unauthorised access with the use of digital certificates.
            Furthermore, this site has security measures in place to protect the loss, misuse, and alteration of the information under our control.`
        },
        {
            title: '', subtitle: 'Note to Parents and Minors', description: `MATW International is concerned about the protection of online privacy for all our website users. We are particularly respectful of the privacy of our young users. We encourage parents to monitor their children’s use of the Internet and to help us protect their privacy by instructing them never to provide personal information on this website or any other without permission. We will never attempt to extract unnecessary information from you, regardless of age.
            MATW International welcomes children to learn more about our organisation and participate in our volunteering opportunities. However, we encourage this to be done only with their parents’ knowledge and supervision. MATW International does not knowingly solicit data from children, nor do we knowingly market to children.`
        },
        {
            title: '', subtitle: 'How You Can Access, Correct or Update Your Information', description: `If you would like to request access to review, update or amend the information you have supplied in any way, feel free to contact MATW International at any time. You can correct factual errors in your personally identifiable information by sending us a request that shows the error. We use this procedure to better safeguard your information. Please note, to protect your privacy and security, we will also take steps to verify your identity before providing information or making corrections.`
        },
        {
            title: '', subtitle: 'Opt-out', description: `MATW International gives you the opportunity to opt-out of receiving additional information from us at any time. If you do not wish to receive e-mails or advertising from MATW International, you can request removal of your details by sending an email to info@matwcheckout.org with “unsubscribe from mailing list” or “unsubscribe from email list” in the subject line, or by unsubscribing directly from an e-mail you receive from us (you can find the “unsubscribe from this list” option in the footer of the email). You can also request to be removed from our mailing lists by calling +61 (02) 9758 9037.`
        },
        {
            title: 'FURTHER INFORMATION', subtitle: 'Managing Links', description: `This privacy policy applies solely to information collected through the MATW International  website, located at matwcheckout.org .
            This website has links to other websites whose privacy policies may differ from ours. These links are provided for users’ convenience. We will not pass your information along to the linked site.
            We make every effort to only link to sites that share our high standards of transparency, decency, fairness and integrity. We try our best to ensure that these links are safe for children and appropriate for our users in general. However, since we cannot guarantee the content of every site to which we are linked, we are not responsible for the privacy practices or the content of these other websites.
            We do not control or guarantee the accuracy, relevance, suitability or entirety of any linked information. The inclusion of links to other websites is not intended to endorse, recommend, or support any views expressed, or products or services offered on these external sites. The fact that MATW International has provided a link to a site is not an endorsement, validation, sponsorship or affiliation with respect to the site in question or any other site, its owners or its providers.
            We encourage you to be aware when you leave our website to read the privacy statements of every website that collects personally identifiable information. These other sites may collect or solicit personal data or send their own cookies to your computer. Please be aware that MATW International is not responsible for the privacy practices of third-party websites.
            Additionally, MATW International is not responsible for any website that links to the MATW International website, nor does it endorse, recommend or support any views expressed on such sites.`
        },
        {
            title: '', subtitle: 'Privacy Policy Changes', description: `If our information practices change at some time in the future, we will post the policy changes to our website to notify you of these changes and provide you with the ability to opt out of these new uses. If you are concerned about how your information is used, you should regularly review this privacy policy section of our website. Any changes to this policy will be posted prior to their implementation.`
        },
        {
            title: '', subtitle: 'Contact Information', description: `If you have any questions regarding our privacy policy, please contact us:
            Level 2 47 Rickard Road, Bankstown, NSW 2200 Australia
            Tel: +61 (02) 9758 9037
            E-mail: info@matwproject.org`
        },
    ];
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        window.scroll(0,0);
    }, [])
    /* -------------------------------------------------------------------------- */

    return (
        <Layout>
             {seo &&
                <Helmet>
                    <title>{`${seo['resourcesAndInformation']['privacyPolicy']['title']}`}</title>
                    <meta name="title" content={`${seo['resourcesAndInformation']['privacyPolicy']['title']}`} />
                    <meta name="description" content={`${seo['resourcesAndInformation']['privacyPolicy']['description']}`} />
                </Helmet>
            }
            <WidgetHome selectedCurrency={(e) => {}} />
            <div className="md:container md:mx-auto px-1 sm:px-3 flex items-center">
                <div className="grid grid-cols-12 px-3 md:px-4">
                    <div className="col-span-12 rounded-md bg-white p-6 mt-4">
                        <h1 className="col-span-12 text-center text-6xl text-[#093484] py-2 md:py-8 font-['Gotcha']">
                            Privacy Policy
                        </h1>
                        {items.map((item, index) => {
                            return <div key={`pri${index}`} className="grid grid-cols-12">
                                <div className="col-span-12 border-b border-stone-200 mb-6 pb-4">
                                    <h2 className="text-[#00a3da] text-2xl mb-3">
                                        {item.title}
                                    </h2>
                                    <h3 className="text-[#777] font-bold text-xl mb-2">
                                        {item.subtitle}
                                    </h3>
                                    <p className="text-[#777] text-md">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default PrivacyPolicyPage;
