export const billionFaqs = [
    {
        id: 0,
        question: 'How do we manage food procurement?',
        answer: `To manage food procurement at a low cost for Our $1 dollar meals project, we implement several strategies, including supporting local farmers and producers, and implementing thorough vetting processes to ensure reliability and cost-effectiveness.
        <br/>
        <br/>
        By partnering with local farmers and food producers we not only reduce transportation costs but also support the local economy. Sourcing ingredients locally, helps us to build strong relationships with suppliers, negotiate competitive prices, and establish long-term contracts to ensure a steady supply of fresh and nutritional food sources at stabilised prices. Buying in bulk and selecting seasonal produce also ensures better cost-effectiveness.
        <br/>
        <br/>
        In selecting our supplies, we conduct thorough market research and price comparison among different suppliers to identify the most cost-effective options without compromising on the quality of food. This involves seeking quotes from multiple vendors and assessing the best value for money as well as checking the supplier’s reputation, track record and adherence to food safety and quality standards.
        <br/>
        <br/>
        We maintain a transparent supply chain management system to track the sourcing and distribution of food materials. This helps ensure accountability and allows MATW to identify any inefficiencies or areas for improvement.            `,
    },
    {
        id: 1,
        question: 'How do we manage our own kitchens?',
        answer: `To ensure cost-effectiveness, minimise waste and create an impactful campaign, our kitchens are carefully managed and designed to be efficient. For example, we ensure efficient inventory management and appropriate storage facilities to avoid overstocking, wastage or spoiling. Our standardised recipes that use locally sourced and seasonal ingredients help ensure consistency, streamlining procurement and minimising food waste, whilst providing vital and nutritious meals to our beneficiaries in need.
        <br/>
        <br/>
        Our kitchens adhere to the food safety standards and hygiene practices to prevent foodborne illnesses and maintain a good reputation for quality. All our staff are trained in food hygiene, ensuring that our meals are prepared in a clean and sanitised environment. Additionally, our storage facilities are regularly checked to help preserve the freshness of ingredients and food hygiene practices.
        <br/>
        <br/>
        Wherever possible we employ staff for the kitchen from the local community. This helps empower the local community and contributes to the local economy as well as helping to ensure that our meals are culturally and religiously appropriate.
        <br/>
        <br/>
        At MATW, we believe in the power of continuous improvement. Our kitchens are subject to regular monitoring and evaluation, allowing us to identify areas for refinement and organisation. This approach ensures that we consistently enhance our cost-effectiveness and the far-reaching impact of our $1 meal campaign.
        `,
    },
    {
        id: 2,
        question: 'How do we recruit our own chefs and staff?',
        answer: `For our $1 meal campaign, we employ a thoughtful and strategic approach to ensure that the team is skilled, committed and aligned with our mission and values, whilst also supporting the local community, in particular women and the youth.
        <br/>
        <br/>
        Through partnering with local NGOs, community-based organisations, community or government officials, and other local stakeholders, we can reach potential staff from the community. In doing so, we strive to create livelihood opportunities for those who might face limited prospects, such as women or those with limited education. This inclusive approach fosters empowerment and ownership among local communities whose engagement is vital to the long-term sustainability of our campaign.
        <br/>
        <br/>
        As part of the recruitment process, we provide relevant training and skill development to bridge any skill gaps. This ensures that all team members are well-equipped to prepare efficient, high-quality, and impactful meals for our beneficiaries.
        <br/>
        <br/>
        By combining our recruitment strategy with strong partnerships and targeted training, we can create a dedicated team that is not only skilled but also deeply connected to our project’s mission.
        `,
    },
    {
        id: 3,
        question: 'We manage the distributions to those in need:',
        answer: `To ensure our project meets its intended goals of supporting those most vulnerable to hunger and malnutrition, MATW adopts comprehensive and data-driven methods of beneficiary identification and selection. Using a combination of desk-based research, on-the-ground needs assessments, and through collaboration with local partners including NGOs, community leaders and government agencies, we identify areas with high levels of poverty and food insecurity. This ensures that our project reaches those most vulnerable and in urgent need.
        <br/>
        <br/>
        In terms of distribution, we have developed a well-organised distribution network that minimises transportation costs and maximises coverage through strategically located central distribution points. This is achieved through the support of local community centres and partner organisations which can serve as distribution hubs. We deliver our meals in bulk to minimise transportation costs and packaging materials and engage local volunteers from the community to assist with distribution.
        <br/>
        <br/>
        MATW also implements real-time monitoring systems to track the progress of distributions and gather feedback from beneficiaries. This enables quick adjustments if any issues arise and ensures the project remains responsive to the needs of the community. Regular evaluation of the distribution process also helps to identify potential bottlenecks and areas of improvement to help refine the distribution strategy over time.
        `,
    },
    {
        id: 4,
        question: 'How do we showcase the impact of your contributions?',
        answer: `To showcase the impact of your contribution to this project, we intend to employ a diverse range of communication methods and channels to demonstrate the positive outcomes and tangible results of the $1 meals campaign.
        <br/>
        <br/>
        Comprehensive impact reports will highlight key achievements, statistics and success stories including individual stories of beneficiaries whose lives have been positively impacted by the project.  These impact reports will offer in-depth narratives of the project whilst our social media and online website will provide more accessible updates and information in real-time. Through photos, videos, and interactive content, we are committed to ensuring that you, our donors, are well-informed and connected to the cause you support. Impact highlights will also be included in our annual reports and newsletters to serve as testament to the transformative power of your contributions.
        <br/>
        <br/>
        At MATW we are committed to keeping our donors informed and engaged with our projects, which would not be possible without your ongoing support and commitment. We extend our heartfelt gratitude to each and every one of you for playing a role in making a positive difference in the lives of those in need. Together, we forge a path of compassion and empowerment, creating a brighter future for vulnerable populations across the globe.
        `,
    },
];