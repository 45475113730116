import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// import ReCAPTCHA from 'react-google-recaptcha';
import useMutation from '../../hooks/useMutaion';
import services from '../../services';
export default function FormSection() {
  const { error, isLoading, mutate, isSuccess } = useMutation(services.postFormContact);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const onSubmit = async data => {

    const ip = await services.getIp();
    const agent = navigator.userAgent;
    data.agent = agent;
    data.ip = ip.data;
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Thank you for contacting us. We will get back to you soon.');
      reset();

    }
    if (error) {
      toast.error('Something went wrong. Please try again later.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, error]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full  text-sm text-textPrimary gap-4 grid grid-cols-1 md:grid-cols-2">
      <div>
        <input
          {...register('name', { required: true })}
          placeholder="Name"
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
        />
        {errors.name && <span className="text-sm text-red-600">This field is required</span>}
      </div>
      <div>
        <input
          {...register('email', {
            required: {
              value: true,
              message: 'This field is required',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'invalid email address',
            },
          })}
          placeholder="E-mail"
          type="email"
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
        />
        {errors.email?.message && <span className="text-sm text-red-600">{errors.email.message}</span>}
      </div>
      <div>
        <input
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
          {...register('phone', { required: true })}
          type="number"
          placeholder="Phone"
        />
        {errors.phone && <span className="text-sm text-red-600">This field is required</span>}
      </div>
      <div>
        <select
          defaultValue={''}
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
          {...register('purpose', { required: true })}
        >
          <option value="">--Please choose an option--</option>
          <option value="Complaint">Complaint</option>
          <option value="donationQuestion">Donation Question</option>
          <option value="progressQuery">Progress Query</option>
          <option value="Other">General enquiry</option>
        </select>
        {errors.purpose && <span className="text-sm text-red-600">This field is required</span>}
      </div>
      <div className="md:col-span-2 col-span-1">
        <textarea
          {...register('message', { required: true })}
          placeholder="Message"
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
        />
        {errors.message && <span className="text-sm text-red-600">This field is required</span>}
      </div>
      {/* <div className="md:col-span-2 col-span-1 flex justify-center items-center">
        <ReCAPTCHA
          grecaptcha={{ lang: 'en' }}
          sitekey={process.env?.REACT_APP_RECAPTCHA}
          // onChange={onChange}
        />
      </div> */}

      <div className="md:col-span-2 col-span-1 flex justify-center items-center">
        <button type="submit" disabled={isLoading} className="rounded-xl bg-[#282828] text-white hover:opacity-7 transition-all duration-300 px-16 py-3">
          {isLoading ? 'Loading...' : 'Send Message'}
        </button>
      </div>
    </form>
  );
}
