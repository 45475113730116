import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import services from '../../services';
import img1 from '../../images/qurban/Make a Qurban Donation and feed those in need copy (1) 1.jpg';
import img2 from '../../images/qurban/Why Donate through MATW 1.jpg';
import img3 from '../../images/qurban/How does one donate through MATW 1.jpg';
import sliderImg1 from '../../images/qurban/Beneficiary-image-1440x600.jpg';
import Accordionn from '../../components/general/accordion';
import bgImg from '../../images/qurban/Water for School 1-27 screenshot.jpg';
import QurbanProductBox from '../../components/general/qurbanProductBox';
// import "react-responsive-carousel/lib/styles/carousel.min.css";

export const allFaqs = [
    {
        id: 0,
        question: 'How can I donate for Qurbani?',
        answer: `You can donate for qurbani online through MATW Project which is a secure and faster means to donate.`,
    },
    {
        id: 1,
        question: 'What is the purpose of a Qurbani donation?',
        answer: `The purpose of a qurbani donation is to commemorate the sacrifice of Prophet Ibrahim (AS) and his son Ismail (AS), and to distribute meat to those who are less fortunate and in need of food.`,
    },
    {
        id: 2,
        question: 'Can I donate for Qurbani in honour of someone else?',
        answer: `Yes, you can donate for Qurbani in honour of someone else as a gift or as a way to express gratitude for the blessings received.`,
    },
    {
        id: 3,
        question: "What is the average cost of a Qurbani donation?",
        answer: `The average cost is typically between $100-$200 per share, which can be shared by multiple donors. Qurban costs change every year according to season, supply and location.`,
    },
    {
        id: 4,
        question: 'How do I know that my qurbani donation is being used properly?',
        answer: `MATW Project that has a track record of performing Qurbani sacrifices and distributing meat to those in need. MATW Project also provides updates and reports on the distribution of meat from the Qurbani sacrifice.`,
    },
    {
        id: 5,
        question: 'Can I donate for Qurbani to a specific country or region?',
        answer: `Yes, MATW project allows you to specify which country or region you would like your Qurbani donation to go towards. (MATW operating countries only).`,
    },
    {
        id: 6,
        question: 'Is my Qurbani donation tax deductible?',
        answer: `In some cases, Qurbani donations may be tax deductible. It's best to check with your local tax authority or consult a tax professional for guidance.`,
    },
    {
        id: 7,
        question: 'Can I donate for qurbani outside of Eid al-Adha?',
        answer: `You can make an animal sacrifice with MATW Project outside of Eid al-Adha. MATW Project offers year-round sacrifice donation options including sheep and cow sacrifice.`,
    },
    {
        id: 8,
        question: 'What are the rules for giving Qurbani?',
        answer: `The rules for giving Qurbani are based on Islamic tradition. Muslims who are financially able are required to give Qurbani during the annual Eid al-Adha festival. The animal must be of a certain age and meet specific requirements, including being healthy and free of defects. The meat from the animal is then distributed to those in need, including the poor and vulnerable.`,
    },
    {
        id: 9,
        question: 'Who has to donate Qurbani?',
        answer: `Muslims who are financially able are required to give Qurbani. This means that those who meet the criteria for Zakat, which is an annual charitable contribution based on wealth, must also give Qurbani. Those who do not meet the criteria for Zakat may still give Qurbani as a voluntary act of worship and charity.`,
    },
    {
        id: 10,
        question: 'Is Qurbani per person or family?',
        answer: `According to most Muslims, Qurbani is compulsory on every sane adult Muslim male/female who has wealth in excess to his/her needs. Normally, if you're eligible to pay Zakat, then you're also obliged to give Qurbani. Allah (SWT) tells us in the Holy Qur'an: And complete the Hajj and 'umrah for Allah.`,
    },
    {
        id: 11,
        question: 'Can Qurbani money be donated?',
        answer: `Yes, Qurbani money can be donated to MATW Project that perform Qurbani sacrifices and distribute meat to those in need. This allows individuals who are unable to perform the sacrifice themselves to fulfill their religious obligation and contribute to the welfare of their community.`,
    },
    {
        id: 12,
        question: 'What is a 100% Qurbani donation policy?',
        answer: `100% Qurbani donation policy means that the entire amount of the donation goes towards the Qurbani sacrifice and its distribution to those in need. This policy ensures that no portion of the donation is used for administrative or overhead expenses.`,
    },
    {
        id: 13,
        question: ' Does Qurbani count as Zakat?',
        answer: `Qurbani does not count as Zakat. Zakat is an annual charitable contribution based on wealth, while Qurbani is a separate religious obligation performed during Eid al-Adha. Muslims who meet the criteria for Zakat are required to give both Zakat and Qurbani`,
    },
];

const SadaqahMostPopularPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [youtubeConHeight, setYoutubeConHeight] = useState(300);
    const [mostPopularQurbanProducts, setMostPopularQurbanProducts] = useState([]);
    const [qurban2023Products, setQurban2023Products] = useState([]);
    const youtubeContainerRef = useRef();
    const styles = {
        youtube: {
            height: youtubeConHeight
        }
    }
    const sadaqahCategory = [
        {
            "id": 250,
            "stripe_product_id": "prod_ODOlYjJumGMqVS",
            "paypal_product_id": "PROD-6KT88945E8213914P",
            "creator": "EMR-DIS-PSE-2023-02",
            "name": "Food Pack for Palestine",
            "description": "Provide food supply to affected innocent families in Palestine.",
            "aud": 50,
            "usd": 35,
            "gbp": 28,
            "cad": 32,
            "eur": 46,
            "sgd": 47,
            "myr": 163,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-palestine-food-pack.jpg",
            "recurring": "Allowed",
            "status": "0",
            "created_at": "2023-07-07 05:32:23",
            "updated_at": "2023-07-07 05:38:55",
            "deleted_at": null,
            "category_id": 27
        },
        {
            "id": 98,
            "stripe_product_id": "prod_NYPzCzROQeIAJd",
            "paypal_product_id": "PROD-0CV39642N8662544H",
            "creator": "WWC-CON-ABW-2023-01-028",
            "name": "Africa Basic Water Well",
            "description": "A manually operated well that is hand-pulled and can last for 12-21 years.",
            "aud": 1000,
            "usd": 735,
            "gbp": 600,
            "cad": 977,
            "eur": 672,
            "sgd": 966,
            "myr": 3171,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-water-well-africa-basic-water-well.jpg",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2023-03-19 10:07:48",
            "updated_at": "2023-03-19 10:35:26",
            "deleted_at": null,
            "category_id": 32
        },
        {
            "id": 99,
            "stripe_product_id": "prod_NYPzpT5xgklMBs",
            "paypal_product_id": "PROD-9TT31369VN764293K",
            "creator": "WWC-CON-BBW-2023-01-029",
            "name": "Bangladesh Basic Water Well",
            "description": "Hand-operated water well pump for Bangladesh",
            "aud": 500,
            "usd": 368,
            "gbp": 300,
            "cad": 489,
            "eur": 336,
            "sgd": 483,
            "myr": 1586,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-water-well-bangladesh-basic-water-well.jpg",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2023-03-19 10:07:48",
            "updated_at": "2023-03-19 10:35:26",
            "deleted_at": null,
            "category_id": 32
        },
        {
            "id": 206,
            "stripe_product_id": "prod_NviqqOI1gSfFcy",
            "paypal_product_id": "PROD-4J6981793M9396135",
            "creator": "QB-2023-S7",
            "name": "Africa Sheep Qurban",
            "description": "MATW is working in many countries in Africa delivering fresh sheep as a Sadaqah within the most poverty stricken communities. Give now",
            "aud": 100,
            "usd": 69,
            "gbp": 55,
            "cad": 90,
            "eur": 65,
            "sgd": 90,
            "myr": 300,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/qurban-sheep-africa-sheep-qurban.jpg",
            "recurring": "Allowed",
            "status": "NEW",
            "created_at": "2023-05-20 23:46:16",
            "updated_at": "2023-05-21 01:34:06",
            "deleted_at": null,
            "category_id": 42
        },
        {
            "id": 201,
            "stripe_product_id": "prod_NviqFoe3t7jczN",
            "paypal_product_id": "PROD-07Y18317XR1286826",
            "creator": "QB-2023-S2",
            "name": "Yemen Sheep Or Goat Qurban",
            "description": "In Yemen, where more than 17 million people are food insecure, YOUR Qurban – either a sheep or goat - will be delivered fresh and will make a huge difference. Give now.",
            "aud": 110,
            "usd": 75,
            "gbp": 60,
            "cad": 100,
            "eur": 70,
            "sgd": 100,
            "myr": 350,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/qurban-sheep-yemen-sheep-or-goat-qurban.jpg",
            "recurring": "Allowed",
            "status": "NEW",
            "created_at": "2023-05-20 23:46:12",
            "updated_at": "2023-05-21 01:34:05",
            "deleted_at": null,
            "category_id": 42
        },
        {
            "id": 1,
            "stripe_product_id": "prod_NYPz1DjEglQ0E1",
            "paypal_product_id": "PROD-5W3176846P1691438",
            "creator": "FST-DEL-F25-2023-01-008",
            "name": "Orphan Feast For 25",
            "description": "Make a difference in the lives of 25 Orphans by sponsoring (Wallima) a nourishing feast.",
            "aud": 100,
            "usd": 74,
            "gbp": 60,
            "cad": 98,
            "eur": 68,
            "sgd": 97,
            "myr": 318,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/orphans-orphan-feast-orphan-feast-for-25.jpg",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2023-03-19 10:07:48",
            "updated_at": "2023-03-19 10:35:02",
            "deleted_at": null,
            "category_id": 11
        }
    ]
    const mostNeededProducts = [
        // {
        //     "id": 136,
        //     "stripe_product_id": "prod_NfWvzWQyErVrGo",
        //     "paypal_product_id": "PROD-5HX3437929399050V",
        //     "creator": "PL-GD01E",
        //     "name": "Emergency Support For Palestine",
        //     "description": "Emergency Appeal due to incident in Palestine or elsewhere",
        //     "aud": 1,
        //     "usd": 1,
        //     "gbp": 1,
        //     "cad": 1,
        //     "eur": 1,
        //     "sgd": 1,
        //     "myr": 2,
        //     "custom_amount": "Yes",
        //     "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/ramadan-emergency-emergency-support-emergency-support-for-palestine.jpg",
        //     "recurring": "Allowed",
        //     "status": "NEW",
        //     "created_at": "2023-04-07 19:43:15",
        //     "updated_at": "2023-04-07 19:49:12",
        //     "deleted_at": null,
        //     "category_id": 27
        // },
        {
            "id": 122,
            "stripe_product_id": "prod_NZYKQBceTRhsk1",
            "paypal_product_id": "PROD-15U431202D489773T",
            "creator": "MKD-MN-001",
            "name": "Where Most Needed",
            "description": "Donate to where it's needed the most, we'll distribute your donation to the people that most need it",
            "aud": 1,
            "usd": 1,
            "gbp": 1,
            "cad": 1,
            "eur": 1,
            "sgd": 1,
            "myr": 2,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/logo-replace.png",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2022-03-22 10:49:11",
            "updated_at": "2022-02-10 12:26:54",
            "deleted_at": null,
            "category_id": 33
        },
        {
            "id": 69,
            "stripe_product_id": "prod_NYPzf15wHtZ4uG",
            "paypal_product_id": "PROD-8UM4616492540181R",
            "creator": "RM24-ZM-013",
            "name": "Zakat Al Mal",
            "description": "Obligatory charity donation given to the needy according to Shariah",
            "aud": 1,
            "usd": 1,
            "gbp": 1,
            "cad": 1,
            "eur": 1,
            "sgd": 1,
            "myr": 2,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/zakat-give-zakat-give-zakat-al-mal.jpg",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2023-03-19 10:07:48",
            "updated_at": "2023-03-19 10:35:19",
            "deleted_at": null,
            "category_id": 25
        },
        {
            "id": 104,
            "stripe_product_id": "prod_NYPz4zXwOJ7mNX",
            "paypal_product_id": "PROD-80E28400TP321263B",
            "creator": "MKD-GEN-MOR-2023-01-119",
            "name": "Orphans Around The World",
            "description": "Orphans Around the World is a project aimed at providing support and assistance to children who have lost one or both parents. These children face many challenges including lack of basic necessities, limited access to education and healthcare, and a higher risk of exploitation and abuse. Our mission is to improve the lives of orphans and give them hope for a brighter future by providing essential resources such as food, shelter, education, and medical care. Join us in making a difference in the lives of orphans and help us create a world where every child has a chance to thrive.",
            "aud": 1,
            "usd": 1,
            "gbp": 1,
            "cad": 1,
            "eur": 1,
            "sgd": 1,
            "myr": 2,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/donate-now-make-a-donation-orphans-around-the-world.jpg",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2023-03-19 10:07:48",
            "updated_at": "2023-03-23 12:26:54",
            "deleted_at": null,
            "category_id": 33
        },
        {
            "id": 168,
            "stripe_product_id": "prod_NhQAp7H07naSPD",
            "paypal_product_id": "PROD-4N810208JU375605K",
            "creator": "GD-MP015",
            "name": "General Masjid Donations",
            "description": "Give whatever you can to help MATW build Masjids in rural and povert-stricken communities.",
            "aud": 1,
            "usd": 1,
            "gbp": 1,
            "cad": 1,
            "eur": 1,
            "sgd": 1,
            "myr": 2,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-masjid-general-donations.jpg",
            "recurring": "Allowed",
            "status": "NEW",
            "created_at": "2023-04-12 20:43:08",
            "updated_at": "2023-04-12 20:59:16",
            "deleted_at": null,
            "category_id": 29
        },
        {
            "id": 81,
            "stripe_product_id": "prod_NYPzsrQeWfeGvB",
            "paypal_product_id": "PROD-7YW18999D3829780M",
            "creator": "MSJ-CON-SMJ-2023-01-011",
            "name": "Small Masjid",
            "description": "Help construct a main masjid block for 80-90 people and a small masjid for a community, each with a praying area of 100 sqm.",
            "aud": 10000,
            "usd": 7350,
            "gbp": 5985,
            "cad": 9765,
            "eur": 6720,
            "sgd": 9660,
            "myr": 31710,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-masjid-small-masjid.jpg",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2023-03-19 10:07:48",
            "updated_at": "2023-03-19 10:35:22",
            "deleted_at": null,
            "category_id": 29
        },
        {
            "id": 100,
            "stripe_product_id": "prod_NYPz6zTpw6GQcB",
            "paypal_product_id": "PROD-9TT31369VN764293K",
            "creator": "WWC-CON-PBW-2023-01-030",
            "name": "Pakistan Basic Water Well",
            "description": "Hand-operated water well pump for Pakistan",
            "aud": 500,
            "usd": 368,
            "gbp": 300,
            "cad": 489,
            "eur": 336,
            "sgd": 483,
            "myr": 1586,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-water-well-pakistan-basic-water-well.jpg",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2023-03-19 10:07:48",
            "updated_at": "2023-03-19 10:35:26",
            "deleted_at": null,
            "category_id": 32
        },
        {
            "id": 131,
            "stripe_product_id": "prod_NfWvhTU6r7Ndvi",
            "paypal_product_id": "PROD-0U87242096388624E",
            "creator": "EM-SYR-EQ1-02-2023",
            "name": "Caravan Fundraising",
            "description": "Help MATW provide caravans for families whose homes were destroyed in the earthquake",
            "aud": 1,
            "usd": 1,
            "gbp": 1,
            "cad": 1,
            "eur": 1,
            "sgd": 1,
            "myr": 2,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/ramadan-caravan-caravan-fundraising.jpg",
            "recurring": "Allowed",
            "status": "NEW",
            "created_at": "2023-04-07 19:43:15",
            "updated_at": "2023-04-07 19:49:11",
            "deleted_at": null,
            "category_id": 36
        },
        {
            "id": 96,
            "stripe_product_id": "prod_NYPz4cFZoXSEi1",
            "paypal_product_id": "PROD-0CV39642N8662544H",
            "creator": "WWC-CON-L1W-2023-01-026",
            "name": "L1 Africa Water Well",
            "description": "Your support can bring the gift of clean running water to a community in need, with a L1 water well enabling drinking, cooking, and washing, with a lifespan of 30-40 years. Make a lasting impact on a deserving community.",
            "aud": 10000,
            "usd": 7350,
            "gbp": 5985,
            "cad": 9765,
            "eur": 6720,
            "sgd": 9660,
            "myr": 31710,
            "custom_amount": "Yes",
            "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-build-a-water-well-l1-africa-water-well.jpg",
            "recurring": "Allowed",
            "status": "1",
            "created_at": "2023-03-19 10:07:48",
            "updated_at": "2023-03-19 10:35:25",
            "deleted_at": null,
            "category_id": 32
        }
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getMostPopularQurbanProducts = async () => {
        try {
            const response = await services.mostPopularQurbanProducts();
            console.log("response of top products", response,mostPopularQurbanProducts);
            let temp = [];
            response.data.map((item, index) => {
                index < 4 && temp.push(item);
                return temp;
            })
            setMostPopularQurbanProducts(temp);
            setYoutubeConHeight(youtubeContainerRef.current.clientWidth / 1.7777777)
        } catch (error) {
            console.error('error========', error);
        }
    };
    const getQurban2023Products = async () => {
        try {
            const response = await services.qurban2023Products();
            console.log("response of top products", response, qurban2023Products);
            setQurban2023Products(response.data);
        } catch (error) {
            console.error('error========', error);
        }
    };
    // const handleThumb = (items) => {
    //     return <div className='w-full flex justify-center'>{items}</div>
    // }
    // const customRenderThumb = (children) =>
    //     children.map((item) => {
    //         const videoId = getVideoId(item.props.url);
    //         return <img src={getVideoThumb(videoId)} />;
    //     });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        getMostPopularQurbanProducts();
        getQurban2023Products();
        // window.scroll({top: 0, left: 0, behavior: 'smooth' })
        // window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <Layout>
            <WidgetHome selectedCurrency={(e) => { }} />
            {/* /* -------------------------------- Products -------------------------------- */}
            <div className='w-full h-auto flex justify-center items-center bg-[#F60362]'>
                <div className="w-full h-full bg-[#093484]- bg-opacity-70 py-4">
                    <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex flex-col justify-center items-center">
                        <div className="basis-full lg:basis-1/2 text-center text-6xl text-white py-2 lg:py-8 font-['Gotcha']">
                            Sadaqah
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full bg-[#F60362] -mt-6 pb-8'>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center mt-6 bg-[#F60362]">
                    <div className="flex flex-row justify-center flex-wrap">
                        {sadaqahCategory.map((item, index) => {
                            return <div key={`products_qurban${index}`} className="basis-1/2 lg:basis-1/3 xl:basis-1/4 p-2">
                                <QurbanProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                            </div>
                        })
                        }
                    </div>
                </div>
            </div>
            <div className='w-full h-auto flex justify-center items-center bg-[#F60362]'>
                <div className="w-full h-full bg-[#093484]- bg-opacity-70 py-4">
                    <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex flex-col justify-center items-center">
                        <h1 className="col-span-12 text-center text-4xl md:text-6xl text-white py-2 md:py-4 font-['Gotcha']">
                            Sadaqah - Most Popular
                        </h1>
                    </div>
                </div>
            </div>
            <div className='w-full bg-[#F60362] -mt-6 pb-8'>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center mt-6 bg-[#F60362]">
                    <div className="flex flex-row justify-center flex-wrap">
                        {mostNeededProducts.map((item, index) => {
                            return <div key={`products.products${index}`} className="basis-1/2 lg:basis-1/3 xl:basis-1/4 p-2">
                                <QurbanProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                            </div>
                        })
                        }
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------------- Section one ------------------------------ */}
            <div className='w-full flex- flex-wrap items-stretch justify-start bg-[#E2EFF3] hidden'>
                <div className="basis-full lg:basis-5/12 xl:basis-7/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden">
                    <img src={img1} alt="" className='w-full min-h-full' />
                </div>
                <div className="basis-full lg:basis-7/12 xl:basis-4/12 flex flex-col px-8">
                    <h2 className='font-bold text-3xl mb-4 xl:mb-8 mt-8'>Your Qurban is more than a donation, it’s a lifeline.</h2>
                    <p className='text-sm mb-8 lg:mb-0'>Every year, millions of vulnerable families around the world wait for Eid Al Adha knowing the joy it will bring because of your Qurban. Qurban is a sunnah of our beloved Prophets. From the time of Prophet Ibrahim (AS) to Prophet Muhammad (SAW), till this very day Qurban is an important act of worship bringing us closer to Allah SWT. The meat provided gives communities essential sustenance that isn’t part of their daily diets. For most of these families, your Qurban during Eid will be the only time they get to eat meat. For us it is the sunnah of our Prophet Ibrahim AS – our ibadah. For the poorest people around the world, it’s nourishment, Eid joy and hope.
                        <br />
                        <br />
                        Last year, you helped us reach more than 6 million people with your Qurban. It’s an Amanah we at MATW take very seriously.
                        <br />
                        <br />
                        Our MATW teams work hard to deliver your Qurban donations within the most impoverished communities around the world. We deliver within the most remote areas and conflict zones including Syria, Palestine, Yemen and Myanmar.
                        <br />
                        <br />
                        From procuring livestock and tending to the animals, right through to delivery, we ensure that every step of the Qurban process is meticulously adhered to with care and in accordance with Islamic guidelines and principles.
                        <br />
                        <br />
                        This year, we’re working in more than 10 countries around the world and aim to reach even more people in need. Donate Now.
                        .</p>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* --------------------------------- Youtube -------------------------------- */}
            <div className="w-full mt-6- hidden" ref={youtubeContainerRef}>
                <div className="w-full" style={styles.youtube}>
                    <iframe className='video'
                        width={'100%'}
                        height={'100%'}
                        title={'youtube'}
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/embed/HlQrTxbcQ5Y?autoplay=0`}
                    >
                    </iframe>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ------------------------------- Section Two ------------------------------ */}
            <div className='w-full flex- hidden flex-wrap items-stretch justify-end bg-[#E2EFF3]'>
                <div className="basis-full lg:basis-7/12 xl:basis-4/12 flex flex-col px-8 py-8 order-2 lg:order-1">
                    <h2 className='font-bold text-3xl mb-4 xl:mb-8'>We’re bringing joy to many
                        families this Eid Al Adha, Insha’Allah.</h2>
                    <p className='text-sm'>
                        Our MATW team works hard to make sure that the most vulnerable families are prioritised to receive the best quality Qurban meat, delivered fresh during Eid. This includes orphans, widows and the elderly.
                        <br />
                        <br />
                        All of Muslim Around The World’s Qurban orders received from donors are processed and done fresh on Eid.
                        <br />
                        <br />
                        Our teams have been working tirelessly, in preparation for Eid so that from the 10th to 13th days of Dhul Hijjah, not a single moment is spared. Qurban is processed and delivered fresh to vulnerable families with each Qurban pack containing 3-5kg of meat and feeding a family of 5-8 people.
                        <br />
                        <br />
                        How does it work?
                        <br />
                        <br />
                        Our process is simple. Make your Qurban donation and our MATW team takes care of the rest. Start by selecting where you’d like to send your Qurban and the quantity you’d like to donate. The livestock is purchased and checked ahead of time with our team ensuring the animals meet the checking requirements. Your Qurban is then processed during Eid and distributed to the poorest families!
                        <br />
                        <br />
                        Reap the rewards of the 10 best days of the year – the blessed first days of Dhul Hijjah! Give your Qurban today.
                    </p>
                    <div className="flex justify-start mt-8">
                        <button className='border border-[#F60362] bg-transparent text-[#F60362] transition-all duration-200 hover:bg-[#F60362] hover:text-white px-8 h-10 flex items-center justify-center rounded' onClick={() => window.scroll(0, 0)}>Donate Now
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="basis-full lg:basis-5/12 xl:basis-7/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden order-1 lg:order-2">
                    <img src={img2} alt="" className='w-full min-h-full' />
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ------------------------------ Section Three ----------------------------- */}
            <div className='w-full flex- hidden flex-wrap items-stretch justify-start bg-[#E2EFF3]'>
                <div className="basis-full lg:basis-5/12 xl:basis-7/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden">
                    <img src={img3} alt="" className='w-full min-h-full' />
                </div>
                <div className="basis-full lg:basis-7/12 xl:basis-4/12 flex flex-col px-8 py-8 order-2 lg:order-1">
                    <h2 className='font-bold text-3xl mb-4 xl:mb-8'>Making the most of the
                        blessed days of Dhul Hijjah</h2>
                    <p className='text-sm'>The first 10 days of the month of Dhul Hijjah are the 10 best days in the entire year – the days that are most loved by Allah SWT! For us at MATW, we know that these days are an opportunity for us to get closer to Allah SWT’s bounty, attain His Mercy, while drawing closer to Him through our ibadah (acts of worship) and deeds most beloved by Him. There is no better time than now to boost your Iman. Maximise your good deeds through prayer, fasting, kindness and generosity.
                        <br />
                        <br />
                        Give from the best of what you have to those who need it most, and donate your Qurban/Udhiyah to poorest people around the world.
                        <br />
                        <br />
                        Do you know about the sunnah of the Prophetic Qurban?
                        <br />
                        <br />
                        It’s not common knowledge that on the day of Eid Al Adha, our beloved Prophet (SAW) used to give Qurban on behalf of himself and an additional Qurban on behalf of those in his Ummah who were unable to give. This extra sacrifice is what we call the Prophetic Qurban. There is wisdom in every act performed by our Prophet (SAW), so we encourage our donors who have the means to give an additional Qurban on behalf of the Ummah. Not only will you Insha’Allah double your reward, but also your impact.
                    </p>
                    <div className="flex justify-start mt-8">
                        <button className='border border-[#F60362] bg-transparent text-[#F60362] hover:bg-[#F60362] hover:text-white px-8 h-10 flex items-center justify-center rounded' onClick={() => window.scroll(0, 0)}>Donate Now
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* --------------------------------- Slider --------------------------------- */}
            <div id='carousel' className='w-full h-auto md:min-h-[340px] flex- hidden flex-wrap justify-start items-center bg-[#E2EFF3]'>
                <img src={sliderImg1} alt='slider' className='w-full' />
                {/* <Carousel showIndicators={false}
                    renderThumbs={(item) => item}
                    showStatus={false}
                >
                    <div>
                        <img src={sliderImg1} alt='slider' />
                    </div>
                    <div>
                        <img src={sliderImg1} alt='slider' />
                    </div>
                    <div>
                        <img src={sliderImg1} alt='slider' />
                    </div>
                </Carousel> */}
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ----------------------------------- FAQ ---------------------------------- */}
            <div className='w-full h-auto flex- hidden flex-col justify-center items-center bg-[#E2EFF3] pb-16'>
                <h2 className="text-2xl text-center mt-8 mb-4">Frequently Asked Questions</h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-8 md:px-0 mt-6">
                    {allFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full' key={`faq${index}`}>
                                <Accordionn key={`keyallfaq${index}`} title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordionn>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ------------------------------ Section Four ------------------------------ */}
            {/* <div className={`w-full bg-[url('../src/images/banners/banner-bg.png')] bg-cover text-white h-auto md:h-[340px] flex justify-center items-center`}></div> */}
            <div className="w-full relative flex- hidden flex-col justify-center items-center bg-[url('../src/images/qurban/Water for School 1-27 screenshot.jpg')] bg-cover h-auto py-4 sm:py-8 xl:h-[540px] overflow-hidden">
                <img src={bgImg} alt="matw" className=' absolute top-0 left-0 w-full h-full' />
                <div className="md:container md:mx-auto flex flex-wrap items-center justify-start mt-6- z-10 px-3 md:px-2">
                    <div className="basis-full lg:basis-2/3 xl:basis-1/3">
                        <h2 className='text-white text-md sm:text-4xl mb-2 sm:mb-6'>Support an Orphan</h2>
                        <p className='text-white text-xs sm:text-base md:text-md'>Every single day, 5,700 children become orphaned. We have to help them.
                            Islamic orphan charity, in particular, is guided by the principles of compassion and generosity that are central to the Islamic faith. Charity is a fundamental principle of Islam, and supporting orphans is a particularly important aspect of it. Orphans face a unique set of challenges that make it difficult for them to thrive. At MATW, our orphan charity is a noble and vital endeavour that seeks to provide support and care to these children who have lost their parents.</p>
                        <div className="flex justify-start">
                            <a className='text-white transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] px-8 h-8 sm:h-10 flex items-center justify-center rounded mt-2 sm:mt-6' href='/orphans'>Learn More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
        </Layout>
    );
}

export default SadaqahMostPopularPage;
