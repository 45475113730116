import React from 'react';
import CustomAccordion from '../../../components/general/customAccordion';
import { foodFaqs, waterFaqs } from '../faqs';

const FAQSection = ({ arabic, lang = 'en' }) => {
  const RawHTML = ({ children, className = '' }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />;

  // Helper function to map FAQs to the selected language
  const mapFaqsToLanguage = faqs =>
    faqs.map(faq => ({
      ...faq,
      question: faq.question[lang],
      answer: faq.answer[lang],
    }));

  return (
    <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10" dir={arabic ? 'rtl' : 'ltr'}>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
        <CustomAccordion>
          <CustomAccordion.Title tag="h2" className={arabic ? '!font-[AlmariMedium] font-bold' : ''}>
            {lang === 'fr' ? (
              <>
                <span className="text-[#F60362]">FAQ </span>
                sur les dons d'aide alimentaire
              </>
            ) : lang === 'ar' ? (
              <>
                حول تبرعات المساعدات الغذائية
                <span className="text-[#F60362]"> أسئلة شائعة</span>
              </>
            ) : (
              <>
                Food Aid Donations
                <span className="text-[#F60362]"> FAQ’s</span>
              </>
            )}
          </CustomAccordion.Title>
          <CustomAccordion.Header>
            {foodFaqs &&
              foodFaqs.map(
                ({ question, answer }, index) =>
                  index < 3 && (
                    <CustomAccordion.HeaderContent key={index}>
                      <CustomAccordion.HeaderQuestion>{question[lang]}</CustomAccordion.HeaderQuestion>
                      <CustomAccordion.HeaderAnswer>
                        <RawHTML>{answer[lang]}</RawHTML>
                      </CustomAccordion.HeaderAnswer>
                    </CustomAccordion.HeaderContent>
                  ),
              )}
          </CustomAccordion.Header>
          <div className="mt-6">
            <CustomAccordion.Accordion items={mapFaqsToLanguage(foodFaqs.filter((_, index) => index > 2))} />
          </div>
        </CustomAccordion>
      </div>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6 md:mt-12">
        <CustomAccordion>
          <CustomAccordion.Title tag="h2" className={arabic ? '!font-[AlmariMedium] font-bold' : ''}>
            {lang === 'fr' ? (
              <>
                <span className="text-[#F60362]">FAQ </span>
                sur les dons d'eau
              </>
            ) : lang === 'ar' ? (
              <>
                حول تبرعات مساعدة المياه
                <span className="text-[#F60362]"> أسئلة شائعة</span>
              </>
            ) : (
              <>
                Water Aid Donations
                <span className="text-[#F60362]"> FAQ’s</span>
              </>
            )}
          </CustomAccordion.Title>
          <CustomAccordion.Header>
            {waterFaqs &&
              waterFaqs.map(
                ({ question, answer }, index) =>
                  index < 3 && (
                    <CustomAccordion.HeaderContent key={index}>
                      <CustomAccordion.HeaderQuestion>{question[lang]}</CustomAccordion.HeaderQuestion>
                      <CustomAccordion.HeaderAnswer>
                        <RawHTML>{answer[lang]}</RawHTML>
                      </CustomAccordion.HeaderAnswer>
                    </CustomAccordion.HeaderContent>
                  ),
              )}
          </CustomAccordion.Header>
          <div className="mt-6">
            <CustomAccordion.Accordion items={mapFaqsToLanguage(waterFaqs.filter((_, index) => index > 2))} />
          </div>
        </CustomAccordion>
      </div>
    </section>
  );
};

export default FAQSection;
