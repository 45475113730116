import { commonImages } from './images';

export const howItWorksItems = [
  {
    icon: commonImages.identificationImg.src,
    title: 'Identification',
    description:
      'Our MATW team on the ground is working closely with local organisations and community leaders to identify Palestinian refugee families who have recently arrived and are facing financial hardship',
  },
  {
    icon: commonImages.assessmentImg.src,
    title: 'Assessment',
    description:
      'Each family will undergo an assessment process with MATW to determine their circumstances and the appropriate level of support required.',
  },
  {
    icon: commonImages.sponsorshipImg.src,
    title: 'Sponsorship',
    description:
      'Once approved, families are enrolled in the program and will begin receiving monthly allowances to cover their immediate expenses such as healthcare, education and essentials.',
  },
  {
    icon: commonImages.monitoringImg.src,
    title: 'Monitoring',
    description:
      'Our Programs team maintains regular contact with families receiving sponsorship to monitor their progress, and their well-being and assess any changing needs or challenges they may encounter.',
  },
];
