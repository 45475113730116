import React, { useState } from 'react';
import slider1Img from '../../images/landings/usa-general-donation/general-donation-slider1.jpg';
import slider2Img from '../../images/landings/usa-general-donation/general-donation-slider2.jpg';
import slider3Img from '../../images/landings/usa-general-donation/general-donation-slider3.jpg';
import slider4Img from '../../images/landings/usa-general-donation/general-donation-slider4.jpg';
import slider5Img from '../../images/landings/usa-general-donation/general-donation-slider5.jpg';
import { useKeenSlider } from 'keen-slider/react';

export default function CarouselImages({ currentSlideText }) {
  /* ----------------------------- Start variables ---------------------------- */
  const [banners] = useState([
    { name: 'Eradicating Poverty', image: slider1Img },
    { name: 'Improving Health', image: slider2Img },
    { name: 'Providing Clean Water', image: slider3Img },
    { name: 'Building Community', image: slider4Img },
    { name: 'Ending Hunger', image: slider5Img }
  ])
  const [opacities, setOpacities] = React.useState([])
  const [sliderRef] = useKeenSlider({
    loop: true,
    slideChanged(slider) {
      currentSlideText(banners[slider.track.details.rel].name)
    },
    slides: banners.length,
    detailsChanged(s) {
      const new_opacities = s.track.details.slides.map((slide) => slide.portion)
      setOpacities(new_opacities)
    },
  },
    [
      slider => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  )
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full md:py-2- min-h-[200px] sm:min-h-[330px] md:min-h-[393px] lg:min-h-[550px] xl:min-h-[674px] 2xl:min-h-[800px]">
      <div ref={sliderRef} className="keen-slider relative h-auto transition-all transform md:rounded-xl overflow-hidden min-h-[400px] lg:min-h-[490px] xl:min-h-[800px] 2xl:min-h-[840px]">
        {banners.map((item, idx) => (
          <div
            key={idx}
            className="fader__slide w-full h-full absolute top-0"
            style={{ opacity: opacities[idx] }}
          >
            <img src={item.image} alt={item.name} className='bg-transparent bg-tr w-full h-full object-cover absolute' />
          </div>
        ))}
      </div>
    </div>
  );
}
