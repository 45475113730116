import SeoData from '../../../utils/seo.json';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectSelectedCountry, selectSelectedCountryCode } from '../../../store/user';
import { selectProducts } from '../../../store/products';
import { useState } from 'react';
import iftarMealsImg from '../../../images/landings/ramadan/iftar-meals.jpg';
import waterWellImg from '../../../images/home/water-well.jpg';
import waterWellSubPhoto from '../../../images/landings/ramadan/water-well-sub.png';
import orphansSubPhoto from '../../../images/landings/ramadan/orphans-sub.png';
import projNoorSubPhoto from '../../../images/landings/ramadan/projectnoor-sub.png';
import { useKeenSlider } from 'keen-slider/react';

const useGetIndexDataHook = () => {
  const selectedCurrencyStore = useSelector(selectSelectedCountry);
  const products = useSelector(state => selectProducts(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [loaded, setLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [helpThemHearPrd, setHelpThemHearPrd] = useState();
  const [sadaqahProducts, setSadaqahProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);

  // const [topAppealsProducts, setTopAppealsProducts] = useState([0, 0, 0, 0]);
  const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0, 0]);
  const [eidGiftProducts, setEidGiftProducts] = useState([0, 0]);
  const [zakatAlFitrProducts, setZakatAlFitrProducts] = useState([0]);
  const [orphansProducts, setOrphansProducts] = useState([0, 0]);
  // const [waterWellProducts, setWaterWellProducts] = useState([0, 0, 0, 0, 0]);
  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const [zakatProducts, setZakatProducts] = useState([0, 0]);

  const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  const [basicWaterWellProducts] = useState({
    title: 'BASIC WATER WELL',
    description: 'Donate to provide clean water to communities that need it is a way to make a tangible and life-changing impact.',
    img: waterWellImg,
    products: [],
    alt: 'Muslim Charity to build a water well',
  });
  const [iftarMealsProducts] = useState({
    title: 'Iftar Meals',
    description: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan...',
    img: iftarMealsImg,
    products: [],
  });

  const tabButtonsProducts = [
    {
      title: 'EID Gifts',
      description: `Spread Eid joy to vulnerable orphaned children by delivering a gift to make them smile!`,
      products: eidGiftProducts,
    },
    {
      title: 'Zakat Al-Fitr',
      description: `Have you forgotten to pay your Zakat Al Fitr? Pay it now!`,
      products: zakatAlFitrProducts,
    },
    {
      title: 'Zakat Al Mal',
      description: `Zakat can be given throughout the year. Fulfil your obligation today and Feel Your Rewards!`,
      products: zakatProducts,
    },
    {
      title: 'Palestine Emergency',
      description: `Ramadan might be over but suffering is still widespread throughout Palestine. Help us continue supporting our Palestinian brothers and sisters.`,
      products: palestineEmergencyProducts,
    },
    {
      title: 'Orphans',
      description: `All children, especially orphans need a safe place to grow and thrive. Use your Sadaqah to help us continue caring for them!`,
      products: orphansProducts,
    },
    {
      title: 'Food Aid',
      description: `Hunger continues even after Ramadan. It’s not too late to feed a hungry family in need. Donate Now.`,
      products: foodAidProducts,
    },
  ];

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSadaqahProducts = [];
    let tempFoodAidProducts = [];
    let tempZakateProducts = [];
    let tempZakatAlFitrProducts = [];
    let tempOrphansProducts = [];
    // let tempFeedOrphansProducts = [];
    let tempTopAppealsProducts = [];
    let tempBasicWaterWellProducts = [];
    let tempWaterWellProducts = [];
    let tempIftarMealsProducts = [];
    let tempEidGiftProducts = [];
    let tempPalestineEmergencyProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Subscription Tab Starts

          if (inner_item_product.creator === 'RM24-OP-014') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Islamic Charity: donate to build Masjids',
            });
            console.log({inner_item_product})
            setHelpThemHearPrd(inner_item_product);
          }

          if (inner_item_product.creator === 'GD-MP015') {
            // General Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              subTitle:
                'Millions of people in underserved communities around the world don’t have access to a Mosque or Masjid. MATW wishes to change that. We believe that everybody should have access to a place of worship that serves as a hub for their community.',
              subTitleCopy:
                'Millions of people in underserved communities around the world don’t have access to a Mosque or Masjid. MATW wishes to change that. We believe that everybody should have access to a place of worship that serves as a hub for their community.',
              image_link: '/images/landings/uk/buildMasjid1.png',
              navigationLink: '/sadaqah-jariyah/build-a-masjid',
            });
            tempSadaqahProducts[0] = inner_item_product;
          }

          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            tempPalestineEmergencyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204') {
            tempPalestineEmergencyProducts[3] = inner_item_product;
          }

          if (inner_item_product.creator === 'FST-DEL-F25-2023-01-008') {
            tempOrphansProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FST-DEL-F50-2023-01-009') {
            tempOrphansProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FST-DEL-F150-2023-01-010') {
            tempOrphansProducts[2] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            // Water Well
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Water Wells',
              subTitle:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              subTitleCopy:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              image_link: waterWellSubPhoto,
              navigationLink: '/sadaqah-jariyah/build-a-water-well',
            });
            tempSadaqahProducts[1] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
            // Food and Water Distribution
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'ORPHANS',
              subTitle:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              subTitleCopy:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              image_link: orphansSubPhoto,
              navigationLink: '/orphans',
            });
            tempSadaqahProducts[2] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
            // gift of sound
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'GIFT OF SOUND',
              subTitle:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              subTitleCopy:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              image_link:
                'https://s3-alpha-sig.figma.com/img/d137/d803/9841f103292c4891626361263f421732?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ae8fphTbMA5XxT2MtgjBJfh5dlPguUd3uw4bI1TSTyT2-cO6Krc2Znc9qWADMsKl7UtGZ1e7zM752AJgxkSmtY38AQl-FEPwMcPQjNGRe9ddB7SwJrEGCPJlnPFyL8~j4w0YSBfknUBqvDZGJh7u~F763vkvsNl-LuetkUWfEtNHzQLx6o0xfkK1AHQUyY0giCSx7vab3JowuQLf~U4KWtVIOSTujtXA3YtV-YRpp5nY1E-b2rgy~WghsRnyCqwfOKfGLCKSQid9303HZjZkARXTfBZ~yjLvkoyb5Kakta3m1lrQKxC5o4i6t2bdhWJC7wUgiwWSZhiWXA88ncjPrA__',
              navigationLink: '/orphans',
            });
            tempSadaqahProducts[3] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
            // Sadaqah Jariyah
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Project Noor',
              subTitle: `Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.`,
              subTitleCopy:
                'Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.',
              status: '1',
              image_link: projNoorSubPhoto,
              navigationLink: '/sadaqah-jariyah/emergency',
            });
            tempSadaqahProducts[4] = inner_item_product;
          }
          // Subscription Tab Ends

          // Start Top Appeals
          // if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') tempTopAppealsProducts[0] = inner_item_product
          // if (inner_item_product.creator === 'GD-MP015') tempTopAppealsProducts[1] = inner_item_product
          // if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') tempTopAppealsProducts[3] = inner_item_product
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestine',
              image_link:
                'https://s3-alpha-sig.figma.com/img/3ed6/51cd/2853ab3b76a1567c9f159e382e198ec4?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Zjw33RIlSk~qfIVwOR~kDI80ZA2OYt9P2vLBfj42G38aGBESPwsl3d07xC2f2NbnV-5TLYDuIalKIzwb5EJwsTM~p-Jehtjjl5aQepR1LEXWFBb75Z~jgbA58sLC0fk50Cj6Ze35qTZqpHCnvDQ1MGJw7BF1nPkticScPJ0OV2Q-3X8Dk~4sBu1DajqaRShN6eyO8a5VO6lD8JC7hXHtJ9MSGnHJ46k2r6BtwlUdV7kYH4ju-3q~2mO5DuosMcfZ-8nPw4huBPVoCMMwhtiyQ559u4nin5YXlN3r3WN8UGUJ6~5yyvBxbsBW5nZtuFqw9O9luH5NPkwTZi0SzoLUtA__',
            });
            tempTopAppealsProducts[0] = inner_item_product;
            tempPalestineEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'GD-MP015') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Islamic Charity: donate to build Masjids',
            });
            tempTopAppealsProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestinian refugees',
            });
            tempTopAppealsProducts[3] = inner_item_product;
          }

          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempBasicWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') tempBasicWaterWellProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-IBW-2023-01-031') tempBasicWaterWellProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') tempBasicWaterWellProducts[3] = inner_item_product;
          // End Top Appeals

          // Start Food Aid
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-002') {
            tempFoodAidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') {
            tempFoodAidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-DBD-2023-01-051') {
            tempFoodAidProducts[2] = inner_item_product;
          }

          if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') {
            tempFoodAidProducts[3] = inner_item_product;
          }

          // End Food Aid

          // Start Zakat
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/zakat',
              staticLinkText: 'GIVE ZAKAT AL MAL',
            });
            console.log({ inner_item_product });
            tempZakateProducts[0] = inner_item_product;
            tempZakateProducts[1] = Object.assign({}, inner_item_product, {
              name: 'ZAKAT CALCULATOR',
              image_link: '/images/zakat/zakat-cal.jpg',
              isStaticProduct: true,
              staticLink: '/zakat-calculator',
              staticLinkText: 'CALCULATE YOUR ZAKAT',
              // linkBtnClasses: 'lg:!text-base !font-normal'
            });
          }
          // End Zakat

          // Start Zakat fitr
          if (inner_item_product.creator === 'RM24-015') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/zakat/zakat-al-fitr',
              staticLinkText: 'Donate Now',
            });
            tempZakatAlFitrProducts[0] = inner_item_product;
          }
          // End Zakat fitr

          // Start Orphan
          if (inner_item_product.creator === 'RM24-OP-014') {
            tempEidGiftProducts[0] = inner_item_product;
          }
          // End Orphan

          // Start Water well
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') tempWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-02-028') tempWaterWellProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempWaterWellProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-PSPW-2023-01-027') tempWaterWellProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-L2W-2023-01-027') tempWaterWellProducts[4] = inner_item_product;
          // End Water well

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempTopAppealsProducts[2] = basicWaterWellProducts;
    tempTopAppealsProducts[2].products = tempBasicWaterWellProducts;
    tempFoodAidProducts[0] = iftarMealsProducts;
    tempFoodAidProducts[0].products = tempIftarMealsProducts;

    tempSadaqahProducts = tempSadaqahProducts.filter(n => n)
    tempEidGiftProducts = tempEidGiftProducts.filter(n => n)
    tempZakateProducts = tempZakateProducts.filter(n => n)
    tempPalestineEmergencyProducts = tempPalestineEmergencyProducts.filter(n => n)
    tempFoodAidProducts = tempFoodAidProducts.filter(n => n)
    tempZakatAlFitrProducts = tempZakatAlFitrProducts.filter(n => n)
    tempOrphansProducts = tempOrphansProducts.filter(n => n)

    setSadaqahProducts(tempSadaqahProducts);
    setEidGiftProducts(tempEidGiftProducts);
    setZakatProducts(tempZakateProducts);

    // setTopAppealsProducts(tempTopAppealsProducts);
    setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
    setFoodAidProducts(tempFoodAidProducts);
    setZakatAlFitrProducts(tempZakatAlFitrProducts);
    setOrphansProducts(tempOrphansProducts);
    // setWaterWellProducts(tempWaterWellProducts);
  };

  // Mobile Slider Variables
  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return {
    products,
    seo,
    sliderRefImpactSlider,
    sadaqahProducts,
    loadedImpact,
    loaded,
    sliderRefImages,
    helpThemHearPrd,
    instanceRefImages,
    currentSlideImages,
    instanceRefImpactSlider,
    currentImpactSlide,
    selectedCurrency,
    tabButtonsProducts,
  };
};

export default useGetIndexDataHook;
