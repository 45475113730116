import React, { useState } from 'react';

import { useKeenSlider } from 'keen-slider/react';

import BangladeshEmergency1 from '../../../images/landings/bangladesh/gallery-3.jpg';
import BangladeshEmergency2 from '../../../images/landings/bangladesh/gallery-1.jpg';
import BangladeshEmergency5 from '../../../images/landings/bangladesh/gallery-5.jpg';
import BangladeshEmergency6 from '../../../images/landings/bangladesh/gallery-6.jpg';
import BangladeshEmergency7 from '../../../images/landings/bangladesh/gallery-7.jpg';

const images = [
  { src: BangladeshEmergency1, alt: 'bangladesh emergency gallery imaage 1' },
  { src: BangladeshEmergency2, alt: 'bangladesh emergency gallery imaage 2' },
  { src: BangladeshEmergency5, alt: 'bangladesh emergency gallery imaage 5' },
  { src: BangladeshEmergency6, alt: 'bangladesh emergency gallery imaage 6' },
  { src: BangladeshEmergency7, alt: 'bangladesh emergency gallery imaage 7' },
];

const BangladeshImagesSection = () => {
  const [loaded, setLoaded] = useState(false);

  const [currentSlideImages, setCurrentSlideImages] = useState(0);

  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',

    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },

    created() {
      setLoaded(true);
    },

    slides: {
      origin: 'auto',

      perView: 1,

      spacing: 8,
    },
  });

  return (
    <section className="mt-4 md:mt-12">
      <div className="md:container md:mx-auto flex justify-center flex-wrap ">
        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
          <div ref={sliderRefImages} className="keen-slider">
            {images.map((item, index) => {
              return (
                <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                  {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}

                  <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full  flex justify-center">
          {loaded && instanceRefImages.current && (
            <div className="dots flex pt-3 ">
              {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRefImages.current?.moveToIdx(idx);
                    }}
                    className={
                      'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                      (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                    }
                  ></button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default BangladeshImagesSection;
