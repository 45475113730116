const ConvertUSD = (currency, _price, showFractionDigits = true) => {
  // console.log("showcurrencies",currency,_price);
  let tempCurrency = currency && currency.toUpperCase();
  let price = Number(_price);
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  if (showFractionDigits) {
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
  }
  switch (tempCurrency) {
    case 'USD':
      return price ? (showFractionDigits ? price.toLocaleString() : price) : 0;
    case 'AUD':
      return price ? (showFractionDigits ? (price * 1.55).toLocaleString() : price * 1.55) : 0;
    case 'CAD':
      return price ? (showFractionDigits ? (price * 1.35).toLocaleString() : price * 1.35) : 0;
    case 'GBP':
      return price ? (showFractionDigits ? (price * 0.81).toLocaleString() : price * 0.81) : 0;
    case 'EUR':
      return price ? (showFractionDigits ? (price * 0.94).toLocaleString() : price * 0.94) : 0;
    case 'SGD':
      return price ? (showFractionDigits ? (price * 1.36).toLocaleString() : price * 1.36) : 0;
    case 'MYR':
      return price ? (showFractionDigits ? (price * 4.69).toLocaleString() : price * 4.69) : 0;
    case 'IDR':
      return price ? (showFractionDigits ? (price * 15393.2).toLocaleString() : price * 15393.2) : 0;
    case 'AED':
      return price ? (showFractionDigits ? (price * 3.67).toLocaleString() : price * 3.67) : 0;
    default:
      return price ? (showFractionDigits ? price.toLocaleString() : price) : 0;
  }
};

export default ConvertUSD;
