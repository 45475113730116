import { useEffect, useState, useRef } from 'react';
import services from '../../services';
import CategoryBox from '../../components/general/categoryBox';
import Layout from '../../components/general/layout';
import { useNavigate, useSearchParams, useLocation, Link } from 'react-router-dom';
import WidgetHome from '../../components/widgets/widgetHome';
// import BreadCrumb from '../components/breadCrumb/BreadCrumb';
// import ChangeDocumentTitle from '../utils/changeDocumentTitle';
import zakatImg1 from '../../images/zakat/page-banner-zakat-3.jpg';
import zakatImg2 from '../../images/zakat/page-banner-zakat-5.jpg';
import zakatImg3 from '../../images/zakat/page-banner-zakat-4.jpg';
import Skeleton from 'react-loading-skeleton';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
// import Skeleton from 'react-loading-skeleton';
import SeoData from '../../utils/seo.json';
import { Helmet } from 'react-helmet';
import AppealLandingPage from '../appeal';

const SubCategory = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const navigate = useNavigate();
    // const location = useLocation();
    const location = useLocation();
    const [params] = useSearchParams();
    const [category, setCategory] = useState({
        name: '',
        sub_categories: [0, 0, 0, 0, 0, 0, 0, 0]
    });
    const youtubeContainerRef = useRef();
    const subCategoryID = params.get("id");
    const [isLoading, setIsLoading] = useState(true);
    const [youtubeConHeight, setYoutubeConHeight] = useState(300);
    const [currentYoutube, setCurrentsetYoutube] = useState('');
    const [currentPdf, setCurrentsetPdf] = useState();
    const [isSubCategory, setIsSubCategory] = useState(true);
    const youtubeVideos = [
        { title: 'Orphans', videoLink: 'eiqJowZoLBc' },
        { title: 'Current Projects', videoLink: 'RSKLrMZxeN4' },
        { title: 'Sadaqah Jariyah', videoLink: 'cB8icb5L2cs' },
        { title: 'Ramadan', videoLink: 'mUvEP4zxyNc' },
        { title: 'Food and Water Aid', videoLink: 'IMShTZGOkt8' },
        { title: 'Zakat', videoLink: '251bv2sydxM' },
        { title: 'Crisis and Emergencies', videoLink: 'XRziciz2sZA' },
        { title: 'Donate Now', videoLink: 'tC_L9aTE8eI' },
        { title: 'Ramadan Emergency', videoLink: 'TcaeEoqaenE' },
    ]
    const pdfFiles = [
        { cat_title: 'Orphans', title: 'Orphans - Annual Impact Report 2022', description: `MATW's Orphan Care Project is providing support and care for orphans and vulnerable children around the world. They offer basic necessisities like food, education, shelter etc and work towards creating sustainable solutions for the community. `, pdfLink: 'pdf-report-orphans.pdf' },

        { cat_title: 'Current Projects', title: 'Qurban - Impact Report 2022', description: `The MATW Qurban Report provides information on the Qurban program, an Islamic tradition where livestock is sacrificed and meat is distributed to those in need. The report includes details on the number of animals sacrificed, meat distribution, and its impact on the community.`, pdfLink: 'pdf-report-current-projects.pdf' },

        { cat_title: 'Sadaqah Jariyah', title: '', description: ``, pdfLink: 'pdf-report-sadaqah-jariyah.pdf' },

        { cat_title: 'Ramadan', title: 'Ramadan 2022 - Impact Report', description: `MATW was able to focus on delivering essential food aid within impoverished communities as well as continue their most crucial and life-changing projects.`, pdfLink: 'pdf-report-ramadan-2022.pdf' },

        { cat_title: 'Food and Water Aid', title: 'Water For Life - Annual Impact Report 2021', description: `MATW's Water for Life project aims to provide clean water and sanitation to vulnerable communities around the world through the construction of water and many other water programs. The project improves health and quality of life by increasing access to safe and reliable water sources.`, pdfLink: 'pdf-report-food-and-water-aid.pdf' },

        { cat_title: 'Zakat', title: 'Zakat - Impact Report 2022', description: `Zakat is a blessing, purity, kindness, and a contribution to the development of entitled recipients. This Ramadan MATW reached more families, built more wells, masjids, orphan homes and fed more vulnerable people than ever before.`, pdfLink: 'pdf-report-zakat.pdf' },

        { cat_title: 'Crisis and Emergencies', title: 'Yemen - Impact Report 2021', description: `MATW has established operations and programs in some of Yemen’s most needed areas to provide access to quality eye care and rehabilitation as well as provide clean drinking water to prevent the spread of waterborne diseases that continue to claim thousands of lives in Yemen.`, pdfLink: 'pdf-report-crisis-and-emergencies.pdf' },

        { cat_title: 'Donate Now', title: '', description: ``, pdfLink: 'pdf-report-donate-now.pdf' },

        { cat_title: 'Ramadan Emergency', title: 'Palestine - Emergency Response Report 2021', description: `MATW has launched an emergency response campaign to provide urgent humanitarian aid to Palestinians affected by the recent conflict in Gaza and other parts of Palestine. The campaign focuses on providing essential items such as food, water, medical supplies, and shelter to those in need. The situation in Palestine is dire, and the campaign aims to alleviate the suffering of those affected by the violence. Donations to the campaign will help provide crucial aid to those in need.`, pdfLink: 'pdf-report-ramadan-emergency.pdf' },

        { cat_title: 'Zakat', title: '', description: ``, pdfLink: 'pdf-report-zakat.pdf' },

        { cat_title: 'Events', title: '', description: ``, pdfLink: 'pdf-report-events.pdf' },
    ]
    const styles = {
        youtube: {
            height: youtubeConHeight
        }
    }

    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [meta, setMeta] = useState({
        parent: '',
        child: ''
    })
    console.log(meta, '****',)

    const RawHTML = ({ children, className = "" }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '') }} />
    //   dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}} />
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getSubCategory = async () => {
        setIsLoading(true);
        try {
            const response = await services.subCategory(subCategoryID);
            console.log('response get subCategory', response);
            if (Object.keys(response.data).length === 0) {
                navigate('/')
            } else {
                let temp = [];
                response.data.map(item => {
                    if (item.name !== 'Sacrifice') {
                        temp.push(item);
                    }
                    return null;
                })
                console.log("temp ==", temp)
                setCategory(temp);
                // ChangeDocumentTitle(`${response.data.name} - MATW Project Muslim Charity`);
            }
            setIsLoading(false);
            // response.data.sub_categories.length ? setCategory(response.data) : navigate('/')
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const getSubCategoryByName = async (name) => {
        setCategory({
            name: '',
            sub_categories: [0, 0, 0, 0, 0, 0, 0, 0]
        })
        setIsLoading(true);
        try {
            const response = await services.subCategoryByName(name);
            console.log('response get getSubCategoryByName', response.data);
            if (Object.keys(response.data).length === 0) {
                // navigate('/');
                setIsSubCategory(false);
            } else {
                let tempObj = response.data;
                let temp = [];
                response.data.sub_categories.map(item => {
                    if (response.data.name === 'Crisis and Emergencies') {
                        response.data.tile_image = '/images/landings/crisis/crisis-and-emergencies.webp';
                        if (item.name === 'Afghanistan') {
                            item.image_link = 'https://cdn.matwproject.org/images/products/1715941403_crisis-and-emergencies-afghanistan-afghanistan-flood-appeal.jpg';
                            temp[0] = item;
                        }
                        if (item.name === 'Earthquake Appeal') {
                            item.page_title = 'Devastating earthquakes hit Syria and Turkey, claiming lives and leaving many injured and homeless. Your donation can help those in need.'
                            temp[1] = item;
                        }
                        if (item.name === 'Palestine') temp[2] = item;
                        if (item.name === 'Caravan') temp[3] = item;
                        // if (item.name === 'Libya Floods') {
                        //     item.page_title = 'Devastating Flood Calamity Strikes Libya'
                        //     item.image_link = 'https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-emergency-support-libya-emergency.jpeg'
                        //     temp[4] = item;
                        // }
                    } else if (item.name !== 'Sacrifice') {
                        temp.push(item);
                    }
                    return null;
                })
                if (response.data.name === 'Zakat') {
                    response.data.tile_image = '/images/zakat/matw-project-zakat-hero-banner.webp';
                    temp && temp.map(item => {
                        if (item.name === 'Give Zakat') {
                            item.page_title = 'MATW & Zakat'

                        }
                        return item;
                    })
                }
                if (response.data.name === 'Current Projects') {
                    response.data.tile_image = '/images/landings/current-projects/current-projects.webp';

                }
                if (response.data.name === 'Donate Now') {
                    response.data.tile_image = '/images/landings/donate-now/donate-now.webp';

                }




                tempObj.sub_categories = temp;
                console.log("temp ==", tempObj)
                setCategory(tempObj);
                youtubeVideos.map(video => video.title === response.data.name && setCurrentsetYoutube(video.videoLink));
                pdfFiles.map(file => file.cat_title === response.data.name && setCurrentsetPdf(file));
                console.log("width", youtubeContainerRef)
                setYoutubeConHeight(youtubeContainerRef.current.clientWidth / 1.7777777)
                // response.data && ChangeDocumentTitle(`${response.data.name} - MATW Project Muslim Charity`);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const handleParagraph = (text) => {
        let tempText = [];
        if (text) {
            tempText = text.split(".");
            return tempText.map((txt, index) => {
                let temp = txt === "" ? '' : <p key={`par${index}`}>{txt}.<br /></p>
                return temp;
            })
        }
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        console.log(window.location.pathname);
        if (window.location.pathname === '/events') {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])
    useEffect(() => {
        // subCategoryID ? getSubCategory() : navigate("/");
        subCategoryID && getSubCategory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subCategoryID])
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' });
        window.scroll(0, 0);
        const handleBackButton = () => {
            navigate("/");
        }
        // console.log("youtubeContainerRef",youtubeContainerRef.current.clientWidth)
        // console.log("youtubeContainerRef",youtubeContainerRef.current)
        window.addEventListener('popstate', (handleBackButton));
        // cleanup function to remove the event listener when component unmounts
        return () => window.removeEventListener('popstate', handleBackButton);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        let pathNames = window.location.pathname.split('/');
        console.log(pathNames)
        if (pathNames.length > 1) {
            let temp = pathNames[1].split('-');
            temp = temp.join(' ');
            getSubCategoryByName(temp)
            // console.log("params",temp)
        }
        if (pathNames.length === 2) {

            let p = camelCaseformat(pathNames[1])
            // let c = camelCaseformat(pathNames[2])
            setMeta({
                ...meta,
                parent: p,

            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])


    const camelCaseformat = (val) => {
        let array = val.split('');
        for (let index = 0; index < array.length; index++) {
            if (array[index] === '-') {
                array[index + 1] = array[index + 1].toUpperCase();
            }
        }
        return array.join('').replaceAll('-', '');
    }
    /* -------------------------------------------------------------------------- */
    return (
        <>
            {isSubCategory
                ? <Layout>
                    {seo && seo.hasOwnProperty(meta?.parent) && seo[meta?.parent].hasOwnProperty(meta?.child) &&
                        <Helmet>
                            <title>{`${seo[meta.parent][meta.child]['title']}`}</title>
                            <meta name="title" content={`${seo[meta.parent][meta.child]['title']}`} />
                            <meta name="description" content={`${seo[meta.parent][meta.child]['description']}`} />
                        </Helmet>
                    }
                    {seo && seo.hasOwnProperty(meta.parent) && !seo[meta.parent].hasOwnProperty(meta?.child) &&
                        <Helmet>
                            <title>{`${seo[meta.parent][meta.parent] && seo[meta.parent][meta.parent]['title']}`}</title>
                            <meta name="title" content={`${seo[meta.parent][meta.parent] && seo[meta.parent][meta.parent]['title']}`} />
                            <meta name="description" content={`${seo[meta.parent][meta.parent] && seo[meta.parent][meta.parent]['description']}`} />

                        </Helmet>
                    }
                    {
                        !isLoading ? <img src={category.tile_image} alt={category.name} className='w-full' onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/general/logo-replace.png";
                            currentTarget.className = 'max-h-[150px] mx-auto'
                        }} /> : <div className='w-full'><Skeleton height={297} /></div>
                    }
                    <WidgetHome selectedCurrency={(e) => { }} />
                    <div className="md:container md:mx-auto mt-5">
                        <div className="grid grid-cols-12 px-4 md:px-2 lg:px-0">
                            <h1 className="col-span-12 text-center text-6xl text-[#253b7e] py-2 md:py-8 font-['Gotcha']">
                                {(category && category.name) ? category.name === 'Qurban' ? 'Sadaqah' : category.name : <Skeleton />}
                            </h1>
                            <div className="col-span-12 rounded-md">
                                <div className="flex flex-row justify-center flex-wrap">
                                    {/* {category ? category.sub_categories.map((item, index) => {
                    return <div key={`subcateindex${index}`} className="basis-1/2 sm:basis-1/3 lg:basis-1/4 p-2">
                        <CategoryBox key={`key${index}`} category={item} pathName={location.pathname} onClick={(id) => { navigate(`${location.pathname}/${item.name.replace(/ /g, "-").toLowerCase()}`) }} buttonTitle='View Appeals' />
                    </div>
                })
                    : <div role="status" className='col-span-12 flex justify-center items-center min-h-[500px]'>
                        <svg aria-hidden="true" className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#00a3da]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                } */}
                                    {category.sub_categories.map((item, index) => {
                                        return <div key={`subcateindex${index}`} className="basis-1/2 sm:basis-1/3 lg:basis-1/4 p-2">
                                            <CategoryBox key={`key${index}`} category={item} pathName={location.pathname} onClick={(id) => { navigate(`${location.pathname}/${item.name.replace(/ /g, "-").toLowerCase()}`) }} buttonTitle='View Appeals' />
                                        </div>
                                    })
                                    }
                                </div>
                            </div>
                            <div ref={youtubeContainerRef} className={`col-span-12 rounded-xl mt-6 bg-white p-6 lg:p-12 text-center shadow-lg ${category && category.name === 'Qurban' ? 'hidden' : ''}`}>
                                <h1 className='text-[#00a3da] text-4xl mb-4'>{category ? category.page_title : <Skeleton />}</h1>
                                <p className="text-[#777] text-xl mb-4">
                                    {category ? category.page_subtitle : <Skeleton />}
                                </p>
                                <div className="text-[#999] text-md">
                                    {/* {category ? category.page_description.includes('<') ? ReactDOMServer.renderToStaticMarkup(htmlToReactParser.parse(category.page_description)) : handleParagraph(category.page_description) : <Skeleton />} */}
                                    {category ? category.page_description && category.page_description.includes('<') ? <RawHTML>{category.page_description}</RawHTML> : handleParagraph(category.page_description) : <Skeleton />}
                                </div>
                            </div>
                            {currentYoutube && <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                                <div className="col-span-12 h-[200px]- sm:h-[300px]- md:h-[400px]- lg:h-[600px]-" style={styles.youtube}>
                                    <iframe className='video'
                                        width={'100%'}
                                        height={'100%'}
                                        title={'youtube'}
                                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                        src={`https://youtube.com/embed/${currentYoutube}?autoplay=0`}
                                    >
                                    </iframe>
                                </div>
                            </div>}
                            {currentPdf && currentPdf.title && <div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                                <h1 className='text-[#00a3da] text-4xl mb-4'>{currentPdf ? currentPdf.title : <Skeleton />}</h1>
                                <div className="text-[#999] text-md">
                                    {currentPdf ? handleParagraph(currentPdf.description) : <Skeleton />}
                                </div>
                                <Link className='text-[#00a3da] text-xl mt-5' to={`/pdf/${currentPdf.pdfLink}`} target="_blank">Download PDF</Link>
                            </div>}
                        </div>
                    </div>
                    {
                        Number(subCategoryID) === 6 && <div className="w-full bg-white mt-24">
                            <div className="md:container md:mx-auto flex justify-center flex-col items-center px-4">
                                <img src={zakatImg1} alt="matw" className='max-w-full md:w-4/6 my-6' />
                                <div className="my-6 flex flex-col items-center">
                                    <h1 className="col-span-12 text-center text-5xl text-[#253b7e] py-2 md:py-8 font-['Gotcha']">
                                        Managing Zakat
                                    </h1>
                                    <p className="text-center text-[#777] text-lg w-full md:w-2/3">
                                        MATW takes Zakat management very seriously for many important reasons, especially that it is one of the pillars of Islam, the special regulations and guidelines of this type of donation and huge impact that Zakat potentially has in changing the lives of those who are eligible to receive it.
                                    </p>
                                    <Link to={'/zakat-calculator'} className='text-white my-6 px-4 text-sm md:text-md lg:text-xl col-span-6 xl:col-span-4 xl:col-start-2 flex rounded-md transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] mr-2 cursor-pointer py-2 justify-center items-center'
                                    // onClick={(e) => {e.preventDefault();navigate('/zakat-calculator')}}
                                    >
                                        Zakat Calculator
                                    </Link>
                                </div>
                                <img src={zakatImg2} alt="matw" className='max-w-full my-6' />
                                <img src={zakatImg3} alt="matw" className='max-w-full md:w-1/2 my-6' />
                            </div>
                        </div>
                    }
                </Layout >
                : <AppealLandingPage />
            }
        </>
    );
}

export default SubCategory;
