import { Link } from 'react-router-dom';

const CancelCryptoPage = () => {
  return (
    <div className=" md:container relative mx-auto overflow-hidden">
      <div className="w-full flex justify-center  absolute top-8 left-0 z-10">
        <div className="p-2 bg-white rounded-full w-24 shadow-md ">
          <a href='/'><img className='w-20 md:w-[80px] rounded-full' src="/images/general/logo-.png" alt="matw" /></a>
        </div>
      </div>
      <div className="flex flex-wrap justify-center py-8 px-3 md:px-0">
        <div className="bg-white flex pt-20 flex-col basis-full lg:basis-9/12 xl:basis-7/12 rounded-md px-6 py-12 shadow-md mt-10 items-center">
          <div className="rounded-full bg-red-50 p-6 flex justify-center items-center border border-red-500">
            <svg viewBox="0 0 14 14" fill="currentColor" className="text-green-700 w-12 h-12" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 0.000366211C3.1 0.000366211 0 3.10037 0 7.00037C0 10.9004 3.1 14.0004 7 14.0004C10.9 14.0004 14 10.9004 14 7.00037C14 3.10037 10.9 0.000366211 7 0.000366211ZM9.7 10.5004L7 7.80037L4.3 10.5004L3.5 9.70037L6.2 7.00037L3.5 4.30037L4.3 3.50037L7 6.20037L9.7 3.50037L10.5 4.30037L7.8 7.00037L10.5 9.70037L9.7 10.5004Z" fill="#EF0F0F" />
            </svg>
          </div>
          <h1 className="text-red-500 text-2xl font-medium">Donation Cancelled</h1>
          <div className="grid grid-cols-5 p-2 sm:p-4 w-full mt-2 gap-2 text-center">
            <p className="col-span-5 text-stone-500 mb-4">
              You have cancelled the order. In case you have already dispatched the payment and clicked on the cancel button by mistake,
              then you don't need to worry. We will send you the invoice soon.
            </p>
            <div className="col-span-5 flex justify-center">
              <Link
                to={'/'}
                className={`mb-3 w-full md:w-1/3 flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#b31641] focus:shadow-[0_2px_5px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] active:bg-[#b31641] hover:bg-[#b31641] hover:shadow-[0_4px_4px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}>
                Donate More
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="ml-2 w-6 h-6">
                  <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className="p-2 sm:p-4 w-full mt-6 gap-2 flex flex-col col-span-12">
          <p className="w-full text-stone-400 text-center text-md mb-3">Follow us on Social Media</p>
          <div className="flex justify-center items-center gap-6">
            <Link
              to="https://www.facebook.com/matwproject/"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/facebook.png" alt="facebook" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://www.instagram.com/matw_project/"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/instagram.png" alt="instagram" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://twitter.com/matw_project"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/twitter.png" alt="twitter" className="w-auto h-[50%]" />
            </Link>
            <Link
              to="https://www.youtube.com/@MATWProject"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/youtube.png" alt="youtube" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://www.tiktok.com/@matw_project"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/tiktok.png" alt="tiktok" className="w-auto h-[60%]" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelCryptoPage;
