import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import { useNavigate } from 'react-router-dom';
import { selectProducts } from '../../store/products';
import { ramadanFaqs } from './faq';
import { RamadanImpactIcon } from '../../components/general/customIcons';
import { OurPromiseSection } from '../../components/general';
import banner from '../../images/ramadan/rewards-generic-hero-banner-desktop.jpg';
import bannerMobile from '../../images/ramadan/rewards-generic-hero-banner-mobile.jpg';
import fidyaImg from '../../images/landings/ramadan/fidya.jpg';
import kaffarahImg from '../../images/landings/ramadan/kaffarah.jpg';
import zakatImg from '../../images/ramadan/zakatImg.png';
import volunteerImg from '../../images/ramadan/volunteerImg.png';
import fundraiserImg from '../../images/ramadan/fundraiserImg.png';
import iftarMealsImg from '../../images/landings/ramadan/iftar-meals.jpg';
import feedOrphanImg from '../../images/landings/ramadan/feed-orphan.jpg';
import { createPortal } from 'react-dom';
import ReportModal from '../../components/general/reportModal';
import { Helmet } from 'react-helmet';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import diyRamadanPhoto from '../../images/landings/ramadan/diy-ramadan.png';
import sadaqahJarsPhoto from '../../images/landings/ramadan/sadaqah-jars.png';
import waterWellSubPhoto from '../../images/landings/ramadan/water-well-sub.png';
import orphansSubPhoto from '../../images/landings/ramadan/orphans-sub.png';
import projNoorSubPhoto from '../../images/landings/ramadan/projectnoor-sub.png';
import ShowCurrencies from '../../utils/showCurrencies';
import ConvertAUD from '../../utils/convertAUD';
import RamadanPageTabs from './components/tabs';
import SadaqahJariyahTabs from '../home/components/sadaqaJariyahTabs';
import CustomButton from '../../components/general/button';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import BlessingAndMercy from '../home/components/blessing&mercy';

const RamadanLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [sadaqahProducts, setSadaqahProducts] = useState([0, 0, 0, 0, 0]);
  const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0, 0]);
  const [zakatProducts, setZakatProducts] = useState([0, 0]);
  const [zakatAlFitrProducts, setZakatAlFitrProducts] = useState([0]);
  const [orphansProducts, setOrphansProducts] = useState([0, 0]);
  const [riceBagsProducts, setRiceBagsProducts] = useState([0]);
  const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);

  const [feedAnOrphanProduct] = useState({
    title: 'Feed an Orphan For 30 Days',
    description: 'Help provide daily nutritious staple meals for an orphaned child every day this Ramadan!',
    img: feedOrphanImg,
    products: [],
    alt: 'Muslim Charity to feed an orphan'
  });
  const [iftarMealsProduct] = useState({
    title: 'Iftar Meals',
    description: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan',
    img: iftarMealsImg,
    products: [],
    alt: 'Muslim Charity to Iftar meals'
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSadaqahProducts = [];
    let tempFoodAidProducts = [];
    let tempIftarProducts = [];
    let tempZakateProducts = [];
    let tempZakatAlFitrProducts = [];
    let tempOrphansProducts = [];
    let tempRiceBagsProducts = [];
    let tempFeedOrphansProducts = [];
    let tempPalestineEmergencyProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Subscription Tab Starts
          if (inner_item_product.creator === 'GD-MP015') {
            // General Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'general masjid donations',
              subTitle:
                'Millions of people in underserved communities around the world don’t have access to a Mosque or Masjid. MATW wishes to change that. We believe that everybody should have access to a place of worship that serves as a hub for their community.',
              subTitleCopy:
                'Millions of people in underserved communities around the world don’t have access to a Mosque or Masjid. MATW wishes to change that. We believe that everybody should have access to a place of worship that serves as a hub for their community.',
              image_link: '/images/landings/uk/buildMasjid1.png',
              navigationLink: '/sadaqah-jariyah/build-a-masjid',
            });
            tempSadaqahProducts[0] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            // Water Well
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Water Wells',
              subTitle:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              subTitleCopy:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              image_link: waterWellSubPhoto,
              navigationLink: '/sadaqah-jariyah/build-a-water-well',
            });
            tempSadaqahProducts[1] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
            // Food and Water Distribution
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'ORPHANS',
              subTitle:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              subTitleCopy:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              image_link: orphansSubPhoto,
              navigationLink: '/orphans',
            });
            tempSadaqahProducts[2] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
            // Sadaqah Jariyah
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'project noor',
              subTitle: `Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.`,
              subTitleCopy:
                'Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.',
              status: '1',
              image_link: projNoorSubPhoto,
              navigationLink: '/sadaqah-jariyah/emergency',
            });
            tempSadaqahProducts[3] = inner_item_product;
          }
          // Subscription Tab Ends

          // Food Aids


          if (inner_item_product.creator === 'RM24-FA-010') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/ramadan/fidya-kaffarah?product=kafarrah',
              staticLinkText: 'CALCULATE YOUR KAFFARAH',
            });
            tempFoodAidProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'RM24-FA-011') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/ramadan/fidya-kaffarah?product=fidya',
              staticLinkText: 'CALCULATE YOUR FIDYA',
            });
            tempFoodAidProducts[3] = inner_item_product;
          }
          // Zakat

          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/zakat',
              staticLinkText: 'GIVE ZAKAT AL MAL',
            });
            tempZakateProducts[0] = inner_item_product;
            tempZakateProducts[1] = Object.assign({}, inner_item_product, {
              name: 'ZAKAT CALCULATOR',
              image_link: '/images/zakat/zakat-cal.jpg',
              isStaticProduct: true,
              staticLink: '/zakat-calculator',
              staticLinkText: 'CALCULATE YOUR ZAKAT',
            });
          }
          if (inner_item_product.creator === 'RM24-015') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/zakat/zakat-al-fitr',
              staticLinkText: 'Donate Now',
            });
            tempZakatAlFitrProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'RM24-OP-014') tempOrphansProducts[1] = inner_item_product; // Orphan Feast For 150
          // palestineEmergencyProducts
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            // inner_item_product = Object.assign({}, inner_item_product, {
            //   name: 'PALESTINE APPEAL',
            // });
            tempPalestineEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
            tempPalestineEmergencyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            tempPalestineEmergencyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204') {
            tempPalestineEmergencyProducts[3] = inner_item_product;
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempOrphansProducts[0] = feedAnOrphanProduct;
    tempOrphansProducts[0].products = tempFeedOrphansProducts;
    tempFoodAidProducts[1] = iftarMealsProduct;
    tempFoodAidProducts[1].products = tempIftarProducts;

    tempSadaqahProducts = tempSadaqahProducts.filter(n => n)
    tempFoodAidProducts = tempFoodAidProducts.filter(n => n)
    tempZakateProducts = tempZakateProducts.filter(n => n)
    tempZakatAlFitrProducts = tempZakatAlFitrProducts.filter(n => n)
    tempOrphansProducts = tempOrphansProducts.filter(n => n)
    tempRiceBagsProducts = tempRiceBagsProducts.filter(n => n)
    tempPalestineEmergencyProducts = tempPalestineEmergencyProducts.filter(n => n)

    setSadaqahProducts(tempSadaqahProducts);
    setFoodAidProducts(tempFoodAidProducts);
    setZakatProducts(tempZakateProducts);
    setZakatAlFitrProducts(tempZakatAlFitrProducts);
    setOrphansProducts(tempOrphansProducts);
    setRiceBagsProducts(tempRiceBagsProducts);
    setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
  };

  const fundraise = () => {
    navigate('/fundraising');
  };
  const closeModal = () => {
    setShowModal(false);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="bg-white" isRamadanPage>
      {showModal && createPortal(<ReportModal closeModal={closeModal} />, document.body)}
      {seo && (
        <Helmet>
          <title>{`${seo['ramadan']['title']}`}</title>
          <meta name="title" content={`${seo['ramadan']['title']}`} />
        </Helmet>
      )}
      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: 'Where Most Needed',
            value: 122,
          }}
        />
      </section>
      <section className="flex justify-center">
        <img src={banner} alt="ramadan 2024" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="ramadan 2024" className="min-[501px]:hidden w-full" />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: 'Where Most Needed',
              value: 122,
            }}
          />
        </div>
      </section>
      {/* /* ------------------------------- Tab ------------------------------ */}
      <RamadanPageTabs
        palestineEmergencyProducts={palestineEmergencyProducts}
        riceBagProduct={riceBagsProducts}
        foodAidProducts={foodAidProducts}
        zakatProducts={zakatProducts}
        zakatAlFitrProducts={zakatAlFitrProducts}
        orphansProducts={orphansProducts}
      />
      {/* /* ------------------------------- Our Promise ------------------------------ */}
      <OurPromiseSection />
      {/* /* ----------------------------- Ramadan Impact ----------------------------- */}
      <section className="px-4 xl:px-20 my-8 sm:my-16 flex flex-col items-center">
        <MaxWidthContainer className={'max-[500px]:w-full'}>
          <h2 className="w-full text-[#253B7E] font-medium text-2xl md:text-[40px] text-center mb-4 md:mb-6">
            Ramadan Charity <span className="text-[#F60362]">Impact</span>
          </h2>
          <div className="w-full sm:w-auto flex justify-center items-center text-white bg-[#0D1943] rounded-xl px-4 md:px-12 py-2 md:py-4 pb-5">
            <span className="hidden sm:block">
              {' '}
              <RamadanImpactIcon />
            </span>
            <div className="flex flex-col w-full pl-2- md:pl-6">
              <h1 className="text-[#00A3DA] text-center text-6xl sm:text-3xl md:text-6xl mb-2">7,147,494</h1>
              <p className="text-[14px] text-center sm:text-[16px] font-brandingMedium sm:font-bold text-white  mb-2">
                Total Beneficiaries In Ramadan 2023
              </p>
              <div className="flex">
                {/* <button
                  onClick={() => setShowModal(true)}
                  className="basis-5/12 bg-[#00A3DA] text-[12px] md:text-base rounded-md text-white px-2 py-1 mr-2"
                >
                  Read More
                </button> */}
                <CustomButton onClick={() => setShowModal(true)} title='Read More' className='mr-2 text-[12px] !bg-[#00A3DA] min-w-[120px]' />
                {/* <Link
                  className="w-[150px] flex justify-center items-center  basis-8/12 bg-[#093686] text-[12px] md:text-base rounded-md text-white px-2 py-1"
                  to={`../pdf/MATW_Ramadan_2023_Report - FINAL.pdf`}
                  target="_blank"
                >
                  Download Report
                </Link> */}
                <CustomButton link='../pdf/MATW_Ramadan_2023_Report - FINAL.pdf' title='Download Report' target="_blank" className='min-w-[150px] basis-8/12 !bg-[#253B7E]' />
                {/* <button onClick={() => navigate('/zakat-calculator')} className='basis-8/12 bg-[#093686] text-[12px] rounded-md text-white px-2 py-1'>Download Impact Report</button> */}
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      {/* /* -------------------------------- Ramadan Message ---------------------------- */}
      <BlessingAndMercy buttons={
        <>
          <CustomButton onClick={() => navigate('/eid-al-fitr-2024')} title='GIVE AN EID GIFT' className='mb-2 md:mb-0 md:mr-2 min-w-[200px]' />
          <CustomButton onClick={() => navigate('/zakat/zakat-al-fitr')} title='PAY ZAKAT AL FITR' className='min-w-[200px]' />
        </>
      } />
      <div className="w-full md:container md:mx-auto">
        {/* /* --------------------------------- YouTube -------------------------------- */}
        <section className="xl:px-20 mb-8 sm:mb-16">
          <div className="relative overflow-hidden flex flex-wrap">
            <div className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[200px] md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2 right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute pt-6 pb-8">
              <div className="leading-[50px] w-full text-[30px] sm:text-[50px] md:text-[30px] lg:text-[50px] px-8 text-white text-center font-gotcha">
                Conflict, Calamity and Hunger.
              </div>
              <p className="text-[16px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center">
                Over the last year, our ummah has experienced suffering on an unprecedented scale. With Ramadan around the corner, we’re gearing up to
                ensure we reach as many people as possible so that no one gets left behind. Will you join us? Give.
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <YoutubeVideoHero src={`https://www.youtube.com/embed/QTNPDosRHi4?controls=0&autoplay=1&loop=1&mute=1&playlist=QTNPDosRHi4`} />
          </div>
        </section>
        {/* /* ------------------------------- MATW In Action ------------------------------- */}
        <section className="px-4 mb-4 md:mb-8">
          <div className=" flex justify-center">
            <h1 className="hidden md:block text-left sm:text-center font-medium text-[#253B7E] text-2xl sm:text-[40px] w-full sm:w-8/12">
              MATW in action this<span className="text-[#F60362]"> Ramadan 2024</span>
            </h1>
            <h1 className="block md:hidden text-left sm:text-center font-medium text-[#253B7E] text-2xl sm:text-[40px] w-full sm:w-8/12">
              MATW in action this
              <br />
              <span className="text-[#F60362]"> Ramadan 2024</span>
            </h1>
          </div>
          <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-brandingMedium space-y-4">
            <p>Ramadan at MATW is a time when we look to take our impact even further by putting your donations to action.</p>
            <p>
              Last Ramadan, our donors helped us deliver an impact of 7,147,494 with their Zakat and their Sadaqah. This Ramadan, we plan to go even
              further Insha'Allah. Our delivery will reach our brothers and sisters in more than 30 countries around the world. From conflict zones in
              Palestine to poverty-stricken communities in Pakistan, our team will be there ensuring your Ramadan donations are put to action for your
              sake, for the sake of our brothers and sisters and for the sake of Allah SWT.
            </p>
            <p>This is our opportunity to attain Jannah, our chance to invest in our Akhirah whilst ensuring we help those in need along the way.</p>
            <p>
              You don’t have to wait for Ramadan to get into the spirit of the holy month. If Ali Banat's legacy has taught us anything, it's that
              time is precious and every second counts. Donate Now.
            </p>
          </div>
        </section>
        {/* /* ------------------------------- Fidya Kaffarah --------------------------- */}
        <section className="mb-4 md:mb-8 px-4 md:px-0">
          <p className="text-left sm:text-center font-medium text-[#253B7E] text-xl sm:text-2xl md:text-[40px] w-full mx-auto mb-4 md:mb-12">
            Start now by paying your Fidya & Kaffarah
          </p>
          <div className="flex flex-col md:flex-row gap-12 md:gap-0">
            <div className="basis-full md:basis-1/2 md:px-4">
              <div>
                <img src={fidyaImg} alt="fidya" className="w-full mb-2 md:mb-4" />
                <p className="text-left font-medium text-[#253B7E] text-xl sm:text-[25px] mb-2 md:mb-4 w-full">What is Fidya?</p>
                <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                  Fidya is a donation that must be paid to feed the poor for unintentionally missing a fast in Ramadan, and not being able to make it
                  up on time (sickness, inability to fast, pregnancy etc).
                  <br />
                  <br />
                  Therefore Fidya is <strong className="text-[#253B7E] mx-1">
                    {ShowCurrencies(selectedCurrency, ConvertAUD(selectedCurrency, 10, false), true)} per missed fast
                  </strong>
                  (feeding 1 poor person two meals per day).
                </p>
              </div>
              <div className="flex justify-center sm:block">
                <CustomButton
                  onClick={() => navigate('/ramadan/fidya-kaffarah', { state: { productName: 'Fidya' } })}
                  title='Calculate your Fidya' className='mt-6 min-w-[145px]' />
                {/* <button
                  onClick={() => navigate('/ramadan/fidya-kaffarah', { state: { productName: 'Fidya' } })}
                  className="min-w-[145px] sm:min-w-[155px] md:min-w-[185px] text-white text-base font-brandingMedium uppercase flex rounded-md transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] cursor-pointer px-5 py-2 justify-center items-center mt-6"
                >
                  Calculate your Fidya
                </button> */}
              </div>
            </div>
            <div className="basis-full md:basis-1/2 md:px-4">
              <img src={kaffarahImg} alt="kaffarah" className="w-full mb-2 md:mb-4" />
              <h2 className="text-left font-medium text-[#253B7E] text-xl sm:text-[25px] mb-2 md:mb-4 w-full">What is Kaffarah?</h2>
              <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                Kaffarah is a donation that must be paid to feed the poor for an intentionally broken fast. This resembles a broken promise to Allah
                and must be atoned for by
                <strong className="text-[#253B7E] mx-1">feeding 60 people</strong> at a rate of
                {/* <strong className="text-[#253B7E] mx-1">{ShowCurrencies(selectedCurrency, product[currency.toLowerCase()], false)}$10 per person per missed fast</strong> which amounts to */}
                <strong className="text-[#253B7E] mx-1">
                  {ShowCurrencies(selectedCurrency, ConvertAUD(selectedCurrency, 10, false), true)} per person per missed fast
                </strong>{' '}
                which amounts to
                <strong className="text-[#253B7E] mx-1">
                  {ShowCurrencies(selectedCurrency, ConvertAUD(selectedCurrency, 600, false), true)}
                </strong>{' '}
                for each intentionally broken fast.
                <br />
                <br />
                Kaffarah can only be paid if you cannot fast continuously for
                <strong className="text-[#253B7E] mx-1">60 days</strong> which is the first option to atone for an intentionally broken fast.
              </p>

              <div className="mt-3">
                {/* <p className="text-[#78716C] font-brandingMedium text-[18px]">
                  Kaffarah is calculated at feeding 60 people per missed fast at $10 per person, totaling $600 per missed fast. Please add x1 Kaffarah
                  for every intentionally broken fast.
                </p> */}
                <div className="flex justify-center sm:block">
                  <CustomButton
                    onClick={() => navigate('/ramadan/fidya-kaffarah')}
                    title='Calculate Your Kaffarah' className='mt-6 min-w-[155px]' />
                  {/* <button
                    onClick={() => navigate('/ramadan/fidya-kaffarah')}
                    className="min-w-[145px] sm:min-w-[155px] md:min-w-[185px] text-white text-base font-brandingMedium uppercase flex rounded-md transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] cursor-pointer px-5 py-2 justify-center items-center mt-6"
                  >
                    Calculate Your Kaffarah
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* /* ------------------------------- How MATW has put your donations to action ( Mobile only) ------------------------------- */}
        <section className="px-4 mt-6 mb-8 sm:hidden- hidden">
          <div className=" flex justify-center">
            <h1 className="text-left sm:text-center font-medium text-[#253B7E] text-2xl sm:text-6xl  w-full sm:w-8/12">
              How MATW Has Put Your
              <br />
              <span className="text-[#F60362]">Donations</span> To Action
            </h1>
          </div>
          <div className=" md:px-4 mt-4 md:mt-8 leading-5">
            <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] ">
              During Ramadan 2023, Muslims Around The World Project (MATW) continued its life-saving work in more than 20 countries around the world.
              Our team worked tirelessly to ensure your donations reached those most in need.
              <br />
              <br />
              Every single dollar, pound and euro went to our projects through our 100% donation policy commitment to our donors.
              <br />
              <br />
              To us, your donation is more than just a transaction - it’s a fulfillment of your obligation to Allah SWT (God) and an Amanah - a
              trusted responsibility that we take very seriously which is why we work hard to ensure we maximize every amount that has been donated.
            </p>
          </div>
        </section>
      </div>
      {/* /* ------------------------------- Tabs ------------------------------------- */}
      <div className="mt-4">
        <SadaqahJariyahTabs sadaqahProducts={sadaqahProducts} currency={selectedCurrency} />
      </div>
      {/* /* ----------------------------------------------------------------------- */}
      <section className="w-full bg-[#F1F1F1] ">
        <div className=" md:container md:mx-auto">
          <section className="px-4 xl:px-20 py-10">
            <div className="flex flex-wrap sm:flex-nowrap mt-8  justify-center gap-x-0 sm:gap-x-8 gap-y-8 ">
              <div
                className={`basis-full sm:basis-[500px] sm:h-[360px] mr-4 md:mr-0 sm:bg-[url('../src/images/ramadan/zakatImg.png')] bg-center bg-cover rounded-md`}
              >
                <img className="sm:hidden" src={zakatImg} alt="img3" />
              </div>
              <div className="basis-full sm:basis-[500px] flex flex-col  justify-center">
                <div className="text-[16px] sm:text-2xl text-[#253A7E] font-bold leading-[18px] sm:leading-7">
                  Your Zakat Changes Lives Every Day, but Transcends in Ramadan.
                </div>
                <p className="text-[14px] text-[#78716C] sm:text-base leading-4 font-brandingMedium my-3 sm:my-6 text-left  sm:pr-12 pr-0">
                  Those of us who are eligible to pay Zakat Al Mal would generally plan to fulfill this beautiful obligation in the month of Ramadan.
                  Why? Your Zakat Al Mal redeems hundreds of times in rewards with Allah SWT as He multiplies this already rich recompense alongside
                  every sincere act of worship in this Holy month.
                  <br />
                  <br />
                  Prepare to fulfill one of the Five Pillars of Islam this Ramadan. Calculate and prepare to give your Zakat Al Mal today.
                </p>
                <div className="flex gap-3 items-center">
                  <CustomButton
                    onClick={() => navigate('/zakat')}
                    title='Give Zakat Al Mal' className='' />
                  {/* <button
                    onClick={() => navigate('/zakat')}
                    className="bg-[#F60362] hover:bg-[#00a3da] px-4 md:px-8 py-2 text-white rounded-lg text-[14px] md:text-base text-center uppercase font-brandingMedium"
                  >
                    Give Zakat Al Mal
                  </button> */}
                  <CustomButton
                    onClick={() => navigate('/zakat-calculator')}
                    title='Calculate My Zakat' className='' />
                  {/* <button
                    onClick={() => navigate('/zakat-calculator')}
                    className="bg-[#F60362] hover:bg-[#00a3da] px-4 md:px-8 py-2 text-white rounded-lg text-[14px] md:text-base text-center uppercase font-brandingMedium"
                  >
                    Calculate My Zakat
                  </button> */}
                </div>
              </div>
            </div>

            <div className="flex flex-wrap sm:flex-nowrap mt-8 justify-center items-center gap-x-0 sm:gap-x-8 gap-y-8">
              <div className="basis-full sm:w-[500px] order-2 xl:order-1 flex flex-col  justify-start sm:items-end">
                <div className="text-[16px] sm:text-2xl text-[#253A7E] font-bold leading-[18px] sm:leading-7">Volunteer With Us This Ramadan </div>
                <p className="text-[14px] sm:text-base text-[#78716C] leading-4 font-brandingMedium my-3 sm:my-6 text-left sm:text-right sm:pl-12">
                  Donating your time and skills is just as rewarding as any other donation - especially during the month of Ramadan. Reap the rewards
                  and make an impact by becoming part of our MATW family anywhere in the world!
                </p>
                <div className="flex justify-center sm:block mt-5 sm:mt-0">
                  <CustomButton
                    onClick={() => navigate('/Volunteer')}
                    title='sign up' className='!bg-[#093686] w-[220px]' />
                  {/* <button
                    onClick={() => navigate('/Volunteer')}
                    className="bg-[#093686] px-8 sm:px-16 py-2 text-white rounded-lg text-base text-center uppercase font-brandingMedium w-[220px] sm:w-auto"
                  >
                    sign up
                  </button> */}
                </div>
              </div>
              <div className={`basis-full sm:w-[500px] sm:h-[360px] order-1 xl:order-2 mr-4 md:mr-0 sm:bg-[url('../src/images/ramadan/volunteer-with-us-this-ramadan.jpg')] bg-center bg-cover rounded-md`}>
                <img className="sm:hidden" src={volunteerImg} alt="volunteer" />
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap mt-8  justify-center gap-x-0 sm:gap-x-8 gap-y-8">
              <div className={`basis-full sm:basis-[500px] sm:h-[360px] mr-4 md:mr-0 sm:bg-[url('../src/images/ramadan/fundraiser.jpg')] bg-center bg-cover rounded-md`}>
                <img className="sm:hidden" src={fundraiserImg} alt="img3" />
              </div>
              <div className="basis-full sm:basis-[500px] flex flex-col  justify-center">
                <div className="text-[16px] sm:text-2xl text-[#253A7E] font-bold leading-[18px] sm:leading-7">Fundraise For A Cause This Ramadan</div>
                <p className="text-[14px] sm:text-base text-[#78716C] leading-4 font-brandingMedium my-3 sm:my-6 text-left  sm:pr-12 pr-0">
                  The most powerful tool you have is right in your hands! Tap into your online community and your network of friends and loved ones.
                  Rally together for a cause, especially during the month of Ramadan and make a difference for those in need by raising funds for a
                  project dear to you!
                </p>
                <div className="flex justify-center sm:block mt-5 sm:mt-0">
                  <CustomButton
                    onClick={() => fundraise()}
                    title='Register To Fundraise' className='' />
                  {/* <button
                    onClick={() => fundraise()}
                    className="bg-[#00A2D9] px-8 py-2 text-white rounded-lg text-base text-center uppercase font-brandingMedium"
                  >
                    Register To Fundraise
                  </button> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section>
        <img src={diyRamadanPhoto} alt="diy ramadan" className="h-[160px]- md:h-[450px]- aspect-video- w-full" />
      </section>
      {/* /* ------------------------------ Sadaqah Jars ------------------------------ */}
      <section className='w-full md:container md:mx-auto px-3 md:px-0'>
        <div className="flex flex-col lg:flex-row justify-between gap-8 items-center mt-4 md:mt-8">
          <p className="text-[#78716C] mt-6 leading-7 md:text-[18px] font-BrandingMedium space-y-4">
            Get Ramadan ready! Download your own DIY decor kit and get crafty with your little ones. Make these lantern decorations and brighten up
            your home for Ramadan this year.
          </p>
          <CustomButton link='../pdf/Lantern Art Steps - MATW Project.pdf' title='download here' target="_blank" className='min-w-[200px]' />
        </div>
        <div className="flex flex-col lg:flex-row justify-start items-start gap-12 mt-12">
          <img src={sadaqahJarsPhoto} alt="sadaqah jars" className="" />
          <div>
            <p className="text-[#253B7E] mb-3 text-[30px] sm:text-[48px] font-brandingBold">
              Sadaqah
              <span className="ml-2 text-[#F60362]">Jars</span>
            </p>
            <div className="text-[#78716C] mt-6 leading-7 md:text-[18px] font-BrandingMedium space-y-4">
              <p className="mb-6">
                This Ramadan, encourage your children to collect Ramadan daily donations and earn the rewards of Sadaqah. Teach them young but make
                it fun. Download this label to print at home. First, pledge to donate to MATW Project - Use an old mason jar at home or buy a new
                one for this activity.
              </p>
              <ul className="list-disc pl-8">
                <li>Make a slit on the top lid to insert donations.</li>
                <li>Stick the label on the jar.</li>
                <li>⁠Encourage children to ask friends and family for donations at Ramadan gatherings this year.</li>
                <li>
                  ⁠In the last 10 nights of Ramadan, ask your children to count the donations they collected, and you can donate that amount or more
                  to MATW ensuring it reaches those in need.
                </li>
              </ul>
            </div>
            <div className="flex justify-center mt-5 sm:block">
              <CustomButton link='../pdf/Sadaqah Jar Stickers - MATW Project.pdf' title='download sticker' target="_blank" className='w-[220px] !bg-[#253B7E]' />
              {/* <Link
                className="bg-[#253B7E] px-5 py-2 text-white rounded-lg text-base text-center uppercase text-nowrap font-brandingMedium min-w-[220px] sm:min-w-fit"
                to={`../pdf/Sadaqah Jar Stickers - MATW Project.pdf`}
                target="_blank"
              >
                download sticker
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------------------FAQS--------------------------------- */}
      <section className="px-4 xl:px-20 mt-12 md:mt-16 bg-white">
        <div className=" md:container md:mx-auto">
          <h1 className="text-[#253B7E] md:text-center mb-8 text-[30px] sm:text-[48px] font-bold">
            Ramadan donations
            <span className="block md:inline-block ml-2 text-[#F60362]">FAQ’s</span>
          </h1>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8">
            <div className="col-span-3 sm:col-span-1">
              <p className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{ramadanFaqs[0].question}</p>
              <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{ramadanFaqs[0].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <p className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{ramadanFaqs[1].question}</p>
              <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{ramadanFaqs[1].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <p className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{ramadanFaqs[2].question}</p>
              <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{ramadanFaqs[2].answer}</p>
            </div>
          </div>
          <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
            {ramadanFaqs.map((item, index) => {
              return (
                index > 2 && (
                  <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                    <Accordion
                      key={`keyallfaq${index}`}
                      shadow
                      title={item.question}
                      index={index}
                      level={1}
                      noBorder={true}
                      className="!text-[#253B7E] text-lg font-brandingBold"
                    >
                      {item.answer}
                    </Accordion>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RamadanLandingPage;