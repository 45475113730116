import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes from './routes';
import Sidebar from './sidebar';
import DashboardNavbar from './components/navbar';

const DashboardLayout = props => {
  /* ----------------------------- Start variables ---------------------------- */
  // const { children, propertyName, className } = props;
  const { children, ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [isMinimize, setIsMinimize] = useState(true);
  const [currentRoute, setCurrentRoute] = useState('Main Dashboard');
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getActiveNavbar = routes => {
    // console.log("getActiveNavbar", routes)
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      // console.log("getActiveNavbar=", routes[i].layout + routes[i].path)
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        // console.log("in ifgetActiveNavbar=", routes[i].secondary)
        return routes[i].secondary;
      }
    }
    // console.log("getActiveNavbar", activeNavbar)
    return activeNavbar;
  };
  const getActiveRoute = routes => {
    let activeRoute = 'Main Dashboard';
    const route = routes.find(i => i.path === window.location.pathname).name ?? activeRoute;
    setCurrentRoute(route);

    return activeRoute;
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    window.addEventListener('resize', () => (window.innerWidth < 1200 ? setOpen(false) : setOpen(true)));
  }, []);
  useEffect(() => {
    getActiveRoute(routes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} onMinimize={() => setIsMinimize(!isMinimize)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary">
        {/* Main Content */}
        <main
          className={`mx-[12px]- h-full flex-none transition-all
          ${isMinimize ? 'xl:ml-[50px]' : 'xl:ml-[313px]'}
          `}
        >
          {/* Routes */}
          <div className="h-full px-3 lg:px-6">
            <DashboardNavbar
              onOpenSidenav={() => setOpen(true)}
              logoText={'MATW Project'}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default DashboardLayout;
