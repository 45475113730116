import { useEffect } from 'react';
import Layout from '../../components/general/layout';
import { Link } from 'react-router-dom';
import VIPWidget from './vipWidget';

const VIPPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const carouselItems = [
        {
            id: 1,
            title: 'We recruit our own chefs and staff',
            image: 'carousel1.jpg',
            link: 'https://www.instagram.com/matw_project/'
        },
        {
            id: 2,
            title: 'We manage the food material procurement',
            image: 'carousel2.jpg',
            link: 'https://www.instagram.com/matw_project/'
        },
        {
            id: 3,
            title: 'We manage our own kitchens',
            image: 'carousel3.jpg',
            link: 'https://www.instagram.com/matw_project/'
        },
        {
            id: 4,
            title: 'We maintain the highest food quality standards',
            image: 'carousel4.jpg',
            link: 'https://www.instagram.com/matw_project/'
        }
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        window.scroll(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='!bg-white'>
            <div className="w-full flex flex-wrap bg-[url('../public/images/landings/vip/vip-banner-mobile.jpg')] sm:bg-[url('../public/images/landings/vip/vip-banner.jpg')] bg-center sm:bg-center bg-cover md:py-8 max-[350px]:min-h-[80px] max-[500px]:min-h-[100px] min-[501px]:min-h-[130px] sm:min-h-[140px] md:min-h-[170px] lg:min-h-[200px] xl:min-h-[270px] 2xl:min-h-[340px] px-4 md:px-0"></div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ---------------------- Section One - Percentage -------------------------- */}
            <div className="w-full flex flex-wrap md:container md:mx-auto px-1 sm:px-4 md:px-0 bg-[#E2EFF3] mt-4 md:mt-20 lg:mt-28 rounded-lg">
                <VIPWidget />
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------ Section Two - Follow us  ------------------------ */}
            <div className="w-full flex flex-wrap md:container mt-8 md:mt-16 md:mx-auto py-4 md:py-8 relative">
                <h1 className="text-xl text-center w-full font-medium md:text-4xl text-[#253B7E] mb-4 md:mb-6 mt-4 md:mt-0 uppercase">
                    Follow us on instagram
                </h1>
                <div className="relative w-full flex flex-wrap py-4 md:py-8 px-4 md:px-0">
                    {carouselItems.map((item, index) => {
                        return <div key={`carousel${index}`} className={`bg-white rounded-lg flex flex-col py-2 lg:py-4 basis-1/2 md:basis-1/4 px-2`}>
                            <Link to={item.link}>
                                <img
                                    className={`w-full h-full- object-center cursor-pointer object-cover rounded-md`}
                                    alt={item}
                                    src={`/images/landings/vip/${item.image}`}
                                />
                            </Link>
                        </div>
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

        </Layout>
    );
}

export default VIPPage;
