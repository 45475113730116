const StepHeader = (props) => {
    const {
        // title,
        children } = props;
    return (
        <div className="w-full px-2 md:px-0">
            <div className="border-b- border-stone-200- flex justify-between items-center">
                {/* <div className="flex"> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6 text-stone-400">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg> */}
                    {/* <h3 className="text-stone-400 text-md font-normal pb-2 hidden">
                        {title}
                    </h3> */}
                {/* </div> */}
                {children}
            </div>
        </div>
    );
}

export default StepHeader;
