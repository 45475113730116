import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import services from '../../services';

const useDashboardLoginHook = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  });
  const [otp, setOTP] = useState("");
  const [step, setStep] = useState(0);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const login = async () => {
    const { email } = userInfo;
    setLoading(true);
    const loginResponse = await services.login(email);
    if (loginResponse.status === 200) {
      localStorage.setItem("email", email);
      navigate('/donor/verify-otp');
    } else toast.error(loginResponse.message);
    setLoading(false);
  };

  const verifyOTP = async () => {
    setLoading(true);
    const email = localStorage.getItem("email");
    const verifyOTPResponse = await services.verifyOTP(email, otp);
    if (verifyOTPResponse.status === 200) {
      localStorage.setItem('token', verifyOTPResponse.data.token);
      navigate('/donor');
    } else toast.error(verifyOTPResponse.message);
    setLoading(false);
  }

  const sendPassword = async () => {
    const { email } = userInfo;
    const sendPasswordResponse = await services.sendPassword(email);

    if (sendPasswordResponse.success) {
      setStep(1);
      toast.success(sendPasswordResponse.message);
    } else toast.error(sendPasswordResponse.message);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */

  return { setUserInfo, loading, sendPassword, step, userInfo, login, setOTP, verifyOTP };
};

export default useDashboardLoginHook;
