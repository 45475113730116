import React from 'react';
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Swal from "sweetalert2";
import CustomButton from '../../../../components/general/button';

const MenuAccordionLayout = ({
    title,
    children,
    index,
    activeIndex,
    level,
    order,
    noBorder,
    setActiveIndex,
    className,
    isCategory,
    lang = 'en',
    handleAdd,
    handleEdit,
    handleDelete
}) => {
    const handleSetIndex = (index) => {
        (activeIndex !== index) ? setActiveIndex(index) : setActiveIndex(-1);
    }
    return (
        <>
            <div onClick={() => handleSetIndex(index)} className={`flex w-full justify-between rounded ${level === 1 ? 'p-2 mt-2' : 'px-4 py-2'} cursor-pointer ${noBorder ? '' : 'border-b border-stone-200'}`}>
                <div className='flex'>
                    <div className={`text-xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} ${activeIndex === index ? 'text-[#00a3da]' : 'text-[#777]'} ${className && className}`}>
                        {noBorder ? '' : activeIndex === index ? '-' : '+'} {noBorder ? '' : `${index + 1}.`} {title}
                        {!isCategory && <div className="flex items-center text-stone-300">
                            <span className={`text-xs ${activeIndex === index ? 'text-[#23408f]' : 'text-stone-400'} mr-1`}>
                                Level {level}
                            </span> |
                            <span className={`text-xs ${activeIndex === index ? 'text-[#23408f]' : 'text-stone-400'} ml-1`}>
                                Order {order}
                            </span>
                        </div>}
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <ChevronDownIcon className={`w-4 h-4 text-stone-600 transition-all duration-300 ${activeIndex === index ? ' transform rotate-180' : ''}`} />
                </div>
            </div>
            <div className={`shadow-3xl w-full rounded-md shadow-cyan-500/50 bg-stone-50 transition-all duration-200 ease-in-out px-3 sm:px-6 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} ${activeIndex === index ? ' h-auto py-4 mb-6' : 'h-0 overflow-hidden'}`}>
                <div className="flex justify-between mb-2 border-b border-stone-300 pb-2 items-center">
                    <h3>{title} {isCategory ? 'SubCategories' :'SubMenus'}</h3>
                    <div className="flex">
                        <CustomButton
                            type='button'
                            onClick={handleAdd}
                            title={isCategory ? 'Add SubCategory' : 'Add SubMenu'}
                            className='!h-[42px] mr-2 md:w-[calc(100%_-_72px)] !bg-[#00A3DA]'
                        />
                        <button
                            type='button'
                            onClick={handleEdit}
                            className={`bg-orange-500 text-white h-[42px] w-10 min-w-[40px] flex items-center justify-center rounded mr-2 transition-all duration-200`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </button>
                        <button
                            type='button'
                            onClick={async () => {
                                await Swal.fire({
                                    title: "Are you sure?",
                                    text: "You will not be able to recover this ITEM!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    cancelButtonText: 'No, cancel it!',
                                    confirmButtonText: 'Yes, I am sure!',
                                    dangerMode: true,
                                }).then(function (isConfirm) {
                                    if (isConfirm.isConfirmed) {
                                        handleDelete()
                                    } else {
                                        console.log("cansel", isConfirm)
                                        new Swal("Cancelled", "This Item wasn’t removed", "error");
                                    }
                                })
                            }}
                            className={`bg-[#F60362] text-white w-10 h-[42px] min-w-[40px] rounded hidden md:flex justify-center items-center`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                        </button>
                    </div>
                </div>
                {children}
            </div>
        </>
    );
};

export default MenuAccordionLayout;