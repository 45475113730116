import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import TabContent from '../../home/components/tab-content';

const PalestineRefugeeTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { supportRefugeeSettlementProducts, sponsorFamilyProducts, lang = 'en' } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    {
      title: lang === 'fr' ? `Soutenir l'installation de réfugiés` : lang === 'ar' ? 'دعم توطين اللاجئين' : 'Support Refugee Settlement',
      description: lang === 'fr' ? `Notre équipe MATW aide les réfugiés palestiniens à se réinstaller en Égypte. Aidez-les à commencer une nouvelle vie.` : lang === 'ar' ? 'يقوم فريق MATW الخاص بنا بمساعدة اللاجئين الفلسطينيين على إعادة التوطين في مصر. مساعدتهم على بدء حياة جديدة.' : `Our MATW team is helping Palestinian refugees resettle in Egypt. Help them start a new life.`,
      products: supportRefugeeSettlementProducts,
    },
    {
      title: lang === 'fr' ? `Parrainer une famille` : lang === 'ar' ? 'مأوى الأيتام' : 'Sponsor a Family',
      description: lang === 'fr' ? `Aidez-nous à continuer de soutenir les familles de réfugiés palestiniens alors qu’elles commencent à arriver en Égypte pour entamer le prochain chapitre de leur vie et reconstruire.` : lang === 'ar' ? 'ساعدونا على مواصلة دعم عائلات اللاجئين الفلسطينيين عندما يبدأون في الوصول إلى مصر لبدء الفصل التالي من حياتهم وإعادة البناء.' : `Help us continue supporting Palestinian refugee families as they begin to arrive in Egypt to begin the next chapter of their lives and rebuild.`,
      products: sponsorFamilyProducts,
    },
  ];

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => {
                window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 })
                setActiveTabProducts(index);
              }}
              className={`${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} text-[14px] rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'}`}>
              {title}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {tabButtonsProducts.map((item, index) =>
              index === activeTabProducts && <React.Fragment key={`description_${index}`}>
                <div className="flex w-full justify-center mb-6" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
                  <p className={`text-center text-white text-[18px] md:text-3xl md:w-2/3 font-bold ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-["Montserrat"]'}`}>
                    {item.description}
                  </p>
                </div>
                <div ref={contentRef}>
                  <TabContent
                    products={item.products}
                    lang={lang}
                    currency={selectedCurrency}
                    productsOnMobile={item.productsOnMobile ? item.productsOnMobile : null}
                    showDots={item.productsOnMobile ? false : true}
                    // showArrows={item.productsOnMobile ? false : true}
                    showArrows={true}
                    isPalestineRefugee
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default PalestineRefugeeTabs;