import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import HomePageTabContent from './tab-content';

const HomePageTabs = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const { urgentAppeals, topAppealsProducts, palestineEmergencyProducts, zakatProducts, waterWellsProducts } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    // {
    //   title: 'ramadan',
    //   products: foodAidProducts,
    //   description: 'More than 828 million people sleep hungry. You can help change that.',
    // },
    {
      title: 'URGENT APPEALS',
      description: '',
      products: urgentAppeals
    },
    {
      title: 'PALESTINE EMERGENCY',
      description: '',
      products: palestineEmergencyProducts
    },
    {
      title: 'top appeals',
      products: topAppealsProducts,
      description: 'Last Ramadan , you helped deliver an impact of 7,147,494. Help us reach more now.',
    },
    {
      title: 'zakat',
      products: zakatProducts,
      description: 'Fulfil your Islamic obligation. Calculate and give your Zakat today.',
    },
    // {
    //   title: 'zakat al-fitr',
    //   products: zakatAlFitrProducts,
    //   description: 'Zakat Al-Fitr (also known as Fitrana or Fitrah) is a charitable donation of the cost of one mealto be given before Eid prayer, before the end of the month of Ramadan.',
    // },
    // {
    //   title: 'orphans',
    //   products: orphansProducts,
    //   description: 'MATW has reached and cared for more than 50,000 orphans worldwide. Help us reach more.',
    //   multiProducts: feedOrphanProducts
    // },
    {
      title: 'WATER WELLS',
      products: waterWellsProducts,
      description: 'Give the ultimate Sadaqah Jariyah by building a water well.',
    },
  ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => {
                window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 })
                setActiveTabProducts(index);
              }}
              className={`${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} text-[14px] md:text-base- rounded-full max-[500px]:col-span-2- p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}>
              {title}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {tabButtonsProducts.map((item, index) =>
              index === activeTabProducts && <React.Fragment key={`description_${index}`}>
                <div className="flex w-full justify-center mb-6">
                  <p className='text-center text-white text-[18px] md:text-3xl md:w-2/3'>
                    {item.description}
                  </p>
                </div>
                <div ref={contentRef}>
                  {/* {index > 0
                    ? <HomePageTabContent
                      showArrows={false}
                      products={item.products}
                      currency={selectedCurrency} />
                    : <>{item.products}</>
                  } */}
                  {item.title === 'URGENT APPEALS'
                    ? <>{item.products}</>
                    : <HomePageTabContent
                      showArrows={false}
                      products={item.products}
                      currency={selectedCurrency} />
                  }
                  {/* <HomePageTabContent
                      showArrows={false}
                      products={item.products}
                      currency={selectedCurrency} /> */}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>

  );
};
export default HomePageTabs;