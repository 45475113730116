import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useMutation from '../../hooks/useMutaion';
import services from '../../services';

export default function ContactForm() {
  const { error, isLoading, mutate, isSuccess } = useMutation(services.postFormContact);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const onSubmit = data => {
    mutate(data);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success('Thank you for contacting us. We will get back to you soon.');
    }
    if (error) {
      toast.error('Something went wrong. Please try again later.');
    }
  }, [isSuccess, error]);

  const options = [{ value: 'General enquiry' }, { value: 'Complaint' }, { value: 'Donation question' }, { value: 'Progress query' }];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="basis-full md:basis-1/2 flex flex-col justify-start">
      <div className="px-4">
        <h2 className="text-white text-xl md:text-2xl lg:text-4xl bg-[#00a3da] py-6 md:py-10 lg:py-14 px-4">Get in touch here</h2>
      </div>
      <div className="flex flex-wrap mt-6">
        <div className="basis-full sm:basis-1/2 px-4 py-2">
          <input
            type="text"
            {...register('name', { required: true })}
            placeholder="Name"
            className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
          />
          {errors.name && <span className="text-sm text-red-600">This field is required</span>}
        </div>
        <div className="basis-full sm:basis-1/2 px-4 py-2">
          <input
            type="text"
            {...register('email', {
              required: {
                value: true,
                message: 'This field is required',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            })}
            placeholder="Email"
            className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
          />
          {errors.email?.message && <span className="text-sm text-red-600">{errors.email.message}</span>}
        </div>
        <div className="basis-full sm:basis-1/2 px-4 py-2">
          <input
            {...register('phone', { required: true })}
            type="number"
            placeholder="Phone"
            className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
          />
          {errors.phone && <span className="text-sm text-red-600">This field is required</span>}
        </div>
        <div className="basis-full sm:basis-1/2 px-4 py-2">
          <select
            {...register('purpose', { required: true })}
            defaultValue={'Please choose an option'}
            className={`h-[48px] w-full rounded border border-stone-300 text-[#777] text-sm focus:ring-[#00a3da] focus:border-[#00a3da] focus-visible:border-[#00a3da] focus-visible:outline-none py-1 px-2`}
          >
            <option value="" disabled>
              Please choose an option
            </option>
            {options.map((item, index) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.value}
                </option>
              );
            })}
          </select>
          {errors.purpose && <span className="text-sm text-red-600">This field is required</span>}
        </div>
        <div className="basis-full px-4 py-2">
          <textarea
            type="text"
            {...register('message', { required: true })}
            cols={40}
            rows={10}
            placeholder="Message"
            className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
          />
          {errors.message && <span className="text-sm text-red-600">This field is required</span>}
        </div>
        <div className="basis-full mb-4 px-4 py-2 flex items-center">
          <button className="bg-[#f60362] h-[48px] px-8 py-2 rounded text-white">{isLoading ? 'loading...' : 'Send Message'}</button>
        </div>
      </div>
    </form>
  );
}
