import React, { useState } from 'react';
import AccordionLayout from './accordionLayout';

const Accordion = (props) => {
    const { title, index, level, children } = props;
    const [activeIndex, setActiveIndex] = useState(-1);
    return (
        <div className='flex flex-col justify-center items-center'>
            <AccordionLayout
                title={title}
                index={index}
                level={level}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
            >
                {children}
            </AccordionLayout>
        </div>
    );
};

export default Accordion;