import React from 'react';

const YoutubeVideoHero = props => {
  const { src, videoStyle, className } = props;
  return (
    <div className={`basis-full min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[890px] ${className ? className : ''} ${videoStyle ? [...videoStyle] : ''}`}>
      <iframe
        className="video pointer-events-none"
        width={'100%'}
        height={'100%'}
        title={'youtube'}
        frameBorder={0}
        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
        src={src ? src : `https://www.youtube.com/embed/QTNPDosRHi4?controls=0&autoplay=1&loop=1&mute=1&playlist=QTNPDosRHi4`}
      ></iframe>
    </div>
  );
};

export default YoutubeVideoHero;
