import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const Accordion = ({ items, className }) => {
  const [expand, setExpand] = useState(Array(items.length).fill(false));

  const toggleExpand = index => {
    setExpand(prevExpand => {
      const newExpand = [...prevExpand];
      newExpand[index] = !newExpand[index];
      return newExpand;
    });
  };
  const RawHTML = ({children, className = ""}) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '')}} />
  return (
    items &&
    items.map((item, index) => (
      <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={index}>
        <div className="flex justify-between gap-8 items-center cursor-pointer" onClick={() => toggleExpand(index)}>
          <h3 className="text-[17px] text-[#253B7E] md:text-lg font-montserratBold">{item.question}</h3>
          <div className="flex items-center justify-center">
            <ChevronDownIcon className={`w-4 h-4 text-stone-600 transition-all duration-300 ${expand[index] ? ' transform rotate-180' : ''}`} />
          </div>
        </div>
        <div
          className={`w-full rounded-md shadow-cyan-500/50 bg-stone-50 transition-all duration-200 ease-in-out px-3 sm:px-6 ${
            expand[index] ? 'h-auto py-4 mt-3' : 'h-0 overflow-hidden'
          }`}
        >
          <p className="text-base font-['MontserratMedium'] text-[#78716C]"><RawHTML>{item.answer}</RawHTML></p>
        </div>
      </div>
    ))
  );
};

export default Accordion;