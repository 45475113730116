import { useEffect, useState } from 'react';
import services from '../../services';
import Layout from '../../components/general/layout';
import Loader from '../../components/general/loader';
import Accordion from './accordion';
import ProductBox from '../../components/general/productBox';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';

const AllProducts = () => {
  const [products, setProducts] = useState();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* ------------------------------ Start methods ----------------------------- */
  const getProducts = async () => {
    try {
      const response = await services.allProducts();
      let tempProducts = response.data.map(item => {
        item.sub_categories.map(inner_item => {
          let tempSubCat = inner_item;
          tempSubCat.products = inner_item.products.filter(inner_item_product => {
            return Number(inner_item_product.status) === 1 && inner_item_product;
          });
          return tempSubCat;
        });
        return item;
      });
      console.log('response get all Products', response);
      setProducts(tempProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore])
  /* -------------------------------------------------------------------------- */
  return (
    <Layout>
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-4 md:px-2 lg:px-0">
          <div className="bg-white col-start-1 col-span-12 rounded-md p-3 md:p-6 shadow-md">
            <div>
              {products ? (
                products.map((item, mainCatIndex) => {
                  return (
                    <Accordion key={`key${mainCatIndex}`} title={item.name} index={mainCatIndex} level={1}>
                      {item.sub_categories?.map((subCat, subCatIndex) => {
                        return (
                          <Accordion key={`keys${subCatIndex}`} title={subCat.name} index={mainCatIndex} level={2}>
                            <div className="flex flex-row justify-center flex-wrap">
                              {subCat.products?.map((prod, prodIndex) => {
                                return prod.name !== 'Pakistan Solar Powered Well' && (<div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={`prod${prodIndex}`}>
                                  <ProductBox product={prod} currency={selectedCurrency} />
                                </div>)
                              })}
                            </div>
                          </Accordion>
                        );
                      })}
                    </Accordion>
                  );
                })
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllProducts;
