import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { sadaqaFaqs } from './faqs';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import ProductBox from '../../components/general/productBox';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import ZakatWidget from '../zakat/zakatWidget';
import { useKeenSlider } from 'keen-slider/react'
import Invest from '../../images/sadaqah/investinsadaqah.jpg';
import EndHunger from '../../images/sadaqah/endhunger.jpg';
import sadaqah1 from '../../images/sadaqah/sadqah1.jpg'
import sadaqah2 from '../../images/sadaqah/sadqah2.jpg'
import sadaqah3 from '../../images/sadaqah/sadqah3.jpg'
import sadaqah4 from '../../images/sadaqah/sadqah4.jpg'
import sadaqah5 from '../../images/sadaqah/sadqah5.jpg'
// import banner from '../../images/sadaqah/sadaqah-hero-banner.jpg';
// import bannerMobile from '../../images/sadaqah/sadaqah-hero-banner-mobile.jpg';
import banner from '../../images/sadaqah/Sadaqajariyah-Generic-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/sadaqah/Sadaqajariyah-Generic-Hero-Banner-Mobile.jpg';
import { selectProducts } from '../../store/products';
import { OurPromiseSection } from '../../components/general';
import { Helmet } from 'react-helmet';
const images = [
    sadaqah1,
    sadaqah2,
    sadaqah3,
    sadaqah4,
    sadaqah5,
];

const Sadaqah = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => selectProducts(state));
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [sadaqahProducts, setSadaqahProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [isLoaded, setIsLoaded] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        loop: true,
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    },
        [
            slider => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 2000);
                }
                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on('dragStarted', clearNextTimeout);
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]

    )
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */

    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];

        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'HLT-DIS-ERI-2023-01-062') {
                        inner_item_product = Object.assign({}, inner_item_product, {
                            alt: 'Sadaqah eye restoration',
                          })
                        tempProductsList[0] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') {
                        inner_item_product = Object.assign({}, inner_item_product, {
                            alt: 'Give Sadaqah online for small masjid',
                          })
                        tempProductsList[1] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') {
                        inner_item_product = Object.assign({}, inner_item_product, {
                            alt: 'Sadaqah Donation for Africa basic well',
                          })
                        tempProductsList[2] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'ORP-CON-ORH-2023-01-043') {
                        tempProductsList[3] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'EM-SDQ-EM-02-2023') {
                        tempProductsList[4] = inner_item_product;
                    }
                    return inner_item_product
                })
                return inner_item
            })
            return item;
        })
        tempProductsList = tempProductsList.filter(n => n)
        setSadaqahProducts(tempProductsList);
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {

        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['sadaqahJariyah']['sadaqah']['title']}`}</title>
                    <meta name="title" content={`${seo['sadaqahJariyah']['sadaqah']['title']}`} />
                    {/* <meta name="description" content={`${seo['sadaqahJariyah']['sadaqahJariyah']['description']}`} /> */}
                </Helmet>
            }
            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                <img src={banner} alt="Give Sadaqah Online" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="Sadaqah Donations Online with MATW Project" className='min-[501px]:hidden w-full' />
            </section>
            <section className='bg-[#DFEEF2]'>
                <section className='max-w-[1440px] mx-auto'>
                    <h3 className='text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Sadaqah Donation</h3>
                    <div className='w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <CrisisWidget className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                            defaultProduct={{
                                creator: "MKD-GEN-MSD-2023-01-121",
                                label: "Sadaqah Jariyah",
                                value: 106
                            }}
                        />
                    </div>
                    <div className='w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center'>
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                            creator: "MKD-GEN-MSD-2023-01-121",
                            label: "Sadaqah Jariyah",
                            value: 106
                        }} />
                    </div>
                </section>
            </section>
            <OurPromiseSection />
            <main className='max-w-[1440px] mx-auto'>
                <section className='xl:px-20 mb-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px]  w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-brandingBold'>The Prophet PBUH said:</div>
                            <p className='text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center'>
                                “Sadaqah extinguishes sin as water extinguishes fire.”
                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1020px]">
                            <iframe className='video pointer-events-none'
                                width={'100%'}
                                height={'100%'}
                                title={'youtube'}
                                frameBorder={0}
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://www.youtube.com/embed/AlpQ2PCe0cw ?controls=0&autoplay=1&loop=1&mute=1&playlist=AlpQ2PCe0cw `}
                            >
                            </iframe>
                        </div>

                    </div>
                </section>
                <section className='xl:px-10'>
                    <div className='flex justify-center'>
                        <h2 className='text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12'>
                        Give Sadaqah online and shield yourself with your sadaqah donation whilst <span className='text-[#F60362] mr-2'>
                                changing the lives</span> of those who need it most.
                        </h2>
                    </div>
                    <div className='px-4 md:px-6 mt-2 sm:mt-10  font- leading-5'>
                        <div className='text-[#78716C] lg:px-20 leading-6 text-[18px] text-left  font-brandingMedium'>
                            People all over the world are facing times of hardship. In Islam, it is emphasised in both the Quran and Sunnah that these hardships
                            are indeed tests from Allah but that Sadaqah can help change our situation, by Allah’s Mercy.
                            <br /><br />
                            Allah (SWT) promised many benefits to the person who gives Sadaqah on a regular basis whether it be to their parents, orphans, widows, the
                            sick or any other person in need. The act of giving Sadaqah on a regular basis allows you to enter through Baab As Sadaqah - the gate of Sadaqah in Jannah.
                            <br /><br />
                            Use your Sadaqah to help save and transform lives with us at MATW today.
                        </div>
                        {/* <div className='flex justify-center mt-8 '>
                            <button onClick={() => navigate('/zakat-calculator')} className='bg-[#F60362] text-sm sm:text-lg rounded-full w-[455px] text-white h-[50px]'>
                                Calculate and give your Zakat Al Mal here
                            </button>
                        </div> */}

                        <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 mb-6 sm:mb-10">
                            {sadaqahProducts && sadaqahProducts.map((item, index) => {
                                return (
                                    <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                        <ProductBox product={item} isStaticProduct={item.isStaticProduct || false} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </section>



                <section className='px-4 xl:px-20  py-8'>

                    <div className='sm:mb-5'>
                        <div className='flex justify-center pt-4'>
                            <h1 className='text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl  w-full sm:w-10/12'>
                                Give Sadaqah with our 100% Donation Policy
                            </h1>
                        </div>
                        <div className=' md:px-28 text-[18px] mt-8 leading-5'>
                            <p className='text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] '>
                                A blessing during times of hardship and protection during times of need, your Sadaqah has the power to
                                better your life, whilst changing the lives of others. At MATW, every donation is an Amanah. We use your Sadaqah to help those most in need.
                                <br />
                                <br />
                                Your Sadaqah can provide immediate support and transform and uplift entire communities. With your
                                Sadaqah, we can deliver
                                urgent relief items such as food, emergency water, crucial medical care and so much more - especially
                                for those struggling in war-torn countries like our brothers and
                                sisters in Yemen, Afghanistan, Syria and Palestine.
                                <br />
                                <br />
                                We also use your Sadaqah to help people in impoverished communities
                                break free from the cycle of poverty through livelihoods projects.
                            </p>
                        </div>
                    </div>



                </section>

                <section className="h-[350px] sm:h-[450px] md:h-[500px] flex justify-center md:pl-[20px] bg-cover bg-center bg-no-repeat bg-[url('../src/images/sadaqah/large-sadaqah-inner-page-hero-banner-mobile.jpg')] sm:bg-[url('../src/images/sadaqah/large-sadaqah-inner-page-hero-banner-desktop.jpg')]" >
                    <div className='mx-auto w-full relative overflow-hidden'>
                        {/* <img src={Banner} alt="banner" className='hidden min-[380px]:block' />
                        <img src={BannerMobile} alt="banner-mobile" className='min-[380px]:hidden' /> */}
                        <div className='absolute  w-full  top-1/2 -translate-y-1/2' >
                            <div className='md:container mx-auto xl:px-20'>
                                <h3 className='text-white text-center md:text-left text-[30px] min-[380px]:leading-[30px] sm:text-[60px] md:text-[60px] sm:leading-[60px] uppercase font-brandingMedium'>
                                    The <span className='font-bold text-white'> Possibilities</span> <br />
                                    with your <span className='font-bold '> Sadaqah</span><br />
                                    are <span className='font-bold text-[#F60362]'> <i>Limitless</i></span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section >

                </section> */}


                <section className='xl:px-20  py-8'>

                    <div >
                        <div className='flex justify-center pt-4'>
                            <div className='text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12'>
                                The difference between Sadaqah and
                                Sadaqah Jariyah.
                            </div>
                        </div>
                        <div className='px-4 md:px-28 text-[18px] mt-8 leading-5'>
                            <p className='text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] '>
                                An important concept within Islam, for us as Muslims is the idea of Sadaqah Jariyah – an ‘ongoing charity’.
                                Sadaqah Jariyah is an investment in an ongoing charity that continues to reap rewards
                                even after the giver has passed away. This can also be given on behalf of a loved one, even if
                                they too have passed.


                            </p>
                        </div>
                    </div>



                </section>

                <section className='px-4 xl:px-20 mt-10 bg-white'>

                    <div className='flex gap-x-10 flex-wrap sm:flex-nowrap'>
                        <div className="basis-full sm:basis-1/2"><img src={Invest} alt="Invest" /></div>
                        <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                            <p className='text-[#253B7E] text-[24px] sm:text-[45px] leading-[55px] font-brandingBold'>Invest in your Akhirah with Sadaqah Jariyah.</p>


                            <div className='text-[16px] sm:text-[18px] text-[#78716C] leading[19px] sm:leading-6 font-brandingMedium my-4'>
                                Sadaqah Jariyah is a charity that continues to earn the  rewards, even after the giver has passed away. When you give Sadaqah Jariyah,
                                it will support a project of lasting benefit both for the communities and for the giver.
                                <br />
                                <br />
                                Ali Banat’s legacy started with three Sadaqah Jariyah projects, and thus MATW was born.
                                <br />
                                <br />
                                What will YOUR Sadaqah Jariyah and legacy be?
                            </div>
                            <button onClick={() => window.scrollTo(0, 500)} className='bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px]  font-bold'>Donate Now</button>
                        </div>
                    </div>
                    <div className='flex gap-x-10 flex-wrap sm:flex-nowrap mt-8'>
                        <div className="basis-full sm:basis-1/2"><img src={EndHunger} alt="EndHunger" /></div>
                        <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                            <p className='text-[#253B7E] text-[24px] sm:text-[45px] leading-[55px] font-brandingBold'>Help us end hunger.</p>


                            <div className='text-[16px] sm:text-[18px] text-[#78716C] leading[19px] sm:leading-6 font-brandingMedium my-4'>
                                As many as 828 million people sleep hungry. You can help us change
                                that. Last Ramadan, you helped us deliver an impact of 7,147,494!
                                <br />
                                <br />
                                Help us reach more now.
                            </div>
                            <button onClick={() => window.scrollTo(0, 500)}
                                className='bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px]  font-bold'>Donate Now</button>
                        </div>
                    </div>


                </section>



                {/* /* --------------------------------- Images --------------------------------- */}
                <section className='bg-[#F8F8F8] mt-10 sm:mt-20 py-12'>
                    <div className="md:container md:mx-auto flex justify-center flex-wrap ">

                        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {

                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                        {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                        <img src={item} alt={`carousel_images${index}`} className=' bg-top object-cover w-full object-top' />
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="w-full  flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 ">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {/* /* -------------------------------------------------------------------------- */}

                {/* -------------------------------------FAQS--------------------------------- */}
                <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h2 className='text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                        Give Sadaqah Donations Online: See the <span className='text-[#F60362]'> FAQ’s</span>
                    </h2>
                    <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{sadaqaFaqs[0].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {sadaqaFaqs[0].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{sadaqaFaqs[1].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {sadaqaFaqs[1].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{sadaqaFaqs[2].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {sadaqaFaqs[2].answer}
                            </p>
                        </div>
                    </div>
                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {sadaqaFaqs.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default Sadaqah;
