import { useSelector } from 'react-redux';
import { selectProducts } from '../../store/products';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { palestineRefugeeFaqs, palestineRefugeeFaqsArabic, palestineRefugeeFaqsFrench } from './components/faqs';
// import Accordion from '../../components/general/accordion';
// import { useKeenSlider } from 'keen-slider/react';
import CrisisWidget from './components/crisisWidget';
import monitoringImg from '../../images/landings/palestine-refugee/monitoring.png';
import sponsorshipImg from '../../images/landings/palestine-refugee/sponsorship.png';
import identificationImg from '../../images/landings/palestine-refugee/identification.png';
import assessmentImg from '../../images/landings/palestine-refugee/assessment.png';
import banner from '../../images/landings/palestine-refugee/Egypt-Resettlement-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/landings/palestine-refugee/Egypt-Resettlement-Hero-Banner-Mobile.jpg';
import bannerAr from '../../images/landings/palestine-refugee/Egypt-Resettlement-Hero-Banner-Desktop-Ar.jpg';
import bannerMobileAr from '../../images/landings/palestine-refugee/Egypt-Resettlement-Hero-Banner-Mobile-Ar.jpg';
import bannerFr from '../../images/landings/palestine-refugee/Egypt-Resettlement-Hero-Banner-Desktop-Fr.jpg';
import bannerMobileFr from '../../images/landings/palestine-refugee/Egypt-Resettlement-Hero-Banner-Mobile-Fr.jpg';
import matwImpactPhoto from '../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import imageJHCO from '../../images/landings/palestine/image-jhco.png';
import imageAlHilal from '../../images/landings/palestine/image-alhilal.png';
import imageEyc from '../../images/landings/palestine/image-eyc.png';
import sponsorFamilyPhoto from '../../images/landings/palestine/multi-prod-palestine.png';

// Carousel Images
import Palestinepreramadan1 from '../../images/landings/palestine-refugee/palestine-refugee-slider-1.jpg';
import Palestinepreramadan2 from '../../images/landings/palestine-refugee/palestine-refugee-slider-2.jpg';
import Palestinepreramadan3 from '../../images/landings/palestine-refugee/palestine-refugee-slider-3.jpg';
import Palestinepreramadan4 from '../../images/landings/palestine-refugee/palestine-refugee-slider-4.jpg';
import Palestinepreramadan5 from '../../images/landings/palestine-refugee/palestine-refugee-slider-5.jpg';
import Palestinepreramadan6 from '../../images/landings/palestine-refugee/palestine-refugee-slider-6.jpg';
import Palestinepreramadan7 from '../../images/landings/palestine-refugee/palestine-refugee-slider-7.jpg';
import Palestinepreramadan8 from '../../images/landings/palestine-refugee/palestine-refugee-slider-8.jpg';
import { OurPromiseSection } from '../../components/general';
import PalestineRefugeeTabs from './components/palestineRefugeeTabs';
import CustomButton from '../../components/general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { palestineVideos as videos } from './fixtures';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import VideosSection from '../../components/general/videosSection';
import { CarouselImages } from '../../components/general/imagesCarousel';
// import MaxWidthContainer from "../../components/general/maxWidthContainer";
import FAQSectionOtherPalestinePage from './components/common-sections/faqs';
import MuslimsAroundSection from './components/common-sections/muslimsAround';
import TotalImpactSection from './components/common-sections/totalImpacts';

const PalestineRefugeeLandingPage = ({ lang = 'en', type = 'RefugeesEgypt' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [addProduct] = useHandleAddProduct();
  // const [loadedImpact, setLoadedImpact] = useState(false);
  // const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sponsorFamilyProducts, setSponsorFamilyProducts] = useState([0, 0, 0, 0]);
  const [palestinianRefugeeProduct, setPalestinianRefugeeProduct] = useState();
  const [supportRefugeeSettlementProducts, setRefugeeSettlementProducts] = useState([0, 0]);
  // const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
  //   mode: 'snap',
  //   slideChanged(slider) {
  //     setCurrentImpactSlide(slider.track.details.rel);
  //   },
  //   created() {
  //     setLoadedImpact(true);
  //   },
  //   loop: true,

  //   slides: {
  //     origin: 'center',
  //     perView: 3,
  //     spacing: 32,
  //   },
  // });
  const commonImages = {
    monitoringImg: {
      src: monitoringImg,
      alt: 'monitoringImg',
    },
    sponsorshipImg: {
      src: sponsorshipImg,
      alt: 'sponsorshipImg',
    },
    identificationImg: {
      src: identificationImg,
      alt: 'identificationImg',
    },
    assessmentImg: {
      src: assessmentImg,
      alt: 'assessmentImg',
    },
    banner: {
      src: banner,
      alt: 'Donate to Palestine',
    },
    bannerMobile: {
      src: bannerMobile,
      alt: 'Donations to Palestine',
    },
    bannerAr: {
      src: bannerAr,
      alt: 'Donate to Palestine',
    },
    bannerMobileAr: {
      src: bannerMobileAr,
      alt: 'Donations to Palestine',
    },
    bannerFr: {
      src: bannerFr,
      alt: 'Donate to Palestine',
    },
    bannerMobileFr: {
      src: bannerMobileFr,
      alt: 'Donations to Palestine',
    },
    matwImpactPhoto: {
      src: matwImpactPhoto,
      alt: 'Donate to Palestine to make your MATW impact',
    },
    impactSliderRightIcon: {
      src: impactSliderRightIcon,
      alt: 'impactSliderRightIcon',
    },
    impactSliderLeftIcon: {
      src: impactSliderLeftIcon,
      alt: 'impactSliderLeftIcon',
    },
    imageJHCO: {
      src: imageJHCO,
      alt: 'jhco logo',
    },
    imageAlHilal: {
      src: imageAlHilal,
      alt: 'alhilal logo',
    },
    imageEyc: {
      src: imageEyc,
      alt: 'eyc logo',
    },
    sponsorFamilyPhoto: {
      src: sponsorFamilyPhoto,
      alt: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan',
    },
  };
  const carouselImages = [
    { src: Palestinepreramadan1, alt: 'Donate to palestine, they need you' },
    { src: Palestinepreramadan2, alt: 'Palestine Charity to help the palestinian people' },
    { src: Palestinepreramadan3, alt: 'Palestine donation to make an impact' },
    { src: Palestinepreramadan4, alt: 'Palestine appeal, help them today' },
    { src: Palestinepreramadan5, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan6, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan7, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan8, alt: 'donate for palestine emergency appeal' },
  ];
  const howItWorksItems = [
    {
      icon: commonImages.identificationImg.src,
      title: lang === 'fr' ? 'Pièce d’identité' : lang === 'ar' ? 'تحديد الهوية' : 'Identification',
      description:
        lang === 'fr'
          ? `Notre équipe MATW sur le terrain travaille en étroite collaboration avec les organisations locales et les dirigeants communautaires pour identifier les familles de réfugiés palestiniens qui sont récemment arrivées et qui sont confrontées à des difficultés financières`
          : lang === 'ar'
          ? `يعمل فريق MATW على الأرض بشكل وثيق مع المنظمات المحلية وقادة المجتمع لتحديد عائلات اللاجئين الفلسطينيين الذين وصلوا مؤخرا ويواجهون صعوبات مالية`
          : `Our MATW team on the ground is working closely with local organisations and community leaders to identify Palestinian refugee families who have recently arrived and are facing financial hardship`,
    },
    {
      icon: commonImages.assessmentImg.src,
      title: lang === 'fr' ? 'Évaluation' : lang === 'ar' ? 'تقييم' : 'Assessment',
      description:
        lang === 'fr'
          ? `Chaque famille fera l’objet d’un processus d’évaluation avec MATW afin de déterminer sa situation et le niveau de soutien approprié requis.`
          : lang === 'ar'
          ? `ستخضع كل أسرة لعملية تقييم مع MATW لتحديد ظروفها والمستوى المناسب من الدعم المطلوب.`
          : `Each family will undergo an assessment process with MATW to determine their circumstances and the appropriate level of support required.`,
    },
    {
      icon: commonImages.sponsorshipImg.src,
      title: lang === 'fr' ? 'Parrainage' : lang === 'ar' ? 'رعايه' : 'Sponsorship',
      description:
        lang === 'fr'
          ? `Une fois approuvées, les familles sont inscrites au programme et commenceront à recevoir des allocations mensuelles pour couvrir leurs dépenses immédiates telles que les soins de santé, l’éducation et les produits de première nécessité.`
          : lang === 'ar'
          ? `بمجرد الموافقة ، يتم تسجيل العائلات في البرنامج وستبدأ في تلقي بدلات شهرية لتغطية نفقاتها الفورية مثل الرعاية الصحية والتعليم والضروريات.`
          : `Once approved, families are enrolled in the program and will begin receiving monthly allowances to cover their immediate expenses such as healthcare, education and essentials.`,
    },
    {
      icon: commonImages.monitoringImg.src,
      title: lang === 'fr' ? 'Surveillance' : lang === 'ar' ? 'رصد' : 'Monitoring',
      description:
        lang === 'fr'
          ? `Notre équipe des programmes entretient des contacts réguliers avec les familles parrainées afin de suivre leurs progrès et leur bien-être et d’évaluer l’évolution de leurs besoins ou des défis qu’elles pourraient rencontrer.`
          : lang === 'ar'
          ? `يحافظ فريق البرامج لدينا على اتصال منتظم مع العائلات التي تتلقى الرعاية لمراقبة تقدمهم ورفاهيتهم وتقييم أي احتياجات أو تحديات متغيرة قد يواجهونها.`
          : `Our Programs team maintains regular contact with families receiving sponsorship to monitor their progress, and their well-being and assess any changing needs or challenges they may encounter.`,
    },
  ];
  const [FAQs, setFAQs] = useState(palestineRefugeeFaqs);
  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSponsorFamilyProducts = [];
    let tempSupportRefugeeSettlementProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // sponsorFamilyProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-4-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Parrainer une famille palestinienne de 4 personnes  pendant 1 mois`
                  : lang === 'ar'
                  ? `اكفل عائلة فلسطينية مكونة من 4 أفراد لمدة 1 شهر`
                  : inner_item_product.name,
              alt: 'Donate to Palestine by sponsoring a palestinian family',
              description:
                lang === 'fr'
                  ? `Aidez-nous à apporter un soutien mensuel à une famille de réfugiés palestiniens de 4 personnes. Votre don couvrira les dépenses essentielles d'une famille entière résidant en Égypte pendant un mois. Cette allocation mensuelle couvre les besoins essentiels, notamment la nourriture, les frais médicaux, le loyer et les frais scolaires.`
                  : lang === 'ar'
                  ? `ساعدنا في تقديم الدعم الشهري لعائلة فلسطينية لاجئة مكونة من 4 أفراد. سيغطي تبرعك التكاليف الأساسية لمدة شهر لجميع أفراد الأسرة المقيمين في مصر. ويغطي هذا البدل الشهري الاحتياجات الأساسية، بما في ذلك الغذاء والنفقات الطبية والإيجار وتكاليف التعليم.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempSponsorFamilyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-8-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Parrainer une famille palestinienne de 8 personnes  pendant 1 mois`
                  : lang === 'ar'
                  ? `اكفل أسرة فلسطينية مكونة من 8 أفراد لمدة 1 شهر`
                  : inner_item_product.name,
              alt: 'Palestine charity to sponsor a palestinian family of 8',
              description:
                lang === 'fr'
                  ? `Aidez-nous à apporter un soutien mensuel à une famille de réfugiés palestiniens de 8 personnes. Votre don couvrira les dépenses essentielles d'une famille entière résidant en Égypte pendant un mois. Cette allocation mensuelle couvre les besoins essentiels, notamment la nourriture, les frais médicaux, le loyer et les frais scolaires`
                  : lang === 'ar'
                  ? `ساعدنا في تقديم الدعم الشهري لعائلة فلسطينية لاجئة مكونة من 8 أفراد. سيغطي تبرعك التكاليف الأساسية لمدة شهر لجميع أفراد الأسرة المقيمين في مصر. ويغطي هذا البدل الشهري الاحتياجات الأساسية، بما في ذلك الغذاء والنفقات الطبية والإيجار وتكاليف التعليم.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempSponsorFamilyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-12-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Parrainer une famille palestinienne de 12 personnes  pendant 1 mois`
                  : lang === 'ar'
                  ? `اكفل أسرة فلسطينية مكونة من 12 فردا لمدة شهر`
                  : inner_item_product.name,
              alt: 'Palestine donation: sponsor a palestinian family of 12',
              description:
                lang === 'fr'
                  ? `Aidez-nous à apporter un soutien mensuel à une famille de réfugiés palestiniens de 12 personnes. Votre don couvrira les dépenses essentielles d'une famille entière résidant en Égypte pendant un mois. Cette allocation mensuelle couvre les besoins essentiels, notamment la nourriture, les frais médicaux, le loyer et les frais scolaires.`
                  : lang === 'ar'
                  ? `ساعدنا في تقديم الدعم الشهري لعائلة فلسطينية لاجئة مكونة من 12 فردا. سيغطي تبرعك التكاليف الأساسية لمدة شهر لجميع أفراد الأسرة المقيمين في مصر. ويغطي هذا البدل الشهري الاحتياجات الأساسية، بما في ذلك الغذاء والنفقات الطبية والإيجار وتكاليف التعليم.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempSponsorFamilyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Parrainer une famille palestienne` : lang === 'ar' ? `اكفل عائلة فلسطينية` : inner_item_product.name,
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              description:
                lang === 'fr'
                  ? `Aider à fournir un soutien continu aux familles de réfugiés palestiniens résidant en Égypte. Offrez des allocations mensuelles pour couvrir leurs besoins essentiels, notamment la nourriture, les frais médicaux, le loyer et les frais scolaires.`
                  : lang === 'ar'
                  ? `المساعدة في تقديم الدعم المستمر لعائلات اللاجئين الفلسطينيين المقيمين في مصر. تقديم بدلات شهرية لتغطية احتياجاتهم الأساسية، بما في ذلك الغذاء والنفقات الطبية والإيجار وتكاليف التعليم.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempSponsorFamilyProducts[3] = inner_item_product;
          }

          // SupportRefugeeSettlementProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Camp de réfugiés palestiniens en Égypte`
                  : lang === 'ar'
                  ? `مخيم للاجئين الفلسطينيين في مصر`
                  : inner_item_product.name,
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              description:
                lang === 'fr'
                  ? `Contribuer à fournir tous les éléments essentiels à l'installation d'une famille de réfugiés palestiniens en Égypte, dans leur nouveau foyer.`
                  : lang === 'ar'
                  ? `المساعدة في توفير جميع الضروريات اللازمة لتأسيس عائلة فلسطينية لاجئة أثناء إعادة توطينهم في مصر، في وطنهم الجديد`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempSupportRefugeeSettlementProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr'
                  ? `Soutien à l’installation des réfugiés palestiniens`
                  : lang === 'ar'
                  ? `دعم توطين اللاجئين الفلسطينيين`
                  : inner_item_product.name,
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              description:
                lang === 'fr'
                  ? `Aider à fournir les éléments essentiels nécessaires aux réfugiés palestiniens pour commencer une nouvelle vie en Égypte. Votre don les aidera à fournir les ressources nécessaires pour leur permettre de reprendre leurs activités et de se réinstaller en Égypte, ce qui leur permettra d’atteindre un niveau de confort de base dans leur nouveau foyer`
                  : lang === 'ar'
                  ? `المساعدة في توفير الضروريات المطلوبة للاجئين من فلسطين لبدء حياة جديدة في مصر. يوفر هذا الصندوق الموارد اللازمة لهم للاستقرار وتحقيق مستويات الراحة الأساسية في منازلهم الجديدة.`
                  : inner_item_product.short_description || inner_item_product.description,
            });
            tempSupportRefugeeSettlementProducts[1] = inner_item_product;
            setPalestinianRefugeeProduct(inner_item_product);
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSponsorFamilyProducts = tempSponsorFamilyProducts.filter(n => n);
    tempSupportRefugeeSettlementProducts = tempSupportRefugeeSettlementProducts.filter(n => n);
    setSponsorFamilyProducts(tempSponsorFamilyProducts);
    setRefugeeSettlementProducts(tempSupportRefugeeSettlementProducts);
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    lang === 'ar' ? setFAQs(palestineRefugeeFaqsArabic) : lang === 'fr' ? setFAQs(palestineRefugeeFaqsFrench) : setFAQs(palestineRefugeeFaqs);
  }, [lang]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && type === 'RefugeesEgypt' ? (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['palestinianRefugeesEgypt']['title']}`}</title>
          <meta name="title" content={`${seo['crisisAndEmergencies']['palestinianRefugeesEgypt']['title']}`} />
          <meta name="description" content={`${seo['crisisAndEmergencies']['palestinianRefugeesEgypt']['description']}`} />
        </Helmet>
      ) : (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
          <meta name="title" content={`${seo['crisisAndEmergencies']['palestine']['title']}`} />
          <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          lang={lang}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: lang === 'ar' ? 'حيث يحتاج الأكثر' : 'Where Most Needed',
            value: 255,
          }}
        />
      </section>

      <section className="flex justify-center">
        <img
          src={lang === 'fr' ? commonImages.bannerFr.src : lang === 'ar' ? commonImages.bannerAr.src : "https://cdn.matwproject.org/images/banners/MATW_PalestineEgyptResettlement_Aug2024_lp.jpg"}
          alt={commonImages.banner.alt}
          className="max-[500px]:hidden w-full"
        />
        <img
          src={lang === 'fr' ? commonImages.bannerMobileFr.src : lang === 'ar' ? commonImages.bannerMobileAr.src : "https://cdn.matwproject.org/images/banners/MATW_PalestineEgyptResettlement_Aug2024_lp.jpg"}
          alt={commonImages.bannerMobile.alt}
          className="min-[501px]:hidden w-full"
        />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            lang={lang}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: lang === 'ar' ? 'حيث يحتاج الأكثر' : 'Where Most Needed',
              value: 255,
            }}
          />
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      <div className="hidden md:flex w-full justify-center">
        <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" lang={lang} />
      </div>
      {/* ------------------- Products Section -------------------- */}
      {/* <HeroTab tabButtons={heroTabProducts} /> */}
      <PalestineRefugeeTabs
        supportRefugeeSettlementProducts={supportRefugeeSettlementProducts}
        sponsorFamilyProducts={sponsorFamilyProducts}
        lang={lang}
      />
      <div className="md:hidden w-full justify-center">
        <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" lang={lang} />
      </div>
      {/* /* ------------------------------ How it works ------------------------------  */}
      <section className="bg-white py-6 md:py-8 px-6 md:px-0 md:container md:mx-auto" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <p className={`text-[#78716C] md:text-lg mb-3 md:mb-6 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratMedium'}`}>
          {lang === 'fr'
            ? `Le récent conflit en Palestine a forcé 1,9 million de personnes à se déplacer. Plus de 85 % de la population de Gaza est aujourd’hui déplacée après avoir été forcée de fuir sans rien. Pour ceux qui ont fui vers les pays voisins, il n’est pas facile de recommencer à zéro.`
            : lang === 'ar'
            ? `لقد أجبر الصراع الأخير في فلسطين 1.9 مليون شخص على النزوح. أكثر من 85 بالمائة من سكان غزة مشردون الآن بعد أن أجبروا على الفرار بلا شيء. وبالنسبة لأولئك الذين فروا إلى البلدان المجاورة، فإن البدء من جديد ليس بالمهمة السهلة.`
            : `The recent conflict in Palestine has forced 1.9 million people into displacement. More than 85 percent of Gaza’s population is now displaced after being forced to flee with nothing. For those who have fled to neighbouring countries, starting over is no easy task.`}
        </p>
        <p className={`text-[#78716C] md:text-lg mb-3 md:mb-6 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratMedium'}`}>
          {lang === 'fr'
            ? `L’équipe de MATW est sur le terrain en Égypte. Nous nous engageons à aider les familles palestiniennes par le biais d’un programme de parrainage familial visant à assurer leur bien-être et à améliorer leur qualité de vie. Notre programme fournira des allocations mensuelles pour aider les familles à prendre un nouveau départ, à alléger leur fardeau financier et à leur donner les moyens de répondre à leurs propres besoins fondamentaux dans la dignité.`
            : lang === 'ar'
            ? `فريقنا في MATW موجود على الأرض في مصر. نحن ملتزمون بمساعدة الأسر الفلسطينية من خلال برنامج كفالة الأسرة الذي يهدف إلى ضمان رفاههم وتحسين نوعية حياتهم. سيوفر برنامجنا مخصصات شهرية لمساعدة العائلات في الحصول على بداية جديدة ، وتخفيف أعبائهم المالية وتمكينهم من تلبية احتياجاتهم الأساسية بكرامة.`
            : `Our team at MATW is on the ground in Egypt. We are committed to assisting Palestinian families through a Family Sponsorship Program aimed at ensuring their well-being and enhancing their quality of life. Our program will provide monthly allowances, to help families get a new start, alleviating their financial burdens and empowering them to cater to their own basic needs with dignity.`}
        </p>
        <h2
          className={`text-[#253B7E] mb-3 text-[25px] md:text-[40px] ${
            lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'
          } text-center`}
        >
          {lang === 'fr' ? 'Comment ça marche :' : lang === 'ar' ? 'كيف يعمل:' : 'How it works:'}
        </h2>
        <div className="grid grid-cols-4 gap-2 md:gap-4 my-6 md:my-10">
          {howItWorksItems.map((item, index) => {
            return (
              <div
                key={`how_${index}`}
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
                className="col-span-2 lg:col-span-1 border border-[#253B7E] rounded-2xl p-2 md:p-4 flex flex-col items-center"
              >
                <img src={item.icon} alt={item.title} className="mb-2" />
                <p
                  className={`text-[#253B7E] text-center mb-2 md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}
                >
                  {item.title}
                </p>
                <p className={`text-[#78716C] text-sm md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratMedium'}`}>
                  {item.description}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col items-center">
          <p
            className={`text-center ${
              lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'
            } text-[#093686] leading-[30px] text-[25px] md:text-[40px] md:leading-[45px] mb-3`}
          >
            {lang === 'fr' ? (
              <>
                Nous avons besoin de <span className="text-[#F60362] mr-1">VOUS!</span>
              </>
            ) : lang === 'ar' ? (
              <>
                نحن بحاجة<span className="text-[#F60362] mr-1">إليك!</span>
              </>
            ) : (
              <>
                We need <span className="text-[#F60362] mr-1">YOU!</span>
              </>
            )}
          </p>
          <p className={`text-[#78716C] md:text-lg mb-3 md:mb-6 text-center ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratMedium'}`}>
            {lang === 'fr' ? (
              <>
                Ensemble, nous pouvons atteindre un plus grand nombre de familles dans le besoin, en leur fournissant le parrainage dont elles ont
                besoin pour prendre un nouveau départ dans la vie après des mois - des années de souffrance.
                <br />
                <br />
                Votre Zakat et votre Sadaqah peuvent nous aider à donner aux familles de réfugiés palestiniens un nouveau départ dans la vie.
              </>
            ) : lang === 'ar' ? (
              <>
                معا، يمكننا الوصول إلى المزيد من الأسر المحتاجة، وتزويدهم بالرعاية التي يحتاجونها للحصول على بداية جديدة في الحياة بعد أشهر - سنوات من
                المعاناة.
                <br />
                <br />
                يمكن أن تساعدنا الزكاة والصدقة في منح عائلات اللاجئين الفلسطينيين بداية جديدة في الحياة ".
              </>
            ) : (
              <>
                Together, we can reach more families in need, providing them with the sponsorship they need to get a new start in life after months -
                years of suffering.
                <br />
                <br />
                Your Zakat and Sadaqah can help us give Palestinian refugee families a new start in life.
              </>
            )}
          </p>
          <CustomButton
            onClick={() => addProduct({product: palestinianRefugeeProduct, currency: selectedCurrency})}
            title={lang === 'fr' ? 'Faites un don maintenant' : lang === 'ar' ? 'تبرع الآن' : 'donate now'}
            className={`mb-2 sm:mb-0 min-w-[226px] ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}
          />
        </div>
      </section>
      {/* ------------------------ Total Impact Section */}
      <TotalImpactSection lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------------------------------------------------- */}
      <section className="xl:px-20 mb-8 sm:mb-16 mt-6 md:mt-10 px-6 md:px-0 md:container md:mx-auto">
        <div className="relative overflow-hidden flex flex-wrap">
          <div className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[390px] md:top-1/2 md:-translate-y-1/2 right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute px-9 py-5 md:px-[48px] md:py-[38px]">
            <p className={`md:text-[18px] mt-3 md:mt-5 text-white text-center ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratMedium'}`}>
              {lang === 'fr'
                ? `Aidez à soulager les souffrances d’une famille palestinienne en leur donnant un nouveau départ.`
                : lang === 'ar'
                ? `ساعد في تخفيف معاناة عائلة فلسطينية من خلال منحهم بداية جديدة.`
                : `Help ease the suffering of a Palestinian family by giving them a fresh start.`}
            </p>
          </div>
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <YoutubeVideoHero src={`https://www.youtube.com/embed/-nmW93dIIQ4?controls=0&autoplay=1&loop=1&mute=1&playlist=-nmW93dIIQ4`} />
        </div>
      </section>

      {/* /* --------------------------------- Muslim Arounds ------------------------- */}
      <MuslimsAroundSection lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* ----------------- videos section ---------------- */}
        <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
        {/* ----------------- videos section --END-------------- */}

        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="mt-4 md:mt-12">
          <CarouselImages carouselImages={carouselImages} />
        </section>
        {/* /* -------------------------------------------------------------------------- */}
      </section>
      <FAQSectionOtherPalestinePage lang={lang} FAQs={FAQs} />
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default PalestineRefugeeLandingPage;
