import axios from 'axios';
const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_BASE_URL;
let config = {
    headers: {
      "X-user-matw": Header
    },
  };
// function getSessionId() {
//     return axios.post('https://matw-new-backend.sajidjaved.com/api/checkout/checkout-session');
// }
// function createNewUser(data) {
//     return axios.post('https://matw-new-backend.sajidjaved.com/api/checkout/customer/create',data);
// }

// // eslint-disable-next-line import/no-anonymous-default-export
// export default {
//     getSessionId,
//     createNewUser
// };
export const getReceipt = async (request) => {
    return axios.get(`${Base}/checkout/stripe/checkout-session?${request}`,config);
    // return axios.get(`${Base}/checkout/stripe/checkout-session?id=${id}`);
}