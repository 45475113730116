import React, { useEffect } from "react";
// import React, { useEffect } from "react";

function Live() {
    useEffect(() => {
        window.location.href = "https://events.matwcheckout.org/";
        window.location.replace('https://events.matwcheckout.org/');
        window.location.redirect('https://events.matwcheckout.org/');
    }, []);

    return (
        <div></div>
    );
}

export default Live;