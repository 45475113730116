import { useEffect, useState } from 'react';
import Card from '../../components/card';
import services from '../../../../services';
import AddBanner from './addBanner';
import Swal from "sweetalert2";
import HomePageBannersTable from './list';

const BannersPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [showModal, setShowModal] = useState(false);
    const [currentBanner, setCurrentBanner] = useState();
    const [banners, setBanners] = useState([]);
    const [lastBannerOrder, setLastBannerOrder] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const columnsDataProducts = [
        {
            Header: "Image",
            accessor: "image",
        },
        {
            Header: "Link",
            accessor: "link",
        },
        {
            Header: "Order",
            accessor: "order",
        },
        {
            Header: "Created Date",
            accessor: "created_at",
        },
        {
            Header: "Updated Date",
            accessor: "updated_at",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Actions",
            accessor: 'id',
        },
    ];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    // const handleChange = (field, value, index) => {
    //     console.log("change", value)
    //     const newTempBanner = banners.map((obj, inner_index) => {
    //         if (inner_index === index) {
    //             return { ...obj, [field]: value }
    //         }
    //         return obj;
    //     });
    //     console.log("newTempBanner", newTempBanner)
    //     setBanners(newTempBanner);
    // }
    const handleUpdate = (banner) => {
        setCurrentBanner(banner);
        setShowModal(true);
    }
    const handleRemove = async (id) => {
        console.log("id", id);
        try {
            const response = await services.deleteBanner(id);
            console.log('response delete Banner', response);
            getBanners();
            Swal.fire({
                icon: 'success',
                title: 'Successfully',
                text: 'You removed!',
            })
        } catch (error) {
            console.error('error========', error);
        }
    }
    const getBanners = async () => {
        setIsLoading(true);
        try {
            const response = await services.getBanners();
            let temp = response.data.sort((a, b) => {
                return a.order - b.order;
            });
            setLastBannerOrder(temp[temp.length - 1].order + 1)
            temp = temp.filter(item => item.section === "slider")
            // setBanners(temp)
            console.log("banners=",temp)
            setBanners(temp)
            setIsLoading(false);
            console.log('response get getBanners', response);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        window.scroll(0, 0);
        getBanners();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full xl:basis-2/3-">
            <Card
                title='Home Banners'
                btn={<div className='flex'>
                    <button className='bg-[#0f9621] hover:bg-[#0a7418] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200' onClick={() => setShowModal(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                        </svg>
                        Add
                    </button>
                </div>}
            >
                <div className="flex w-full flex-wrap max-h-[650px] overflow-y-scroll md:pr-4">
                    {/* {banners.map((item, index) => {
                        return <div className='basis-full flex flex-wrap items-end py-4 md:py-2 border-b border-stone-200 md:border-0' key={`banners_sliders${item.id}`}>
                            <div className="flex w-full md:basis-auto- md:w-auto relative">
                                <div className="flex md:w-[130px] md:h-16 overflow-hidden">
                                    <img src={item.image ? item.image : '/images/general/logo-replace.png'} alt="slider" className={`${item.image ? 'w-full h-auto rounded' : 'w-auto mx-auto'}`} onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/images/general/logo-replace.png";
                                        currentTarget.className = 'max-h-[64px] mx-auto'
                                    }} />
                                </div>
                            </div>
                            <div className="flex max-[767px]:flex-wrap items-end w-full md:w-[calc(100%_-_130px)] md:pl-3 mt-3 md:mt-0">
                                <div className="flex max-[767px]:basis-full flex-col w-full mb-2 md:mb-0 md:pr-3">
                                    <label htmlFor="link">Link</label>
                                    <input name='link' type="text" value={item.link} disabled onChange={(e) => handleChange('link', e.target.value, index)} className='border border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                </div>
                                <div className="flex max-[767px]:w-[calc(100%_-_160px)] flex-col pr-3">
                                    <label htmlFor="order">Order</label>
                                    <input name='order' type="text" value={item.order} disabled onChange={(e) => handleChange('order', e.target.value, index)} className='border border-stone-200 max-[767px]:w-full rounded-md h-10 w-20 px-2' />
                                </div>
                                <div className="flex items-center- h-10- flex-col pr-2 min-w-[120px]-">
                                    <label htmlFor="status">Status</label>
                                    <span className={`flex px-2 py-1 rounded-full h-10 w-20 justify-center items-center ${item.status === 1 ? 'border border-green-700 bg-green-50 text-green-700' : 'border border-red-700 bg-red-50 text-red-700'}`}>
                                        {item.status === 1 ? 'Active' : 'Deactive'}
                                    </span>
                                </div>
                                <button className='bg-orange-500 text-white hover:bg-orange-700 h-10 w-10 min-w-[40px] flex items-center justify-center rounded mr-2 transition-all duration-200' onClick={() => handleUpdate(item)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </button>
                                <button className='text-[#F60362] hover:text-white bg-transparent hover:bg-[#F60362] h-10 w-10 min-w-[40px] flex items-center justify-center rounded transition-all duration-200'
                                    onClick={async () => {
                                        await Swal.fire({
                                            title: "Are you sure?",
                                            text: "You will not be able to recover this banner!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            cancelButtonText: 'No, cancel it!',
                                            confirmButtonText: 'Yes, I am sure!',
                                            dangerMode: true,
                                        }).then(function (isConfirm) {
                                            if (isConfirm.isConfirmed) {
                                                handleRemove(item.id)
                                            } else {
                                                Swal("Cancelled", "The banner didn't remove", "error");
                                            }
                                        })
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    })} */}
                    {console.log("banners before table=",banners.length,banners)}
                    {(banners.length && !isLoading)
                        ? <HomePageBannersTable
                            columnsData={columnsDataProducts}
                            tableData={banners || []}
                            pageSize={banners.length}
                            isLoading={isLoading}
                            onUpdateBanner={(e) => handleUpdate(e)}
                            onDeleteBanner={(e) => handleRemove(e)}
                        />
                        : <></>
                    }
                </div>
                <AddBanner
                    title='Add New Banner'
                    show={showModal}
                    lastBannerOrder={lastBannerOrder}
                    currentBanner={currentBanner}
                    onClose={() => { setShowModal(false); setCurrentBanner(null) }}
                    onSaved={() => getBanners()}
                >
                </AddBanner>
            </Card>
        </div>

    );
};
export default BannersPage;