import { useEffect } from 'react';
// import Layout from "../components/general/layout";
import Layout from '../../components/general/layout';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
const GDPR = () => {
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];

  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  return (
    <Layout>
      {seo && (
        <Helmet>
          <title>{`${seo['aboutUs']['gdpr']['title']}`}</title>
          <meta name="title" content={`${seo['aboutUs']['gdpr']['title']}`} />
          <meta name="description" content={`${seo['aboutUs']['gdpr']['description']}`} />
        </Helmet>
      )}
      <div className="bg-white pb-10">
        <div className="w-full bg-opacity-70 gdpr_banner text-center">
          <h1 className="text-white text-5xl font-Roboto font-extrabold">Privacy Policy for MATW</h1>
          <p className="mt-4 text-white leading-10 px-16  text-lg	">
            At MATW Australia,&nbsp;one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of
            information that is collected and recorded by MATW Australia and how we use it. If you have additional questions or require more
            information about our Privacy Policy, do not hesitate to contact us.
          </p>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto"> General Data Protection Regulation (GDPR)</h2>
          <div className="font-semibold text-[#7A7A7A] ">
            <p className="mt-4 leading-10">We are a Data Controller of your information.</p>
            <p className="mt-4 leading-10">
              MATW legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information
              we collect and the specific context in which we collect the information:
            </p>
            <ul className="marker:text-green mt-4 leading-10 list-disc list-outside ml-6">
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">MATW needs to perform a contract with you</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">You have given MATW permission to do so</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Processing your personal information is in MATW legitimate interests</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">MATW needs to comply with the law</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">
                  MATW will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will
                  retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our
                  policies.
                </span>
              </li>
            </ul>
            <p className="mt-4 leading-10">
              If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what
              Personal Information we hold about you and if you want it to be removed from our systems, please{' '}
              <span className="text-lg text-[#666]">contact us.</span>
            </p>
            <p className="mt-4 text-lg text-[#666]">In certain circumstances, you have the following data protection rights:</p>
            <ul className="mt-4 leading-10 list-disc ml-3">
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">The right to access, update or to delete the information we have on you</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">The right of rectification.</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">The right to object.</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">The right of restriction.</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">The right to data portability</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">The right to withdraw consent</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto"> Log Files</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              MATW Australia follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies
              do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are
              not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the
              site, tracking users’ movement on the website, and gathering demographic information.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Cookies and Web Beacons</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              Like any other website, MATW Australia uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and
              the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing
              our web page content based on visitors’ browser type and/or other information.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Privacy Policies</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              {' '}
              You may consult this list to find the Privacy Policy for each of the advertising partners of MATW Australia.{' '}
            </p>
            <p className="mt-4 leading-10">
              Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective
              advertisements and links that appear on MATW Australia, which are sent directly to users’ browser. They automatically receive your IP
              address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize
              the advertising content that you see on websites that you visit. Note that MATW Australia has no access to or control over these cookies
              that are used by third-party advertisers.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Third Party Privacy Policies</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              {' '}
              MATW Australia’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about
              how to opt-out of certain options.
            </p>
            <p className="mt-4 leading-10">
              You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers’ respective websites.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Children’s Information</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              {' '}
              Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe,
              participate in, and/or monitor and guide their online activity.
            </p>
            <p className="mt-4 leading-10">
              MATW Australia does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that
              your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best
              efforts to promptly remove such information from our records.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Online Privacy Policy Only</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              {' '}
              Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that
              they shared and/or collect in MATW Australia. This policy is not applicable to any information collected offline or via channels other
              than this website.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Consent</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10"> By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GDPR;
