import { toast } from 'react-toastify';

const SubscriptionTypes = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { onSetBasketStates, onSelectedSubscribe, selectedSubscribe, basketStates } = props;
    const subscribePopupMessages = [
        { title: 'year', message: <span>Important: You will donate this amount EVERY YEAR until you cancel. You can cancel anytime from this link <a className='text-red-500' href='/cancel'>here.</a></span> },
        { title: 'month', message: <span>Important: You will donate this amount EVERY MONTH until you cancel. You can cancel anytime from this link <a className='text-red-500' href='/cancel'>here.</a></span> },
        { title: 'week', message: <span>Important: You will donate this total amount EVERY WEEK until you cancel. You can cancel anytime from this link <a className='text-red-500' href='/cancel'>here.</a></span> },
        { title: 'day', message: <span>Important: You will donate this total amount EVERY DAY until you cancel. You can cancel anytime from this link <a className='text-red-500' href='/cancel'>here.</a></span> },
        { title: 'one-off', message: <span>You will donate this total amount once only. You will not be charged more than once.</span> }
    ];
    const subscriptions = [
        { checked: true, title: 'Single/One-Time', value: 'one-off' },
        // { checked: true, title: 'Daily', value: 'day' },
        { checked: false, title: 'Weekly', value: 'week' },
        { checked: false, title: 'Monthly', value: 'month' },
        { checked: false, title: 'Yearly', value: 'year' },
    ];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start Methods ----------------------------- */
    const handleSubscriptionChange = (value) => {
        let tempBasket = basketStates;
        // if (value === 'day') {
        //     tempBasket.recurring = 'one-off';
        //     tempBasket.products.map(item => item.recurring = 'one-off');
        //     tempBasket.mode = 'payment';
        //     subscribePopupMessages.map((item, index) => {
        //         if (value === item.title) {
        //             toast.dismiss();
        //             toast.error('Daily donatinos are not allowed for this donation');
        //         }
        //         return value
        //     })
        //     onSelectedSubscribe('one-off')
        // } else {
        //     tempBasket.recurring = value;
        //     tempBasket.products.map(item => item.recurring = value)
        //     tempBasket.mode = value === 'one-off' ? 'payment' : 'subscription';
        //     subscribePopupMessages.map((item, index) => {
        //         if (value === item.title) {
        //             toast.dismiss();
        //             toast.warn(item.message);
        //         }
        //         return value
        //     })
        //     onSelectedSubscribe(value);
        // }
        tempBasket.recurring = value;
        tempBasket.products.map(item => item.recurring = value)
        tempBasket.mode = value === 'one-off' ? 'payment' : 'subscription';
        subscribePopupMessages.map((item, index) => {
            if (value === item.title) {
                toast.dismiss();
                toast.warn(item.message);
            }
            return value
        })
        onSelectedSubscribe(value);

        onSetBasketStates(tempBasket);
    }
    /* -------------------------------------------------------------------------- */
    return (
        <div className="mt-8 mb-2- grid grid-cols-4 gap-4">
            <div className="col-span-4 bg-[#00a3da] text-sm rounded-md text-white py-2 px-2 md:px-4 text-center">
                How often would you like to pay this?
            </div>
            {subscriptions.map((item, index) => (
                <div
                    key={`subscriptions_${index}`}
                    className={`col-span-2 sm:col-span-2 md:col-span-1 hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${item.value === selectedSubscribe ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`}
                    onClick={() => handleSubscriptionChange(item.value, item.title)}
                >
                    <div className="flex flex-col">
                        <div className="flex items-center justify-start">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 33.51" width='33' className={`transition-all duration-150 ${item.value === selectedSubscribe ? 'fill-stone-100' : 'fill-stone-400'}`}>
                                <path d="M.08,30.09l4.17,6.45a.47.47,0,0,0,.31.2l.1,0a.5.5,0,0,0,.26-.08l5.44-3.52a2.68,2.68,0,0,1,1.83-.41l11,1.5a2.19,2.19,0,0,0,.36,0,2.65,2.65,0,0,0,1.59-.54h0l13.8-10.2a2.63,2.63,0,0,0-3.13-4.22h0a2.63,2.63,0,0,0-3.13,0l-4.19,3L16,20.69a5.34,5.34,0,0,0-3.55.8L.22,29.42a.44.44,0,0,0-.14.13A.5.5,0,0,0,.08,30.09ZM35,19.93C33.51,21,31.9,22.1,30.23,23.12a3,3,0,0,0-.58-.49l3.59-2.54A1.65,1.65,0,0,1,35,19.93ZM1.15,30,13,22.31a4.34,4.34,0,0,1,2.91-.66l12.65,1.61a1.59,1.59,0,0,1,1.09.64,1.66,1.66,0,0,1-1.55,2.64L18.22,25.2a2,2,0,0,0-.57,4h.1a3.64,3.64,0,0,0,.48,0c1.81,0,4.57-1.11,6.72-2.12l3,.41a2.62,2.62,0,0,0,3-2.24,2.73,2.73,0,0,0-.17-1.33,63.37,63.37,0,0,0,5.36-3.64h0l.27-.2a1.66,1.66,0,1,1,2,2.67L24.57,33h0a1.61,1.61,0,0,1-1.23.33l-11-1.5a3.65,3.65,0,0,0-2.49.56l-5,3.25Zm22-3.13c-2.43,1-4.32,1.54-5.31,1.38h0A1,1,0,0,1,16.92,27a1.06,1.06,0,0,1,1.17-.89Z" transform="translate(0 -3.24)" />
                                <path d="M18.7,18.5a7.63,7.63,0,1,0-7.63-7.63A7.63,7.63,0,0,0,18.7,18.5Zm0-14.29A6.67,6.67,0,1,1,12,10.87,6.67,6.67,0,0,1,18.7,4.21Z" transform="translate(0 -3.24)" />
                                <path d="M18.7,13.83a1.23,1.23,0,0,1-1.24-1.23.49.49,0,1,0-1,0,2.21,2.21,0,0,0,1.72,2.14v1.4a.49.49,0,0,0,1,0v-1.4a2.2,2.2,0,0,0-.48-4.35,1.24,1.24,0,1,1,1.23-1.24.49.49,0,0,0,1,0A2.21,2.21,0,0,0,19.18,7V5.61a.49.49,0,0,0-1,0V7a2.21,2.21,0,0,0,.49,4.36,1.24,1.24,0,0,1,0,2.47Z" transform="translate(0 -3.24)" />
                            </svg>
                            <span className={`ml-2 text-xs ${item.value === selectedSubscribe ? 'text-stone-100' : 'text-stone-400'}`}>Donate</span>
                        </div>
                        <p className={`mb-0 text-sm sm:text-lg mt-3 transition-all duration-100 ${item.value === selectedSubscribe ? 'text-stone-100 font-medium ml-1' : 'text-[#777] font-normal ml-0'}`}>{item.title}</p>
                    </div>
                    <div className=''>
                        <svg className={`transition-all duration-200 text-[#00a3da] ${item.value === selectedSubscribe ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                            <circle cx="24" cy="24" r="24" />
                            <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                        </svg>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default SubscriptionTypes;