import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/general/button";
import HandleCategory from "./handleCategory";
import MenuAccordionLayout from "../menus/menuAccordion";
import services from "../../../../services";
import { toast } from "react-toastify";

const Categories = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    // const {  } = props;
    // const [isLoading, setIsLoading] = useState(false);
    const [currentParentId, setCurrentParentId] = useState(0);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeIndexL2, setActiveIndexL2] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [allCategories,setAllCategories] = useState([]);
    const [selectedCategoryItem, setSelectedCategoryItem] = useState();

  /* ------------------------------ Start methods ----------------------------- */
  const getCategories = async () => {
    // setIsLoading(true);
    try {
      const response = await services.getAllCategories();
      console.log("response=",response);
      setAllCategories(response.data.data);
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.error('error========', error);
    }
  };
  const handleAddCategory = async (_parentID) => {
    setCurrentParentId(_parentID);
    setShowModal(true);
  }
  const handleUpdateCategory = async (item,_parentID) => {
    setCurrentParentId(_parentID);
    setShowModal(true);
    setSelectedCategoryItem(item)
  }
  const handleDeleteCategory = async (id) => {
    try {
        await services.deleteCategory(id);
        toast.success('The category has been deleted successfully');
        getCategories();
    } catch (error) {
        toast.error(error.response.data.message || 'Something went wrong!');
        console.error('error========', error);
    }
}
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
    return (
        <>
            <div className="flex flex-col w-full pt-6">
                <div className='basis-full flex justify-between border-b border-stone-300 pb-2'>
                    <h2 className='text-lg md:text-2xl text-stone-700'>Categories</h2>
                    <CustomButton
                        type='button'
                        onClick={() => handleAddCategory(0)}
                        title={'Add Category'}
                        className='!h-[42px] !bg-[#00A3DA]'
                    />
                </div>
                {allCategories && allCategories.map((l1Item, l1Index) => {
                    return <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md border border-stone-200" key={`l1${l1Index}`}>
                        <MenuAccordionLayout
                            title={l1Item.name}
                            index={l1Index}
                            isCategory
                            noBorder={true}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            className="!text-lg !font-bold"
                            handleAdd={() => handleAddCategory(l1Item.id)}
                            handleEdit={() => handleUpdateCategory(l1Item)}
                            handleDelete={() => handleDeleteCategory(l1Item.id)}
                        >
                            {l1Item.sub_categories.length
                                ? l1Item.sub_categories.map((l2Item, l2Index) => {
                                    return <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md border border-stone-200" key={`l2${l2Index}`}>
                                        <MenuAccordionLayout
                                            title={l2Item.name}
                                            index={l2Index}
                                            isCategory
                                            noBorder={true}
                                            activeIndex={activeIndexL2}
                                            setActiveIndex={setActiveIndexL2}
                                            className="!text-base !font-bold"
                                            handleAdd={() => handleAddCategory(l2Item.id)}
                                            handleEdit={() => handleUpdateCategory(l2Item)}
                                            handleDelete={() => handleDeleteCategory(l2Item.id)}
                                        >
                                            <div className="flex w-full justify-center items-center py-4 text-stone-400">No SubCategory!</div>
                                        </MenuAccordionLayout>
                                    </div>
                                })
                                : <div className="flex w-full justify-center items-center py-4 text-stone-400">No SubCategory!</div>
                            }
                        </MenuAccordionLayout>
                    </div>
                })}
            </div>
            {showModal && <HandleCategory
                show={showModal}
                selectedCategoryItem={selectedCategoryItem}
                currentParentId={currentParentId}
                onClose={() => { setShowModal(false); setSelectedCategoryItem(null) }}
                onSaved={() => {
                    getCategories();
                }}
            >
            </HandleCategory>}
        </>
    );
};
export default Categories;