import { Link } from 'react-router-dom';
import logoAcnsImg from './../../../../images/general/logo-acns.png';
import logoCardsImg from './../../../../images/general/logo-cards.png';
import logoMatwPolicyImg from './../../../../images/general/logo-matw-policy.png';
import { useEffect, useState } from 'react';
const MainFooter = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const [isUk, setIsUk] = useState(false);
  const [isUsa, setIsUsa] = useState(false);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleCheckUK = referrer => {
    const href = referrer;
    const findTerm = term => {
      if (href.includes(term)) {
        return href;
      }
    };
    switch (referrer) {
      case findTerm('matwproject.org.uk'): // UK Website
        setIsUk(true);
        break;
      case findTerm('matwprojectusa.org'): // USA Website
        setIsUsa(true);
        break;
      default:
        setIsUk(false);
        setIsUsa(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    handleCheckUK(window.location.href);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="w-full bg-gradient-to-r from-[#00a3da] to-[#093484] px-2 py-6">
        <div className="md:container mx-auto grid grid-cols-12 px-2 gap-2 md:gap-4">
          <div className="col-span-12 sm:col-span-6 lg:col-span-3">
            <ul className="flex flex-col">
              <li className="w-full pt-2 pb-4">
                <span className="text-white font-bold text-2xl">About Us</span>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff] font-light" to="/ali-banat">
                  About Ali Banat
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/purpose">
                  Purpose
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/gdpr">
                  GDPR
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/Volunteer/">
                  Volunteer
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/contact/">
                  Contact
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/cancel">
                  Subscriptions Portal
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/fundraising">
                  Fundraise with MATW
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/subscription">
                  Subscription
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/our-promise">
                  Our Promise
                </Link>
              </li>
              {/* <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/cancel">
                  Subscriptions Portal
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-3">
            <ul className="flex flex-col">
              <li className="w-full pt-2 pb-4">
                <span className="text-white font-bold text-2xl">Resources & Information</span>
              </li>
              <li className="w-full text-[#c6e9ff] transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff] mr-1" to="/faq/">
                  FAQs
                </Link>
                |
                <Link className="text-[#c6e9ff] ml-1" to="/social-media/">
                  Social Media
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/phishing-scams">
                  Scams & Phishing
                </Link>
              </li>
              {/* <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/social-media/">
                  Social Media
                </Link>
              </li> */}
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/annual-reports/">
                  Annual Reports
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/privacypolicy/">
                  Privacy Policies
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/policies/">
                  Our Policies
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/opt-out/">
                  Opt-out
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <a className="text-[#c6e9ff]" href="https://blog.matwproject.org/">
                  Blog
                </a>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to={`/pdf/uk-terms-refunds.pdf`} target="_blank">
                  Terms & Refunds (UK)
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to={`/pdf/global-terms-refunds.pdf`} target="_blank">
                  Terms & Refunds (Global)
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-3">
            <ul className="flex flex-col">
              <li className="w-full pt-2 pb-4">
                <span className="text-white font-bold text-2xl">Quick Links</span>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/zakat-calculator">
                  Zakat Calculator 2024
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/fidya">
                  Fidya 2024
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/sadaqah-jariyah/build-a-water-well">
                  Build a Well
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/sadaqah-jariyah/build-a-masjid">
                  Build a Mosque
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/crisis-and-emergencies/palestine">
                  Donate to Palestine
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/zakat/zakat-al-fitr">
                  Zakat al Fitr
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="https://matwproject.org/duas/">
                  Duas
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/sadaqah-jariyah">
                  Sadaqah Jariyah
                </Link>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <Link className="text-[#c6e9ff]" to="/100-donation-policy">
                  100% Donation Policy
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="col-span-12 sm:col-span-6 lg:col-span-2">
            <ul className="flex flex-col">
                {/* <li className="w-full pt-2 pb-4">
                  <span className="text-white font-bold text-2xl">Bank Details</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <Link className="text-[#c6e9ff]" to="/contact/">
                    Contact us
                  </Link>
                </li> */}
          {/* <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Account Name: MATW INTERNATIONAL</span>
                </li> */}
          {/* <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">BSB: 112879</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Account Number: 47879 1884</span>
                </li> */}
          {/* <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">BIC/SWIFT: SPPVGB2L</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">IBAN: GB67SPPV23188429044117</span>
                </li> */}
          {/* <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Bank Name: St. George Bank</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Address: 4-16 Montgomery St, Kogarah NSW 2217</span>
                </li> */}
          {/* </ul> */}

          {/* </div>  */}
          <div
            className={`col-span-12 sm:col-span-6 lg:col-span-3 flex flex-col ${
              isUk ? 'justify-between' : 'justify-end'
            } items-center sm:items-start mt-3 sm:mt-0 px-4`}
          >
            {isUk ? (
              <ul className="flex flex-col">
                <li className="w-full pt-2 pb-4">
                  <span className="text-white font-bold text-2xl">Bank Details</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Bank: Equals Money</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Account Name: MATW PROJECT</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Account Number: 57759839</span>
                </li>
                <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                  <span className="text-[#c6e9ff]">Sort Code: 23-18-84</span>
                </li>
              </ul>
            ) : null}

            <div>
              {!isUk && !isUsa && (
                <img
                  src={logoAcnsImg}
                  alt="MATW Project"
                  className="mb-3 w-[100px] h-[100px] sm:w-[80px] sm:h-[80px] md:w-[100px] md:h-[100px] lg:w-[60px] lg:h-[60px] xl:w-[80px] xl:h-[80px] 2xl:w-[100px] 2xl:h-[100px]"
                />
              )}
              <img
                src={logoMatwPolicyImg}
                alt="MATW Project"
                className="mb-3 w-[260px] h-[50px] sm:w-[280px] sm:h-[52px] md:w-[335px] md:h-[63px] lg:w-[207px] lg:h-[38px] xl:w-[271px] xl:h-[51px] 2xl:w-[335px] 2xl:h-[63px]"
              />
              <img
                src={logoCardsImg}
                alt="MATW Project"
                className="max-w-full w-[260px] h-[35px] sm:w-[280px] sm:h-[36px] md:w-[335px] md:h-[40px] lg:w-[207px] lg:h-[30px] xl:w-[271px] xl:h-[32px] 2xl:w-[335px] 2xl:h-[40px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#282828] py-6 px-4 lg:px-12 w-full">
        {isUk && (
          <p className="text-center text-[#aaa] text-lg copy-right">
            MATW Project UK is a UK registered charity (1201110)
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" className="w-[14px] h-[14px] inline-block mx-1">
              <path
                fillRule="evenodd"
                clipRule={'evenodd'}
                fill="#aaa"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7,0c3.9,0,7,3.2,7,7s-3.1,7-7,7s-7-3.1-7-7S3.2,0,7,0z M7,1C3.7,1,1,3.7,1,7s2.7,6,5.9,6s6-2.7,6-6S10.3,1,7,1
            z M10.7,9.5c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2C9,9.6,8.1,10.2,7,10.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
            c1.1,0,2,0.5,2.6,1.4c0.2,0.3,0.6,0.4,0.9,0.2s0.4-0.6,0.2-0.9c-0.8-1.2-2.2-2-3.7-2c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
            C8.5,11.5,9.9,10.7,10.7,9.5z"
              />
            </svg>
            2023 and registered office at 275 Featherstall Road North OLDHAM OL1 2NJ
          </p>
        )}
        {isUsa && (
          <p className="text-center text-[#aaa] text-lg copy-right">
Head office: 501(c)(3) nonprofit organization 4860 Eisenhower Ave Unit 492 Alexandria VA 2304-7350 EIN: 92-1915834
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" className="w-[14px] h-[14px] inline-block mx-1">
            <path fillRule='evenodd' clipRule={'evenodd'} fill='#aaa' strokeLinecap="round" strokeLinejoin="round" d="M7,0c3.9,0,7,3.2,7,7s-3.1,7-7,7s-7-3.1-7-7S3.2,0,7,0z M7,1C3.7,1,1,3.7,1,7s2.7,6,5.9,6s6-2.7,6-6S10.3,1,7,1
            z M10.7,9.5c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2C9,9.6,8.1,10.2,7,10.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
            c1.1,0,2,0.5,2.6,1.4c0.2,0.3,0.6,0.4,0.9,0.2s0.4-0.6,0.2-0.9c-0.8-1.2-2.2-2-3.7-2c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
            C8.5,11.5,9.9,10.7,10.7,9.5z"/>
          </svg>
          2023 and registered office at 275 Featherstall Road North OLDHAM OL1 2NJ */}
          </p>
        )}
        {!isUk && !isUsa && (
          <p className="text-center text-[#aaa] text-md copy-right">
            MATW INTERNATIONAL LTD with ABN 60610666325 is a Charity and Public Benevolence Institution (PBI) dedicated to assisting the needy in
            Australia and abroad. MATW INTERNATIONAL LTD is endorsed as a Deductible Gift Recipient (DGR). Address: Level 2 47 Rickard Road,
            Bankstown, NSW 2200 Australia
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" className="w-[14px] h-[14px] inline-block mx-1">
              <path
                fillRule="evenodd"
                clipRule={'evenodd'}
                fill="#aaa"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7,0c3.9,0,7,3.2,7,7s-3.1,7-7,7s-7-3.1-7-7S3.2,0,7,0z M7,1C3.7,1,1,3.7,1,7s2.7,6,5.9,6s6-2.7,6-6S10.3,1,7,1
            z M10.7,9.5c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2C9,9.6,8.1,10.2,7,10.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
            c1.1,0,2,0.5,2.6,1.4c0.2,0.3,0.6,0.4,0.9,0.2s0.4-0.6,0.2-0.9c-0.8-1.2-2.2-2-3.7-2c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
            C8.5,11.5,9.9,10.7,10.7,9.5z"
              />
            </svg>
            2016-2024 MATW Project
          </p>
        )}
      </div>
    </>
  );
};

export default MainFooter;