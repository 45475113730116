const Card = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { title, btn, className, children } = props;
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`w-full bg-white shadow-md rounded-lg p-4 md:p-6 ${className ? className : ''}`}>
            {title && <div className='basis-full flex justify-between border-b border-stone-300 pb-2'>
                <h2 className='text-lg md:text-2xl text-stone-700'>{title}</h2>
                {btn && btn}
            </div>}
            <div className="flex py-4">
                {children}
            </div>
        </div>
    );
};
export default Card;