const giftSoundFaqs = [
    {
        id: 0,
        question: 'How do you identify those who will benefit from this project?',
        answer: `We have a strict identification process that’s followed after conducting a thorough needs assessment. We work closely with local organisations and authorities who help us identify potential cases. Those who require support then undergo MATW’s screening process and are eligible to receive support if they meet the requirements.`
    },
    {
        id: 1,
        question: 'What does ‘Gift of Sound’ do?',
        answer: `In the same way that we use your Sadaqah Jariyah to restore sight in Project Noor, our newly established Gift of Sound project aims to do the same but through sound. Those receiving support will be given hearing aids for treatable hearing loss. Not only does this restore dignity, hope and provides a sense of empowerment - this is a beautiful Sadaqah Jariyah.`
    },
    {
        id: 2,
        question: 'Is this project a Sadaqah Jariyah?',
        answer: `Absolutely! Providing use of any of the senses whether it be through sight or in this case sound is a Sadaqah Jariyah - especially when used in the course of Allah, to better the lives of those in need.`
    }

];
export { giftSoundFaqs };