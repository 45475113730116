import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import services from '../../../../services';
import CustomModal from '../../components/modal';
import CheckBox from '../../../../components/general/checkbox';
// import { Loader } from '../../../../components/general';

const AddBanner = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const Header = process.env.REACT_APP_BASE_URL;
    const fileRef = useRef(null);
    const mobileBannerRef = useRef(null);
    const { show, onClose, onSaved, currentBanner, lastBannerOrder } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState();
    const [mobileFile, setMobileFile] = useState();
    const [hasMobileFile, setHasMobileFile] = useState(false);
    const [banner, setBanner] = useState(
        {
            name: '',
            image: '',
            mobileImage: '',
            imgUrl: '',
            mobileImgUrl: '',
            link: '',
            section: 'page_builder',
            order: 1,
            status: 1,
            alt: ''
        }
    )
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleChange = (field, value) => {
        setBanner({ ...banner, [field]: value });
    }
    const handleSave = () => {
        saveBanner();
        // toast.success('The sliders have been updated successfully');
    }
    const handleBannerFileChange = (e) => {
        console.log("files", e.target.files, fileRef.current)
        if (e.target.files) {
            let tempFile = e.target.files[0];
            if (tempFile.type !== 'image/jpeg') {
                toast.warning('Please upload a jpg file');
                fileRef.current.value = '';
                return false;
            }
            if (tempFile.size > 500000) {
                toast.warning('Please upload a file smaller than 500 KB');
                fileRef.current.value = '';
                return false;

            }
            setFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(tempFile);
            setBanner({ ...banner, imgUrl: objectUrl, name: tempFile.name.replace(/\s+/g, '') });
            console.log("name,file", tempFile, objectUrl,)
        }
    };
    const handleMobileBannerFileChange = (e) => {
        if (e.target.files) {
            let tempFile = e.target.files[0];
            if (tempFile.type !== 'image/jpeg') {
                toast.warning('Please upload a jpg file');
                mobileBannerRef.current.value = '';
                return false;
            }
            if (tempFile.size > 500000) {
                toast.warning('Please upload a file smaller than 500 KB');
                mobileBannerRef.current.value = '';
                return false;

            }
            setMobileFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(tempFile);
            setBanner({ ...banner, mobileImgUrl: objectUrl });
        }
    };
    const saveBanner = async () => {
        setIsLoading(true);
        console.log("banner before save", banner.image)
        const formData = new FormData();
        formData.append('image', file);
        formData.append('mobile_image', hasMobileFile ? mobileFile : '');
        formData.append('name', banner.name);
        formData.append('link', '/');
        formData.append('order', '100');
        formData.append('status', banner.status);
        formData.append('alt', banner.alt);
        formData.append('section', banner.section);
        try {
            const response = await services.createBanner(formData, file);
            onSaved();
            closeModal();
            setIsLoading(false);
            console.log('response get getBanners', response);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const handleUpdate = async () => {
        setIsLoading(true);
        console.log("banner before save", banner.image)
        const formData = new FormData();
        file && formData.append('image', file);
        formData.append('name', banner.name);
        formData.append('link', '/');
        formData.append('order', '100');
        formData.append('status', banner.status);
        formData.append('alt', banner.alt);
        formData.append('section', banner.section);
        // let config = {
        //     headers: {
        //         accept: '*/*',
        //         'content-type': file ? file.type : '',
        //         'content-length': `${file ? file.size : 0}`,
        //         "X-user-matw": Header
        //     },
        // };
        try {
            const response = await services.updateBanner(banner.id, formData, file);
            onSaved();
            closeModal();
            console.log('response get getBanners', response);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };

    const closeModal = () => {
        setBanner({
            ...banner,
            name: '',
            img: '',
            image: '',
            mobileImage: '',
            imgUrl: '',
            mobileImgUrl: '',
            link: '',
            section: 'page_builder',
            order: 1,
            status: 1,
            alt: ''
        });
        setFile(null)
        setMobileFile(null)
        setHasMobileFile(false)
        onClose();
    }
    // const statuses = [
    //     { label: 'Active', value: 1 },
    //     { label: 'Deactive', value: 0 }
    // ]
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        show && console.log("show modal", currentBanner, file, fileRef);
        if (show) {
            if (currentBanner) {
                setBanner(currentBanner)
            } else {
                fileRef.current.value = '';
                // mobileBannerRef.current.value = '';
                setBanner({ ...banner, order: lastBannerOrder });
            }
        }
        // (currentBanner && show) ? setBanner(currentBanner) : fileRef.current.value = '';
        // (!currentBanner && show) && setBanner({ ...banner, order: lastBannerOrder });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    useEffect(() => {
        show && console.log("banner has changed", banner);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [banner]);
    /* -------------------------------------------------------------------------- */
    return (
        <CustomModal
            title={currentBanner && show ? 'Update Banner' : 'Add New Banner'}
            show={show}
            className='pb-0'
            onClose={() => closeModal()}
        >
            <div className='w-full grid grid-cols-4 items-end gap-2 md:gap-3'>
                <div className="flex col-span-4">
                    <div className="w-full border border-red-600 bg-red-50 flex flex-col justify-center items-center text-center rounded-lg mb-3 py-2">
                        {/* <h2 className='w-full text-xl'>Please ensure:</h2> */}
                        <p className=''>Format: <b>'.jpg'</b>
                            <br />
                            width: <b>1920px</b> | height: <b>500px</b>
                            {/* <br /> */}
                            {/* It's size is less than <b>500kb</b> and in RGB colour format. */}
                        </p>
                    </div>
                </div>
                <div className="flex flex-col col-span-4 md:col-span-2 md:w-auto relative-">
                    <div className="w-full relative">
                        <div className="absolute bottom-1 right-1 rounded h-6 flex flex-col items-center md:flex-row overflow-hidden">
                            <span className="h-6 w-6 flex items-center justify-center rounded-full cursor-pointer text-blue-700 bg-white">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M1 8a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 018.07 3h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0016.07 6H17a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2V8zm13.5 3a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM10 14a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                </svg>
                            </span>
                            <input ref={fileRef} type="file" accept="image/jpeg" className='opacity-0 absolute w-full cursor-pointer' onChange={(e) => handleBannerFileChange(e)} />
                        </div>
                        <p className="w-full text-sm text-stone-500 mb-2">Desktop:</p>
                        <div className="flex items-center w-full md:max-h-[250px] overflow-hidden border border-stone-200 rounded-md">
                            <img src={
                                banner.imgUrl
                                    ? banner.imgUrl
                                    : banner.image ? banner.image : `/images/general/logo-replace.png`
                            } alt="slider" className='w-full- w-auto h-auto rounded' />
                        </div>
                    </div>
                    <p className="w-full text-sm text-stone-500 my-2">Alt Tag:</p>
                    <div className="flex items-center w-full md:max-h-[250px] overflow-hidden border border-stone-200 rounded-md">
                        <input name='alt' type="text" value={banner.alt} onChange={(e) => handleChange('alt', e.target.value)} className='text-xs md:text-base rounded-md h-10 px-2 w-full' />
                    </div>
                </div>
                {hasMobileFile && <div className="flex flex-col col-span-4 md:col-span-2 relative">
                    <div className="absolute bottom-1 right-1 rounded h-6 flex flex-col items-center md:flex-row overflow-hidden">
                        <span className="h-6 w-6 flex items-center justify-center rounded-full cursor-pointer text-blue-700 bg-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M1 8a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 018.07 3h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0016.07 6H17a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2V8zm13.5 3a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM10 14a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                            </svg>
                        </span>
                        <input ref={mobileBannerRef} type="file" accept="image/jpeg" className='opacity-0 absolute w-full cursor-pointer' onChange={(e) => handleMobileBannerFileChange(e)} />
                    </div>
                    <p className="w-full text-sm text-stone-500 mb-2">Mobile:</p>
                    <div className="flex items-center w-full md:max-h-[250px] overflow-hidden border border-stone-200 rounded-md">
                        <img src={
                            banner.mobileImgUrl
                                ? banner.mobileImgUrl
                                : banner.mobileImage ? banner.mobileImage : `/images/general/logo-replace.png`
                        } alt="slider" className='w-full- w-auto h-auto rounded' />
                    </div>
                </div>
                }
                <div className="flex col-span-4 pt-4">
                    <div className="flex order-11 col-span-12 items-center [&>input]:mt-0 my-3">
                        <CheckBox
                            name="hasMobileFile"
                            value={hasMobileFile}
                            checked={hasMobileFile}
                            setter={(e) => setHasMobileFile(e)}
                        >
                            <span className='text-stone-500'>Add Mobile Banner</span>
                        </CheckBox>
                    </div>
                </div>
                <div className="flex col-span-4 justify-end pt-4">
                    <button className='border border-red-500 hover:bg-red-500 text-red-500 hover:text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 mr-2' onClick={() => closeModal()}>Cancel</button>
                    <button disabled={isLoading} className='bg-[#0f960f] hover:bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200' onClick={() => {
                        currentBanner ? handleUpdate() : handleSave();
                    }}>
                        {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        </>) : (
                            currentBanner && show ? 'Update' : 'Save'
                        )}
                    </button>
                </div>
            </div>
        </CustomModal>
    );
};
export default AddBanner;