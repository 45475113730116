import { useEffect, useRef } from 'react';
import Card from './card';

const CustomModal = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const wrapperRef = useRef(null);
    const { show, title, children, className, onClose } = props;
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose();
                console.log("clicked", event)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperRef]);
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-stone-700 bg-opacity-40 justify-center items-center p-4 lg:p-0 ${show ? 'flex' : 'hidden'}`} tabIndex={-1} aria-hidden="true">
            <Card title={title} className={`pointer-events-none relative translate-y-[-50px] opacity-100 transition-all duration-300 ease-in-out lg:w-1/2 ${className ? className : ''}`}>
                <div ref={wrapperRef} className="pointer-events-auto relative flex flex-col w-full border-none bg-clip-padding text-current outline-none">
                    {children}
                </div>
            </Card>
        </div>
    );
};

export default CustomModal;
