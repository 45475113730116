import { useEffect, useRef, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ZakatWidget from './zakatWidget';
import Accordionn from '../../components/general/accordion';
import imgSlider from '../../images/qurban/Water for School 1-27 screenshot 2.jpg';
import ProductBox from '../../components/general/productBox';
import { zakatFaqs } from './faq';
import { selectProducts } from '../../store/products';

const ZakatLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const youtubeContainerRef = useRef();
    const [isLoaded, setIsLoaded] = useState(false);
    const products = useSelector((state) => selectProducts(state));
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [youtubeConHeight, setYoutubeConHeight] = useState(300);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [zakatAlMalProduct, setZakatAlMalProduct] = useState();
    const styles = {
        youtube: {
            height: youtubeConHeight
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        setYoutubeConHeight(youtubeContainerRef.current.clientWidth / 1.7777777);
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'RM24-ZM-013') setZakatAlMalProduct(inner_item_product); // Zakat Al Mal
                    // if (inner_item.name === 'Make A Donation') {
                    // };
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout>
            <div className="w-full flex flex-wrap md:bg-[url('../public/images/zakat/tile-image.jpg')] bg-cover md:py-8">
                <div className="hidden md:flex items-center md:container md:mx-auto md:px-4">
                    <ZakatWidget defaultProduct={{
                        creator: "MKD-GEN-MZK-2023-01-123",
                        label: "Zakat",
                        value: 108
                    }} />
                    <img src={'/images/zakat/text-slider.png'} alt={'matw'} className='w-full- ml-4' />
                </div>
                <img src={'/images/zakat/text-slider.jpg'} alt={'matw'} className='w-full flex md:hidden' />
                <img src={'/images/zakat/slider.jpg'} alt={'matw'} className='w-full flex md:hidden' />
            </div>
            {/* /* ------------------------------- Widget on Mobile ---------------------------- */}
            <div className='w-full bg-[#F60362] p-4 flex justify-center items-center md:hidden'>
                <ZakatWidget defaultProduct={{
                    creator: "MKD-GEN-MZK-2023-01-123",
                    label: "Zakat",
                    value: 108
                }} />
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ------------------------------- Section One ------------------------------ */}
            <div className='w-full flex flex-wrap items-stretch justify-start bg-[#E2EFF3]'>
                <div className="basis-full lg:basis-5/12 xl:basis-6/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden">
                    <img src={'/images/zakat/zakat-img.jpg'} alt="" className='w-full min-h-full' />
                </div>
                <div className="basis-full lg:basis-7/12 xl:basis-5/12 flex flex-col justify-center px-8 py-8 lg:pt-16 order-2 lg:order-1">
                    <h2 className='font-bold text-3xl mb-4 xl:mb-8'>Empower others with Your Zakat</h2>
                    <p className='text-sm'>
                        <strong>Your Zakat is powerful. It can transform and uplift entire communities, lifting them out of poverty. Fulfil your duty and donate your Zakat Al Mal and rest assured that 100% of your Zakat goes to those who need it most.</strong>
                        <br />
                        <br />
                        “Truly those who believe, and do deeds of righteousness, and perform Salat, and give Zakat, they will have their reward with their Lord”
                        <br />
                        <br />
                        - (Surat Al -Baqara - Verse 277).
                    </p>
                    <div className="flex justify-start mt-8">
                        <button className='border border-[#F60362] bg-transparent text-[#F60362] hover:bg-[#F60362] hover:text-white px-8 h-10 flex items-center justify-center rounded' onClick={() => window.scroll(0, 0)}>Donate Now
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* --------------------------------- Youtube -------------------------------- */}
            <div className="w-full mt-6-" ref={youtubeContainerRef}>
                <div className="w-full" style={styles.youtube}>
                    <iframe className='video'
                        width={'100%'}
                        height={'100%'}
                        title={'youtube'}
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/embed/Lrte3Wg9ZxY?autoplay=0`}
                    >
                    </iframe>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ------------------------------- Section Two ------------------------------ */}
            <div className='w-full flex flex-wrap items-stretch justify-end bg-[#E2EFF3]'>
                <div className="basis-full lg:basis-7/12 xl:basis-4/12 flex flex-col justify-center px-8 py-8 order-2 lg:order-1">
                    <h2 className='font-bold text-3xl mb-4 xl:mb-8'>MATW & Zakat Al Mal</h2>
                    <p className='text-sm'>
                        2.5%. That’s all it takes from our wealth to make a huge difference in the lives of those who need it most.
                        <br />
                        <br />
                        Zakat Al Mal is a beautiful pillar of our faith that transforms entire communities. Not only does it make a huge difference in the lives of those struggling to survive, there are many  blessings and rewards that Allah bestows upon those who fulfil this duty.
                        <br />
                        <br />
                        <br />
                        <strong className='text-lg'>With us at MATW, your Zakat is in trusted hands.</strong>
                        <br />
                        <br />
                        Every single dollar or pound and penny donated to MATW is an Amanah that we take seriously, especially Your Zakat. We follow a strict Zakat policy verified by local scholars to ensure that your donation is accounted for and adhered to in accordance with Islamic principles.
                        <br />
                        <br />
                        From donation right through to the delivery of the project to those most in need, you can trust that Your Zakat is in the best hands every step of the way.
                    </p>
                    <div className="flex justify-start mt-8">
                        <button className='border border-[#F60362] bg-transparent text-[#F60362] hover:bg-[#F60362] hover:text-white px-8 h-10 flex items-center justify-center rounded' onClick={() => window.scroll(0, 0)}>Donate Now
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="basis-full lg:basis-5/12 xl:basis-7/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden order-1 lg:order-2">
                    <img src={'/images/zakat/zakat-img.jpg'} alt="" className='w-full min-h-full' />
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ------------------------------- Section Three ---------------------------- */}
            <div className='w-full bg-[#F60362] text-white py-4 md:py-8'>
                <div className="md:container md:mx-auto flex flex-wrap">
                    <div className="basis-full md:basis-1/2 lg:basis-6/12 flex flex-col justify-center px-8 py-8">
                        <h2 className='font-bold text-3xl mb-4 xl:mb-8 text-center md:text-left'>Calculate Your Zakat with Ease and Precision</h2>
                        <p className='text-base md:text-lg text-center md:text-left'>
                            Use our calculator to find out what you need to give in zakat.
                        </p>
                    </div>
                    <div className="basis-full md:basis-1/2 lg:basis-6/12 flex flex-wrap justify-center h-full">
                        <div className="basis-1/2 px-2">
                            <ProductBox product={zakatAlMalProduct} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                        </div>
                        <div className="basis-1/2 px-2">
                            <ProductBox product={{ category_id: 0, id: 0, image_link: '/images/zakat/zakat-give-zakat-zakat-calculator.jpg', name: 'Zakat Calculator', description: 'Use our calculator to find out what you need to give in zakat.', creator: '' }} isStaticProduct={true} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                        </div>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ----------------------------------- FAQ ---------------------------------- */}
            <div className='w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3] pb-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4">Zakat FAQs</h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-8 md:px-0 mt-6">
                    {zakatFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full' key={`faq${index}`}>
                                {/* {index === 0 && <h2 className='text-black text-lg md:text-2xl font-medium mb-8 text-center'>Grouped Questions</h2>} */}
                                <Accordionn key={`keyallfaq${index}`} title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordionn>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* /* ----------------------------------- Image -------------------------------- */}
            <div className='w-full h-auto flex items-center'>
                <a href="/zakat/give-zakat" className='w-full'>
                    <img src={imgSlider} alt="" className='w-full min-h-full' />
                </a>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
        </Layout>
    );
}

export default ZakatLandingPage;
