import React, { useState } from 'react';
import SadaqahJariyahUk from '../../../components/general/sadaqahJariyahUk';
import { useNavigate } from 'react-router-dom';

const sadaqahJariyahUkRamadanStyle = {
  subTitle: '!text-left !sm:text-xl !px-0 !py-0 mb-8',
  name: `text-[#253B7E] uppercase font-bold`,
  description: '!text-[14px]- leading-4',
  body: 'flex-col-reverse md:flex-row-reverse !py-4 !px-4',
  photoFrame: 'md:flex-none md:flex-[0_0_300px]',
  photo: 'w-full md:max-w-[300px]',
  desktopButtonGroup: 'flex !justify-start gap-3 lg:flex-nowrap',
  mobileButtonGroup: '!hidden',
  button: 'w-full uppercase !mb-0',
  content: '!pr-0',
};

const SadaqahJariyahTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [activeTabSadaqah, setActiveTabSadaqah] = useState(0);

  const { tabButtonsSadaqah } = props;
  const tabButtonsSadaqahArray = tabButtonsSadaqah
    ? tabButtonsSadaqah
    : [
        {
          title: 'MASJIDS',
        },
        {
          title: 'WATER WELLS',
        },
        {
          title: 'ORPHANS',
        },
        {
          title: 'PROJECT NOOR',
        },
      ];
  /* -------------------------------------------------------------------------- */
  return (
    <section className="py-6 md:py-12 bg-[url('../src/images/ali-banat/sdaqah-jariyah-pattern.png')]">
      <div className="md:container mx-auto w-full px-4 md:px-0">
        <div className="text-[#253B7E]">
          <p
            onClick={() => navigate('/sadaqah-jariyah')}
            className="cursor-pointer w-full text-center text-[30px] lg:text-[40px] sm:text-7xl font-['Gotcha']"
          >
            Sadaqah Jariyah
          </p>
          {props.text ? (
            <p className="mt-4 md:mt-6 md:leading-7 md:text-[18px] text-center font-BrandingMediumItalic">{props.text}</p>
          ) : (
            <p className="mt-4 md:mt-6 md:leading-7 md:text-[18px] text-center font-BrandingMediumItalic">
              Sadaqah Jariyah, the gift that keeps giving, is a charitable practice with everlasting impact. Like Ali Banat, who left a legacy of
              Sadaqah Jariyah through MATW, you too can do the same, contributing to a mosque or water well in your name or for a loved one. This
              enduring act ensures positive change in the lives of those in need, planting the seeds of everlasting rewards.
            </p>
          )}
        </div>
        <div className="flex flex-wrap gap-3 items-center justify-center my-8">
          {tabButtonsSadaqahArray.map(({ title }, index) => (
            <button
              onClick={() => setActiveTabSadaqah(index)}
              key={`sadaqah_${index}`}
              className={`${
                index === activeTabSadaqah ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
              } rounded-full p-2 w-[150px] uppercase text-[14px] font-['Montserrat'] font-bold`}
            >
              {title}
            </button>
          ))}
        </div>
        {props.sadaqahProducts?.length > 0 &&
          props.sadaqahProducts.map((item, index) => {
            return (
              index === activeTabSadaqah && (
                <div key={`sadaqah_pr_${index}`} index={index} className="py-5 px-4 md:px-8 bg-[#14A2DC] rounded-lg">
                  <SadaqahJariyahUk product={item} currency={props.currency} customStyle={sadaqahJariyahUkRamadanStyle} anyAmount />
                </div>
              )
            );
          })}
      </div>
    </section>
  );
};
export default SadaqahJariyahTabs;