import React, { useState } from 'react'
import Layout from '../../components/general/layout';
import CarouselImages from './carouselSection';
import logoImg from '../../images/landings/usa-general-donation/logo.png';
import USAGeneralWidget from './usaGeneralWidget';
import CountUpBannerUSA from '../../components/general/countUpBannerUSA';
import YouTubeModal from './youTubeModal';
import aliImg from '../../images/landings/usa-general-donation/ali.jpg';

const USAGeneralDonation = () => {
    /* ---------------------------------- Variables ----------------------------- */
    const [sliderTitle, setSliderTitle] = useState("Eradicating Poverty");
    const [showModal, setShowModal] = useState(false);
    /* -------------------------------------------------------------------------- */
    /* ------------------------------ Start Methods ----------------------------- */
    const closeModal = () => {
        setShowModal(false);
    };
    /* -------------------------------------------------------------------------- */
    return (
        <Layout className='bg-white' >
            {showModal && <YouTubeModal show={showModal} closeModal={closeModal} />}
            <div className="flex flex-wrap pt-0 lg:pt-16">
                <div className="w-full lg:w-1/2 xl:w-7/12 z-0">
                    <CarouselImages currentSlideText={(e) => setSliderTitle(e)} />
                </div>
                <div className="w-full lg:w-1/2 xl:w-5/12 bg-[#DFEEF2] sm:bg-white px-6 py-3 md:py-6 md:px-12 z-[1]">
                    <div className="w-full bg-white rounded-md p-4 flex flex-wrap -mt-28 mb-4 sm:mt-0 sm:mb-0 ">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex flex-col">
                                <span className='text-[#253B7E] text-base font-medium'>Your impact is on</span>
                                <h3 className='text-[#14A2DC] text-2xl md:text-4xl font-medium'>{sliderTitle}</h3>
                            </div>
                            <img src={logoImg} alt="matw" />
                        </div>
                        <p className="text-lg mt-4">
                            Give a gift that will make a lasting change, impacting the lives of those in need all over the world.
                            <br />
                            Through our 100% donation policy,  your Zakat and Sadaqah is making a world of difference in the most
                            impoverished communities in the world, helping them thrive.
                        </p>
                    </div>
                    <div className="w-full py-4 lg:py-8 lg:px-4 2xl:px-8 rounded-md bg-[#DFEEF2]">
                        <USAGeneralWidget hasSuggestPrice defaultProduct={{
                            creator: "EMR-MR-WMN-001-2023",
                            label: "Morocco Appeal",
                            value: 293
                        }} />
                    </div>
                </div>
            </div>
            {/*---------------------------- YouTube Section ---------------------------- */}
            <section className="w-full md:container flex flex-wrap md:mx-auto px:4 sm:px-10 mt-4 lg:mt-14 pb-4 md:pb-16">
                <div className="w-full md:w-1/2 relative flex justify-center">
                    <div className="hidden lg:flex cursor-pointer w-[350px] h-[350px] rounded-full border-8 border-white absolute -top-40">
                        <div className=" relative"
                            onClick={() => setShowModal(true)}
                        >
                            <div className="overflow-hidden lg:rounded-full">
                            <img src={aliImg} alt="matw" />
                            </div>
                        <div className="flex absolute bottom-[10%] right-0">
                            <svg width="113" height="76" viewBox="0 0 113 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2770_423)">
                                    <path d="M110.519 12.4111C109.875 10.1662 108.619 8.11963 106.876 6.47512C105.133 4.83062 102.964 3.64559 100.585 3.03803C91.8739 0.796631 56.8165 0.796631 56.8165 0.796631C56.8165 0.796631 21.7573 0.864477 13.0466 3.10588C10.6673 3.71347 8.49812 4.89856 6.75521 6.54315C5.01229 8.18773 3.75641 10.2344 3.11264 12.4794C0.477878 27.082 -0.544196 49.333 3.18499 63.3515C3.82883 65.5963 5.08473 67.6429 6.82765 69.2874C8.57056 70.9319 10.7397 72.117 13.119 72.7245C21.8296 74.9659 56.8879 74.9659 56.8879 74.9659C56.8879 74.9659 91.9458 74.9659 100.656 72.7245C103.035 72.117 105.205 70.932 106.948 69.2875C108.691 67.643 109.947 65.5964 110.59 63.3515C113.369 48.7282 114.226 26.4908 110.519 12.4111Z" fill="#F60362" />
                                    <path d="M45.6577 53.7745L74.7405 37.8811L45.6577 21.9878V53.7745Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2770_423">
                                        <rect width="112.253" height="74.4651" fill="white" transform="translate(0.746643 0.796631)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="flex lg:hidden w-full h-[250px] md:h-[350px] overflow-hidden border-8 border-white">
                        <iframe className='video'
                            width={'100%'}
                            height={'100%'}
                            title='Gifted with Cancer - Ali Banat'
                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                            src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=1&playsinline=1&loop=1`}>
                        </iframe>
                    </div>
                <div className="w-full md:w-1/2 px-4 md:px-0">
                    <h1 className='text-lg md:text-2xl text-[#253B7E] font-medium mb-2 md:mb-4'>Our promise to you:</h1>
                    <p className='leading-7 text-[#78716C] font-brandingMedium text-md lg:text-lg'>
                        Founded by Ali Banat in 2015, Muslims Around The World Project (MATW) is a global Islamic Charity working across 24
                        countries around the world. Our 100% donation policy ensures that every single donation goes the extra mile in
                        reaching those most in need.
                    </p>
                </div>
            </section>
            {/*---------------------------- impact section ----------------------------*/}
            <CountUpBannerUSA />
            {/* ----------------------------Sadaqah jariya---------------------------- */}
        </Layout >
    )
}

export default USAGeneralDonation;