import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import Card from '../../components/card';

const SubscriptionsList = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { columnsData, tableData, cancelSubscription, pageSize, isLoading, currentSearches, onSearchItems } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableRef = useRef(null);

  var tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const [searchItems, setSearchItems] = useState({
    page: 1,
    per_page: 10,
    status: 'Paid',
    mode: '',
    currency: '',
    payment_gateway: '',
    referal: '',
    host: '',
    email: '',
    phone: '',
    name: '',
    from: new Date().toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0],
    subscriptionTypes: 'All',
  });
  // const [loadingReset, setLoadingReset] = useState(false);
  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState, state } = tableInstance;
  initialState.pageSize = pageSize;
  state.pageSize = pageSize;
  /* -------------------------------------------------------------------------- */

  console.log({ page });

  /* ------------------------------ Start methods ----------------------------- */
  const handleRecurring = value => {
    switch (value) {
      case 'day':
        return 'Daily';
      case 'month':
        return 'Monthly';
      case 'week':
        return 'Weekly';
      case 'one-off':
        return 'Single';
      default:
        return '';
    }
  };

  // const handleSearch = (name, value) => {
  //   // console.log("handle search", name, value);
  //   if (name === 'page') {
  //     setSearchItems({ ...searchItems, [name]: value });
  //   } else if (name === 'per_page') {
  //     let temp = searchItems;
  //     temp.per_page = value;
  //     temp.page = 1;
  //     setLoadingReset(true);
  //     onSearchItems(temp);
  //     setSearchItems({ ...searchItems, [name]: value, page: 1 });
  //   } else if (name === 'from') {
  //     let dateTo = new Date(searchItems.to);
  //     let dateFrom = new Date(value);
  //     // let diffDays = dateTo.getDate() - dateFrom.getDate();
  //     const diffTime = Math.abs(dateTo - dateFrom);
  //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //     // console.log("diff from",diffDays,diffMonths,diffYears,diffTime,diffDays2)
  //     console.log('diff from', diffTime, diffDays);
  //     if (diffDays > 31 && searchItems.email === '') {
  //       let newToDate = new Date(dateFrom).getTime() + 2678400000;
  //       let dateToDay = new Date(newToDate).getDate();
  //       dateToDay = dateToDay < 10 ? `0${dateToDay}` : dateToDay;
  //       let dateToMonth = new Date(newToDate).getMonth() + 1;
  //       dateToMonth = dateToMonth < 10 ? `0${dateToMonth}` : dateToMonth;
  //       let dateToYear = new Date(newToDate).getFullYear();
  //       let newDateTo = dateToYear + '-' + dateToMonth + '-' + dateToDay;
  //       // console.log("diffDays", diffDays, dateToDay, dateToMonth, dateToYear, newDateTo);
  //       // console.log("diffDays=", value, newDateTo, searchItems);
  //       setSearchItems({ ...searchItems, to: newDateTo, from: value, page: 1 });
  //     } else {
  //       setSearchItems({ ...searchItems, [name]: value, page: 1 });
  //     }
  //     // setSearchItems({ ...searchItems, [name]: value, page: 1 })
  //   } else if (name === 'to') {
  //     let dateFrom = new Date(searchItems.from);
  //     let dateTo = new Date(value);
  //     // let diffDays = dateTo.getDate() - dateFrom.getDate();
  //     const diffTime = Math.abs(dateTo - dateFrom);
  //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //     if (diffDays > 31 && searchItems.email === '') {
  //       let newFromDate = new Date(dateTo).getTime() - 2678400000;
  //       let dateFromDay = new Date(newFromDate).getDate();
  //       dateFromDay = dateFromDay < 10 ? `0${dateFromDay}` : dateFromDay;
  //       let dateFromMonth = new Date(newFromDate).getMonth() + 1;
  //       dateFromMonth = dateFromMonth < 10 ? `0${dateFromMonth}` : dateFromMonth;
  //       let dateFromYear = new Date(newFromDate).getFullYear();
  //       let newDateFrom = dateFromYear + '-' + dateFromMonth + '-' + dateFromDay;
  //       // console.log("diffDaysTo", diffDays, dateFromDay, dateFromMonth, dateFromYear, newDateFrom);
  //       // console.log("diffDaysTo=", newDateFrom, value, searchItems);
  //       setSearchItems({ ...searchItems, from: newDateFrom, to: value, page: 1 });
  //     } else {
  //       setSearchItems({ ...searchItems, [name]: value, page: 1 });
  //     }
  //     // setSearchItems({ ...searchItems, [name]: value, page: 1 })
  //   } else {
  //     setSearchItems({ ...searchItems, [name]: value, page: 1 });
  //   }
  // };
  const handleFirstCharCapital = text => {
    if (text !== '') {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  };
  // const handleAddStarToPhone = text => {
  //   return text.substr(0, 6) + text.substr(6, text.length - 2).replace(/[0-9]/g, '*') + text.substr(text.length - 2, text.length);
  // };
  // const handleAddStarToEmail = text => {
  //   var a = text.split('@');
  //   var b = a[0];
  //   var newstr = '';
  //   for (var i in b) {
  //     if (i > 2 && i < b.length - 1) newstr += '*';
  //     else newstr += b[i];
  //   }
  //   return newstr + '@' + a[1];
  // };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // console.log(searchItems);
    onSearchItems(searchItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItems.page]);
  // useEffect(() => {
  //   !isLoading && setLoadingReset(isLoading);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading]);
  useEffect(() => {
    currentSearches && setSearchItems(currentSearches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearches]);
  /* -------------------------------------------------------------------------- */

  console.log({ page, headerGroups });

  return (
    <Card extra={'w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6'}>
      <div className="w-full bg-white rounded-md px-6- sm:overflow-auto">
        <header className="relative flex flex-col justify-between">
          <div className="text-xl md:text-2xl font-bold- text-stone-600 w-full text-left pb-4 border-b border-stone-200 flex flex-col justify-between md:flex-row">
            <h1>Subscriptions</h1>
          </div>
        </header>
        <div className="mt-8 overflow-hidden">
          {isLoading ? (
            <div role="status" className="w-full col-span-12 flex justify-center items-center min-h-[500px]">
              <svg
                aria-hidden="true"
                className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#49acea]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : data.length > 0 ? (
            <div className="w-full overflow-x-auto">
              <table {...getTableProps()} className="w-full 2xl:table-fixed" variant="simple" color="gray-500" mb="24px" ref={tableRef}>
                <thead>
                  {headerGroups.map((headerGroup, index) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers
                        .filter(i => i.id !== 'account_type')
                        .map((column, index) => (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className="border-b border-gray-200 pr-16 pb-[10px] text-start uppercase"
                            key={index}
                          >
                            <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs whitespace-nowrap">
                              {column.render('Header')}
                            </div>
                          </th>
                        ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, rowIndex) => {
                    prepareRow(row);

                    return (
                      <tr
                        {...row.getRowProps()}
                        key={rowIndex}
                        className={`${rowIndex % 2 === 0 ? 'bg-gray-50' : ''} transition-all duration-200 hover:bg-gray-100`}
                      >
                        {row.cells
                          .filter(i => i.column.id !== 'account_type')
                          .map((cell, index) => {
                            let data = '';
                            if (cell.column.Header === 'Amount') {
                              // console.log("amount",cell)
                              data = (
                                <div className="flex items-center gap-2">
                                  <p className="text-xs font-medium text-navy-700">
                                    {/* {ShowCurrencies(currency, cell.value)} */}
                                    {cell.value ? Number(cell.value).toFixed(2) : <span className="text-base font-normal text-stone-400">NULL</span>}
                                  </p>
                                </div>
                              );
                            } else if (cell.column.Header === 'Currency') {
                              data = <p className="text-xs text-left font-medium text-navy-700 uppercase max-w-[68px]">{cell.value}</p>;
                            } else if (cell.column.Header === 'Status') {
                              data = <p className="text-xs text-left font-medium text-navy-700">{handleFirstCharCapital(cell.value)}</p>;
                            } else if (cell.column.Header === 'Recurring') {
                              data = (
                                <div className="flex items-center max-w-[100px]">
                                  <p className="text-xs font-medium text-navy-700 text-left overflow-hidden">
                                    {/* {isSubscription && handleRecurring(cell.value)} */}
                                    {handleRecurring(cell.value)}
                                  </p>
                                </div>
                              );
                            } else if (cell.column.id === 'sub_id') {
                              data = (
                                <div className="flex items-center">
                                  <p className="text-xs font-medium text-navy-700 max-w-[300px] text-left overflow-hidden mr-2">
                                    {cell.value ? (
                                      <a
                                        href={cell.value}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="transition-all duration-150 text-stone-500 hover:text-[#49acea] text-[12px]"
                                      >
                                        {cell.value}
                                      </a>
                                    ) : (
                                      <span className="text-xs font-normal text-stone-400">NULL</span>
                                    )}
                                  </p>
                                </div>
                              );
                            } else if (cell.column.Header === 'Created Date') {
                              data = (
                                <p className="text-xs text-left font-medium text-navy-700">
                                  {/* {moment(cell.value, 'YYYY-MM-DD').format('MM-DD-YYYY')} */}
                                  {cell.value}
                                </p>
                              );
                            } else if (cell.column.Header === 'Actions') {
                              data = (
                                <button
                                  className="bg-red-500 p-2 text-white hover:bg-red-700 h-10 min-w-[40px] flex items-center justify-center rounded mr-2 transition-all duration-200"
                                  onClick={() => cancelSubscription(row.values.account_type, row.values.sub_id)}
                                >
                                  Cancel Subscription
                                </button>
                              );
                            }
                            return (
                              <td
                                {...cell.getCellProps()}
                                key={index}
                                className="pt-[14px] pb-[16px] sm:text-[14px] text-stone-500 font-normal max-w-max- w-[150px]- max-w-[120px]-"
                              >
                                {data}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full flex items-center justify-center">No Data</div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default SubscriptionsList;
