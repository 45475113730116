import CustomModal from "../../components/general/modal"

const YouTubeModal = ({ closeModal, show }) => {
    return (
        <CustomModal
            show={show}
            onClose={() => closeModal()}
            className="md:w-2/3"
            title=""
        >
            <iframe className='video min-h-[400px]'
                width={'100%'}
                height={'100%'}
                title='Gifted with Cancer - Ali Banat'
                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`}>
            </iframe>
            {/* <button onClick={() => closeModal()} className='w-[150px] mr-5 leading-3 h-[30px] rounded-md text-xs font-brandingMedium text-white bg-[#00A3DA]'>Close</button> */}
        </CustomModal>
    )
}

export default YouTubeModal