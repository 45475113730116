const HandleShowAdminCost = (currency) => {
  switch (currency.toLowerCase()) {
    case 'usd':
        return process.env.REACT_APP_ADMIN_COST_USD
    case 'aud':
        return process.env.REACT_APP_ADMIN_COST_AUD
    case 'cad':
        return process.env.REACT_APP_ADMIN_COST_CAD
    case 'gbp':
        return process.env.REACT_APP_ADMIN_COST_GBP
    case 'eur':
        return process.env.REACT_APP_ADMIN_COST_EUR
    case 'sgd':
        return process.env.REACT_APP_ADMIN_COST_SGD
    case 'myr':
        return process.env.REACT_APP_ADMIN_COST_MYR
    case 'aed':
        return process.env.REACT_APP_ADMIN_COST_AED
    default:
        return process.env.REACT_APP_ADMIN_COST_USD
}
};

export default HandleShowAdminCost;