import foodPackIcon from '../../../images/landings/food-aid/food-icon.png';
import helpIcon from '../../../images/landings/food-aid/help-icon.png';
import waterIcon from '../../../images/landings/food-aid/water-icon.png';
import beneficiariesIcon from '../../../images/landings/food-aid/beneficiaries-icon.png';

// Impact Slider Data
export const impactSliderData = [
  {
    img: { src: waterIcon, alt: 'Litres water served' },
    value: {
      en: '4 Million L',
      fr: `4 millions de`,
      ar: '4  مليون لتر'
    },
    title: {
      en: 'Litres water served',
      fr: `LITRES D’EAU SERVIE`,
      ar: 'لتر ماء تم تقديمها'
    },
  },
  {
    img: { src: beneficiariesIcon, alt: 'Beneficiaries served' },
    value: {
      en: '36k',
      fr: `36 000`,
      ar: '36 ألف'
    },
    title: {
      en: 'Beneficiaries served',
      fr: `BÉNÉFICIAIRES SERVIS`,
      ar: 'مستفيد تم خدمتهم'
    }
  },
  {
    img: { src: foodPackIcon, alt: 'Food packs distributed' },
    value: {
      en: '245k',
      fr: `245 000`,
      ar: '245 ألف'
    },
    title: {
      en: 'Food packs distributed',
      fr: `PANIERS ALIMENTAIRES DISTRIBUÉS`,
      ar: 'حزمة غذائية تم توزيعها'
    }
  },
  {
    img: { src: helpIcon, alt: 'Beneficiaries served' },
    value: {
      en: '2.9 Million',
      fr: `2,9 millions`,
      ar: '2.9 مليون'
    },
    title: {
      en: 'Beneficiaries served',
      fr: `BÉNÉFICIAIRES SERVIS`,
      ar: 'مستفيد تم خدمتهم'
    }
  }
];
