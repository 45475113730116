import { useEffect, useState } from 'react';
import ProductBox from '../../../components/general/productBox';
import Layout from '../../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../../utils/seo.json';
import { palestineFaqs } from '../components/faqs';
import Accordion from '../../../components/general/accordion';
import { useKeenSlider } from 'keen-slider/react'
import CrisisWidget from '../components/crisisWidget';
import logos from '../../../images/landings/palestine/logos(1).png';

import FoodPackImg from '../../../images/landings/winter/foodPack.png';
import MedicalKitImg from '../../../images/landings/winter/medical-kits.png';
import FuelImg from '../../../images/landings/winter/fuel.png';
import MattressesImg from '../../../images/landings/winter/mattresses.png';
import MedicalReliefImg from '../../../images/landings/winter/medical-relief.png';
import HotMealsImg from '../../../images/landings/winter/hot-meals.png';
import BlanketsImg from '../../../images/landings/winter/blankets.png';
import MedicalBedsImg from '../../../images/landings/winter/medical-beds.png';
import VideosSection from '../../../components/general/videosSection';
import { getProducts } from '../../../store/products';
import { OurPromiseSection } from '../../../components/general';
import Palestinepreramadan1 from '../../../images/landings/palestine/pre-ramadan-palestine1.jpeg'
import Palestinepreramadan2 from '../../../images/landings/palestine/pre-ramadan-palestine2.jpeg'
import Palestinepreramadan3 from '../../../images/landings/palestine/pre-ramadan-palestine3.jpeg'
import Palestinepreramadan4 from '../../../images/landings/palestine/pre-ramadan-palestine5.jpeg'
import Palestinepreramadan5 from '../../../images/landings/palestine/pre-ramadan-palestine5.jpeg'
import ProductBoxNewDesign from '../../../components/general/multiSelectProductsBox';
import ZakatWidget from '../../zakat/zakatWidget';
import banner from '../../../images/landings/palestine/Palestine-mercy-wide-hero-banner.jpg'
import bannerMobile from '../../../images/landings/palestine/Palestine-mercy-mobile-hero-banner.jpg'
const images = [
    Palestinepreramadan1,
    Palestinepreramadan2,
    Palestinepreramadan3,
    Palestinepreramadan4,
    Palestinepreramadan5,
];
export const impactInfo = {
    blankets: '38,500',
    foodPacks: '10,577,400',
    fuel: '33,500 L',
    hotMeals: '557,700',
    mattresses: '3,450',
    medicalKits: '12,500',
    medicalAid: '28,183',
    total: '11,389,333',
    lastUpdate: '24th January 2024',
    hygieneKits: '138,100'
}
const impactNumbers = [
    {
        total: '11,389,333',
        lastUpdate: '24th January 2024'
    },
    {
        title: 'Medical Kits',
        amount: '12,500',
        image: MedicalKitImg
    },
    {
        title: 'Fuel',
        amount: '33,500 L',
        image: FuelImg
    },
    {
        title: 'Mattresses',
        amount: '3,450',
        image: MattressesImg
    },
    {
        title: 'Food Packs',
        amount: '10,577,400',
        image: FoodPackImg
    },
    {
        title: 'Medical Relief',
        amount: '28,183',
        image: MedicalReliefImg
    },
    {
        title: 'Hot Meals',
        amount: '557,700',
        image: HotMealsImg
    },
    {
        title: 'Blankets',
        amount: '38,500',
        image: BlanketsImg
    },
    {
        title: 'Medical Beds & Loading Stretchers',
        amount: '121,600',
        image: MedicalBedsImg
    }

];

export const videos = [
    {
        link: 'https://www.youtube.com/embed/Qjff-B-CPVk',
        title: '',
        date: '4 March, 2024'

    },
    {
        link: 'https://www.youtube.com/embed/Nub_2Fz1Vgo',
        title: '',
        date: '4 March, 2024'
    },
    {
        link: 'https://www.youtube.com/embed/IMTVH6dcsnY',
        title: '',
        date: '4 March, 2024'
    },
];

const PalestineUat = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [palestineProducts, setPalestineProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const products = useSelector((state) => getProducts(state, 'Crisis and Emergencies', 'Palestine'));
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
    const seo = SeoData[selectedcountryCode];
    const [currentSlideImpact, setCurrentSlideImpact] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [topProducts, setTopProducts] = useState([]);

    const [sliderRefImpact, instanceRefImpact] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImpact(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    /* -------------------------------------------------------------------------- */


    /* ------------------------------ Start methods ----------------------------- */

    const getAllProducts = () => {
        setIsLoaded(true);
        let tempCritical = [];
        let topProduct = [];
        let p = products?.subCategory?.products;
        console.log(p)
        p?.forEach(item => {

            if (item.creator === 'EMR-DIS-BFPL-2023-01-204') {
                item = Object.assign({}, item, {
                    alt: "Palestine Charity: Emergency Orphan Support",
                })
                tempCritical[0] = item; // Emergency Orphan Support
            }


            if (item.creator === 'EMR-DIS-MDPL-2023-01-102') {
                item = Object.assign({}, item, {
                    alt: "Donate to Palestine: Emergency Medical Kit",
                })
                tempCritical[1] = item; // Palestine Emergency Medical Kit
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-102') {
                item = Object.assign({}, item, {
                    alt: "Donate to Palestine: Essential Family Food Pack",
                })
                tempCritical[2] = item; // Palestine Essential Family Food Pack
            }
            if (item.creator === 'EMR-DIS-PSE-2023-01-01') {
                item = Object.assign({}, item, {
                    alt: "Donations to Palestine: Medical Relief",
                })
                tempCritical[3] = item; // Palestine Medical Relief
            }
            if (item.creator === 'EMR-DIS-AA-2024-208') {
                item = Object.assign({}, item, {
                    alt: "Donations to Palestine: Sponsor a Palestinian Family",
                })
                tempCritical[4] = item; // Donations to Palestine: Sponsor a Palestinian Family
            }
            if (item.creator === 'EMR-DIS-AA-2024-01-207') {
                item = Object.assign({}, item, {
                    alt: "Palestine Gaza Donate: Palestinian Refugee Settlement",
                })
                tempCritical[5] = item; // Palestinian Refugee Settlement
            }
            if (item.creator === 'EMR-DIS-BFPL-2024-12-208') {
                item = Object.assign({}, item, {
                    alt: "Palestine Gaza Donate: Sponsor A Palestianian Family of 12 for 1 year",
                })
                tempCritical[6] = item; // Sponsor A Palestinian Family Of 12 For 1 Year
            }
            if (item.creator === 'EMR-DIS-BFPL-2024-8-208') {
                item = Object.assign({}, item, {
                    alt: "Donate to Gaza Palestine: Sponsor a Plestinian Family of 8 for 1 year",
                })
                tempCritical[7] = item; // Sponsor A Palestinian Family Of 8 For 1 Year
            }
            if (item.creator === 'EMR-DIS-BFPL-2024-4-208') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[8] = item; // Palestine Medical Relief
            }
            // if (item.creator === 'EMR-DIS-PSE-2023-01' && item.name === 'Palestine Appeal') {
            if (item.creator === 'EMR-DIS-PSE-2023-01') {
                item = Object.assign({}, item, {
                    image_link: "/images/landings/crisis/palestine/palestineMostNeedNew.jpeg",
                })
                topProduct.push(item) // Palestine Medical Relief
            }
            if (item.creator === 'EMR-DIS-BFPL-2024-01-207') {
                item = Object.assign({}, item, {
                    image_link: "/images/landings/crisis/palestine/refugeImage.jpeg",
                })
                topProduct.push(item) // Palestine Medical Relief
            }

        });
        setPalestineProducts(tempCritical);
        console.log(topProduct)
        setTopProducts(topProduct)
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // getProductsByName('palestine');
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='!bg-white' hideFooter={true}>
            {seo &&
                <Helmet>
                    <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
                    <meta name="title" content={`${seo['crisisAndEmergencies']['palestine']['title']}`} />
                    <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
                    {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
                </Helmet>
            }
            {/* <div className='hidden sm:flex justify-center md:mx-auto'><img src={Banner} alt="palestine-banner" /></div> */}
            <section className="flex justify-center" >
                <img src={banner} alt="Donate to Palestine" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="Donations to Palestine" className='min-[501px]:hidden w-full' />
            </section>
            {/* /* ------------------------------- Widget ----------------------------------- */}


            <section className='bg-[#DFEEF2]'>
                <section className='max-w-[1440px] md:mx-auto sm:px-0 px-4 '>
                    <div className='text-[#253B7E]   pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Donation</div>
                    <div className='w-full  hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <CrisisWidget className={' px-6 h-auto pt-10 bg-[#DFEEF2]'}
                            defaultProduct={{
                                creator: "EMR-DIS-PSE-2023-01",
                                label: "Palestine Appeal",
                                value: 255
                            }}
                        />
                    </div>
                    <div className='w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center'>
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                            creator: "EMR-DIS-PSE-2023-01",
                            label: "Palestine Appeal",
                            value: 255
                        }} />
                    </div>
                </section>
            </section>

            <OurPromiseSection />
            {/* /* -------------------------------------------------------------------------- */}

            <div className='max-w-[1440px] mx-auto'>
                <section className='xl:px-20 mb-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[300px]
                            md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                            right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px] w-full text-[30px] sm:text-[40px] px-8 text-white text-center font-bold'>Palestine Needs YOU Now.</div>
                            <p className='text-2xl  leading-8 px-10 text-white text-center font-brandingMedium'>
                                Let your Zakat or Sadaqah be their lifeline, and your entry to Jannah.
                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1020px]">
                            <iframe className='video pointer-events-none'
                                width={'100%'}
                                height={'100%'}
                                title={'youtube'}
                                frameBorder={0}
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://www.youtube.com/embed/8fCYKifjeg4?controls=0&autoplay=1&loop=1&mute=1&playlist=8fCYKifjeg4`}
                            >
                            </iframe>
                        </div>

                    </div>
                </section>

            </div>
            <div className="md:container md:mx-auto flex px-4 pb-5 flex-wrap ">
                <div className="w-full flex justify-start sm:justify-center">
                    <h1 className="text-3xl md:text-6xl text-[#253b7e] mb-4 font-bold">
                        Donate to Palestine: <br /> <span className='text-[#f60362]'>Palestinian Emergency</span>
                    </h1>
                </div>
                <p className='text-[#78716C] leading-[19px] sm:leading-[25px] font-brandingMedium text-[18px] pb-3'>
                    Since the start of the conflict on October 7, more than 30,000 Palestinians have lost their lives, including more than 12,000 children. Over 63,000 Palestinians have been injured and more than 1.9 million people have become internally displaced. Families have fled south where conditions are barely liveable. Urgent food, water and medical supplies are required to ensure their survival.
                    <br /> <br />
                    MATW is on the ground providing life-saving assistance for displaced families in Palestine and more recently, for Palestinian families seeking refuge in Egypt. We urgently need your support to help the people of Palestine survive, and to help refugee families start over.
                    <br /> <br />
                    Use your Zakat and Sadaqah to help us provide urgent medical support, food, clean water, shelter and family relocation assistance today.

                </p>
            </div>

            <section className='bg-[#E1EFF2] py-10'>

                <div className="md:container md:mx-auto flex flex-wrap">
                    {topProducts.map((item, index) => {
                        return (
                            <>
                                <div key={`top.products${index}`} className="hidden sm:block basis-full sm:basis-1/2  p-2">
                                    <ProductBoxNewDesign product={item} alt={item.alt} currency={selectedCurrency} />
                                </div>
                                <div key={`products.products${index}`} className="sm:hidden basis-full p-2">
                                    <ProductBox product={item} alt={item.alt} currency={selectedCurrency} />
                                </div>
                            </>
                        )
                    })
                    }
                </div>
            </section>

            <div className="md:container md:mx-auto flex flex-wrap mt-5">
                <div className="basis-full">
                    <div className="flex flex-row justify-center flex-wrap">
                        {palestineProducts.map((item, index) => {
                            return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                <ProductBox product={item} alt={item.alt} currency={selectedCurrency} />
                            </div>
                        })
                        }
                    </div>
                </div>
            </div>
            {/* /* --------------------------------- Muslim Arounds ------------------------- */}
            <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
                <h1 className="text-3xl md:text-5xl w-full text-[#253B7E] text-center font-medium mb-8 md:mb-16 lg:mb-18">
                    Muslims Around the World (MATW) on the ground in Palestine:
                </h1>
                <h2 className='text-[#282828] text-center  basis-full'>In Partnership with</h2>
                <div className="basis-full md:basis-1/2 lg:basis-1/2 flex flex-wrap">
                    <div className="flex justify-center items-center">
                        <img src={logos} alt="sh logo" className='max-h-[100px]' />
                    </div>
                    {/* <div className="basis-1/3 flex justify-center items-center">
                                    <img src={unrwaImg} alt="unrwa logo" className='max-h-[120px]' />
                                </div>
                                <div className="basis-1/3 flex justify-center items-center">
                                    <img src={eycImg} alt="eyc logo" className='max-h-[140px]' />
                                </div> */}
                </div>
                <p className="text-[#78716C] text-[16px] leading-[19px] sm:leading-[28px] sm:text-[20px] font-brandingMedium   mt-8 md:mt-12">
                    With a long-standing presence in Palestine and supplies on standby for an immediate response, our
                    MATW team has been quick to deliver urgent essentials to those directly affected by the conflict
                    in Palestine. Our direct response efforts are focused on those who are most vulnerable including
                    orphans, widows, the elderly and families.
                    <br />
                    <br />
                    Our operations team is delivering emergency medical kits, urgent food packs, fuel, hot meals
                    and more to those who have been displaced.

                    <br />
                    <br />
                    Our medical response within hospitals proved to be a critical lifeline. Thanks to donors, our team
                    was able to deliver stretchers, beds, mattresses, blankets, medical kits and supplies. We also were
                    able to provide staff support and fuel to ensure medical make-shift camps and operational hospitals
                    continue to run.

                    <br />
                    <br />
                    Our team has now partnered with the Egyptian Youth Council, Egyptian Red Crescent and The
                    Jordanian Hashemite Charity Organisation (JHCO) to ensure relief is reaching those in need in Palestine.
                    <br /> <br />
                    More recently, we are focused on providing urgent essentials and family relocation support to
                    Palestinian families seeking refuge in Egypt. Through these projects, donors will be able to
                    help us provide family sponsorship and settlement support to ensure a new start for families
                    with all that they need.
                    <br />
                    <br />

                    We are determined as ever to never give up. Every life is valuable and your support allows us to continue
                    doing the work that we do.

                    <br />
                    <br />
                    With full transparency to our donors, every single donation to our Palestine Emergency Appeal will go
                    directly to the field, with a 100% donation policy.
                    <br /><br />
                    Donations to our Palestine Emergency Appeal are also 100% Zakat applicable.
                </p>
                <div className='basis-full flex justify-center py-4 md:py-8'>
                    <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-md'
                        onClick={() => window.scroll({ top: 500, left: 0, behavior: 'smooth' })}>
                        DONATE NOW
                    </button>
                </div>
            </div>

            {/* /* -------------------------------------------------------------------------- */}
            <section className='bg-[#F8F8F8] mt-4 sm:pt-5'>



                {/* ----------------- videos section ---------------- */}

                <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />

                {/* ----------------- videos section --END-------------- */}

                {/* /* --------------------------------- Images --------------------------------- */}
                <section className=' mt-2 sm:mt-6 py-12'>
                    <div className="md:container md:mx-auto flex justify-center flex-wrap ">

                        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {

                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                        {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                        <img src={item} alt={`carousel_images${index}`} className=' bg-top object-cover w-full object-top' />
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="w-full  flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 ">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {/* /* -------------------------------------------------------------------------- */}





            </section>
            {/* <div className="w-full mt-5 bg-[#00a3da]">
                <div className="md:container md:mx-auto mt-5 flex flex-wrap text-white pb-8 md:pb-16">
                    <div className='basis-full mt-6 p-6 lg:p-12 text-center'>
                        <p className="text-center text-xl md:text-5xl">
                            The people of
                            <span className="font-['Gotcha'] font-medium text-[#f60362] md:text-5xl mx-2 md:mx-4">
                                Palestine
                            </span>
                            need your help
                        </p>
                        <p className="text-center text-base md:text-2xl mt-2">
                            UNREST IS UNFOLDING IN PALESTINE
                        </p>
                    </div>
                    <div className='basis-full md:basis-1/2 px-4 md:px-6 mt-3'>
                        <p>
                            Devastating violence and unrest is unfolding in Palestine creating upheaval, leaving families in a state of uncertainty and fear.
                            Our MATW team is ready to respond to the crisis and are preparing to provide live saving assistance to what could be the biggest conflict in years.
                            Use your Zakat and Sadaqah to help us provide urgent medical support, food, clean water and shelter.
                            <br />
                            <br />
                            Described as the worlds largest open air prison, decades of violence has crippled Palestine’s infrastructure.
                            Families are in a constant state of rebuilding and recovery.Our MATW team on the ground reports an escalating situation of danger.
                        </p>
                    </div>
                    <div className='basis-full md:basis-1/2 px-4 md:px-6 mt-3'>
                        <p>
                            Families are preparing to flee their homes to protect themselves from the violence to come.MATW has been working on the ground in
                            Palestine delivering urgent support during emergencies, providing care for orphans through our care program and more recently,
                            rebuilding homes through our project build initiative.
                            <br />
                            <br />
                            We are dedicated to delivering urgent assistance in the most difficult to reach areas to those most in need.Every second counts.
                            Your donation could be the difference between life and death.Stand for the people of Palestine in their hour of greatest need.
                        </p>
                    </div>
                    <div className='basis-full flex justify-center py-4 md:py-8'>
                        <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-full' onClick={() => window.scroll(0, 0)}>
                            DONATE NOW
                        </button>
                    </div>
                </div>
            </div> */}

            {/* <div className="md:container md:mx-auto mt-5 flex flex-wrap">
                <div className="basis-full rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                    <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                        <iframe className='video'
                            width={'100%'}
                            height={'100%'}
                            title={'youtube'}
                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                            src="https://youtube.com/embed/9kvOCK3HKMc?autoplay=0"
                        />
                    </div>
                </div>
            </div> */}

            {/* /* --------------------------Impact Numbers------------------------------------------------ */}

            <div className="md:container md:mx-auto  mt-12 px-4 md:px-0">
                <h2 className="basis-full mb-3 md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-6xl">
                    MATW's <span className='text-[#F60362]'>IMPACT</span>
                </h2>
                <div className=' flex flex-col  items-center text-center text-white font-brandingMedium'>
                    <div className='bg-[#F60362] w-[348px] text-[26px] sm:w-[675px] my-6  h-[80] rounded-full sm:text-[50px]'>
                        TOTAL IMPACT: <span className='font-bold'>{impactNumbers[0].total}</span>
                    </div>
                    {/* <div className='text-[#78716C] hidden sm:block text-[25px]'>{impactNumbers[0].lastUpdate}</div> */}
                </div>

                <div className='hidden sm:flex gap-4 mt-6 mb-16 justify-center flex-wrap'>
                    {impactNumbers.map((item, index) => (

                        index > 0 && <div key={index} className='relative flex justify-center  p-3'>
                            <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                {item.amount}
                            </div>
                            <div className='flex flex-col justify-around items-center min-h-[365px] w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                <div><img src={item.image} alt={item.title} className='w-[130px]' /></div>
                                <div className='text-[#253B7E] text-[24px] font-bold mt-24 px-3 leading-5 text-center'> {item.title}</div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* -------------Impact numbers slider for mobile----------- */}


                <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                    <div ref={sliderRefImpact} className="keen-slider">
                        {impactNumbers.map((item, index) => {
                            return index > 0 && <div key={`carousel_impact${index}`} className={`keen-slider__slide basis-full px-4 py-2 flex justify-center`}>
                                <div className='relative flex justify-center  p-3'>
                                    <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                        {item.amount}
                                    </div>
                                    <div className='flex flex-col justify-around items-center h-[365px] min-w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                        <div><img src={item.image} alt={item.title} className='w-[130px]' /></div>
                                        <div className='text-[#253B7E] text-[24px] font-bold mt-20 leading-5 text-center'> {item.title}</div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {loaded && instanceRefImpact.current && (
                        <>
                            <Arrow
                                left
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.prev()
                                }
                                disabled={currentSlideImpact === 0}
                            />

                            <Arrow
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.next()
                                }
                                disabled={
                                    currentSlideImpact ===
                                    instanceRefImpact.current.track.details.slides.length - 1
                                }
                            />
                        </>
                    )}
                </div>
                <div className="w-full sm:hidden flex justify-center">
                    {loaded && instanceRefImpact.current && (
                        <div className="dots flex pt-3 pb-6">
                            {[
                                ...Array(instanceRefImpact.current.track.details.slides.length).keys(),
                            ].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRefImpact.current?.moveToIdx(idx)
                                        }}
                                        className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImpact === idx ? " active bg-[#F60362]" : " bg-gray-300")}
                                    ></button>
                                )
                            })}
                        </div>
                    )}
                </div>

            </div>





            {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
            {/* <div className='w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3]- py-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center  mb-4 text-[#253B7E]">Palestine donations <strong className='text-[#f60362]'>FAQs</strong></h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-2 md:px-0 mt-6">
                    {palestineFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordion>
                            </div>
                        );
                    })}
                </div>
            </div> */}
            <section className='max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10'>
                <h2 className='text-[#253B7E] text-left  sm:text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                    Donate to Palestine: <span className='text-[#F60362]'> FAQ’s</span>
                </h2>
                <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[0].question}</h1>
                        <p className='text-base font-brandingMedium leading-4 mt-3'>
                            {palestineFaqs[0].answer}
                        </p>
                    </div>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[1].question}</h1>
                        <p className='text-base font-brandingMedium leading-4 mt-3'>
                            {palestineFaqs[1].answer}
                        </p>
                    </div>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[2].question}</h1>
                        <p className='text-base font-brandingMedium leading-4 mt-3'>
                            {palestineFaqs[2].answer}
                        </p>
                    </div>
                </div>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                    {palestineFaqs.map((item, index) => {
                        return index > 2 && (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordion>
                            </div>
                        );
                    })}
                </div>
            </section>
            {/* /* -------------------------------------------------------------------------- */}

        </Layout>
    );
}
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <div
            className={`${props.bg ? props.bg : 'bg-[#F60362]'} w-[30px] h-[60px] absolute top-[calc(50%-60px)] translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.left ? "arrow--left left-[0px]" : "arrow--right left-auto right-[0px]"}`}
            onClick={props.onClick}
        >
            <svg
                className={`w-[24px] h-[24px] fill-white cursor-pointer arrow ${props.left ? "arrow--left" : "arrow--right"
                    } ${disabeld}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                {props.left && (
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                )}
                {!props.left && (
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                )}
            </svg>
        </div>
    )
}
export default PalestineUat;