import React, { useState } from 'react';
import Card from '../../components/card';
import Products from './products';
import Categories from './categories';

const ProductsPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    'Products',
    'Categories',
  ]
  /* -------------------------------------------------------------------------- */
  return (
    <Card className={"w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6"}>
      <div className="flex flex-col w-full">
        <div className="flex w-full border-b border-stone-400 mb-4">
          {tabs.map((item, index) => {
            return <div
              key={`tabs${index}`}
              className={`flex justify-center items-center px-4 py-2 rounded-tl-md rounded-tr-md cursor-pointer ${activeTab === index ? 'bg-primaryDark text-white font-bold' : ' text-stone-600'}`}
              onClick={() => setActiveTab(index)}
            >
              {item}
            </div>
          })}
        </div>
        {activeTab === 0 && <Products />}
        {activeTab === 1 && <Categories />
        }
      </div>
    </Card>
  );
};
export default ProductsPage;
