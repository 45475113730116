
import CustomButton from "../../general/button"
import CustomModal from "../../general/modal"

const ConfirmModal = ({ closeModal, show, confirm }) => {
    return (
        <CustomModal
            show={show}
            onClose={() => closeModal()}
            className="md:w-1/3"
            title=""
        >
            <div className="flex flex-col w-full">
                <h3 className="text-[#78716C] font-bold text-center text-2xl my-3 md:my-5 w-full">
                    Are you sure to have another future payment?
                </h3>
                <div className="flex justify-center items-center flex-col md:flex-row w-full">
                    <CustomButton onClick={closeModal} title="Cancel" className="!bg-[#f60362] mb-2 md:mb-0 md:mr-2 min-w-[150px]" />
                    <CustomButton onClick={() => confirm()} title="Yes" className="!bg-[#00a3da] min-w-[150px]" />
                </div>
            </div>
        </CustomModal>
    )
}

export default ConfirmModal