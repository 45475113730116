const qurbanFaqs = [
  {
    id: 0,
    question: 'What is Qurban or Udhiyah?',
    answer:
      'Qurban, or Udhiyah is one of the most spiritual deeds performed during the days of Dhul Hijjah. The word Qurban or Udhiyah means sacrifice. It is the ultimate act of worship. Every year, during the month of Dhul Hijjah, millions of Muslims around the world commemorate Dhul Hijjah by fulfilling this important religious obligation through the sacrifice of animals including sheep, goats, cows and camels.',
  },
  {
    id: 1,
    question: 'Why do Muslims celebrate Eid Al Adha and perform Qurban?',
    answer:
      'The celebration of Eid Al-Adha commemorates the willingness of Prophet Ibrahim’s (as) to sacrifice his son Ismail (as) to prove his faith to Allah (swt). As he was about to perform the sacrifice, Allah (swt) in His infinite wisdom saved Ismail (as) and replaced him with a sheep. For us as Muslims, we celebrate Eid Al-Adha to commemorate the loyalty and devotion of Prophet Ibrahim (as) towards Allah (swt). In doing so, we perform Qurban which is the sacrifice of a sheep, goat, or cow of which its meat is often distributed to the poor.',
  },
  {
    id: 2,
    question: 'When is Eid Al-Adha 2024?',
    answer:
      'This year, Eid Al Adha is expected to fall on Sunday, June 16th and end on the evening of Thursday, June 20th. Eid Al Adha falls on the 10th day of Dhul Hijjah and lasts for three to four days.',
  },
  {
    id: 3,
    question: 'Who Should Give Qurban And What Are The Rules?',
    answer: 'The majority of scholars say that Qurbani is highly recommended and a stressed sunnah for every sane adult Muslim whose wealth exceeds their needs (above the nisaab level). The Hanafi madhab believes it’s compulsory if you fit this criteria.',
  },
  {
    id: 4,
    question: 'How Do I Calculate The Qurban?',
    answer: `Those who are eligible to pay Zakat can give Qurbani.
    <br />
    To calculate this properly, you can use the Zakat calculator here: <a class="text-blue-500" href="https://matwproject.org/zakat-calculator">https://matwproject.org/zakat-calculator</a>
    <br />
    You don’t need to give an entire animal for Qurban. You can donate a share of a large animal (such as a cow or camel) instead.
    <br />
    To calculate the amount of Qurban to give, use the following as a reference:
    <br />
    1 Sheep or Goat = 1 Qurban
    <br />
    1 Cow or Camel = 7 Qurbans (one Qurban is equivalent to 1/7th of a large animal)
    <br />
    It is permissible to donate a Qurban on behalf of a deceased relative or on behalf of someone else (for example your children) if you wish to.
    `,
  },
  {
    id: 5,
    question: 'When Should I Donate My Qurban?',
    answer: `To count as a proper Qurban, you must donate BEFORE the end of Eid-ul-Adha which lasts for 4 days.
    <br />

    <span class="italic font-bold mr-1">IMPORTANT</span>- The time for sacrifice ends at sunset on the fourth day or the 13th of Dhul Hijjah. Therefore, to ensure we can complete your Qurban on time, please donate before the 12th of Dhul Hijjah - by sunset on the third day of Eid.
    `,
  },
];
const qurbanArabicFaqs = [
  {
    id: 0,
    question: 'ما هو القربان أو الأضحية؟',
    answer:
      'القربان أو الأضحية هو أحد أعظم الأعمال الروحية التي تُقام خلال أيام ذي الحجة. كلمة "قربان" أو "أضحية" تعني التضحية. إنها الفعل الأعظم للعبادة. كل عام، خلال شهر ذي الحجة، يحتفل المسلمون حول العالم بذكرى ذي الحجة من خلال الوفاء بهذه الواجبات الدينية الهامة من خلال ذبح الحيوانات مثل الخراف والماعز والأبقار والجمال.',
  },
  {
    id: 1,
    question: 'لماذا يحتفل المسلمون بعيد الأضحى ويؤدون القربان؟',
    answer:
      'يحتفل المسلمون بعيد الأضحى ليذكروا استعداد النبي إبراهيم (عليه السلام) لأن يضحي ابنه إسماعيل (عليه السلام) ليثبت إيمانه بالله (سبحانه وتعالى). وفي لحظة الذبح، أنقذ الله (سبحانه وتعالى) إسماعيل (عليه السلام) واستبدله بخروف. نحن كمسلمين نحتفل بعيد الأضحى لنذكر ولاء وتفاني النبي إبراهيم (عليه السلام) تجاه الله (سبحانه وتعالى). وبهذا نؤدي القربان، الذي يتمثل في ذبح خروف أو ماعز أو بقرة وتوزيع لحمها على الفقراء.',
  },
  {
    id: 2,
    question: 'متى يكون عيد الأضحى في عام 2024؟',
    answer:
      'هذا العام، من المتوقع أن يكون عيد الأضحى يوم الأحد 16 يونيو وينتهي مساء يوم الخميس 20 يونيو. يأتي عيد الأضحى في اليوم العاشر من ذي الحجة ويستمر لمدة ثلاثة إلى أربعة أيام.',
  },
];
const qurbanFranceFaqs = [
  {
    id: 0,
    question: 'Qu’est-ce que le Qurban (ou Udhiyah) ?',
    answer:
      'Le Qurbane, également appelé Udhiyah, Ghorban ou Tabaski, est l’un des actes les plus spirituels accomplis au cours du mois de Dhou al-hijja. Ce mot signifie « sacrifice », l’action de grâce ultime. Chaque année, pendant Dhou al-hijja, des millions de musulmans à travers le monde célèbrent ce mois sacré en se soumettant à cette importante obligation religieuse par le sacrifice d’animaux tels que des moutons, des chèvres, des vaches et des chameaux.',
  },
  {
    id: 1,
    question: 'Pourquoi les musulmans fêtent-ils l’Aïd al-Adha et accomplissent-ils le Qurbane?',
    answer:
      'La fête de l’Aïd al-Adha a lieu en l’honneur du prophète Ibrahim (Paix soit sur lui), qui était prêt à sacrifier son fils Ismaël (Paix soit sur lui) pour prouver sa foi à Allah (Glorifié et Exalté soit-Il). Allah (Glorifié et Exalté soit-Il), dans Sa sagesse infinie, a sauvé Ismaël (Paix soit sur lui) avant que le sacrifice ne soit accompli, le remplaçant par un mouton. En tant que musulmans, nous célébrons l’Aïd al-Adha pour commémorer la loyauté et la dévotion du prophète Ibrahim (Paix soit sur lui) envers Allah (Glorifié et Exalté soit-Il). Ainsi, nous nous livrons au Qurban à travers le sacrifice d’un mouton, d’une chèvre ou d’une vache dont la viande est ensuite généralement distribuée aux pauvres.',
  },
  {
    id: 2,
    question: 'Quand aura lieu l’Aïd al-Adha 2024 ?',
    answer:
      'Cette année, l’Aïd al-Adha devrait survenir le dimanche 16 juin et se terminer le jeudi 20 juin au soir. Débutant le dixième jour de Dhou al-hijja, les festivités se déroulent généralement sur trois à quatre jours.',
  },
];
const qurbaniFaqs = [
  {
    id: 0,
    question: 'What is Qurbani or Udhiyah?',
    answer:
      'Qurbani, or Udhiyah is one of the most spiritual deeds performed during the days of Dhul Hijjah. The word Qurbani or Udhiyah means sacrifice. It is the ultimate act of worship. Every year, during the month of Dhul Hijjah, millions of Muslims around the world commemorate Dhul Hijjah by fulfilling this important religious obligation through the sacrifice of animals including sheep, goats, cows and camels.',
  },
  {
    id: 1,
    question: 'Why do Muslims celebrate Eid Al Adha and perform Qurbani?',
    answer:
      'The celebration of Eid Al-Adha commemorates the willingness of Prophet Ibrahim’s (as) to sacrifice his son Ismail (as) to prove his faith to Allah (swt). As he was about to perform the sacrifice, Allah (swt) in His infinite wisdom saved Ismail (as) and replaced him with a sheep. For us as Muslims, we celebrate Eid Al-Adha to commemorate the loyalty and devotion of Prophet Ibrahim (as) towards Allah (swt). In doing so, we perform Qurbani which is the sacrifice of a sheep, goat, or cow of which its meat is often distributed to the poor.',
  },
  {
    id: 2,
    question: 'When is Eid Al-Adha 2024?',
    answer:
      'This year, Eid Al Adha is expected to fall on Sunday, June 16th and end on the evening of Thursday, June 20th. Eid Al Adha falls on the 10th day of Dhul Hijjah and lasts for three to four days.',
  },
];
const qurbaniFaqs2024 = [
  {
    id: 0,
    question: 'What is Qurbani or Udhiyah?',
    answer:
      'Qurbani, or Udhiyah is one of the most spiritual deeds performed during the days of Dhul Hijjah. The word Qurbani or Udhiyah means sacrifice. It is the ultimate act of worship. Every year, during the month of Dhul Hijjah, millions of Muslims around the world commemorate Dhul Hijjah by fulfilling this important religious obligation through the sacrifice of animals including sheep, goats, cows and camels.',
  },
  {
    id: 1,
    question: 'Why do Muslims celebrate Eid Al Adha and perform Qurbani?',
    answer:
      'The celebration of Eid Al-Adha commemorates the willingness of Prophet Ibrahim’s (as) to sacrifice his son Ismail (as) to prove his faith to Allah (swt). As he was about to perform the sacrifice, Allah (swt) in His infinite wisdom saved Ismail (as) and replaced him with a sheep. For us as Muslims, we celebrate Eid Al-Adha to commemorate the loyalty and devotion of Prophet Ibrahim (as) towards Allah (swt). In doing so, we perform Qurbani which is the sacrifice of a sheep, goat, or cow of which its meat is often distributed to the poor.',
  },
  {
    id: 2,
    question: 'When is Eid Al-Adha 2024?',
    answer:
      'This year, Eid Al Adha is expected to fall on Sunday, June 16th and end on the evening of Thursday, June 20th. Eid Al Adha falls on the 10th day of Dhul Hijjah and lasts for three to four days.',
  },
];
const dayOfArafatFaqs = [
  {
    id: 0,
    question: 'When Is the Day of Arafat?',
    answer:
      'The day of Arafat falls on the ninth day of Dhul Hijjah, the 12th and final month in the Islamic calendar year. Muslims are encouraged to fast on this day as it expiates sins. It occurs on the second day of the Hajj pilgrimage to Makkah.',
  },
  {
    id: 1,
    question: 'Can I still observe the day of Arafat if I am not a Hajj pilgrim?',
    answer:
      'Yes! All Muslims all over the world whether they’re pilgrims of Hajj or not, observe the day of Arafat. Those not performing Hajj observe by fasting and increasing their acts of worship (prayer, duas, charity).',
  },
  {
    id: 2,
    question: 'What are some of the special virtues of the Day of Arafat?',
    answer: `1.  It is the day Allah SWT perfected our faith, Islam.
    2.  It is the day Allah SWT bestowed the completion of His divine favor upon the Muslims, and for humanity.
    3.  It is the day Allah SWT said He chose and was pleased with Islam as the religion of the believers until the day of judgement.
    4.  It is the day Allah SWT revealed the three virtues of Islam to Prophet Muhammad PBUH, as part of the Quran (Surat Al-Maidah, 5:3).
    5.  It is the day of the Prophet Muhammad PBUH farewell Pilgrimage and to all the believers which took place on a Friday, the day of Jummah.`,
  },
];
const eidAlAdhaFaqs = [
  {
    id: 0,
    question: 'When is Eid Al Adha 2024?',
    answer:
      'Eid Al Adha occurs on the 10th day of Dhul Hijjah, which commemorates the last month of the Islamic calendar year. In 2024, it is expected to fall on the 16th of June, 2024.',
  },
  {
    id: 1,
    question: 'What do Muslims do on Eid Al Adha?',
    answer: `There are many things Muslims do to commemorate Eid including:
     - Eid prayer
     - Wearing new or your best clothes (A Sunnah of the Prophet PBUH!)
     - Sacrificing and sharing Qurban with family and friends if not with those less fortunate.
     - Cut hair and nails (after their Qurban is performed)
     - Gather with family and friends.
     - Share gifts and glad tidings
     - Engage in acts of Ibadah and make dua.
`,
  },
  {
    id: 2,
    question: 'Do MATW implement Eid projects?',
    answer: `Yes! Our incredible team works tirelessly during Eid to ensure the joy is spread among those in need, so that families can celebrate just as we do.
    We deliver Eid meals so families in need can engage in festivities. We also deliver Eid gifts for orphaned children.
    During Eid Al Adha, we deliver your Qurban and serve in impoverished communities, the same as Eid Al Fitr.
  `,
  },
];
export { qurbanFaqs, qurbaniFaqs, qurbaniFaqs2024, dayOfArafatFaqs, qurbanArabicFaqs, qurbanFranceFaqs, eidAlAdhaFaqs };
