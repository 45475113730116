import { createSlice } from '@reduxjs/toolkit';

const products = createSlice({
    name: 'products',
    initialState: {
        allProducts: [],
        lastOrders: []
    },
    reducers: {
        setProducts(state, action) {
            state.allProducts = action.payload;
        },
        setLastOrders(state, action) {
            state.lastOrders = action.payload;
        },
    }
})
export const { setProducts, setLastOrders } = products.actions;

export const selectProducts = (state) => state.products.allProducts;
export const selectLastOrders = (state) => state.products.lastOrders;
export const getProducts = (state, category,subCategory) => {

    let { products:{allProducts} } = state;
    if(allProducts.length > 0){
        // console.log(allProducts, category,subCategory);

        let cat = allProducts.filter(i=>(i.name).toLowerCase() === category.toLowerCase());
        if(cat.length > 0 && subCategory){
            // console.log(cat);
            let subCat = cat[0]?.sub_categories.filter(i=>(i.name).toLowerCase() === subCategory.toLowerCase());
            // console.log(subCat);
            const res= {...cat[0]};
            // console.log(res)
            delete res.sub_categories ;
            let obj =  {...res} ;
            if(subCat.length > 0) obj[`subCategory`] = subCat[0];
            return obj;

        }
        if(cat.length > 0 ){
            return cat[0]
        }
        return cat ;
    }
}

export default products.reducer;

