import React from 'react'
import CustomAccordion from '../../../../components/general/customAccordion';
import { palestineFaqs, newPalestineFaqsArabic, palestineFaqsFrench } from '../faqs';

const FAQSection = ({ arabic, lang = 'en' }) => {
  return (
    <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
    <div className="md:container md:mx-auto flex flex-col items-center justify-center md:px-0 mt-6">
      <CustomAccordion>
        <CustomAccordion.Title tag="h2" className={arabic || lang === 'ar' ? '!font-[AlmariMedium] font-bold' : 'text-[#253B7E] md:text-center mb-5 md:mb-6 lg:mb-8 text-[30px] sm:text-[48px] font-montserratBold'}>
          {arabic || lang === 'ar'
            ? 'الأسئلة الشائعة حول تبرع لفلسطين: اكتشف كيف يغذي دعمك مبادراتنا الخيرية في فلسطين'
            : lang === 'fr'
              ? <><span className="text-[#F60362]">FAQ</span> sur le don de Palestine</>
              : <>Palestine Donations <span className="text-[#F60362]"> FAQ’s</span></>
          }
        </CustomAccordion.Title>
        <CustomAccordion.Header className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8">
          {arabic || lang === 'ar'
            ? newPalestineFaqsArabic &&
            newPalestineFaqsArabic.map(
              ({ question, answer }, index) =>
                index < 3 && (
                  <CustomAccordion.HeaderContent key={index}>
                    <CustomAccordion.HeaderQuestion className={'!font-[AlmariMedium] font-bold'}>{question}</CustomAccordion.HeaderQuestion>
                    <CustomAccordion.HeaderAnswer className={'!font-[AlmariMedium]'}>{answer}</CustomAccordion.HeaderAnswer>
                  </CustomAccordion.HeaderContent>
                ),
            )
            : lang === 'fr'
              ? palestineFaqsFrench && palestineFaqsFrench.map(
                ({ question, answer }, index) =>
                  index < 3 && (
                    <CustomAccordion.HeaderContent key={index}>
                      <CustomAccordion.HeaderQuestion>{question}</CustomAccordion.HeaderQuestion>
                      <CustomAccordion.HeaderAnswer>{answer}</CustomAccordion.HeaderAnswer>
                    </CustomAccordion.HeaderContent>
                  ),
              )
              : palestineFaqs &&
              palestineFaqs.map(
                ({ question, answer }, index) =>
                  index < 3 && (
                    <CustomAccordion.HeaderContent key={index}>
                      <CustomAccordion.HeaderQuestion className="text-[#253B7E] font-medium text-xl leading-7">{question}</CustomAccordion.HeaderQuestion>
                      <CustomAccordion.HeaderAnswer>{answer}</CustomAccordion.HeaderAnswer>
                    </CustomAccordion.HeaderContent>
                  ),
              )
          }
        </CustomAccordion.Header>
        <div className={`mt-6 ${arabic || lang === 'ar' ? '[&_*]:!font-[AlmariMedium]' : ''}`}>
          <CustomAccordion.Accordion className={arabic || lang === 'ar' ? '!font-[AlmariMedium]' : ''} items={arabic || lang === 'ar' ? newPalestineFaqsArabic.filter((_, index) => index > 2) : lang === 'fr' ? palestineFaqsFrench.filter((_, index) => index > 2) : palestineFaqs.filter((_, index) => index > 2)} />
        </div>
      </CustomAccordion>
    </div>
  </section>
  
  
  )
}

export default FAQSection;