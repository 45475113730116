import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import ShowCurrencies from "../../utils/showCurrencies";
import ZakatSelectCurrency from "../zakat/zakatSelectCurrency";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { selectProducts } from "../../store/products";

const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const OneBillionWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const { defaultProduct, defaultSubscription, hasSuggestPrice, className } = props;
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const products = useSelector((state) => selectProducts(state));
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [addProduct] = useHandleAddProduct();
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedRecurringMode, setSelectedRecurringMode] = useState('subscription');
    const [selectedSubscription, setSelectedSubscription] = useState(defaultSubscription);
    const [selectedPrice, setSelectedPrice] = useState({ label: 'Monthly', value: 'month', USD: 30, AUD: 23, GBP: 24, CAD: 41, EUR: 28, SGD: 41, MYR: 27, AED: 0, IDR: 0 });
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
    const suggestedPrices = [
        { label: 'Weekly', value: 'week', USD: 7, AUD: 6, GBP: 6, CAD: 9, EUR: 6, SGD: 10, MYR: 7, AED: 26, IDR: 107600 },
        { label: 'Monthly', value: 'month', USD: 30, AUD: 23, GBP: 24, CAD: 41, EUR: 28, SGD: 41, MYR: 27, AED: 110, IDR: 461150 },
        { label: 'Yearly', value: 'year', USD: 365, AUD: 281, GBP: 288, CAD: 493, EUR: 336, SGD: 496, MYR: 339, AED: 1340, IDR: 5610652 },
    ]
    const suggestedRecurringModes = [
        { lable: 'Recurring', value: 'subscription' },
        { lable: 'Give once', value: 'one-off' }
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempMakeDonationProducts = [];
        let tempProductsList = [];
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === defaultProduct.creator) {
                        setSelectedProduct(inner_item_product)
                    }
                    if (inner_item.name === 'Food Aid') {
                        if (inner_item_product.creator === 'FWD-ODM-001') { // 1 Billion Legacy – Food Aid
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                    };
                    if (inner_item.name === 'Make A Donation') {
                        tempMakeDonationProducts.push(inner_item_product);
                        tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                    }
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
        setProductsList(tempProductsList);
        setAllProducts(tempMakeDonationProducts)
    };
    const handleCurrency = (currency) => {
        setCurrentCurrency(currency.value);
        // !Number(productAmount) && setSelectedPrice(handlePriceByCurrency(0, currency.value));
        // selectedCurrency(currency);
    }
    const handleProduct = (value) => {
        // console.log("product", value, selectedProduct);
        allProducts.map(item => {
            if (item.id === value.value) {
                setSelectedProduct(item);
            }
            return item;
        })
    }
    const handlePriceByCurrency = (index, currency) => {
        if (currency === 'AUD') {
            return Number(suggestedPrices[index][currency]) * 2
        } else if (currency === 'MYR') {
            return Number(suggestedPrices[index][currency]) * 5
        } else {
            return suggestedPrices[index][currency]
        }
    }
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice[currentCurrency]) ? Number(selectedPrice[currentCurrency]) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedSubscription,
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`max-w-[400px] bg-[#E2EFF3] rounded-lg p-3 md:p-5 flex items-center ${className}`}>
            <div className="w-full grid grid-cols-12 gap-4">
                <div className="col-span-12">
                    <ReactSelect
                        options={productsList}
                        className='max-h-[48px]'
                        defaultValue={selectedProduct}
                        isSearchable={false}
                        styles={styles}
                        onChange={(e) => handleProduct(e)}
                    />
                </div>
                <div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                    {suggestedRecurringModes.map((mode, index) => {
                        return <div key={`modes${index}`} className="col-span-3" style={styles.shadow}>
                            <div className={`h-12 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedRecurringMode === mode.value ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedRecurringMode(mode.value);
                                    if (mode.value === 'one-off') {
                                        setSelectedSubscription('one-off')
                                    } else {
                                        setSelectedSubscription('week')
                                    }
                                    setProductAmount('');
                                    console.log("selected price", handlePriceByCurrency(0, currentCurrency), productAmount)
                                    // setSelectedPrice(handlePriceByCurrency(0, currentCurrency));
                                    // setSelectedPrice(null);
                                }}
                            >
                                {mode.lable}
                            </div>
                        </div>
                    })}
                </div>
                {hasSuggestPrice && <div className="col-span-12 grid grid-cols-6 gap-2 h-14-">
                    {suggestedPrices.map((item, index) => {
                        return <div key={`prices${index}`} className="col-span-3 sm:col-span-2" style={styles.shadow}>
                            <div
                                className={`h-14 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex flex-col justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedPrice && selectedPrice.value === item.value ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedPrice(item);
                                    selectedRecurringMode === 'one-off'
                                        ? setSelectedSubscription('one-off')
                                        : setSelectedSubscription(item.value)
                                    setProductAmount('');
                                }}
                            >
                                {selectedRecurringMode === 'subscription' && <span className="text-sm">{item.label}</span>}
                                <div className={`flex items-center ${currentCurrency === 'IDR' ? 'text-base' : ''}`}>{ShowCurrencies(currentCurrency, handlePriceByCurrency(index, currentCurrency), true)}</div>
                            </div>
                        </div>
                    })}
                </div>}
                <div className="col-span-12 flex flex-wrap">
                    <div className="flex justify-center items-center mr-2 bg-white text-[#F60362] rounded-md w-12 h-12" style={styles.shadow}>
                        {currentCurrency}
                    </div>
                    <div className="flex justify-center items-center mr-2 bg-white text-[#F60362] rounded-md w-12 h-12" style={styles.shadow}>
                        <ZakatSelectCurrency onChangeCurrency={(e) => handleCurrency(e)} />
                    </div>
                    <div className="flex justify-center items-center bg-white text-[#F60362] rounded-md w-[calc(100%_-_112px)] h-12 px-2" style={styles.shadow}>
                        <input
                            value={productAmount}
                            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                            type="number"
                            onChange={(e) => {
                                setProductAmount(e.target.value);
                                setSelectedPrice(0);
                                selectedRecurringMode === 'one-off' ? setSelectedSubscription('one-off') : setSelectedSubscription('month')
                            }}
                            placeholder="Enter Amount" />
                    </div>

                </div>
                <div className="col-span-12">
                    <button
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-[38px] px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={(e) => { handleAddNewProduct() }}
                    >Donate Now</button>
                </div>
                <div className="col-span-12 text-[#6C6B6A] text-center">
                    We accept all major cards and PayPal
                </div>
            </div>
        </div>
    );
}

export default OneBillionWidget;
